import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

type TButtonProps = {
  color?: 'grey' | 'white' | 'yellow' | 'darkGrey' | 'black';
  bordered?: boolean;
  disabled?: boolean;
  width?: number;
  testId: string;
  as?: 'a' | 'button' | 'div';
  href?: string;
  flex?: boolean;
  iconOnly?: boolean;
  fullWidth?: boolean;
  small?: boolean;
  reportPanel?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<React.PropsWithChildren<TButtonProps>> = ({
  children,
  disabled,
  bordered,
  color = 'yellow',
  width,
  fullWidth,
  small,
  type = 'button',
  className,
  testId,
  as = 'button',
  href,
  flex,
  reportPanel,
  iconOnly,
  ...props
}) => {
  const buttonStyles = useMemo(() => {
    if (!width) {
      return undefined;
    }
    return {
      width: `${width}px`,
    };
  }, [width]);
  return React.createElement(
    as,
    {
      ...props,
      className: classNames(
        styles.button,
        [styles[color]],
        {
          [styles.bordered]: bordered,
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
          [styles.small]: small,
          [styles.reportPanel]: reportPanel,
          [styles.flex]: flex,
          [styles.iconOnly]: iconOnly,
        },
        className,
      ),
      disabled: !!disabled,
      style: buttonStyles,
      type,
      href,
      'data-test-id': `button_${testId}`,
      'data-test-disabled': disabled,
    },
    children,
  );
};
