import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

const FULLSCREEN_CLASSNAME = 'fullscreen';

const useIsFullscreenShared = (): [boolean, (value: boolean) => void] => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    if (isFullscreen) {
      document.body.classList.add(FULLSCREEN_CLASSNAME);
    } else {
      document.body.classList.remove(FULLSCREEN_CLASSNAME);
    }
  }, [isFullscreen]);

  useEffect(() => {
    if (isFullscreen) {
      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsFullscreen(false);
        }
      };
      document.addEventListener('keydown', onEscape);
      return () => document.removeEventListener('keydown', onEscape);
    }
  }, [isFullscreen]);

  return [isFullscreen, setIsFullscreen];
};

export const IsFullscreen = createContainer(useIsFullscreenShared);

export const useIsFullscreen = () => IsFullscreen.useContainer();
