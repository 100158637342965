import { DS1R4Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { getGroupByFeature } from '../AllPurposeReport/dataFeatures/groupBy';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'Покупатели для Продавца';

export const ds1o4Config: TReportConfigType<DS1R4Response> = {
  title: NAME,
  backendKey: 'ds1r4',

  dataFeatures: [
    getGroupByFeature<DS1R4Response>({
      options: [
        {
          groupBy: 'buyerInn',
          label: 'ИНН',
          additionalColumns: [
            {
              key: 'buyerInn',
              title: 'ИНН покупателя',
              sort: sorter('buyerInn'),
            },
            {
              key: 'buyerName',
              title: 'Наименование покупателя',
              sort: sorter('buyerName'),
            },
          ],
        },
        {
          groupBy: 'buyerNetName',
          label: 'Сети',
          additionalColumns: [
            {
              key: 'buyerNetName',
              title: 'Название сети',
              sort: sorter('buyerNetName'),
            },
          ],
        },
      ],
      summarizeFields: ['buyingsCnt', 'sharePercent'],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS1R4Response>({
      defaultSort: 'buyingsCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'buyingsCnt',
          title: 'Объем покупок, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('buyingsCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.buyingsCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.buyingsCnt);
          },
        },
      ],
    }),
    getBarChartFeature<DS1R4Response>({
      nameKey: 'buyerName',
      valueKey: 'buyingsCnt',
      innKey: 'buyerInn',
      filename: NAME,
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
    getBarChartFeature<DS1R4Response>({
      nameKey: 'buyerName',
      valueKey: 'buyingsCnt',
      innKey: 'buyerInn',
      withPercents: true,
      accumulateBy: 'sharePercent',
      icon: 'barChartWithLine',
      filename: NAME,
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
    getTreeMapFeature<DS1R4Response>({
      topName: NAME,
      parentNameKey: 'buyerInn',
      childrenNameKey: 'buyerName',
      innKey: 'buyerInn',
      valueKey: 'buyingsCnt',
      filename: NAME,
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      required: true,
      single: true,
      commonDataKey: 'retailSellers',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'GTIN',
      icon: 'package',
      commonDataKey: 'gtins',
      mode: 'gtins',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['retailSellers'],

  isRequiredFieldsFilled: (config) =>
    Boolean(config.inn) || Boolean(config.netName),
};
