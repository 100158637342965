import { getSelection } from '../../../../../common/PageWithFilter/utils/getSelection';
import { TFilterCreator, TFilterPreparer } from '../reportConfigType';

const prepareTopFilterValue: TFilterPreparer = (filterState) => {
  const top = getSelection(filterState.top);
  return {
    top: top[0] ? Number(top[0]) : undefined,
  };
};

export const getTopFilter = <TDataItem>(): TFilterCreator<TDataItem> => {
  return () => {
    return {
      filter: {
        label: 'ТОП участников',
        icon: 'contact',
        key: 'top',
        initialValue: {},
        getTags: (value): string[] => {
          const values = getSelection(value);
          if (values.length) {
            return [`${values.length}`];
          }
          return [];
        },
        filter: {
          type: 'select',
          uniqueKey: 'top',
          getOptions: () =>
            Promise.resolve([
              { value: '10', label: 'ТОП-10' },
              { value: '30', label: 'ТОП-30' },
              { value: '50', label: 'ТОП-50' },
              { value: '100', label: 'ТОП-100' },
              { value: '200', label: 'ТОП-200' },
            ]),
          withSearch: false,
          canSelectAll: false,
          canSelectNothing: true,
          canSelectMany: false,
        },

        getQueryKeyPart: (filterState) => [filterState.top],
      },

      preparer: prepareTopFilterValue,

      descriptionPreparer(filterState) {
        const value = getSelection(filterState.top);
        if (!value.length) {
          return [];
        }
        const selected = value[0];
        if (selected) {
          return [`ТОП-${selected}`];
        }
        return [];
      },
    };
  };
};
