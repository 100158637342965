import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { TQueueItem, useReportQueue } from './useReportQueue';
import { useNavigate } from 'react-router-dom';
import { useIsFetching, useQuery } from '@tanstack/react-query';

import styles from './ReportQueue.module.scss';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import checkBox from 'react-useanimations/lib/checkBox';
import alertTriangle from 'react-useanimations/lib/alertTriangle';
import dayjs from 'dayjs';
import { useNow } from '../../shared/hooks/useNow';
import classNames from 'classnames';
import { useOutsideClickRef } from 'rooks';

const HistoryReportQueueItem: React.FC<TQueueItem> = ({
  title,
  url,
  loadedAt,
  meta = [],
}) => {
  const navigate = useNavigate();
  useNow();

  return (
    <div
      className={classNames(styles.item)}
      data-test-id={`report-queue-item-${title}`}
      data-test-url={url}
      onClick={() => {
        const pathname = window.location.pathname;
        const urlPathname = url.split('?')[0];
        if (pathname === urlPathname) {
          navigate('/loader');
        }
        setTimeout(() => {
          navigate(url, { replace: true });
        }, 20);
      }}
    >
      <span className={styles.icon}>
        <UseAnimations
          animation={checkBox}
          size={20}
          autoplay
          wrapperStyle={{ marginTop: -2, overflow: 'visible' }}
        />
      </span>
      <div className={styles.title}>
        <span className={styles.link}>{title}</span>
        <div className={styles.meta}>
          <span>{dayjs(loadedAt).format('HH:mm')}</span>
          {meta.length > 0 && ' • '}
          {meta.map((item, index) => (
            <React.Fragment key={item}>
              {index > 0 && ' • '}
              <span>{item}</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const ReportQueueItem: React.FC<
  TQueueItem & {
    markAsHistory: (queryKey: string) => void;
  }
> = ({
  queryKey,
  title,
  url,
  queryFn,
  createdAt,
  markAsHistory,
  meta = [],
}) => {
  useNow();
  const navigate = useNavigate();
  const queryKeyObject = useMemo(() => {
    try {
      return JSON.parse(queryKey);
    } catch {
      return {};
    }
  }, [queryKey]);
  const queryData = useQuery(queryKeyObject, queryFn, {
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isError = queryData.isError && !queryData.isFetching;
  const isFetching = queryData.isFetching;
  const isSuccessful = queryData.isSuccess;
  const errorsCount = queryData.errorUpdateCount;

  useEffect(() => {
    if (isSuccessful) {
      markAsHistory(queryKey);
    }
  }, [isSuccessful, markAsHistory, queryKey]);

  let icon = checkBox;
  let iconKey = 'success';
  if (isError) {
    icon = alertTriangle;
    iconKey = 'error';
  }
  if (isFetching) {
    icon = loading;
    iconKey = 'loading';
  }

  return (
    <div
      className={classNames(styles.item)}
      data-test-id={`report-queue-item-${title}`}
      data-test-url={url}
      onClick={() => {
        if (isError) {
          queryData.refetch();
        } else {
          const pathname = window.location.pathname;
          const urlPathname = url.split('?')[0];
          if (pathname === urlPathname) {
            navigate('/loader');
          }
          setTimeout(() => {
            navigate(url, { replace: true });
          }, 20);
        }
      }}
    >
      <span key={iconKey} className={styles.icon}>
        <UseAnimations
          animation={icon}
          size={20}
          autoplay
          wrapperStyle={{ marginTop: -2, overflow: 'visible' }}
        />
      </span>
      <div className={styles.title}>
        <span className={styles.link}>{title}</span>
        <div className={styles.meta}>
          <span>{dayjs(createdAt).format('HH:mm')}</span>
          {errorsCount !== 0 && (
            <>
              {' • '}
              <span>попытка {errorsCount + 1}</span>
            </>
          )}
          {meta.length > 0 && ' • '}
          {meta.map((item, index) => (
            <React.Fragment key={item}>
              {index > 0 && ' • '}
              <span>{item}</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export const ReportQueue = () => {
  const { queue, markAsHistory } = useReportQueue();

  const [active, history] = useMemo(() => {
    const activeQueue = queue.filter((item) => !item.isHistory);
    const historyQueue = queue.filter((item) => item.isHistory);

    const activeSortedQueue = activeQueue.sort(
      (a, b) => a.createdAt - b.createdAt,
    );
    const historySortedQueue = historyQueue.sort(
      (a, b) => (b.loadedAt as number) - (a.loadedAt as number),
    );

    return [activeSortedQueue, historySortedQueue];
  }, [queue]);

  return (
    <div className={styles.container}>
      {active.map((item) => {
        return (
          <ReportQueueItem
            {...item}
            markAsHistory={markAsHistory}
            key={item.queryKey}
          />
        );
      })}
      {active.length > 0 && history.length > 0 && (
        <div className={styles.divider} />
      )}
      {history.map((item) => {
        return <HistoryReportQueueItem {...item} key={item.queryKey} />;
      })}
    </div>
  );
};

export const ReportQueueInHeader = () => {
  const { queue } = useReportQueue();
  const historyQueue = queue.filter((item) => item.isHistory);
  const [lastReadHistoryLength, setLastReadHistoryLength] = useState(
    historyQueue.length,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [containerRef] = useOutsideClickRef(() => {
    setIsOpen(false);
  });

  const [hasUnreadHistory, setHasUnreadHistory] = useState(false);

  useEffect(() => {
    if (historyQueue.length > lastReadHistoryLength) {
      setHasUnreadHistory(true);
    }
  }, [historyQueue.length, lastReadHistoryLength]);

  useEffect(() => {
    if (isOpen) {
      setHasUnreadHistory(false);
      setLastReadHistoryLength(historyQueue.length);
    }
  }, [isOpen, historyQueue.length]);

  const isReportsFetching = useIsFetching(['report']);
  let icon = checkBox;
  let iconKey = 'success';

  if (isReportsFetching) {
    icon = loading;
    iconKey = 'loading';
  }

  if (queue.length === 0) {
    return null;
  }

  return (
    <>
      <span
        key={iconKey}
        className={classNames(styles.icon, styles.headerButton, {
          [styles.active]: hasUnreadHistory,
        })}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <UseAnimations
          animation={icon}
          size={21}
          autoplay
          strokeColor={hasUnreadHistory ? '#fff' : '#000'}
          wrapperStyle={{
            marginTop: -7,
            marginLeft: -1,
            overflow: 'visible',
            pointerEvents: 'none',
          }}
        />
      </span>
      {ReactDOM.createPortal(
        <div
          className={classNames(styles.headerContainer, {
            [styles.hidden]: !isOpen,
          })}
          ref={containerRef}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <ReportQueue />
        </div>,
        document.body,
      )}
    </>
  );
};
