import React from 'react';
import { createPortal } from 'react-dom';
import { useOutsideClickRef } from 'rooks';
import classNames from 'classnames';

import styles from './Modal.module.scss';

const MODAL_CLASSNAME = 'modal_woandoaiwdnoabeifyrwefiod';

type TModalProps = {
  onClose?: () => void;
  isOpened: boolean;
  testId: string;
  className?: string;
};

export const Modal = ({
  children,
  isOpened,
  onClose,
  className,
  testId,
}: React.PropsWithChildren<TModalProps>) => {
  const [modalRef] = useOutsideClickRef((e) => {
    // @ts-ignore
    if (!e.target.closest(`.${MODAL_CLASSNAME}`) && onClose) {
      onClose();
    }
  });
  if (!isOpened) {
    return null;
  }
  return createPortal(
    <div className={styles.wrapper} data-test-id={`${testId}_modal`}>
      <div className={classNames(styles.modal, MODAL_CLASSNAME)} ref={modalRef}>
        {!!onClose && (
          <div
            className={styles.close}
            data-test-id="modal-close"
            onClick={onClose}
          />
        )}
        <div className={className} data-test-id="modal-content">
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
};

Modal.Title = ({
  children,
  gap = false,
  sub = false,
  left = false,
}: React.PropsWithChildren<{
  left?: boolean;
  gap?: boolean;
  sub?: boolean;
}>) => (
  <div
    data-test-id="modal-title"
    className={classNames(styles.title, {
      [styles.gap]: gap,
      [styles.sub]: sub,
      [styles.left]: left,
    })}
  >
    {children}
  </div>
);

Modal.Body = ({
  children,
  center,
}: React.PropsWithChildren<{
  center?: boolean;
}>) => (
  <div
    data-test-id="modal-body"
    className={classNames(styles.body, {
      [styles.center]: center,
    })}
  >
    {children}
  </div>
);

Modal.Footer = ({ children }: React.PropsWithChildren<{}>) => (
  <div data-test-id="modal-footer" className={styles.footer}>
    {children}
  </div>
);
