import React from 'react';

export type TModalInfoProps = {
  innInfo: { name: string; inn: string };
  reportInfo: { name: string };
};

export const ModalInfo: React.FC<TModalInfoProps> = ({
  innInfo,
  reportInfo,
}) => {
  return (
    <>
      <div>
        <span>ИНН: </span>
        <span>
          {innInfo?.name} ({innInfo?.inn})
        </span>
      </div>
      <div>
        <span>Отчет: </span>
        <span>{reportInfo?.name}</span>
      </div>
    </>
  );
};
