import {
  TDictionariesData,
  getDictionaryOptions,
} from '../../../../../../shared/hooks/useDictionariesData';
import { TDatasets } from '../../../../../common/PageWithFilter/filter';
import { ICONS } from '../../../../../common/PageWithFilter/PageWithFilter';
import { getSelection } from '../../../../../common/PageWithFilter/utils/getSelection';
import { TFilterCreator, TFilterPreparer } from '../reportConfigType';
import {
  TCommonData,
  useCommonData,
} from '../../../../../../api/reports/useCommonData';
import {
  SELECT_MODES,
  TMode,
  TModeKeys,
  TModeKeysWithoutMultipleNetworkNameKey,
} from './selectTypes';
import { getDeclarator } from '../../../../../../shared/utils/declOfNum';

const prepareSelectFilterValue =
  (mode: TMode, single: boolean): TFilterPreparer =>
  (filterState) => {
    const values = filterState[mode] || {};
    let selection: string | string[] = getSelection(values);
    if (single) {
      selection = selection[0];
    }

    const modeConfig = SELECT_MODES[mode];

    if (modeConfig) {
      const isInverted = values.isInverted;

      const isDefaultValue =
        !values.datasetKey ||
        modeConfig.defaultDatasetKey === values.datasetKey;
      const isNetwork =
        values.datasetKey &&
        modeConfig.networkDatasetKey &&
        values.datasetKey === modeConfig.networkDatasetKey;
      const isDictionary = !isNetwork && !isDefaultValue;

      const keys: TModeKeys = isInverted ? modeConfig.inverted : modeConfig;
      const normalizedKeys: TModeKeysWithoutMultipleNetworkNameKey = {
        valueKey: single ? keys.singleValueKey : keys.multipleValueKey,
        dictionaryNameKey: single
          ? keys.dictionaryNameKey
          : keys.dictionaryNamesKey,
        networkNameKey: single
          ? keys.networkNameKey
          : keys.multipleNetworkNameKey,
      };

      let keyForUsedDataset = normalizedKeys.valueKey;
      if (isDictionary) {
        keyForUsedDataset = normalizedKeys.dictionaryNameKey;
      }
      if (isNetwork && normalizedKeys.networkNameKey) {
        keyForUsedDataset = normalizedKeys.networkNameKey;
      }

      const resultFilter = {
        [keyForUsedDataset]: selection,
      };

      if (isDictionary) {
        resultFilter[modeConfig.dictionaryKey] = values.datasetKey;
      }

      return resultFilter;
    }

    throw new Error(`Unknown mode: ${mode}`);
  };

type TSelectFilterConfig = {
  shouldUseNetwork: boolean;
  shouldUseDictionary: boolean;
  label?: string;
  commonDataKey?: keyof TCommonData;
  mode?: TMode;
  icon?: keyof typeof ICONS;
  required?: boolean;
  single?: boolean;
  singleSelectAllLabel?: string;
};

const NETWORK_KEY: Partial<Record<keyof TCommonData, keyof TCommonData>> = {
  retailSellers: 'retailSellerNetworks',
  buyers: 'buyerNetworks',
};

const LABELS: Record<TMode, string> = {
  inns: 'ИНН',
  gtins: 'GTIN',
  codes: 'Код',
  addresses: 'Адрес',
};

const VALUE_LABELS: Partial<Record<TMode, string>> = {
  addresses: 'ИНН',
};

const NAME_LABELS: Partial<Record<TMode, string>> = {
  addresses: 'Адрес',
};

const KEYS: Record<TMode, string> = {
  inns: 'inn',
  gtins: 'gtin',
  codes: 'code',
  addresses: 'innAddressHash',
};

const LABEL_KEYS: Record<TMode, string> = {
  inns: 'inn',
  gtins: 'gtin',
  codes: 'code',
  addresses: 'sellerInn',
};

const NAME_KEY: Record<TMode, string> = {
  inns: 'name',
  gtins: 'gtinName',
  codes: 'name',
  addresses: 'sellerAddr',
};

const DECLARATORS: Record<TMode, ReturnType<typeof getDeclarator>> = {
  inns: getDeclarator(['ИНН', 'ИНН', 'ИНН'], true, true),
  gtins: getDeclarator(['GTIN', 'GTIN', 'GTIN'], true, true),
  codes: getDeclarator(['регион', 'региона', 'регионов'], true, true),
  addresses: getDeclarator(['адрес', 'адреса', 'адресов'], true, true),
};

export const getSelectFilter = <TDataItem>(
  config: TSelectFilterConfig,
): TFilterCreator<TDataItem> => {
  const shouldUseDatasets =
    config.shouldUseDictionary || config.shouldUseNetwork;

  const commonDataKey = config.commonDataKey || 'retailSellers';
  const label = config.label || 'Продавцы';
  const mode = config.mode || 'inns';
  const icon = config.icon || 'contact';

  return (props) => {
    const commonData = useCommonData();
    const usedCommonData = commonData[commonDataKey] as unknown[];

    const hash = window.location.hash;
    const hashValues = hash
      .replace('#', '')
      .split('&')
      .map((item) => item.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as Record<
      string,
      string
    >;

    const modeValues = hashValues[mode] as string | undefined;
    const modeInvertedValues = hashValues[`!${mode}`] as string | undefined;
    const values = modeValues || modeInvertedValues;
    const isInverted = !!modeInvertedValues;
    const defaultValues = typeof values === 'string' ? values.split(',') : [];

    const dictKey = SELECT_MODES[mode]?.dictionaryDataKey;
    const dictionaries = dictKey
      ? (props[dictKey] as TDictionariesData)
      : props.innDictionaries;

    const hasDictionaries =
      dictionaries?.dictionaries &&
      Object.keys(dictionaries.dictionaries).length > 0;

    const datasets = shouldUseDatasets
      ? ([
          {
            label: LABELS[mode],
            value: KEYS[mode],
          },
          config.shouldUseNetwork && {
            label: 'Сеть',
            value: 'network',
          },
          ...(config.shouldUseDictionary && hasDictionaries
            ? Object.keys(dictionaries.dictionaries).map((guid) => ({
                label: dictionaries.dictionaries[guid],
                value: guid,
                hint: `Справочник "${dictionaries.dictionaries[guid]}"`,
              }))
            : []),
          ...(config.shouldUseDictionary && !hasDictionaries
            ? [
                {
                  label: 'Добавить справочник',
                  onClick: () => {
                    window.open(
                      `/dictionaries?dictType="${
                        mode === 'inns' ? 'inn' : 'gtin'
                      }"`,
                      '_blank',
                    );
                  },
                },
              ]
            : []),
        ].filter(Boolean) as TDatasets)
      : undefined;

    return {
      filter: {
        label: label,
        icon,
        key: mode,
        initialValue: defaultValues
          ? defaultValues.reduce((acc, value) => ({ ...acc, [value]: true }), {
              datasetKey: KEYS[mode],
              isInverted,
            })
          : {},
        getTags: (value): string[] => {
          if (!value) {
            return [];
          }
          const values = getSelection(value);
          if (values.length) {
            const isInn = !value.datasetKey || value.datasetKey === KEYS[mode];
            const isNetwork = value.datasetKey === 'network';
            let label = '';
            if (isInn) {
              label = LABELS[mode];
            } else if (isNetwork) {
              label = 'Сеть';
            } else {
              label = dictionaries?.dictionaries
                ? dictionaries.dictionaries[value.datasetKey]
                : '';
            }
            return [label, `${values.length}`];
          }
          return [];
        },
        initiallyOpen: !!config.required,
        isRequired: !!config.required,
        filter: {
          type: 'select',
          uniqueKey: `${props.reportKey}-select-${mode}`,
          datasets,
          getOptions: async (datasetKey, search) => {
            if (datasetKey === KEYS[mode] || !datasetKey) {
              return usedCommonData.map((item: any) => ({
                label: `${item[KEYS[mode]]} ${item[NAME_KEY[mode]]}`,
                value: item[KEYS[mode]],
                fields: item,
              }));
            }
            if (datasetKey === 'network') {
              const networkKey = NETWORK_KEY[commonDataKey];
              if (!networkKey) {
                return [];
              }
              return (commonData[networkKey] as string[]).map((item) => ({
                label: item,
                value: item,
              }));
            }
            if (
              dictionaries &&
              datasetKey &&
              datasetKey in dictionaries.dictionaries
            ) {
              return getDictionaryOptions(dictionaries, datasetKey);
            }
            return [];
          },
          optionFields: [
            {
              key: LABEL_KEYS[mode],
              label: VALUE_LABELS[mode] || LABELS[mode],
            },
            {
              key: NAME_KEY[mode],
              label: NAME_LABELS[mode] || 'Наименование',
            },
          ],
          withLiveSearch: false,
          withSearch: true,
          canSelectAll: false,
          canSelectNothing: false,
          canSelectMany: !config.single,
          selectAllLabel: config.singleSelectAllLabel,
          searchPlaceholder: `Поиск по ${LABELS[mode]} и Наименованию`,
        },

        getQueryKeyPart: (filterState) => [filterState[mode]],
      },

      preparer: prepareSelectFilterValue(mode, !!config.single),

      descriptionPreparer: (filterState) => {
        const count = getSelection(filterState[mode]).length;
        if (!count) {
          return [];
        }
        const declarator = DECLARATORS[mode];
        return [declarator(count)];
      },
    };
  };
};
