import React from 'react';
import classNames from 'classnames';

import styles from './Event.module.scss';
import dayjs from 'dayjs';
import { Tooltip } from '../Tooltip/Tooltip';

export type TEventSimple = {
  link: string;
  publishDttm: string;
  text: string;
  title: string;
  type: 'ERROR' | 'INFO' | 'OTHER';
};

export const Event: React.FC<{
  event: TEventSimple;
}> = ({ event }) => {
  const dayjsDate = dayjs(event.publishDttm);

  const isOlderThanDay = dayjsDate.isBefore(dayjs().subtract(1, 'day'));

  return React.createElement(
    event.link ? 'a' : 'div',
    {
      className: classNames(styles.event, {
        [styles.error]: event.type === 'ERROR',
        [styles.info]: event.type === 'INFO',
        [styles.other]: event.type === 'OTHER',

        [styles.viewed]: isOlderThanDay,
      }),
      href: event.link,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    <>
      <div
        className={classNames(styles.title, {
          [styles.withLink]: event.link,
        })}
      >
        {event.title}
      </div>
      <div className={styles.text}>{event.text}</div>
      <div className={styles.date}>
        <Tooltip
          testId={'event-date'}
          text={dayjsDate.format('DD.MM.YYYY HH:mm')}
        >
          <span>{dayjsDate.fromNow()}</span>
        </Tooltip>
      </div>
    </>,
  );
};
