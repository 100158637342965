import { useQuery } from '@tanstack/react-query';
import { $api } from '../../../api/utils';
import { saveAsFile } from '../../../shared/utils/saveAsFile';
import { LinkingInfo } from '../../../api/address/Api';
import { queryClient } from '../../../store/query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { formatError } from '../../../shared/utils/withErrorHandling';
import { withLoader } from '../../common/FullscreenLoader/FullscreenLoader';
import { convertCsvToXslx } from '../../../shared/utils/worker/csvToXslx';

export const uploadSample = async (file: File) => {
  withLoader(async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await $api.post(`/link-address/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Файл успешно загружен');
      queryClient.invalidateQueries(['link-address']);
      return data;
    } catch (e) {
      const errorText = formatError(e);
      toast.error(`Ошибка при загрузке файла: ${errorText}`);
      let fileStringContent = '';
      try {
        fileStringContent = await file.text();
      } catch {
        // ignore
      }
      Sentry.captureException(e, {
        extra: {
          fileStringContent,
        },
      });
      console.error(e);
    }
  });
};

export const downloadSample = async () => {
  const { data } = await $api.get('/link-address/sample', {
    responseType: 'blob',
  });
  const string = await data.text();
  const xslxFile = await convertCsvToXslx({
    csvString: string,
    filename: 'sample',
  });
  const url = window.URL.createObjectURL(xslxFile);

  saveAsFile(url, 'sample.xlsx');
};

export const downloadDictionary = async () => {
  await withLoader(async () => {
    const { data } = await $api.get('/link-address/download', {
      responseType: 'blob',
    });
    const string = await data.text();
    const xslxFile = await convertCsvToXslx({
      csvString: string,
      filename: 'link-address-dictionary',
    });

    const url = window.URL.createObjectURL(xslxFile);

    saveAsFile(url, 'link-address-dictionary.xlsx');
  });
};

export const downloadUnlinkedPoints = async () => {
  await withLoader(async () => {
    const { data } = await $api.get('/link-address/unlinked-crpt-points', {
      responseType: 'blob',
    });
    const string = await data.text();
    const xslxFile = await convertCsvToXslx({
      csvString: string,
      filename: 'unlinked-points',
    });

    const url = window.URL.createObjectURL(xslxFile);

    saveAsFile(url, 'unlinked-points.xlsx');
  });
};

const getInfo = async () => {
  const { data } = await $api.get<LinkingInfo>('/link-address/info');
  return data;
};

const getIsExist = async () => {
  const { data } = await $api.get<boolean>('/link-address/client/exists', {
    responseType: 'text',
  });
  return {
    isExist: data,
  };
};

export const useIsExist = () => {
  return useQuery(['link-address', 'is-exist'], getIsExist, {
    refetchOnWindowFocus: true,
    cacheTime: 60 * 1000,
    staleTime: 0,
  });
};

export const useInfo = (enabled = false) => {
  return useQuery(['link-address', 'info'], getInfo, {
    refetchOnWindowFocus: true,
    cacheTime: 30 * 60 * 1000,
    staleTime: 0,
    refetchInterval: 20 * 1000,
    enabled,
  });
};
