import React from 'react';
import * as d3 from 'd3';
import { classSankey, sankey_get_changes } from './sankey_lib_v2.0';

import './sankey_lib_v2.0.css';
import { getByIndexFromArray } from '../../shared/utils/getRandomFromArray';
import { COLORS } from '../../shared/chartsConfigs/lines';
import { showContextMenu } from '../../newComponents/ContextMenu/ContextMenu';
import { getSelection } from '../../newComponents/common/PageWithFilter/utils/getSelection';

function getFilter(query) {
  const params = new URLSearchParams(query);
  const filter = params.get('filter');
  if (filter) {
    const decodedFilter = decodeURIComponent(filter);
    return JSON.parse(decodedFilter);
  }
  return null;
}

const getDsIndex = (query) => {
  const params = new URLSearchParams(query);
  return params.get('ds1r6-select-gtins-ds');
};

const getFilterForInn = (inn) => {
  const filter = getFilter(window.location.search);
  const dsIndex = getDsIndex(window.location.search);
  const filterWithInn = {
    ...filter,
    inns: {
      [inn]: true,
    },
  };
  return `?filter=${encodeURIComponent(
    JSON.stringify(filterWithInn),
  )}&ds1r5-select-gtins-ds=${dsIndex}&ds1r4-select-gtins-ds=${dsIndex}`;
};

/*
Структурура объекта:

    Родительский контейнер
        svg c ID sankey_frame + ID контрола - на нём отслеживается событие скроллинга для нижележащего
            g c ID main_sankey - актуального размера, который показывается с zoom коэффициентом и смещением
                defs - внутри вспомогательный компонент с фоном-градиентом для раскраски узлов по типам
                g с ID main_sankey_links - внутри связи, ID связи:
                g с ID main_sankey_nodes - внутри узлы, ID узла:
*/
/*
Использование - размеры родительского контрола должны быть заданы по высоте:

<div style={{ width: "100%", height: "800px", border: "1pt solid gray" }}>
<Sankey align={align} data={data} key={"sankeyConrol" + resetId}/>
</div>
*/

export default class Sankey extends React.Component {
  constructor(props) {
    super(props);

    this.sankey = null;
    this.refChart = React.createRef();
    this.graphID = 'sankey1';
    // размеры должны определиться при инициализации
    this.width = 600;
    this.height = 400;

    this.chartBorderWidth = 10;

    // Данные графа
    this.graph = props.data ? props.data : [];

    // Режимы отображения - вклюачя разные показатели
    // this.modes = this.sankey_init_modes( this.graph )
    this.activeMode = { measure: 'value' }; // this.measures[0]

    // Для отложенной перерисовки
    this.timeoutRedrawID = undefined;
    this.timeoutRedrawDelay = 50;
  }

  // Вызов перерисовки - если поменялись размеры
  callRedraw() {
    var frame_size = this.sankey && this.sankey.frameSize();

    try {
      // Вызов перерисовки с восстановлением относительных позиций узлов и зума
      if (
        frame_size.width !== this.refChart.current.clientWidth ||
        frame_size.height !== this.refChart.current.clientHeight
      ) {
        // console.log("Перерисовка : " + frame_size.width + "x" + frame_size.height + " vs " +
        //     this.refChart.current.clientWidth + "x" + this.refChart.current.clientHeight)

        // сохраняем текущие параметры пользовательских изменений
        var changes = sankey_get_changes(this.sankey);

        // вызов перерисовки под новые размеры
        this.reinitSankey(
          this.graph,
          this.props.align,
          changes,
          this.refChart.current.clientWidth,
          this.refChart.current.clientHeight,
        );
      }
    } catch {}

    this.clearTriggerRedraw();
  }

  // Очистка вызова отложенной перерисовки, если есть активный
  clearTriggerRedraw() {
    if (typeof this.timeoutRedrawID === 'number') {
      clearTimeout(this.timeoutRedrawID);
    }
    this.timeoutRedrawID = undefined;
  }

  // Вызов отложенной перерисовки
  callTriggerRedraw() {
    this.clearTriggerRedraw();

    this.timeoutRedrawID = setTimeout(
      function (msg) {
        this.callRedraw();
        this.clearTriggerRedraw();
      }.bind(this),
      this.timeoutRedrawDelay,
    );
  }

  // Вызов отложенной перерисовки - при изменении размеров окна
  updateDimensions() {
    // if(!(this.sankey && this.refChart && this.refChart.current) ) return
    this.callTriggerRedraw();
  }

  componentDidMount() {
    // отслеживание изменений размеров контрола - для перерисовки
    window.addEventListener('resize', this.updateDimensions.bind(this));

    this.resizeObserver = new ResizeObserver((entries) => {
      this.updateDimensions();
    });

    this.resizeObserver.observe(this.refChart.current);

    // this.InitD3GraphTest();
    // this.InitD3Graph(this.refChart.current.clientWidth, this.refChart.current.clientHeight);

    try {
      this.reinitSankey(
        this.graph,
        this.props.align,
        this.changes,
        this.refChart.current.clientWidth,
        this.refChart.current.clientHeight,
      );
    } catch {
      // always runs
      // console.log("WARNING: sankey can't be displayed")
    }

    // this.reinitSankey(this.graph, this.props.align, this.changes, this.refChart.current.clientWidth, this.refChart.current.clientHeight)
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
    // снятие отслеживания изменений размеров контрола
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    // Делам обновление только если поменялись определённые условия:
    //   изменилось выравнивание
    //   ? изменились данные
    //   ? изменился показатель
    //   ? изменились размеры контрола - делаем перерисовку с сохранением размеров
    if (
      this.sankey &&
      this.sankey.align &&
      this.sankey.align() !== this.props.align
    ) {
      // console.log("Перерисовка : " + this.sankey.align() + " vs " + this.props.align)
      this.sankey_draw(this.sankey, { align: this.props.align }, null);
    } else this.callTriggerRedraw();
  }

  // Инициализация диаграммы - отрисовка узлов / связей
  sankey_draw(sankey, options, changes) {
    if (!sankey) {
      console.log('sankey object is not defined');
      return null;
    }

    var component = this;
    var svg = d3
      .select(component.refChart.current)
      .select('#main_sankey_id_' + this.graphID);

    if (options.node_padding) sankey.nodePadding(options.node_padding);

    if (options.measure) {
      if (options.graph_type) {
        sankey.graph_type(options.graph_type);
      } else {
        sankey.graph_type('general');
      }
      if (options.measure_unit_nm) {
        sankey.unit_nm(options.measure_unit_nm);
      } else {
        sankey.unit_nm('');
      }
      if (options.measure_weight) {
        sankey.measure_weight(options.measure_weight);
      } else {
        sankey.measure_weight(null);
      }
      if (options.measure_weight_unit_nm) {
        sankey.measure_weight_unit_nm(options.measure_weight_unit_nm);
      } else {
        sankey.measure_weight_unit_nm('');
      }
      if (options.measure_value_shift) {
        sankey.measure_value_shift(options.measure_value_shift);
      } else {
        sankey.measure_value_shift(null);
      }
    }

    // console.log( 'sankey.measure_value_shift: ' + sankey.measure_value_shift() );

    // console.log('options.graph_type: ' + options.graph_type);
    // console.log('options.measure: ' + options.measure);
    // console.log('options.align: ' + options.align);

    if (
      (options.align && options.align !== '') ||
      (options.measure && options.measure !== '')
    ) {
      if (options.align && options.align !== '') {
        // Если поменялась раскладка - забываем все кастомные изменения
        if (changes && (!changes.align || changes.align !== options.align)) {
          changes = null;
        }
        sankey.align(options.align);
      }
      if (options.measure && options.measure !== '') {
        sankey.measure(options.measure);
      }
      sankey.layout(32);
    }

    var links =
        document.getElementById('main_sankey_links') === null
          ? svg.append('g').attr('id', 'main_sankey_links')
          : d3.select('#main_sankey_links'),
      nodes =
        document.getElementById('main_sankey_nodes') === null
          ? svg.append('g').attr('id', 'main_sankey_nodes')
          : d3.select('#main_sankey_nodes');

    var animDuration = 800;

    var formatNumber = d3.format('.2f'), //(sankey.graph_type() === "markup") ? d3.format(".2f") : d3.format(".0f"), // ".0f" - zero decimal places
      format = function (d) {
        return formatNumber(d) + ' ' + sankey.unit_nm();
      },
      formatWeight = function (d) {
        return formatNumber(d) + ' ' + sankey.measure_weight_unit_nm();
      },
      color = d3.scaleOrdinal(d3.schemeCategory10);

    // add in the links
    var link = links.selectAll('.link').data(sankey.links());

    // алиасы функций - для сокращения текста кода
    function mvalue(l) {
      return sankey.mvalue(l);
    }

    function mwvalue(l) {
      return sankey.mwvalue(l);
    }

    if (changes) {
      // console.log("Проверяем");
      sankey.nodes().forEach(function (d) {
        // Зачищаем старое
        d.yr_moved = null;
        changes.nodes.forEach(function (old_node) {
          if (old_node.id === d.node) {
            // Меняем значения положения по Y - согласно предыдущему смещению
            // console.log('move ' + d.node + ': '+ d.y + ' -> ' + old_node.yr_moved*sankey.size()[1]);
            d.yr_moved = old_node.yr_moved;
            d.y = old_node.yr_moved * sankey.size()[1];
          }
        });
        return d;
      });
    } else {
      //console.log("Удаляем");
      sankey.nodes().forEach(function (d) {
        delete d.yr_moved; // = null;
        return d;
      });
    }

    // Разделено - для перерисовки после изменения выравнивания
    var newLink = link
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('id', function (d) {
        d.id =
          'l' +
          d.source.node +
          '-' +
          d.target.node +
          '-' +
          (d.type ? d.type : 1);
        return d.id;
      })
      .style('stroke-width', 1)
      .style('stroke', function (d) {
        // Связи одной группы
        if (
          d.source.group &&
          d.target.group &&
          d.source.group === d.target.group
        )
          return (d.color = color(d.source.group));
        else if (d.type === 2) return 'red';
        else return 'gray';
      })
      .style('fill', function (d, i) {
        if (
          d.source.group &&
          d.target.group &&
          d.source.group === d.target.group
        )
          return 'green';
        else if (d.type === 2) return 'red';
        else return 'gray';
      });
    // add the link titles
    newLink.append('title');

    // Добавлено - для перерисовки после изменения выравнивания
    link = newLink.merge(link);

    link
      .transition()
      .duration(animDuration)
      .attr('d', sankey.link())
      .style('stroke-width', 1)
      .style('stroke', function (d) {
        // Связи одной группы - зеленым цветом
        if (d.type === 2) return (d.color = 'red');
        else if (
          d.source.group &&
          d.target.group &&
          d.source.group === d.target.group
        )
          return (d.color = color(d.source.group)); //'green'
        else return (d.color = 'gray');
      })
      .style('fill', function (d, i) {
        if (d.type === 2) return (d.color = 'red');
        else if (
          d.source.group &&
          d.target.group &&
          d.source.group === d.target.group
        )
          return (d.color = color(d.source.group)); //'green'
        else return (d.color = 'gray');
      })
      .select('title')
      .text(function (d) {
        // Хинт для прамой ссылки, для обратной ссылки
        if (d.type === 1) {
          return (
            d.source.name +
            (d.source.group ? ' ' + d.source.group : '') +
            ' → ' +
            d.target.name +
            (d.target.group ? ' ' + d.target.group : '') +
            '\n' +
            format(mvalue(d)) +
            (sankey.graph_type() === 'markup'
              ? ' (' +
                (sankey.measure_value_shift()
                  ? format(
                      parseFloat(sankey.measure_value_shift()) + mvalue(d),
                    ) + ', '
                  : '') +
                formatWeight(mwvalue(d)) +
                ')'
              : '')
          );
          // + "\nid: " + d.source.node + ' → ' + d.target.node;

          // console.log('sankey.measure_value_shift(): ' + sankey.measure_value_shift())
        } else {
          return (
            d.source.name +
            (d.source.group ? ' ' + d.source.group : '') +
            ' ← ' +
            d.target.name +
            (d.target.group ? ' ' + d.target.group : '') +
            '\n' +
            format(mvalue(d)) +
            (sankey.graph_type() === 'markup'
              ? ' (' +
                (sankey.measure_value_shift()
                  ? format(
                      parseFloat(sankey.measure_value_shift()) + mvalue(d),
                    ) + ', '
                  : '') +
                formatWeight(mwvalue(d)) +
                ')'
              : '')
          );
          // + "\nid: " + d.target.node + ' ← ' + d.source.node;
        }
      });

    // ===== Add in / Update the nodes ====================================

    var node = nodes.selectAll('.node').data(sankey.nodes());

    // пример по перетаскиванию мышкой- см. https://dev.to/taowen/make-react-svg-component-draggable-2kc
    var newNode = node
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      })
      .call(
        d3
          .drag()
          .subject(function (event, d) {
            return d;
            // достаточно вернуть x и y
            // return { x: d.x, y: d.y }
          })
          .on('start', function (event) {
            // event больше нет в d3
            // d3.event.sourceEvent.stopPropagation(); // silence other listeners
            event.sourceEvent.stopPropagation();
          })
          // .on("drag", dragmove)
          .on('drag', function (event, d) {
            d3.select(this).attr(
              'transform',
              'translate(' +
                d.x +
                ',' +
                // event больше нет в d3 - берем из event
                // d.y = Math.max(0, Math.min(sankey.size()[1] - d.dy, d3.event.y))
                (d.y = Math.max(
                  0,
                  Math.min(sankey.size()[1] - d.dy, event.y),
                )) +
                ')',
            );
            sankey.relayout();
            link.attr('d', sankey.link());
          })
          .on('end', function (event, d) {
            d.yr_moved = (d.y * 1.0) / sankey.size()[1];
            return d;
          }),
      );

    // add the rectangles for the nodes
    newNode
      .append('rect')
      .attr('height', function (d) {
        return d.dy;
      })
      .attr('width', sankey.nodeWidth())
      .style('fill', function (d, i) {
        return nodeColor(d, i);
      })
      .style('stroke', function (d) {
        if (d.selected) return 'blue';
        else return d3.rgb(d.color).darker(2);
      })
      //.on("click", nodeclick)
      .on('click', highlight_node_links)
      .on('contextmenu', function (event, node) {
        event.preventDefault();
        const inn = node.id.split(' ')[0];
        if (inn) {
          const numericInn = parseInt(inn);
          if (!isNaN(numericInn)) {
            let period = '';
            try {
              const filterJSON = new URLSearchParams(
                window.location.search,
              ).get('filter');
              const filter = JSON.parse(filterJSON);
              if (Array.isArray(filter.period) && filter.period.length === 2)
                period = filter.period.map((dateString) =>
                  new Date(dateString).getTime(),
                );
            } catch {
              // do nothing
            }

            showContextMenu({
              open: true,
              target: {
                x: event.clientX,
                y: event.clientY,
              },
              items: [
                {
                  title: 'Продавцы для ' + node.name,
                  onClick: () => {
                    const filterUrl = getFilterForInn(numericInn);
                    const link = `/reports/report-ds1-r5${filterUrl}`;
                    window.open(link, '_blank');
                  },
                },
                {
                  title: 'Покупатели для ' + node.name,
                  onClick: () => {
                    const filterUrl = getFilterForInn(numericInn);
                    const link = `/reports/report-ds1-r4${filterUrl}`;
                    window.open(link, '_blank');
                  },
                },
              ],
              testId: 'sankey-context-menu',
            });
          }
        }
      })
      .append('title');

    // add in the title for the nodes
    newNode
      .append('text')
      .attr('dy', '.35em')
      .attr('transform', null)
      .attr('y', function (d) {
        return d.dy / 2;
      });

    node
      .transition()
      .duration(animDuration)
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });

    node = newNode.merge(node);

    // === Двигаем связи и узлы после изменения параметров отрисовки - ориентации ====================

    // Меняем размер и двигаем узел
    node
      .select('rect')
      .transition()
      .duration(animDuration)
      .attr('height', function (d) {
        return d.dy;
      })
      .style('fill', function (d, i) {
        return nodeColor(d, i);
      })
      .style('stroke', function (d) {
        if (d.selected) return 'blue';
        else return d3.rgb(d.color).darker(2);
      });

    // Перестраиваем заголовок узла относительно размеров и размещения узла
    node
      .select('text')
      .html(function (d) {
        if (d.group) {
          return (
            "<tspan x='" +
            (d.x < sankey.size()[0] / 2 ? 6 + d.dx : -6) +
            "' dy='0'>" +
            d.name +
            '</tspan>' +
            "<tspan x='" +
            (d.x < sankey.size()[0] / 2 ? 6 + d.dx : -6) +
            "' dy='1.0em'>" +
            d.group +
            '</tspan>'
          );
        } else {
          return d.name;
        }
        // return d.name;
      })
      .attr('x', -6)
      .attr('text-anchor', 'end')
      .filter(function (d) {
        return d.x < sankey.size()[0] / 2;
      })
      .attr('x', 6 + sankey.nodeWidth())
      .attr('text-anchor', 'start');

    node
      .select('text')
      .transition()
      .duration(animDuration)
      .attr('y', function (d) {
        return d.dy / 2;
      });

    node
      .select('title')
      .transition()
      .text(function (d) {
        // Хинт для режима наценки - с динамическим пересчётом (средне-взвешенное)
        if (
          sankey.graph_type() === 'markup' &&
          sankey.measure_weight() &&
          sankey.measure_weight() !== ''
        ) {
          // average-weighted
          var inTotal = null;
          var inWeight = null;
          var outTotal = null;
          var outWeight = null;
          var inMarkup = null;
          var outMarkup = null;

          d.sourceLinks.forEach(function (link) {
            if (link.type === 1) {
              outTotal += mvalue(link) * mwvalue(link);
              outWeight += mwvalue(link);
            } else {
              inTotal += mvalue(link) * mwvalue(link);
              inWeight += mwvalue(link);
            }
          });
          d.targetLinks.forEach(function (link) {
            if (link.type === 1) {
              inTotal += mvalue(link) * mwvalue(link);
              inWeight += mwvalue(link);
            } else {
              outTotal += mvalue(link) * mwvalue(link);
              outWeight += mwvalue(link);
            }
          });
          if (inWeight !== null) {
            inMarkup = inTotal / inWeight;
          }
          if (outWeight !== null) {
            outMarkup = outTotal / outWeight;
          }

          var markupInfo = '';

          if (!inMarkup) {
            markupInfo =
              '→ () → ' +
              format(
                outMarkup +
                  (sankey.measure_value_shift()
                    ? parseFloat(sankey.measure_value_shift())
                    : 0),
              );
          } else if (!outMarkup) {
            markupInfo =
              format(
                inMarkup +
                  (sankey.measure_value_shift()
                    ? parseFloat(sankey.measure_value_shift())
                    : 0),
              ) + ' → () →';
          } else {
            // If value shift defined
            if (sankey.measure_value_shift()) {
              var shift = parseFloat(sankey.measure_value_shift());
              markupInfo =
                formatNumber(inMarkup + shift) +
                ' → (' +
                formatNumber(outMarkup - inMarkup) +
                ', ' +
                formatNumber(
                  ((outMarkup - inMarkup) / (inMarkup + shift)) * 100.0,
                ) +
                '%) → ' +
                format(outMarkup + shift);
            } else {
              markupInfo =
                formatNumber(inMarkup) +
                ' → (' +
                formatNumber(outMarkup - inMarkup) +
                ') → ' +
                format(outMarkup);
            }
          }

          return d.name + (d.group ? '\n' + d.group : '') + '\n' + markupInfo;
          //   + "\nid=" + d.node;
        } else {
          return (
            d.name +
            (d.group ? '\n' + d.group : '') +
            '\n' +
            formatNumber(d.valueIn) +
            ' → (' +
            formatNumber(d.valueOut - d.valueIn) +
            ') → ' +
            format(d.valueOut)
          );
          // + "\nid=" + d.node;
        }
      });

    function nodeColor(d, i) {
      return getByIndexFromArray(COLORS, i);
    }

    // Show / hide path from selected node
    // REACT: первый атрибут - event
    // function highlight_node_links(node,i){
    function highlight_node_links(event, node) {
      var remainingNodes = [],
        nextNodes = [];
      var nextNodesIndexes = [],
        processedNodesIndexes = []; // remainingNodesIndexes=[],

      node.selected = node.selected === null ? true : !node.selected;

      var stroke_opacity = node.selected ? 0.8 : 0.2;

      var traverse = [
        { linkType: 'sourceLinks', nodeType: 'target' },
        { linkType: 'targetLinks', nodeType: 'source' },
      ];

      traverse.forEach(function (step) {
        node[step.linkType].forEach(function (link) {
          remainingNodes.push(link[step.nodeType]);
          highlight_link(link.id, stroke_opacity);
          if (!processedNodesIndexes.includes(node.node)) {
            processedNodesIndexes.push(node.node);
          }
        });

        while (remainingNodes.length) {
          nextNodes = [];
          nextNodesIndexes = [];
          remainingNodes.forEach(function (node) {
            node[step.linkType].forEach(function (link) {
              // Избегаем повторных пробегов по узлам и их линкам
              if (!processedNodesIndexes.includes(link[step.nodeType].node)) {
                // Фиксируем в списке обработанных в текущей итерации
                if (!nextNodesIndexes.includes(link[step.nodeType].node)) {
                  nextNodes.push(link[step.nodeType]);
                  nextNodesIndexes.push(link[step.nodeType].node);
                }
              }
              highlight_link(link.id, stroke_opacity);
            });
          });

          remainingNodes = nextNodes;
          nextNodesIndexes.forEach(function (nodeIndex) {
            if (!processedNodesIndexes.includes(nodeIndex)) {
              processedNodesIndexes.push(nodeIndex);
            }
          });
        }
      });
    }

    function highlight_link(id, opacity) {
      // console.log('hl: ' + id + ', ' + opacity);
      d3.select('#' + id).style('stroke-opacity', opacity);
      d3.select('#' + id).style('fill-opacity', opacity);
    }
  }

  // Инициализация диаграммы - полное создание полотная и вызов первичной отрисовки узлов / связей
  sankey_init(graph, frame_width, frame_height, options, changes) {
    if (!(graph && Array.isArray(graph.links) && Array.isArray(graph.nodes))) {
      console.log('WARNING reinitSankey: graph data is wrong');
      return;
    }

    if (!options) options = {};

    //     if( document.getElementById("main_sankey")!= null ){
    //         return sankey_draw(graph, frame_width, frame_height, options);
    //     };

    const component = this;
    const container = component.refChart.current;

    var node_padding = options.node_padding ? options.node_padding : 60,
      graph_type = options.graph_type ? options.graph_type : '',
      align = options.align ? options.align : 'left',
      g_zoom = options.g_zoom ? options.g_zoom : 1,
      measure = options.measure ? options.measure : 'value',
      measure_unit_nm = options.measure_unit_nm ? options.measure_unit_nm : '',
      // measure_weight          = (options.measure_weight          ? options.measure_weight         : null),
      measure_weight_unit_nm = options.measure_weight_unit_nm
        ? options.measure_weight_unit_nm
        : '',
      measure_value_shift = options.measure_value_shift
        ? options.measure_value_shift
        : 0;
    var margin = { top: 160, right: 20, bottom: 160, left: 20 },
      // size of canvas with - without border
      width = Math.round(frame_width / g_zoom),
      height = Math.round(frame_height / g_zoom);

    // console.log('size of frame: ' + frame_width + 'x' + frame_height );
    // console.log('size of canvas: ' + width + 'x' + height );

    // Зачистка - при реинициализации
    d3.select(container).selectAll('*').remove();

    // append the svg canvas to the page
    var svg_frame = d3
      .select(container)
      // .attr("id",     components.graphID)
      .attr('x', 0)
      .attr('y', 0)
      .attr('id', 'sankey_frame_' + component.graphID)
      .attr('width', frame_width)
      .attr('height', frame_height);

    var svg = svg_frame
      .append('g')
      .attr('id', 'main_sankey_id_' + component.graphID) //id of main svg
      .attr('width', width)
      .attr('height', height);
    // === Zoomed main SVG ===================================================
    // Фиксирование стартовое состояние зумма и начального расположения левого верхнего угла
    var zoomAttr =
      changes && changes.zoomInfo
        ? changes.zoomInfo
        : { k: g_zoom, x: 0, y: 0 };
    var zoomInit = d3.zoomIdentity
      .translate(zoomAttr.x, zoomAttr.y)
      .scale(zoomAttr.k);

    // Gradient definition
    // Vertical gradient 2 & 3
    var lg = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient2_3') //id of the gradient
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    lg.append('stop')
      .attr('offset', '0%')
      .style('stop-color', 'lightyellow') //end in red
      .style('stop-opacity', 1);

    lg.append('stop')
      .attr('offset', '100%')
      .style('stop-color', 'lightblue') //start in blue
      .style('stop-opacity', 1);

    // Set the sankey diagram properties
    var sankey = classSankey()
      .nodeWidth(36)
      .nodePadding(node_padding)
      .size([width, height])
      .frameSize({ width: frame_width, height: frame_height })
      .align(align)
      .graph_type(graph_type)
      .measure(measure)
      .unit_nm(measure_unit_nm)
      .measure_weight_unit_nm(measure_weight_unit_nm)
      .measure_value_shift(measure_value_shift)
      .padding(margin)
      .zoomInfo(zoomAttr);

    // console.log('zoomInfo  ' + "k:" + sankey.zoomInfo().k + " x:" + sankey.zoomInfo().x + " y:" + sankey.zoomInfo().y );

    function zoomed(event) {
      // console.log( event )
      svg.attr('transform', event.transform);
      sankey.zoomInfo({
        k: event.transform.k,
        x: event.transform.x,
        y: event.transform.y,
      });
    }

    var zoom = d3.zoom().scaleExtent([0.01, 10]).on('zoom', zoomed);

    svg_frame.call(zoom);
    svg_frame.transition().duration(0).call(zoom.transform, zoomInit);

    // Проверка наличия индексации - добавляем, если нет - переиндексируем из id в node
    if (!graph.nodes[0].hasOwnProperty('node')) {
      // console.log( 'Reindex nodes' );

      // Добавляем к id индекс (атрибут node) в nodes
      var index = 0;
      graph.nodes.forEach(function (node) {
        node.node = index++;
        return node;
      });

      // Перекодируем связи из исходных значений (key) в индексы (node) в links
      graph.links.forEach(function (link) {
        var nodeSource = graph.nodes.filter((d) => d.id === link.source)[0];
        var nodeTarget = graph.nodes.filter((d) => d.id === link.target)[0];

        link.sourceId = link.source;
        link.targetId = link.target;

        link.source = nodeSource.node;
        link.target = nodeTarget.node;

        return link;
      });
    }

    // Проверяем наличие флага type - если нет, выставляем 1
    graph.links.forEach(function (link) {
      if (!link.hasOwnProperty('type')) {
        link.type = 1;
        return link;
      }
    });

    sankey
      .nodes(graph.nodes)
      .links(graph.links)
      .dataDttm(graph.create_dttm ? graph.create_dttm : '');

    this.sankey_draw(sankey, options, changes);

    return sankey;
  }

  // Инициализация - предварительное определение зумма, отображаемого показателя, вызов создания и отрисовк
  reinitSankey(graph, align, changes, width, height) {
    if (
      !(
        graph &&
        Array.isArray(graph.links) &&
        Array.isArray(graph.nodes) &&
        graph.links.length > 0 &&
        graph.nodes.length > 0
      )
    ) {
      console.log('WARNING reinitSankey: graph data is wrong');
      // console.log( graph )
      return;
    }

    this.setState({
      width: width,
      height: height,
    });

    // Предвартельная оценка - с каким зумом отрисовывать диаграмму - исходя из кол-ва узлов
    // Реальное значение чтобы диаграмма поместилась надо смотреть после отрисовки
    const margin = 20;

    // const sankeyWidth  = width  - 2 * margin - 2 * this.chartBorderWidth;
    const sankeyHeight = height - 2 * margin - 2 * this.chartBorderWidth;

    // console.log('sankeyWidth: ' + sankeyWidth )
    // console.log('sankeyHeight: ' + sankeyHeight )

    var requiredHeight = sankeyHeight;

    var sankeyPadding = 60;

    if (graph.nodes.length < 20) {
      requiredHeight = 1000;
    } else if (graph.nodes.length < 80) {
      requiredHeight = 2600;
    } else if (graph.nodes.length < 200) {
      requiredHeight = 4000;
    } else if (graph.nodes.length < 300) {
      requiredHeight = 7000;
    } else if (graph.nodes.length < 500) {
      requiredHeight = 10000;
    } else if (graph.nodes.length < 1000) {
      requiredHeight = 12000;
    } else requiredHeight = 14000;

    if (graph.nodes.length >= 1500) {
      sankeyPadding = 10;
    } else if (graph.nodes.length >= 1000 && graph.nodes.length < 1500) {
      sankeyPadding = 20;
    } else if (graph.nodes.length >= 100 && graph.nodes.length < 1000) {
      sankeyPadding = 30;
    } else if (graph.nodes.length >= 80 && graph.nodes.length < 100) {
      sankeyPadding = 40;
    } else if (graph.nodes.length >= 50 && graph.nodes.length < 80) {
      sankeyPadding = 50;
    } else sankeyPadding = 60;

    // const sankeyInitalZoom=((docHeight - divChart.offsetTop)/requiredHeight);
    var sankeyInitalZoom = height / requiredHeight;

    if (sankeyInitalZoom > 1.0) {
      sankeyInitalZoom = 1.0;
    }

    this.sankey = this.sankey_init(
      graph,
      width,
      height,
      {
        g_zoom: sankeyInitalZoom,
        node_padding: sankeyPadding,
        align: align,
        // mode: this.activeMode
        measure: this.activeMode.measure,
        graph_type: this.activeMode.graph_type,
        measure_unit_nm: this.activeMode.measure_unit_nm,
        measure_weight: this.activeMode.measure_weight,
        measure_weight_unit_nm: this.activeMode.measure_weight_unit_nm,
        measure_value_shift: this.activeMode.measure_value_shift,
      },
      changes,
    );
  }

  copyStylesInline(destinationNode, sourceNode) {
    var containerElements = ['svg', 'g'];
    for (var cd = 0; cd < destinationNode.childNodes.length; cd++) {
      var child = destinationNode.childNodes[cd];
      if (containerElements.indexOf(child.tagName) !== -1) {
        this.copyStylesInline(child, sourceNode.childNodes[cd]);
        continue;
      }
      var style =
        sourceNode.childNodes[cd].currentStyle ||
        window.getComputedStyle(sourceNode.childNodes[cd]);
      if (style === 'undefined' || style === null) continue;
      for (var st = 0; st < style.length; st++) {
        child.style.setProperty(style[st], style.getPropertyValue(style[st]));
      }
    }
  }

  onDownload() {
    console.log('WARNING Sankey: call onDownload');

    return;

    // TODO: надо переделать - сохранять не базовый SGV с зуммом, а внутреннее полотно в полный размер

    // try {
    //     // копирование SVG со стилями
    //     // const svg2src = document.getElementById("main_sankey_id_" + this.graphID)
    //     const svg2src = document.getElementById("sankey_frame_" + this.graphID)
    //     // console.log("check 1")
    //     // console.log(svg2src)
    //     let {  width,  height } = svg2src.getBBox()

    //     width  = Math.trunc(width)   + 1
    //     height = Math.trunc(height)  + 1

    //     console.log("size: " + width + "," + height)
    //     console.log("check 2")

    //     let svg2export = svg2src.cloneNode(true)

    //     // Удаление зумминга и перемещения
    //     // svg2export.removeAttribute("transform")

    //     // svg2export.attr("transform", "translate(0,0) scale(1)")
    //     console.log(svg2export)

    //     // console.log(svg2export.attr("transform"))

    //     // console.log("check 2.1")
    //     // явное копирование стилей в SVG элементах - иначе стили вне SVG игнорируются
    //     this.copyStylesInline(svg2export, svg2src)

    //     console.log("check 3")

    //     // const svg2export = document.getElementById(this.graphID)

    //     // создание canvas и отрисовка на нём SVG
    //     var canvas = document.createElement('canvas')

    //     canvas.width  = width
    //     canvas.height = height

    //     var ctx = canvas.getContext('2d')
    //     var data = (new XMLSerializer()).serializeToString(svg2export)
    //     var DOMURL = window.URL || window.webkitURL || window

    //     var img = new Image()
    //     var svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})

    //     console.log(svgBlob)

    //     var url = DOMURL.createObjectURL(svgBlob)

    //     console.log(url)

    //     console.log("4")
    //     img.onload = function () {

    //         console.log("5")
    //         ctx.drawImage(img, 0, 0);
    //         DOMURL.revokeObjectURL(url);

    //         var imgURI = canvas
    //             // для JPEG надо фон отрисовывать белым прямоугольником - иначе он черный вместо прозрачного
    //             // .toDataURL('image/jpeg', 1.0)
    //           .toDataURL('image/png, 1.0')
    //           .replace('image/png', 'image/octet-stream');

    //           console.log("5")
    //         var a = document.createElement('a');
    //         a.setAttribute('download', 'image.png');
    //         a.setAttribute('href', imgURI)
    //         a.setAttribute('target', '_blank')
    //         a.click()
    //         console.log("check last")
    //         a.remove()
    //     }

    //     img.src = url
    //     console.log("6")
    // } catch {
    //     console.log("Ошибка выгрузки файла с изображением")
    // }
  }

  render() {
    return (
      <>
        {this.graph &&
        Array.isArray(this.graph.links) &&
        Array.isArray(this.graph.nodes) &&
        this.graph.links.length > 0 &&
        this.graph.nodes.length > 0 ? (
          <>
            <div
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <svg
                ref={this.refChart}
                style={{ width: '100%', height: '100%' }}
                id={this.graphID}
              />
            </div>
          </>
        ) : (
          <div className="text-center p-2">Данных для отображения нет.</div>
        )}
      </>
    );
  }
}
