import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { TSelectFilter } from '../../../filter';

import { useQuery } from '@tanstack/react-query';
import { Loader } from '../../../../Loader/Loader';
import { Input } from '../../../../../../uikit/Input/Input';
import classNames from 'classnames';

import styles from './SelectFilter.module.scss';
import { Checkbox } from '../../../../../../uikit/Checkbox/Checkbox';
import { LineSelect } from '../../../../LineSelect/LineSelect';
import { useSearchQueryState } from '../../../../../../shared/hooks/useSearchQueryState';
import { getSelection } from '../../../utils/getSelection';
import { useDebouncedValue, useOutsideClickRef } from 'rooks';
import { Icon } from '../../../../Icon/Icon';
import { Spacer } from '../../../../Spacer/Spacer';
import {
  hasSubstring,
  normalizeString,
} from '../../../../../../shared/utils/hasSubstring';

type TValue = Record<string, boolean> & {
  datasetKey?: string;
  isInverted?: boolean;
};

type TSelectFilterProps = {
  filter: TSelectFilter;
  value: TValue;
  onChange: (value: TValue) => void;
  key: string;
};

const QUERY_OPTIONS = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
};

const useSelectFilterData = (
  filter: TSelectFilter,
  datasetIndex = 0,
  datasetName = '',
  search = '',
) => {
  return useQuery(
    [
      'filterDataset',
      filter.uniqueKey,
      datasetIndex,
      datasetName,
      filter.withLiveSearch ? search : '',
    ],
    () =>
      filter.getOptions(
        filter.datasets?.[datasetIndex]?.value,
        filter.withLiveSearch ? search : '',
      ),
    QUERY_OPTIONS,
  );
};

const useUninvertedValues = (value: TValue, data: any[]) => {
  return useMemo(() => {
    if (!value) {
      return {};
    }
    if (value.isInverted) {
      const invertedValue: TValue = {};
      data?.forEach((item) => {
        if (!value[item.value]) {
          invertedValue[item.value] = true;
        }
      });
      invertedValue.datasetKey = value.datasetKey;
      invertedValue.isInverted = false;
      return invertedValue;
    }
    return value;
  }, [data, value]);
};

export const SelectFilterPrefetcher: React.FC<TSelectFilterProps> = ({
  filter,
  onChange,
  value = {},
}) => {
  const { data } = useSelectFilterData(filter);
  const uninvertedValue = useUninvertedValues(value, data || []);
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      const noValue =
        !uninvertedValue || getSelection(uninvertedValue).length === 0;
      if (data?.[0]?.value && filter.initiallySelectFirstOption && noValue) {
        const newValues: TValue = {
          [data[0].value]: true,
        };
        if (uninvertedValue.datasetKey) {
          newValues.datasetKey = uninvertedValue.datasetKey;
        }
        onChange(newValues);

        firstRender.current = false;
      }
    }
  }, [data, filter.initiallySelectFirstOption, onChange, uninvertedValue]);
  return null;
};

const LIMIT = 350;

const getShouldInvert = ({
  selectedRealCount,
  notSelectedRealCount,
  selectedItemsCount,
  notSelectedItemsCount,
}: {
  selectedRealCount: number;
  notSelectedRealCount: number;

  selectedItemsCount: number;
  notSelectedItemsCount: number;
}): boolean => {
  const isSelectedMoreThanLimit = selectedItemsCount > LIMIT;
  const isNotSelectedMoreThanLimit = notSelectedItemsCount > LIMIT;

  if (isSelectedMoreThanLimit === isNotSelectedMoreThanLimit) {
    return notSelectedRealCount < selectedRealCount;
  }
  if (isSelectedMoreThanLimit) {
    return true;
  }
  return false;
};

export const SelectFilter: React.FC<TSelectFilterProps> = ({
  filter,
  value = {},
  onChange,
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  const [searchText, setSearchText] = useState<string>('');
  const [searchToApply] = useDebouncedValue<string>(searchText, 300);
  const [datasetIndex, setDatasetIndex] = useSearchQueryState<number>(
    `${filter.uniqueKey}-ds`,
    0,
  );
  const currentDataset = filter.datasets?.[datasetIndex];
  const datasetName = currentDataset?.value || '';
  const { data, isLoading } = useSelectFilterData(
    filter,
    datasetIndex,
    datasetName,
    searchToApply || '',
  );

  const uninvertedValue = useUninvertedValues(value, data || []);

  useEffect(() => {
    if (datasetIndex !== undefined) {
      setSearchText('');
    }
  }, [datasetIndex]);

  const handleChange = (newValue: TValue) => {
    newValue.datasetKey = filter.datasets?.[datasetIndex]?.value;
    newValue.isInverted = false;

    if (filter.canSelectMany && filter.canBeInverted !== false) {
      const selectedValuesTotalCount =
        data
          ?.filter((item) => newValue[item.value])
          .map((item) => item.realItemsCount || 1)
          .reduce((a, b) => a + b, 0) || 0;
      const notSelectedValuesTotalCount =
        data
          ?.filter((item) => !newValue[item.value])
          .map((item) => item.realItemsCount || 1)
          .reduce((a, b) => a + b, 0) || 0;

      const shouldInvert = getShouldInvert({
        selectedRealCount: selectedValuesTotalCount,
        notSelectedRealCount: notSelectedValuesTotalCount,

        selectedItemsCount: getSelection(newValue).length,
        notSelectedItemsCount:
          (data?.length || 0) - getSelection(newValue).length,
      });

      if (shouldInvert) {
        const invertedValue: TValue = {};
        data?.forEach((item) => {
          if (!newValue[item.value]) {
            invertedValue[item.value] = true;
          }
        });
        invertedValue.datasetKey = newValue.datasetKey;
        invertedValue.isInverted = true;
        onChange(invertedValue);
        return;
      }
    }

    onChange(newValue);
  };

  const isSomeValues = data?.length !== 0;
  const [isSelectedAll, isSelectedSome] = useMemo(() => {
    const valueClone = getSelection(uninvertedValue);
    const isSelectedAll = filter.canSelectMany
      ? valueClone.length === data?.length
      : valueClone.length === 0;

    const isSelectedSome = valueClone.length > 0 && !isSelectedAll;
    return [isSelectedAll, isSelectedSome];
  }, [data?.length, filter.canSelectMany, uninvertedValue]);

  const normalizedSearchText = normalizeString(searchText);

  const [renderLimit, setRenderLimit] = useState<number>(500);
  useEffect(() => {
    const list = listRef.current;
    if (!(data && list)) {
      return;
    }
    const onScroll = () => {
      const isMoreThanHalf = list.scrollTop > list.scrollHeight / 2;
      if (isMoreThanHalf) {
        setRenderLimit((oldRenderLimit) => oldRenderLimit + 500);
      }
    };
    list.addEventListener('scroll', onScroll);
    return () => {
      list.removeEventListener('scroll', onScroll);
    };
  }, [data]);

  useEffect(() => {
    if (searchText) {
      setRenderLimit(500);
    }
  }, [searchText]);

  const sortedValues = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.sort((a, b) => {
      const aChecked = value[a.value] || false;
      const bChecked = value[b.value] || false;
      if (aChecked && !bChecked) {
        return -1;
      }
      if (!aChecked && bChecked) {
        return 1;
      }
      return 0;
    });
  }, [data]);

  const filteredData = useMemo(() => {
    if (filter.withSearch && !filter.withLiveSearch && normalizedSearchText) {
      const shouldUseFields = datasetIndex === 0;
      const keysToSearch = (shouldUseFields &&
        filter.optionFields?.map((field) => field.key)) || ['label', 'value'];
      return sortedValues.filter((item) => {
        const mergedSearchString = keysToSearch
          .map((key) => {
            const value =
              shouldUseFields && filter.optionFields
                ? item.fields?.[key]
                : item[key as 'label' | 'value'];

            return value || '';
          })
          .join(' ');

        return hasSubstring(normalizedSearchText, mergedSearchString, true);
      });
    }
    return sortedValues;
  }, [
    data,
    filter.withLiveSearch,
    filter.withSearch,
    filter.optionFields,
    normalizedSearchText,
  ]);

  const dataWithLimit = useMemo(() => {
    return filteredData.slice(0, renderLimit);
  }, [filteredData, renderLimit]);

  const someValueHasFields = useMemo(() => {
    return !!data?.some((item) => item.fields);
  }, [data]);

  const [isFieldsMenuOpen, setIsFieldsMenuOpen] = useState<
    | false
    | {
        left: number;
        top: number;
      }
  >(false);
  const [uncheckedFields, setUncheckedFields] = useSearchQueryState<
    Record<string, boolean>
  >(`${filter.uniqueKey}-fields`, {});
  const [ref] = useOutsideClickRef((e) => {
    e.stopImmediatePropagation();
    setIsFieldsMenuOpen(false);
  });

  return (
    <>
      {filter.datasets && (
        <div className={styles.datasets}>
          <LineSelect
            testId="dataset"
            value={filter.datasets[datasetIndex]?.value || ''}
            onChange={(value) => {
              const index = filter.datasets?.findIndex(
                (item) => item.value === value,
              );
              setDatasetIndex(index || 0);
              handleChange({});
            }}
            options={filter.datasets}
            fullWidth
          />
        </div>
      )}
      {filter.withSearch && (
        <Input
          testId="search"
          block
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={filter.searchPlaceholder}
          small
          noGap
          withClear
          leftIcon={<Icon size={18} name="search" />}
        />
      )}
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      {!isLoading && !isSomeValues && (
        <div className={styles.nothingFound}>Ничего не найдено</div>
      )}
      {!isLoading && (
        <div className={styles.controls}>
          <div
            className={classNames(styles.control, {
              [styles.disabled]: !isSelectedSome && !isSelectedAll,
            })}
            onClick={() => {
              if (!isSelectedSome && !isSelectedAll) {
                return;
              }
              handleChange({});
            }}
            data-test-id="reset-filter"
          >
            <Icon name="close" />{' '}
            <span className={styles.controlsLabel}>Сбросить</span>
          </div>
          {filter.canSelectMany && (
            <div
              className={classNames(styles.control, {
                [styles.disabled]: !isSelectedSome,
              })}
              onClick={() => {
                if (!isSelectedSome) {
                  return;
                }
                const newSelectedOptions: Record<string, boolean> = {};
                data?.forEach((option) => {
                  newSelectedOptions[option.value] =
                    !uninvertedValue[option.value];
                });
                handleChange(newSelectedOptions);
              }}
              data-test-id="invert-filter"
            >
              <Icon name="invert" />{' '}
              <span className={styles.controlsLabel}>Инверcия</span>
            </div>
          )}
          <Spacer />
          {someValueHasFields && filter.optionFields && (
            <div className={styles.control}>
              <div
                className={styles.control}
                onClick={(e) => {
                  if (isFieldsMenuOpen) {
                    setIsFieldsMenuOpen(false);
                    return;
                  }

                  const target = e.target as HTMLElement;
                  const rect = target.getBoundingClientRect();
                  setIsFieldsMenuOpen({
                    left: rect.left,
                    top: rect.bottom,
                  });
                }}
                data-test-id="filter-view-options"
              >
                Отображение
                <Icon
                  name={isFieldsMenuOpen ? 'collapseFilled' : 'expandFilled'}
                  size={24}
                />
              </div>
              {isFieldsMenuOpen &&
                ReactDOM.createPortal(
                  <div
                    className={styles.fieldsMenu}
                    ref={ref}
                    style={{
                      left: isFieldsMenuOpen.left,
                      top: isFieldsMenuOpen.top,
                    }}
                  >
                    {filter.optionFields.map((field) => {
                      return (
                        <Checkbox
                          key={field.key}
                          checked={!uncheckedFields[field.key]}
                          onChange={() => {
                            setUncheckedFields({
                              ...uncheckedFields,
                              [field.key]: !uncheckedFields[field.key],
                            });
                          }}
                          label={field.label}
                        />
                      );
                    })}
                  </div>,
                  document.body,
                )}
            </div>
          )}
        </div>
      )}
      {!isLoading && (
        <div
          className={styles.list}
          ref={listRef}
          data-test-id-unique-key={filter.uniqueKey}
          data-test-first-dataset={filter.datasets?.[0]?.value}
          data-test-current-dataset={currentDataset?.value}
          data-test-id="select-filter-list"
        >
          {isSomeValues && filter.canSelectMany && (
            <Checkbox
              testId={`select-all`}
              checked={isSelectedAll}
              onChange={() => {
                if (filter.canSelectMany) {
                  if (isSelectedAll) {
                    handleChange({});
                  } else {
                    const newSelectedOptions: Record<string, boolean> = {};
                    filteredData?.forEach((option) => {
                      newSelectedOptions[option.value] = true;
                    });
                    handleChange(newSelectedOptions);
                  }
                } else {
                  handleChange({});
                }
              }}
              label="Выбрать все"
            />
          )}
          {isSomeValues && !filter.canSelectMany && filter.selectAllLabel && (
            <Checkbox
              testId={`select-all`}
              checked={isSelectedAll}
              onChange={() => {
                handleChange({});
              }}
              radio
              label={filter.selectAllLabel}
            />
          )}
          {dataWithLimit.map((item, index) => {
            return (
              <Checkbox
                testId={`select-${item.value}`}
                key={index}
                checked={!!uninvertedValue[item.value]}
                onChange={() => {
                  if (filter.canSelectMany) {
                    if (uninvertedValue[item.value]) {
                      const newSelectedOptions = { ...uninvertedValue };
                      delete newSelectedOptions[item.value];
                      handleChange(newSelectedOptions);
                    } else {
                      handleChange({
                        ...uninvertedValue,
                        [item.value]: true,
                      });
                    }
                  } else {
                    handleChange({
                      [item.value]: true,
                    });
                  }
                }}
                radio={!filter.canSelectMany}
                label={
                  item.fields && filter.optionFields
                    ? filter.optionFields
                        .filter((field) => !uncheckedFields[field.key])
                        .map((field) => item.fields?.[field.key])
                        .join(' ')
                    : item.label
                }
              />
            );
          })}
        </div>
      )}
    </>
  );
};
