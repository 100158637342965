import { DS2R10Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Справочник юниверса производителя';

export const ds2o10Config: TReportConfigType<DS2R10Response> = {
  title: NAME,
  backendKey: 'ds2r10',

  dataFeatures: [],

  viewFeatures: [
    getTableFeature<DS2R10Response>({
      filename: NAME,
      baseColumns: [
        {
          key: 'sellerAddrSfCode',
          title: 'Код СФ',
          sort: sorter('sellerAddrSfCode', true),
          size: `130px`,
          isNumeric: true,
        },
        {
          key: 'sellerAddrSfName',
          title: 'Субъект РФ',
          sort: sorter('sellerAddrSfName'),
        },
        {
          key: 'sellerInn',
          title: 'ИНН участника',
          sort: sorter('sellerInn'),
        },
        {
          key: 'sellerName',
          title: 'Наименование участника',
          sort: sorter('sellerName'),
        },
        {
          key: 'sellerAddr',
          title: 'Адрес',
          sort: sorter('sellerAddr'),
        },
      ],
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'retailSellers',
      mode: 'inns',
      label: 'ИНН',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'rfCodes',
      mode: 'codes',
      label: 'Субъект Федерации',
      icon: 'location',
      single: false,
    }),
  ],
  requiredData: {
    availablePeriod: true,
  },
  requiredCommonData: ['retailSellers', 'rfCodes'],

  isRequiredFieldsFilled: () => true,
};
