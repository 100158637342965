/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import TPromise from 'thread-promises';

const EMPTY_ARRAY: any[] = [];

declare global {
  interface Window {
    importScripts: (script: string) => void;
  }
}

export const useThreadMemo = <TValue>(
  valueGetter: (...params: any[]) => TValue | string,
  defaultValue: TValue,
  deps: React.DependencyList,
  params: any[] = EMPTY_ARRAY,
  scriptsDeps: string[] = EMPTY_ARRAY,
): [value: TValue, isReady: boolean, isInitial: boolean] => {
  const [value, setValue] = useState<TValue>(defaultValue);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [wasReady, setWasReady] = useState<boolean>(false);
  const iterationId = useRef<number>(0);

  useEffect(() => {
    setIsReady(false);
    iterationId.current += 1;

    const currentIterationId = iterationId.current;

    const getValue = () => {
      const functionString = valueGetter.toString();

      new TPromise(
        // @ts-expect-error It expects function, but can work with string too
        `(resolve, reject, functionString, params, scriptsDeps) => {
          // eslint-disable-next-line no-debugger
          try {
            if (scriptsDeps) {
              for (let i = 0; i < scriptsDeps.length; i++) {
                const script = scriptsDeps[i];
                // eslint-disable-next-line no-undef
                importScripts(\`\${globalThis.location.origin}/\${script}\`);
              }
            }
          } catch (error) {
            reject(error);
          }

          try {
            // eslint-disable-next-line no-new-func
            const valueGetterInside = new Function(
              'return (' + functionString + ')',
            )();

            const result = valueGetterInside(...params);

            resolve(result);
          } catch (error) {
            reject(error);
          }
        }`,
        functionString,
        params,
        scriptsDeps,
      )
        .then((value) => {
          if (currentIterationId !== iterationId.current) {
            return;
          }

          setValue(value as TValue);
          setIsReady(true);
          setWasReady(true);
        })
        .catch((e) => {
          console.error(e);
        });
    };

    getValue();
  }, [...deps, ...params, ...scriptsDeps]);

  return [value, isReady, !wasReady];
};
