import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './Tooltip.module.scss';

type TTooltipProps = {
  hidden?: boolean;
  children: React.ReactNode;
  text: string;
  testId: string;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';
};

export const Tooltip: React.FC<TTooltipProps> = ({
  hidden,
  children,
  text,
  testId,
  placement = 'top',
}) => {
  const [isVisibile, setIsVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [referenceChildElement, setReferenceChildElement] =
    useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (referenceElement) {
      setReferenceChildElement(referenceElement.children[0] as HTMLElement);
    }
  }, [referenceElement]);

  useEffect(() => {
    if (!hidden && referenceElement) {
      const handleMouseEnter = () => setIsVisible(true);
      const handleMouseLeave = () => setIsVisible(false);

      referenceElement.addEventListener('mouseenter', handleMouseEnter);
      referenceElement.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        referenceElement.removeEventListener('mouseenter', handleMouseEnter);
        referenceElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [hidden, referenceElement]);

  const { styles: popperStyles, attributes } = usePopper(
    referenceChildElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
  );

  return (
    <div
      className={styles.container}
      data-test-id={`tooltip-${testId}`}
      data-tooltip-visible={!hidden && isVisibile}
      data-tooltip-text={text}
      ref={setReferenceElement}
    >
      {children}
      {!hidden &&
        isVisibile &&
        ReactDOM.createPortal(
          <div
            className={styles.tooltip}
            {...attributes.popper}
            style={popperStyles.popper}
            ref={setPopperElement}
          >
            {text}
          </div>,
          document.body,
        )}
    </div>
  );
};
