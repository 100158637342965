import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './FitToContent.module.scss';

type TFitToContentProps = {
  // Single html node, must have strict height and width
  children: React.ReactNode;
};

export const FitToContent: React.FC<TFitToContentProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isInitial, setIsInitial] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const observer = new ResizeObserver(() => {
      setIsCalculated(false);
    });
    observer.observe(container);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isCalculated) {
      const container = containerRef.current;
      if (!container) {
        return;
      }
      const contentElement = container.children[0] as HTMLElement;
      if (!contentElement) {
        return;
      }

      const contentHeight = contentElement.offsetHeight;
      const containerHeight = container.offsetHeight;

      const contentWidth = contentElement.offsetWidth;
      const containerWidth = container.offsetWidth;

      const heightScale = containerHeight / contentHeight;
      const widthScale = containerWidth / contentWidth;
      const scale = Math.min(heightScale, widthScale);

      contentElement.style.transform = `scale(${scale})`;
      contentElement.style.transformOrigin = 'center center';

      setIsCalculated(true);
      setIsInitial(false);
    }
  }, [isCalculated]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.initial]: isInitial,
      })}
      ref={containerRef}
    >
      {children}
    </div>
  );
};
