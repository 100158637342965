import React from 'react';
import { Button } from '../../../../uikit/Button/Button';
import XLSX from 'xlsx';
import { Table } from '../../../common/Table/Table';
import { Chart } from '../../../common/Chart/Chart';
import { PageContent } from '../../../common/PageContent/PageContent';
import { useIsFullscreen } from '../../../common/PageContent/useIsFullscreen';
import { Input } from '../../../../uikit/Input/Input';

import styles from './treeMap.module.scss';
import { Gap } from '../../../../uikit/Gap/Gap';
import { Modal } from '../../../../uikit/Modal/Modal';
import { LineSelect } from '../../../common/LineSelect/LineSelect';
import { useSearchQueryState } from '../../../../shared/hooks/useSearchQueryState';
import EChartsReact from 'echarts-for-react';
import { DEFAULT_ECHARTS_PROPS } from '../../../../shared/charts';
import {
  getTreemapConfig,
  GRID_TOP_OPTIONS,
} from '../../../../shared/chartsConfigs/treemap';
import { useGroupForTree } from '../../../../shared/utils/useGroupForTree';

type TRow = {
  name: string;
  inn: string;
  netName: string;
  value: number;
};

const DATA_EXAMPLE: TRow[] = [
  {
    name: 'Магазин А',
    inn: '1234567890',
    netName: 'Сеть А',
    value: 100000,
  },
  {
    name: 'Магазин Б',
    inn: '1234567891',
    netName: 'Сеть А',
    value: 200000,
  },
  {
    name: 'Магазин B',
    inn: '1234567892',
    netName: 'Сеть B',
    value: 300000,
  },
];

const COLUMNS_CONFIG = [
  {
    wch: 20,
  },
  {
    wch: 20,
  },
  {
    wch: 20,
  },
];

const TABLE_ROWS = [
  {
    key: 'name',
    title: 'Название',
  },
  {
    key: 'inn',
    title: 'ИНН',
  },
  {
    key: 'netName',
    title: 'Сеть',
  },
  {
    key: 'value',
    title: 'Значение',
  },
];

export const TreeMapScreenshoter = () => {
  const [currentData, setCurrentData] = React.useState<TRow[]>([]);
  const [isFullscreen] = useIsFullscreen();

  const [label, setLabel] = useSearchQueryState<string>(
    'label',
    'Оптовые продавцы',
  );
  const [datasetMode, setDatasetMode] = useSearchQueryState<'inn' | 'network'>(
    'dsMode',
    'inn',
  );
  const [gridTop, setGridTop] = useSearchQueryState<'all' | number>(
    'gridTop',
    'all',
  );

  const [isContentOpen, setIsContentOpen] = React.useState(false);
  const [fileKey, setFileKey] = React.useState(0);

  const gridData = useGroupForTree(
    currentData,
    {
      parentKey: datasetMode !== 'inn' ? 'netName' : 'inn',
      parentNameKey: datasetMode !== 'inn' ? 'netName' : 'name',
      childrenNameKey: 'name',
      innKey: 'inn',
      valueKey: 'value',
    },
    true,
    datasetMode !== 'inn',
  );

  return (
    <div className={styles.container}>
      {!isFullscreen && (
        <div className={styles.left}>
          <Chart testId="chart">
            <div>
              <Button
                testId="download-example"
                onClick={() => {
                  const worksheet = XLSX.utils.json_to_sheet(DATA_EXAMPLE);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  worksheet['!cols'] = COLUMNS_CONFIG;
                  XLSX.writeFile(workbook, 'example.xlsx');
                }}
              >
                Скачать пример
              </Button>
            </div>
            <Gap size={16} />
            <div className={styles.formatTable}>
              <Table
                noMargin
                cols={[
                  {
                    key: 'key',
                    title: 'Поле',
                  },
                  {
                    key: 'type',
                    title: 'Тип',
                  },
                ]}
                rows={[
                  {
                    key: 'name',
                    type: 'Строка',
                  },
                  {
                    key: 'inn',
                    type: 'Строка',
                  },
                  {
                    key: 'netName',
                    type: 'Строка',
                  },
                  {
                    key: 'value',
                    type: 'Число',
                  },
                ]}
              />
            </div>
            <Gap size={16} />
            <Input
              testId="file"
              block
              custom={
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) {
                      return;
                    }
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const data = e.target?.result;
                      if (!data) {
                        return;
                      }
                      const workbook = XLSX.read(data, { type: 'binary' });
                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                      const json = XLSX.utils.sheet_to_json(worksheet);
                      setCurrentData(json as TRow[]);
                      setFileKey(fileKey + 1);
                    };
                    reader.readAsBinaryString(file);
                  }}
                />
              }
              label="Файл"
            />
            <Input
              testId="label"
              block
              value={label}
              label="label"
              onChange={(e) => setLabel(e.target.value)}
            />
            <Input
              testId="dataset-mode"
              label="Тип данных"
              block
              custom={
                <LineSelect
                  testId="dataset-mode-select"
                  value={datasetMode}
                  // @ts-ignore
                  onChange={setDatasetMode}
                  options={[
                    {
                      value: 'inn',
                      label: 'ИНН',
                    },
                    {
                      value: 'network',
                      label: 'Сети',
                    },
                  ]}
                />
              }
            />
            <Input
              testId="grid-top"
              label="Топ"
              block
              custom={
                <LineSelect
                  testId="grid-top-select"
                  whiteBackground
                  value={`${gridTop}`}
                  // @ts-ignore
                  onChange={(value) => {
                    if (value === 'all') {
                      setGridTop('all');
                    } else {
                      setGridTop(+value);
                    }
                  }}
                  options={[
                    {
                      value: 'all',
                      label: 'Все',
                    },
                    ...GRID_TOP_OPTIONS.map((item) => ({
                      value: `${item}`,
                      label: `Топ-${item}`,
                    })),
                  ]}
                />
              }
            />

            <div>
              <Button
                testId="open-data"
                color="darkGrey"
                onClick={() => {
                  setIsContentOpen(!isContentOpen);
                }}
              >
                Данные
              </Button>
            </div>

            <Modal
              testId="data"
              isOpened={isContentOpen}
              onClose={() => setIsContentOpen(false)}
              className={styles.modal}
            >
              <Table
                noMargin
                rows={currentData}
                // @ts-ignore
                cols={TABLE_ROWS}
              />
            </Modal>
          </Chart>
        </div>
      )}
      <div className={styles.right}>
        {currentData.length > 0 && (
          <PageContent>
            <Chart fullHeight testId="chart">
              <EChartsReact
                key={datasetMode + fileKey}
                {...DEFAULT_ECHARTS_PROPS}
                option={getTreemapConfig(
                  gridData,
                  gridTop,
                  gridTop === 'all' && datasetMode === 'inn',
                  label,
                )}
              />
            </Chart>
          </PageContent>
        )}
      </div>
    </div>
  );
};
