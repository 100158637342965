import QueryString from 'qs';
import { contextLengthApi } from '../../shared/contexts/contentLength';
import { formatError } from '../../shared/utils/withErrorHandling';
import { $api } from '../utils';
import { ReportApi } from './ReportApiTypes';
import * as Sentry from '@sentry/browser';
import { parseReportKey } from '../../shared/utils/parseReportKey';

const getValuesGetter = (path: string) => async (search?: string) => {
  try {
    const { data } = await $api.get(`${path}?search=${search || ''}`);

    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

export const getReportApi = <TReportInfo extends any>(
  reportKey: string,
): ReportApi<TReportInfo> => {
  const { datasetId, reportId } = parseReportKey(reportKey);

  return {
    fetchAvailablePeriods: getValuesGetter(`/data/ds${datasetId}/periods`),

    fetchReportInfo: async (config, api = $api, queryKey?: unknown[]) => {
      const queryKeyString = queryKey ? JSON.stringify(queryKey) : '';

      try {
        const start = Date.now();
        const { data } = await api.get(`/data/ds${datasetId}/r${reportId}`, {
          params: config,
          paramsSerializer: (params) => {
            return QueryString.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
          onDownloadProgress: (event) => {
            if (queryKeyString && event.total && event.loaded) {
              contextLengthApi.current.setContentLengthsPercent(
                queryKeyString,
                Math.round((100 * event.loaded) / event.total),
              );
            }
          },
        });
        const end = Date.now();
        const duration = end - start;
        if (duration > 30000) {
          Sentry.captureMessage(`Report ${reportKey} took too long to load:`, {
            extra: {
              duration,
              config,
            },
          });
        }

        return data;
      } catch (error) {
        throw new Error(formatError(error));
      } finally {
        if (queryKeyString) {
          contextLengthApi.current.deleteContentLengthsPercents(queryKeyString);
        }
      }
    },
  };
};
