import { initialValues } from './constants';
import { isEmpty } from './../../../shared/utils/isEmpty';

// eslint-disable-next-line import/no-unused-modules
export const getRandomString = (length) => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const validateFields = (values, updateFormValues) => {
  let errors = {};

  for (const item in values) {
    if (isEmpty(values[item])) {
      errors.full = 'Необходимо заполнить все поля';
      updateFormValues(errors);
      return false;
    }
  }

  if (values.alias && /[А-Я-Ёа-я-ё]/.test(values.alias)) {
    errors.alias = 'Псевдоним может содержать только латиницу';
    updateFormValues(errors);
    return false;
  }

  if (values.password !== values.passwordConfirm) {
    errors.full = 'Пароли отличаются';
    updateFormValues(errors);
    return false;
  }

  if (!values.login) {
    errors.login = 'E-mail обязателен';
  } else if (!/.+@.+\..+/i.test(values.login)) {
    errors.login = 'E-mail введен неверно';
  }
  updateFormValues(errors);

  if (Object.keys(errors).length === 0) {
    return true;
  } else {
    return false;
  }
};

export const getInitialValuesByAdminAndType = (isAdmin, type) => {
  const fieldsByAdmin = isAdmin
    ? {
        alias: '',
      }
    : {
        role: 'CLIENT_MANAGER',
      };
  const fieldsByType =
    type !== 'edit'
      ? {
          passwordConfirm: '',
        }
      : {};

  return {
    ...initialValues,
    ...fieldsByAdmin,
    ...fieldsByType,
  };
};
