import { formatNumericNames } from './formatNumericNames';

export const EXACT_NUMERIC_FORMATTER = (value: number) => {
  if (!value) {
    return '0';
  }
  return value.toLocaleString('ru', {
    maximumFractionDigits: 0,
    useGrouping: true,
  });
};

export const DEFAULT_NUMERIC_FORMATTER = (
  value: number,
  short: boolean = false,
) => formatNumericNames(value, short);

export const DEFAULT_PERCENT_FORMATTER = (value: number) =>
  `${Math.round(value * 100)}%`;

export const AS_IS_FORMATTER = (value: any) => value;
