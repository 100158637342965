import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useColumnsShared = (): [any[], (newData: any[]) => void] => {
  const [data, setData] = useState([] as any[]);
  return [data, setData];
};

export const Columns = createContainer(useColumnsShared);

export const useColumns = () => Columns.useContainer();
