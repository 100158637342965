import isEqual from 'lodash/isEqual';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

type TState =
  | string
  | number
  | boolean
  | null
  | {
      [key: string]: TState;
    };

export const useSearchQueryState = <TStateType extends TState>(
  key: string,
  defaultState: TStateType,
): [value: TStateType, setValue: (newValue: TStateType) => void] => {
  const [state, setState] = useState<TStateType>(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const value = urlSearchParams.get(key);
    if (value === null) {
      return defaultState;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      Sentry.captureException(e);
      return defaultState;
    }
  });

  const updateState = useCallback(
    (newState: TStateType) => {
      setState(newState);
      const stateJSON = JSON.stringify(newState);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, stateJSON);
      window.history.replaceState(null, '', '?' + searchParams.toString());
      const popStateEvent = new PopStateEvent('popstate');
      dispatchEvent(popStateEvent);
    },
    [key],
  );

  useEffect(() => {
    function handlePopstate() {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const stateJSON = searchParams.get(key);
        if (stateJSON) {
          const parsedState = JSON.parse(stateJSON);
          if (!isEqual(parsedState, state)) {
            setState(parsedState);
          }
        } else {
          if (!isEqual(defaultState, state)) {
            setState(defaultState);
          }
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    window.addEventListener('popstate', handlePopstate);

    return () => window.removeEventListener('popstate', handlePopstate);
  }, [key, state, defaultState]);

  return [state, updateState];
};
