import React, { useMemo } from 'react';
import {
  DEFAULT_PERCENT_FORMATTER,
  EXACT_NUMERIC_FORMATTER,
} from '../../../../../../shared/utils/formatters';
import { Chart } from '../../../../../common/Chart/Chart';
import { RegionsMap } from '../../../../../common/RegionsMap/RegionsMap';
import { ViewFeature } from '../reportConfigType';

type TGetMapFeatureConfig<TDataItem> = {
  filename: string;
  regionCodeKey: keyof TDataItem;
  valueKey: keyof TDataItem;
  subValueKey?: keyof TDataItem;
  valueLabel: string;
  subValueLabel?: string;
  showPercentArrow?: boolean;
  subValueFormatter?: (value: number) => string;
  valueFormatter?: (value: number) => string;
};

export const getMapFeature = <TDataItem,>(
  config: TGetMapFeatureConfig<TDataItem>,
): ViewFeature<TDataItem> => {
  const MapComponent: ViewFeature<TDataItem>['component'] = ({ data }) => {
    const mapData = useMemo(() => {
      if (!data) {
        return [];
      }
      return data.map((row) => ({
        regionCode: row[config.regionCodeKey] as number,
        value: row[config.valueKey] as number,
        subValue: (config.subValueKey && row[config.subValueKey]) as
          | number
          | undefined,
      }));
    }, [data]);

    return (
      <>
        <Chart fullHeight testId="map" name="Карта">
          <RegionsMap
            data={mapData}
            subValueFormatter={
              config.subValueFormatter || EXACT_NUMERIC_FORMATTER
            }
            valueFormatter={config.valueFormatter || DEFAULT_PERCENT_FORMATTER}
            valueLabel={config.valueLabel}
            subValueLabel={config.subValueLabel}
            showPercentArrow={config.showPercentArrow}
          />
        </Chart>
      </>
    );
  };

  return {
    key: 'map',
    title: 'Карта',
    icon: 'map',
    hint: 'Карта',
    component: MapComponent,
  };
};
