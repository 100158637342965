import React from 'react';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { PageContent } from '../../common/PageContent/PageContent';

import { Widget } from './components/Widget/Widget';

import styles from './Dashboard.module.scss';
import { withUserFlagRestriction } from '../../../shared/hocs/withRestriction';

export const Dashboard: React.FC = withUserFlagRestriction('moduleAOrB', () => {
  return (
    <>
      <PageHeader title="Дашборд" supportKey="db" />
      <PageContent scroll>
        <div className={styles.dashboard}>
          <div className={styles.grid}>
            <Widget name="types" />
            <Widget name="rSales" />
            <Widget name="gtinCount" />
            <Widget name="rPoints" />
            <Widget name="dataUpdate" />
            <Widget name="myTools" />
            <Widget name="events" />
          </div>
        </div>
      </PageContent>
    </>
  );
});
