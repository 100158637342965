/* eslint-disable no-console */
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

export const withErrorHandling = async (
  fn: () => Promise<void>,
): Promise<void> => {
  try {
    await fn();
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    // @ts-ignore
    console.log(error, error.message);
    toast.error((error as Error)?.message || 'Что-то пошло не так');
  }
};

export const formatError = (error: any) => {
  const {
    response: { data },
  } = error;
  const fields = [data?.code, data?.error].filter(Boolean);
  return fields.join(': ') || error.message || 'Unknown error';
};
