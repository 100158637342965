import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { REGIONS_LIST } from '../../newComponents/common/RegionsMap/constants';
import { saveAsFile } from '../../shared/utils/saveAsFile';
import { RegionsMap } from '../../newComponents/common/RegionsMap/RegionsMap';
import EChartsReact from 'echarts-for-react';
import { makeScreenshot } from '../../shared/utils/makeScreenshot';
import { SCREENSHOT_CLASSNAME } from '../../newComponents/common/Chart/components/DownloadChart/DownloadChart';

type TDataItem = {
  value: number;
  valuesHistory: number[];
};

type TData = Record<string, TDataItem>;

const getRandomData = (): TData => {
  return REGIONS_LIST.reduce((acc: TData, region) => {
    const value = Math.round(Math.random() * 100);
    const valuesHistory = [];
    for (let i = 0; i < 10; i++) {
      valuesHistory.push(Math.round(Math.random() * 100));
    }
    acc[region.code] = {
      value,
      valuesHistory,
    };
    return acc;
  }, {} as TData);
};

export const MapDev: React.FC = () => {
  const [data, setData] = useState<TData | null>(null);
  const [gradientByHistory, setGradientByHistory] = useState(false);

  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          try {
            const file = e.target.files?.[0];
            if (!file) return;
            const reader = new FileReader();
            reader.onload = () => {
              const result = reader.result as string;
              const data = JSON.parse(result);
              setData(data);
            };
            reader.readAsText(file);
          } catch (e) {
            console.error(e);
            toast.error('Ошибка чтения файла');
          }
          e.target.value = '';
        }}
      />
      <div>
        <button
          onClick={() => {
            const content = JSON.stringify(getRandomData(), null, 2);
            const url = window.URL.createObjectURL(new Blob([content]));
            saveAsFile(url, 'data.json');
          }}
        >
          Скачать пример
        </button>
      </div>

      <div>
        <button
          onClick={() => {
            makeScreenshot(`.${SCREENSHOT_CLASSNAME}`, 'map.png');
          }}
        >
          Скачать картинку
        </button>
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            checked={gradientByHistory}
            onChange={(e) => {
              setGradientByHistory(e.target.checked);
            }}
          />
          Градиент по истории
        </label>
      </div>

      {!data && <div>Нет данных</div>}
      {data && (
        <div
          style={{
            width: '100vw',
            height: 'calc(100vh - 200px)',
            position: 'relative',
          }}
        >
          <RegionsMap
            gradientByHistory={gradientByHistory}
            colorScheme="redToGreen"
            compact
            data={Object.keys(data).map((key) => ({
              regionCode: +key,
              value: data[key].value,
              valuesHistory: data[key].valuesHistory,
            }))}
            subValueLabel="История"
            subValueFormatter={(_value, valuesHistory) => {
              if (!valuesHistory) {
                return null;
              }
              return (
                <div
                  style={{
                    height: 30,
                    width: 75,
                    flexShrink: 0,
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      height: 100,
                      width: 260,
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      transformOrigin: 'top left',
                      transform: `scale(${30 / 100})`,
                    }}
                  >
                    <EChartsReact
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                      option={{
                        grid: {
                          left: 10,
                          right: 15,
                          top: 10,
                          bottom: 23,
                        },
                        xAxis: {
                          type: 'category',
                          show: false,
                        },
                        yAxis: {
                          type: 'value',
                          show: false,
                        },
                        legend: {
                          show: false,
                        },
                        series: [
                          {
                            type: 'line',
                            data: valuesHistory,
                            smooth: true,
                            lineStyle: {
                              width: 6,
                            },
                            itemStyle: {
                              opacity: 0,
                            },
                          },
                        ],
                        animation: false,
                      }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
