import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from '../../common/PageHeader/PageHeader';
import { PageContent } from '../../common/PageContent/PageContent';
import { getVisibleReports, ReportMeta, TAGS } from './constants';
import { Disclaimers } from '../../Disclaimers/Disclaimers';
import {
  withRestriction,
  withUserFlagRestriction,
} from '../../../shared/hocs/withRestriction';
import { LineSelect } from '../../common/LineSelect/LineSelect';
import { useLocalStorageState } from '../../../shared/hooks/useLocalStorageState';
import { ReportsList } from './ReportsList';
import { ReportsTagsFilter } from './ReportsTagsFilter';
import { useSearch } from '../../../shared/hooks/useSearch';
import { Input } from '../../../uikit/Input/Input';
import { useCommonData } from '../../../api/reports/useCommonData';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import { useFavoritesList } from '../../common/FavoriteButton/FavoriteButton';
import styles from './Reports.module.scss';
import { Icon } from '../../common/Icon/Icon';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';

const NUMBERS_REGEXP = /^(дс|ds)?-?(\d+)[-rрoо•]{0,2}-?(\d+)?$/;

const searchByNumbers = (item: ReportMeta, search: string): boolean => {
  if (!search) {
    return false;
  }
  const numbers = search.trim().replace(/\s/g, '').match(NUMBERS_REGEXP);
  const sectionNumber = numbers?.[2];
  const reportNumber = numbers?.[3];
  if (sectionNumber && reportNumber) {
    return (
      item.sectionKey === +sectionNumber && item.reportKey === +reportNumber
    );
  }
  if (sectionNumber) {
    return item.sectionKey === +sectionNumber;
  }
  return false;
};

export const Reports: React.FC = withUserFlagRestriction(
  'moduleAOrB',
  withRestriction(['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'], () => {
    const userData = useAuthorizedUser();
    const visibleReports = getVisibleReports(userData);

    useCommonData();
    const navigate = useNavigate();
    const [selectedTags, setSelectedTags] = useState<Record<string, boolean>>(
      {},
    );
    const [onlyFavorites, setOnlyFavorites] = useLocalStorageState<boolean>(
      'onlyFavorites',
      false,
    );
    const favorites = useFavoritesList();
    const filteredReports = useMemo(() => {
      const filterByFavorites = (report: ReportMeta) => {
        if (!onlyFavorites) {
          return true;
        }
        const reportFavKey = `ds${report.sectionKey}r${report.reportKey}`;
        return favorites[reportFavKey];
      };

      if (!Object.values(selectedTags).some(Boolean)) {
        return visibleReports.filter(filterByFavorites);
      }
      const selectedTagsList = Object.entries(selectedTags)
        .filter(([, isSelected]) => isSelected)
        .map(([tag]) => tag);

      return visibleReports
        .filter((report) => {
          return selectedTagsList.every((tag) => report.tags.includes(tag));
        })
        .filter(filterByFavorites);
    }, [selectedTags, favorites, onlyFavorites, visibleReports.length]);

    const breakpoints = useBreakpoint();
    const [viewMode, setViewMode] = useLocalStorageState<'grid' | 'table'>(
      'viewMode',
      'grid',
    );

    const [searchValue, onChange, filteredReportsWithSearch] = useSearch(
      filteredReports,
      searchByNumbers,
    );

    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

    const searchInput = (
      <Input
        testId="search"
        noGap
        width={isInputFocused && !breakpoints.isSmall ? 400 : 200}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        leftIcon={<Icon size={18} name="search" />}
        placeholder="Поиск по названию"
        search
        value={searchValue}
        onChange={onChange}
      />
    );

    return (
      <>
        <PageHeader
          supportKey="rep"
          align="left"
          title="Отчеты"
          secondLine={<Disclaimers />}
          rightTitleContent={
            <div className={styles.headerRow}>
              {breakpoints.isLarge && (
                <ReportsTagsFilter
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  tags={TAGS}
                />
              )}
              {searchInput}
            </div>
          }
        />
        <PageContent>
          <div className={styles.filtersRow}>
            <div className={styles.filtersRowItem}>
              <LineSelect
                testId="onlyFavorites"
                value={`${onlyFavorites}`}
                onChange={(value) => {
                  setOnlyFavorites(value === 'true');
                }}
                whiteBackground
                primaryColor
                options={[
                  {
                    value: 'false',
                    label: 'Все',
                  },
                  {
                    value: 'true',
                    label: 'Избранное',
                  },
                ]}
              />
            </div>

            <div className={styles.filtersRowItem}>
              {breakpoints.isLarge && (
                <LineSelect
                  testId="viewMode"
                  value={viewMode}
                  onChange={setViewMode}
                  whiteBackground
                  primaryColor
                  options={[
                    {
                      value: 'grid',
                      label: 'Таблица',
                    },
                    {
                      value: 'table',
                      label: 'Список',
                    },
                  ]}
                />
              )}
            </div>
          </div>
          {filteredReportsWithSearch.length !== 0 && (
            <ReportsList
              viewMode={breakpoints.isLarge ? viewMode : 'grid'}
              reports={filteredReportsWithSearch}
              navigate={navigate}
              key={`${onlyFavorites}`}
            />
          )}
          {filteredReportsWithSearch.length === 0 && (
            <div className={styles.notFound}>
              К сожалению, с выбранными фильтрами ничего не найдено
            </div>
          )}
        </PageContent>
      </>
    );
  }),
);
