import { DS1R7Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { getGroupByFeature } from '../AllPurposeReport/dataFeatures/groupBy';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'GTIN в рознице';

export const ds1o7Config: TReportConfigType<DS1R7Response> = {
  title: NAME,
  backendKey: 'ds1r7',

  dataFeatures: [
    getGroupByFeature<DS1R7Response>({
      options: [
        {
          groupBy: 'sellerInn',
          label: 'ИНН',
          additionalColumns: [
            {
              key: 'sellerInn',
              title: 'ИНН продавца',
              sort: sorter('sellerInn'),
            },
            {
              key: 'sellerName',
              title: 'Название продавца',
              sort: sorter('sellerName'),
            },
          ],
        },
        {
          groupBy: 'sellerNetName',
          label: 'Сети',
          additionalColumns: [
            {
              key: 'sellerNetName',
              title: 'Название сети',
              sort: sorter('sellerNetName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', 'sharePercent'],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS1R7Response>({
      defaultSort: 'salesCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'salesCnt',
          title: 'Объем продаж, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('salesCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
        },
      ],
    }),
    getBarChartFeature<DS1R7Response>({
      nameKey: 'sellerName',
      valueKey: 'salesCnt',
      filename: NAME,
      innKey: 'sellerInn',
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
      },
    }),
    getBarChartFeature<DS1R7Response>({
      nameKey: 'sellerName',
      valueKey: 'salesCnt',
      withPercents: true,
      innKey: 'sellerInn',
      accumulateBy: 'sharePercent',
      icon: 'barChartWithLine',
      filename: NAME,
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
      },
    }),
    getTreeMapFeature<DS1R7Response>({
      topName: NAME,
      parentNameKey: 'sellerInn',
      childrenNameKey: 'sellerName',
      innKey: 'sellerInn',
      valueKey: 'salesCnt',
      filename: NAME,
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товар',
      icon: 'package',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['gtins'],

  isRequiredFieldsFilled: () => true,
};
