import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import filterIcon from './icons/filter.svg';
import contactIcon from './icons/contact.svg';
import sortIcon from './icons/sort.svg';
import calendarIcon from './icons/calendar.svg';
import packageIcon from './icons/package.svg';
import locationIcon from './icons/location.svg';

import styles from './PageWithFilter.module.scss';
import { TFilter } from './filter';
import { FilterContent, FilterPrefetcher } from './FilterContent/FilterContent';
import { Button } from '../../../uikit/Button/Button';
import { useSearchQueryState } from '../../../shared/hooks/useSearchQueryState';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import { useIsFullscreen } from '../PageContent/useIsFullscreen';
import { Tooltip } from '../Tooltip/Tooltip';
import { useIsFetching } from '@tanstack/react-query';
import { useModuleAOnly } from '../../../shared/hooks/useModuleAOnly';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';

export const ICONS = {
  filter: filterIcon,
  contact: contactIcon,
  sort: sortIcon,
  calendar: calendarIcon,
  package: packageIcon,
  location: locationIcon,
};

export type TFilterItem = {
  label: string;
  icon: keyof typeof ICONS;
  key: string;
  filter: TFilter;
  initialValue: any;
  getTags?: (value: any) => string[];
  initiallyOpen?: boolean;
  isRequired?: boolean;

  getQueryKeyPart?: (filterState: Record<string, any>) => string[];
};

const GROWABLE: Partial<Record<TFilter['type'], boolean>> = {
  select: true,
};

type TPageWithFilterProps = {
  filters: Array<TFilterItem>;
  forceExpanded?: boolean;
  withBorder?: boolean;
};

const RESIZE_MIN = 430;
const RESIZE_MAX = RESIZE_MIN * 2;

const getInitialState = (filters: Array<TFilterItem>) => {
  const initialState: Record<string, any> = {};

  filters.forEach((filter) => {
    initialState[filter.key] = filter.initialValue;
  });

  return initialState;
};

export const useFilterState = (
  filters: Array<TFilterItem>,
): [
  value: Record<string, any>,
  setValue: (newValue: Record<string, any>) => void,
  initialValue: Record<string, any>,
] => {
  const initialState = useMemo(() => getInitialState(filters), [filters]);
  const [storedValue, setStoredValue] = useSearchQueryState(
    'filter',
    initialState,
  );
  const setStoredValueAndResetHash = (newValue: Record<string, any>) => {
    window.location.hash = '';
    setStoredValue(newValue);
  };
  return [
    storedValue as Record<string, any>,
    setStoredValueAndResetHash,
    initialState,
  ];
};

const prepareForCompare = (value: Record<string, any>) => {
  const newValue = { ...value };
  delete newValue.datasetKey;
  for (const key in newValue) {
    if (newValue[key] instanceof Date || typeof newValue[key] === 'string') {
      newValue[key] = dayjs(newValue[key]).format('YYYY-MM-DD');
    } else if (typeof newValue[key] === 'object' && newValue[key] !== null) {
      newValue[key] = prepareForCompare(newValue[key]);
    }
  }
  return newValue;
};

export const PageWithFilter: React.FC<
  React.PropsWithChildren<TPageWithFilterProps>
> = ({ children, filters, forceExpanded: propForceExpanded, withBorder }) => {
  const breakpoints = useBreakpoint();
  const forceExpanded = propForceExpanded && !breakpoints.isSmall;
  const [isFullscreen] = useIsFullscreen();

  const isSomeFilterOpen = useMemo(
    () => filters.some((filter) => filter.initiallyOpen),
    [filters],
  );
  const [isExpandedState, setIsExpanded] = useState<boolean>(isSomeFilterOpen);
  const isExpanded = forceExpanded || isExpandedState;
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);
  const [expandedFilters, setExpandedFilters] = useState<
    Record<string, boolean>
  >(() => {
    const result: Record<string, boolean> = {};

    filters.forEach((filter) => {
      result[filter.key] = filter.initiallyOpen || false;
    });

    return result;
  });

  const [storedValue, setStoredValue, initialState] = useFilterState(filters);
  const [value, setValue] = useState<Record<string, any>>(
    storedValue as Record<string, any>,
  );

  const areValuesEqual = useMemo(
    () => isEqual(value, storedValue),
    [value, storedValue],
  );

  const isFetching = useIsFetching();
  const wasAppliedRef = React.useRef(false);
  const isModuleAOnly = useModuleAOnly();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isFetching && !wasAppliedRef.current && !isModuleAOnly) {
        wasAppliedRef.current = true;
        setStoredValue(value);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [areValuesEqual, isFetching, isModuleAOnly, setStoredValue, value]);

  const filterListRef = React.useRef<HTMLDivElement>(null);
  const [expandWidth, setExpandWidth] = useState<number>(RESIZE_MIN);
  const [isExpanding, setIsExpanding] = useState<boolean>(false);
  const [expandTriggerStartPosition, setExpandTriggerStartPosition] =
    useState<number>(0);
  const [expandWidthAtStart, setExpandWidthAtStart] = useState<number>(0);

  useEffect(() => {
    if (isExpanding) {
      const onMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        const expandWidthDiff = e.clientX - expandTriggerStartPosition;
        const newWidth = Math.min(
          Math.max(expandWidthAtStart + expandWidthDiff, RESIZE_MIN),
          RESIZE_MAX,
        );
        const filterList = filterListRef.current;
        if (filterList) {
          filterList.style.width = `${newWidth}px`;
        }
        setExpandWidth(newWidth);
      };
      const onMouseUp = () => {
        setIsExpanding(false);
      };
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('blur', onMouseUp);

      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        window.removeEventListener('blur', onMouseUp);
      };
    }
  }, [expandTriggerStartPosition, isExpanding, expandWidthAtStart]);

  return (
    <div className={styles.container}>
      {breakpoints.isSmall && <div className={styles.filtersPlaceholder} />}
      <div
        className={classNames(styles.filters, {
          [styles.expanded]: isExpanded,
          [styles.isFullscreen]: isFullscreen,
          [styles.isResizing]: isExpanding,
          [styles.withBorder]: withBorder,
        })}
        data-test-id="filters"
        style={{
          flexBasis: isExpanded ? expandWidth : undefined,
        }}
      >
        {filters.map((filter) => (
          <FilterPrefetcher
            value={value[filter.key]}
            key={filter.key}
            filter={filter.filter}
            onChange={(newValue) => {
              setValue({
                ...value,
                [filter.key]: newValue,
              });
            }}
          />
        ))}
        <div className={styles.filtersListCollapsed}>
          <div
            className={styles.filterItem}
            data-test-id="filter-item-collapsed"
            onClick={toggleIsExpanded}
          >
            <div className={styles.icon}>
              <img src={filterIcon} alt="Filters" />
            </div>
          </div>
          {filters.map((filter) => {
            const hasValue = !isEqual(
              prepareForCompare(value[filter.key]),
              prepareForCompare(filter.initialValue),
            );
            return (
              <Tooltip
                testId={'filter-item'}
                text={filter.label}
                placement="right"
                key={filter.key}
              >
                <div
                  data-test-id="filter-item-collapsed"
                  data-test-filter-type={`${filter.key}-small`}
                  data-test-has-value={hasValue}
                  className={classNames(styles.filterItem, {
                    [styles.hasSelected]: hasValue,
                  })}
                  onClick={(e) => {
                    setExpandedFilters({
                      [filter.key]: true,
                    });
                    toggleIsExpanded();
                  }}
                >
                  <div className={styles.icon}>
                    <img src={ICONS[filter.icon]} alt={filter.label} />
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </div>
        <div
          className={styles.filtersListExpanded}
          ref={filterListRef}
          style={{
            width: breakpoints.isSmall ? undefined : expandWidth,
          }}
        >
          <div className={styles.list}>
            <div className={classNames(styles.item, styles.header)}>
              <div className={styles.head}>
                <div className={styles.icon}>
                  <img src={filterIcon} alt="Filters" />
                </div>{' '}
                Фильтры
              </div>{' '}
            </div>
            {filters.map((filter) => (
              <div
                className={classNames(styles.item, {
                  [styles.expanded]: expandedFilters[filter.key],
                  [styles.shouldGrow]:
                    expandedFilters[filter.key] && GROWABLE[filter.filter.type],
                })}
                data-test-id="filter-item"
                data-test-filter-type={filter.key}
                data-test-filter-is-expanded={expandedFilters[filter.key]}
                key={filter.key}
              >
                <div
                  className={styles.head}
                  onClick={() => {
                    setExpandedFilters({
                      [filter.key]: !expandedFilters[filter.key],
                    });
                  }}
                  data-test-id="filter-item-title"
                >
                  <div className={styles.icon}>
                    <img src={ICONS[filter.icon]} alt={filter.label} />
                  </div>
                  <div className={styles.label}>
                    {filter.label}
                    {filter.isRequired && (
                      <span
                        data-test-id="required-mark"
                        className={styles.required}
                      >
                        *
                      </span>
                    )}
                  </div>
                  {filter.getTags && (
                    <div
                      className={styles.tags}
                      data-test-id="filter-item-tags"
                    >
                      {filter.getTags(value[filter.key]).map((tag) => (
                        <span className={styles.tag} key={tag}>
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                  <div
                    className={classNames(styles.expand, {
                      [styles.expanded]: expandedFilters[filter.key],
                    })}
                  />
                </div>
                {expandedFilters[filter.key] && (
                  <div className={styles.content} data-test-id="filter-content">
                    <FilterContent
                      value={value[filter.key]}
                      onChange={(newValue) => {
                        setValue({
                          ...value,
                          [filter.key]: newValue,
                        });
                      }}
                      filter={filter.filter}
                      key={filter.key}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              testId="reset-filter-button"
              fullWidth
              color="grey"
              onClick={(e) => {
                setValue(initialState);
                setStoredValue(initialState);
                toggleIsExpanded();
              }}
            >
              Сбросить
            </Button>
            <Button
              testId="apply-filter-button"
              fullWidth
              onClick={(e) => {
                setStoredValue(value);
                setIsExpanded(false);
              }}
              disabled={areValuesEqual}
              color={areValuesEqual ? 'grey' : 'yellow'}
            >
              Применить
            </Button>
          </div>
          <div
            className={styles.dragTrigger}
            onMouseDown={(e) => {
              e.preventDefault();
              setExpandTriggerStartPosition(e.clientX);
              setExpandWidthAtStart(expandWidth);
              setIsExpanding(true);
            }}
          />
        </div>
      </div>
      <div
        className={classNames(styles.expandButton, {
          [styles.expanded]: isExpanded,
          [styles.isHidden]: forceExpanded,
        })}
        style={{
          left: isExpanded ? expandWidth : 64,
        }}
        onClick={toggleIsExpanded}
        data-test-id="filters-expand-button"
        data-test-expanded={isExpanded}
      >
        <div className={styles.icon} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
