import React, { useState } from 'react';
import classNames from 'classnames';
import icon from './wired-outline-736-funnel-tools-utensils.json';

import { useLottie } from 'lottie-react';

import styles from './Reports.module.scss';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import { Icon } from '../../common/Icon/Icon';

type Props = {
  tags: string[];
  selectedTags: Record<string, boolean>;
  setSelectedTags: (selectedTags: Record<string, boolean>) => void;
};

export const ReportsTagsFilter: React.FC<Props> = ({
  tags,
  selectedTags,
  setSelectedTags,
}) => {
  const [areTagsExpanded, setAreTagsExpanded] = useState<boolean>(false);
  const { View, goToAndPlay } = useLottie(
    {
      animationData: icon,
      autoplay: false,
      loop: false,
    },
    {
      width: 22,
      height: 22,
    },
  );

  const breakpoints = useBreakpoint();

  return (
    <div className={styles.tagsSelect} data-test-id="tags-filter">
      <button
        key="open"
        data-test-id="tags-expand"
        onClick={() => {
          setAreTagsExpanded(true);
        }}
        className={classNames(styles.tag, {
          [styles.hidden]: areTagsExpanded,
        })}
        onMouseOver={() => {
          goToAndPlay(0);
        }}
      >
        {View}
        {!breakpoints.isSmall && <>&nbsp;&nbsp;Фильтр по тегам</>}
      </button>
      {areTagsExpanded && (
        <button
          data-test-id="tags-collapse"
          key="close"
          onClick={() => {
            setAreTagsExpanded(false);
          }}
          className={classNames(styles.tag, styles.close)}
        >
          <Icon
            name="collapse"
            size={18}
            className={styles.closeIcon}
            clickable
          />
        </button>
      )}
      {areTagsExpanded &&
        tags.map((tag) => (
          <button
            data-test-id="tag"
            data-test-selected={selectedTags[tag]}
            onClick={() => {
              setSelectedTags({ ...selectedTags, [tag]: !selectedTags[tag] });
            }}
            className={classNames(styles.tag, {
              [styles.selected]: selectedTags[tag],
            })}
            key={tag}
          >
            {tag}
            <span className={styles.close} />
          </button>
        ))}
    </div>
  );
};
