import React from 'react';
import classNames from 'classnames';

import styles from './Reports.module.scss';
import { ReportMeta } from './constants';
import { useWindowEventListener } from 'rooks';
import { Report } from './Report';

type Props = {
  reports: ReportMeta[];
  viewMode: 'grid' | 'table';
  navigate: (path: string) => void;
  onScroll?: (e: any) => void;
};

export const ReportsList: React.FC<Props> = ({
  reports,
  viewMode,
  navigate,
  onScroll,
}) => {
  useWindowEventListener('keydown', (e) => {
    const event = e as KeyboardEvent;
    if (event.key === 'Enter' && reports.length === 1) {
      navigate(
        `/reports/report-ds${reports[0].sectionKey}-r${reports[0].reportKey}`,
      );
    }
  });

  return (
    <div
      data-test-id="reports-list"
      data-test-view-mode={viewMode}
      className={classNames(styles.reports, {
        [styles.grid]: viewMode === 'grid',
        [styles.table]: viewMode === 'table',
      })}
      onScroll={onScroll}
    >
      {reports.map((report) => {
        return (
          <Report
            key={`${report.sectionKey}-${report.reportKey}`}
            report={report}
            viewMode={viewMode}
            navigate={navigate}
          />
        );
      })}
    </div>
  );
};
