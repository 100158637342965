import { $api } from '../../api/utils';
import { DictionariesType } from '../constants';

export type TDictionary = {
  guid: string;
  type: string;
  name: string;
  createAuthor: string;
  lastUpdateAuthor: string;
  createdAt: string;
  updatedAt: string;
  fillPercent: number;
};

export const getDictionaries = async (): Promise<TDictionary[]> => {
  const dictsByTypes = await Promise.all(
    Object.values(DictionariesType).map(async (type) => {
      const { data } = await $api.get(`/dictionaries?type=${type}`);
      return data;
    }),
  );
  return dictsByTypes.flat();
};
