import { useMemo } from 'react';

type Tree<TDataItem> = {
  name: string;
  value: number;
  path: string;
  children?: (TDataItem & {
    name: string;
    inn: string;
    value: number;
    path: string;
  })[];
}[];

export const useGroupForTree = <TDataItem extends Record<string, any>>(
  data: TDataItem[] | undefined,
  {
    parentKey,
    parentNameKey = 'sellerNetName',
    childrenNameKey = 'sellerName',
    innKey = 'sellerInn',
    valueKey = 'salesCnt',
  }: {
    parentKey?: keyof TDataItem;
    parentNameKey?: keyof TDataItem;
    childrenNameKey?: keyof TDataItem;
    innKey?: keyof TDataItem;
    valueKey?: keyof TDataItem;
  },
  enabled = true,
  canGroup = false,
): Tree<TDataItem> => {
  return useMemo(() => {
    if (!data) {
      return [];
    }
    if (!enabled) {
      return [];
    }
    const parentKeyWithDefault = parentKey || parentNameKey;

    const nets: Record<
      string,
      {
        netName: string;
        items: TDataItem[];
        total: number;
      }
    > = {};
    data.forEach((item) => {
      if (!nets[item[parentKeyWithDefault]]) {
        nets[item[parentKeyWithDefault]] = {
          netName: item[parentNameKey],
          items: [],
          total: 0,
        };
      }
      nets[item[parentKeyWithDefault]].items.push(item);
      nets[item[parentKeyWithDefault]].total += item[valueKey];
    });

    const resultData: Tree<TDataItem> = [];

    const prepareItem = (item: TDataItem) => ({
      ...item,
      name: item[childrenNameKey],
      inn: item[innKey],
      value: item[valueKey],
      path: `${item[parentNameKey]}/${item[childrenNameKey]}`,
    });

    Object.values(nets)
      .sort((a, b) => b.total - a.total)
      .forEach(({ netName, items, total }) => {
        resultData.push({
          ...(items.length === 1 ? prepareItem(items[0]) : {}),
          name: netName,
          value: total,
          path: netName,
          children:
            canGroup && items.length > 1
              ? items
                  .map((item) => prepareItem(item))
                  .sort((a, b) => b.value - a.value)
              : undefined,
        });
      });

    return resultData;
  }, [
    canGroup,
    childrenNameKey,
    data,
    enabled,
    innKey,
    parentKey,
    parentNameKey,
    valueKey,
  ]);
};
