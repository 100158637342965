import { FC, memo, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { isDev } from '../utils/isDev';
import { Loggers, getLogger } from '../utils/logger';

const dev = isDev();
const shouldExplainMemo = localStorage.getItem('shouldExplainMemo') === '1';

const deepMemoLogger = getLogger(Loggers.deepMemo);

if (shouldExplainMemo) {
  deepMemoLogger(
    '[deepMemo] shouldExplainMemo is enabled, performance may be affected',
  );
}

const isEqualWithExplanation = (
  prev: any,
  next: any,
  currentKey: string = '',
): true | string => {
  if (!shouldExplainMemo) {
    return isEqual(prev, next) || 'not equal';
  }
  if (prev === next) {
    return true;
  }
  if (typeof prev !== typeof next) {
    return `type of ${currentKey} ${typeof prev} !== ${typeof next}`;
  }
  if (typeof prev === 'object' && prev !== null && next !== null) {
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);
    if (prevKeys.length !== nextKeys.length) {
      return `keys length ${prevKeys.length} !== ${nextKeys.length}`;
    }
    for (const key of prevKeys) {
      const result = isEqualWithExplanation(
        prev[key],
        next[key],
        currentKey ? `${currentKey}.${key}` : key,
      );
      if (result !== true) {
        return result;
      }
    }
    return true;
  }
  return `${currentKey} ${typeof prev} !== ${typeof next}`;
};

export const deepMemo = <T extends FC<P>, P>(
  component: T,
  devName: string,
): T =>
  memo(component, (prev, next) => {
    const areEqual = isEqualWithExplanation(prev, next);
    if (dev && areEqual !== true) {
      const componentName =
        devName || component.displayName || component.name || 'Component';
      deepMemoLogger(`[${componentName}] props changed, ${areEqual}`, {
        prev,
        next,
      });
    }
    return areEqual === true;
  }) as unknown as T;

export const useDeepMemo = <T>(value: T, devName: string) => {
  const prevValue = useRef<T>(value);
  return useMemo(() => {
    const areEqual = isEqual(prevValue.current, value);
    if (areEqual) {
      return prevValue.current;
    }
    if (dev && !areEqual) {
      deepMemoLogger(`[${devName}] changed`, {
        prev: prevValue.current,
        next: value,
      });
    }
    prevValue.current = value;
    return value;
  }, [value]);
};
