import React from 'react';
import { usePagination } from '../../usePagination';
import classNames from 'classnames';

import styles from './Pagination.module.scss';
import { useBreakpoint } from '../../../../../uikit/hooks/useBreakpoint';

import rightArrow from './right.svg';

export const Pagination = () => {
  const { currentPage, setCurrentPage, total, pageSize } = usePagination();
  const totalPages = Math.ceil(total / pageSize);
  const minPageToShow = Math.max(1, currentPage - 1);
  const maxPageToShow = Math.min(totalPages, currentPage + 1);
  const pagesToShow = [];
  for (let i = minPageToShow; i <= maxPageToShow; i++) {
    pagesToShow.push(i);
  }

  const breakpoints = useBreakpoint();

  if (totalPages <= 1) {
    return null;
  }
  return (
    <div
      className={styles.pagination}
      data-test-id="pagination"
      data-total-pages={totalPages}
      data-current-page={currentPage}
    >
      {pagesToShow[0] > 1 && (
        <>
          {!breakpoints.isMobile && (
            <button
              data-test-id="first-page"
              className={classNames(styles.page, styles.withArrow)}
              onClick={() => setCurrentPage(1)}
            >
              <img
                className={classNames(
                  styles.arrow,
                  styles.compact,
                  styles.reverse,
                )}
                src={rightArrow}
                alt="next page"
              />
              <img
                className={classNames(
                  styles.arrow,
                  styles.compact,
                  styles.reverse,
                )}
                src={rightArrow}
                alt="next page"
              />
            </button>
          )}
        </>
      )}
      <button
        data-test-id="prev-page"
        className={classNames(styles.page, styles.withArrow)}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img
          className={classNames(styles.arrow, styles.reverse)}
          src={rightArrow}
          alt="next page"
        />
      </button>

      {pagesToShow.map((page) => (
        <button
          className={classNames(styles.page, {
            [styles.active]: page === currentPage,
          })}
          key={page}
          onClick={() => setCurrentPage(page)}
          data-test-id={`page-${page}`}
        >
          <span>{page}</span>
        </button>
      ))}
      <button
        data-test-id="next-page"
        className={classNames(styles.page, styles.withArrow)}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <img className={styles.arrow} src={rightArrow} alt="next page" />
      </button>
      {pagesToShow[pagesToShow.length - 1] < totalPages && (
        <>
          {!breakpoints.isMobile && (
            <button
              data-test-id="last-page"
              className={classNames(styles.page, styles.withArrow)}
              onClick={() => setCurrentPage(totalPages)}
            >
              <img
                className={classNames(styles.arrow, styles.compact)}
                src={rightArrow}
                alt="next page"
              />
              <img
                className={classNames(styles.arrow, styles.compact)}
                src={rightArrow}
                alt="next page"
              />
            </button>
          )}
        </>
      )}
    </div>
  );
};
