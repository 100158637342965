import { useEffect, useRef } from 'react';
import { useUserSetting } from './useUserSettings';

export const useUsageCount = (key?: string) => {
  const [counts, setCounts] = useUserSetting('useUsageCount', {});

  const wasIncremented = useRef(false);
  useEffect(() => {
    if (key && !wasIncremented.current) {
      const count = counts[key] || 0;
      setCounts({ ...counts, [key]: count + 1 });
      wasIncremented.current = true;
    }
  }, [counts, key, setCounts]);

  return counts;
};
