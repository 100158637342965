import { refreshToken } from '../../api';
import { storage } from './storage';

export const checkTimeToken = () => {
  const diff = new Date().getTime() - Number(storage.getItem('startTimeToken'));
  const minutesDiff = Math.round(diff / 60000);

  if (minutesDiff > 30) {
    refreshToken();
  }
};
