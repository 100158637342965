import React, { useMemo } from 'react';
import { useUsageCount } from '../../../../../../../shared/hooks/useUsageCount';
import { REPORTS, ReportMeta } from '../../../../../Reports/constants';

import styles from './MyTools.module.scss';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useFavoritesList } from '../../../../../../common/FavoriteButton/FavoriteButton';
import uniqBy from 'lodash/uniqBy';

const MINIMAL_USAGE = 10;

const keyRegexp = /ds(\d+)r(\d+)/;

const PRESET_REPORTS = ['ds1r9', 'ds1r1', 'ds1r6'];

export const MyTools: React.FC = () => {
  const counts = useUsageCount();
  const favoriteReports = useFavoritesList();

  const topThree = useMemo<ReportMeta[]>(() => {
    const pairs = Object.entries(counts);
    const moreThanMinimal = pairs.filter(([, count]) => count >= MINIMAL_USAGE);
    const sorted = moreThanMinimal.sort(
      ([, countA], [, countB]) => countB - countA,
    );
    const favorites: [string, number][] = Object.keys(favoriteReports).map(
      (key) => [key, Infinity],
    );
    const commonList = uniqBy(
      [
        ...favorites,
        ...sorted,
        ...PRESET_REPORTS.map((key) => [key, 0] as [string, number]),
      ],
      '0',
    );
    const topThree = commonList.slice(0, 3);

    const reports = topThree
      .map(([key]) => {
        const match = key.match(keyRegexp);
        if (!match) {
          return null;
        }
        const [, ds, r] = match;
        return REPORTS.find(({ reportKey, sectionKey }) => {
          return sectionKey === Number(ds) && reportKey === Number(r);
        });
      })
      .filter(Boolean) as ReportMeta[];
    return reports;
  }, [counts, favoriteReports]);

  return (
    <div className={styles.tools}>
      {topThree.map(({ icon, reportKey, sectionKey, name }) => {
        return (
          <Link
            to={`/reports/report-ds${sectionKey}-r${reportKey}`}
            key={`${sectionKey}${reportKey}`}
            className={styles.tool}
          >
            <div className={styles.toolIcon}>
              <Lottie
                animationData={icon}
                loop={false}
                autoplay={false}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </div>
            <div className={styles.toolTitle}>{name}</div>
          </Link>
        );
      })}
    </div>
  );
};
