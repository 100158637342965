import { isSmall } from '../../uikit/hooks/useBreakpoint';
import { DEFAULT_CONFIG, MOBILE_GRID } from '../charts';
import { formatNumericNames } from '../utils/formatNumericNames';
import { getByIndexFromArray } from '../utils/getRandomFromArray';

export const LINES_TOP_OPTIONS = [50, 100, 200];

type TItem = {
  name: string;
  inn?: string;
  visible: boolean;
  values: {
    x: string;
    y: number;
  }[];
};

export const COLORS = [
  '#FE8888',
  '#619500',
  '#B588FE',
  '#537A00',
  '#48D3FF',
  '#FEC888',
  '#88D4FE',
  '#F588FE',
  '#415DF3',
  '#FFDF38',
  '#DAF279',
  '#88A2FE',
  '#FF9548',
  '#8459FD',
  '#E25C3B',
];

export const getLinesConfig = (data: TItem[], hoveredInn: string | null) => {
  const allCategories = new Set();
  data.forEach((item) => {
    item.values.forEach((value) => {
      allCategories.add(value.x);
    });
  });

  return {
    ...DEFAULT_CONFIG,
    grid: isSmall()
      ? MOBILE_GRID
      : {
          ...DEFAULT_CONFIG.grid,
          top: 30,
          left: 20,
          right: 20,
          bottom: 50,
        },
    legend: undefined,
    tooltip: {
      trigger: 'item',
    },
    xAxis: [
      {
        type: 'category',
        data: [...allCategories],
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => formatNumericNames(value),
        },
      },
    ],
    series: data.map((item, index) => {
      const color = getByIndexFromArray(COLORS, index);
      const lineColor =
        hoveredInn && hoveredInn !== item.inn ? `${color}50` : color;
      return {
        id: item.inn || index,
        data: item.visible
          ? item.values.map((value) => ({
              name: value.x,
              value: value.y,
            }))
          : [],
        type: 'line',
        cursor: 'default',
        name: item.name || item.inn,
        tooltip: {
          valueFormatter: formatNumericNames,
        },
        markPoint:
          hoveredInn && hoveredInn === item.inn
            ? {
                data: item.values.map((value) => ({
                  name: value.x,
                  coord: [value.x, value.y],
                  value: formatNumericNames(value.y, true),
                })),
                label: {
                  fontSize: 9,
                  color: '#fff',
                },
              }
            : undefined,
        lineStyle: {
          color: lineColor,
        },
        itemStyle: {
          color: lineColor,
        },
      };
    }),
  };
};
