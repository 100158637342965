import { getRole } from '../../../../shared/utils/getRole';
import { ROLES } from '../../../../shared/constants';
import { formTypes } from '../constants';
import { useEffect } from 'react';
import { Input } from '../../../../uikit/Input/Input';
import { Gap } from '../../../../uikit/Gap/Gap';
import { Checkbox } from '../../../../uikit/Checkbox/Checkbox';
import copy from 'copy-text-to-clipboard';

import { ReactComponent as RandomIcon } from './random.svg';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { $api } from '../../../../api/utils';
import { getRandomString } from '../utils';
import { useAuthorizedUser } from '../../../../shared/hooks/useUser';
import { useProductGroup } from '../../../../api/accesses/accesses';

const useTariffsList = (active) => {
  const { data } = useQuery(
    ['tariffs'],
    async () => {
      const { data } = await $api.get('/tariff');
      return data || [];
    },
    {
      enabled: active,
    },
  );
  return data || [];
};

export const ModalForm = ({
  formErrors,
  formik,
  formTypeByRole,
  modalType,
}) => {
  const userInfo = useAuthorizedUser();
  const { data, isLoading: isProductGroupLoading } = useProductGroup();
  const productGroup = data?.productGroups || {};
  const isAdmin = getRole(userInfo?.roles, ROLES.admin);
  const isClient = getRole(userInfo?.roles, ROLES.client);
  const isClientAdmin = getRole(userInfo?.roles, ROLES.client_admin);
  const modalTypeIsEdit = modalType === 'edit';
  const showRoleSelect =
    formTypeByRole === formTypes.officer && !modalTypeIsEdit;

  const tariffs = useTariffsList(isAdmin && !modalTypeIsEdit);

  useEffect(() => {
    if (
      isAdmin &&
      !modalTypeIsEdit &&
      tariffs.length &&
      !formik.values.tariff
    ) {
      const defaultTariff = tariffs.find((tariff) => tariff.isDefault);
      formik.setFieldValue('tariff', defaultTariff?.guid);
    }
  }, [isAdmin, modalTypeIsEdit, tariffs]);

  useEffect(() => {
    if (!isProductGroupLoading && !formik.values.productGroup) {
      formik.setFieldValue('productGroup', Object.keys(productGroup)[0]);
    }
  }, [isProductGroupLoading]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    formik.setFieldValue(name, value);
    if (name === 'password') {
      formik.setFieldValue('passwordConfirm', value);
    }
  };

  const getRoleOptions = () => {
    if (isAdmin || isClient) {
      return (
        <>
          <option value="CLIENT_ADMIN">Менеджер</option>
          <option value="CLIENT_MANAGER">Пользователь</option>
        </>
      );
    }
    if (isClientAdmin) {
      return <option value="CLIENT_MANAGER">Пользователь</option>;
    }
  };

  const productsGroup = productGroup ? Object.entries(productGroup) : [];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Gap size={16} />
      <Input
        label="Имя и фамилия"
        value={formik.values.name}
        onChange={handleChange}
        block
        name="name"
      />
      <Input
        label="E-mail"
        value={formik.values.login}
        onChange={handleChange}
        block
        name="login"
        error={
          formErrors?.login &&
          `Необходимо ввести значение в формате example@your-email.com`
        }
      />
      {showRoleSelect && (
        <Input
          label="Роль"
          value={formik.values.role}
          onChange={handleChange}
          type="select"
          name="role"
          block
        >
          {getRoleOptions()}
        </Input>
      )}
      {isAdmin && !modalTypeIsEdit && (
        <Input
          label="Алиас"
          value={formik.values.alias}
          name="alias"
          onChange={handleChange}
          block
          error={formErrors?.alias}
        />
      )}
      {modalTypeIsEdit && (
        <Input
          label="Статус"
          block
          custom={
            <Checkbox
              onChange={(checked) => formik.setFieldValue('active', checked)}
              checked={formik.values.active}
              label="Активен"
              name="active"
              alignCenter
            />
          }
        />
      )}
      {isAdmin && !modalTypeIsEdit && (
        <Input
          type="select"
          name="productGroup"
          label="Товарная группа"
          value={formik.values.productGroup}
          onChange={handleChange}
          block
        >
          {productsGroup?.map((item) => (
            <option value={item?.[0]} key={item?.[0]}>
              {item?.[1]}
            </option>
          ))}
        </Input>
      )}
      {isAdmin && !modalTypeIsEdit && (
        <Input
          type="select"
          name="tariff"
          label="Тариф"
          value={formik.values.tariff}
          onChange={handleChange}
          block
        >
          {tariffs?.map((item) => (
            <option value={item?.guid} key={item?.guid}>
              {item?.name}
            </option>
          ))}
        </Input>
      )}
      {!modalTypeIsEdit && (
        <Input
          label="Пароль"
          value={formik.values.password}
          onChange={handleChange}
          block
          name="password"
          rightIcon={<RandomIcon />}
          onRightIconClick={() => {
            const newPassword = getRandomString(Math.random() * 5 + 8);
            formik.setFieldValue('password', newPassword);
            formik.setFieldValue('passwordConfirm', newPassword);
            copy(newPassword);
            toast('Пароль скопирован в буфер обмена');
          }}
        />
      )}

      {formErrors?.full && (
        <div style={{ color: '#f52a1b' }}>{formErrors.full}</div>
      )}
    </form>
  );
};
