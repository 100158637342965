import axios from 'axios';
import { deleteCookie } from '../../shared/utils/getAuthToken';
import { apiPath } from '../constants';
import { $api } from '../utils';
import { storage } from '../../shared/utils/storage';
import { queryClient } from '../../store/query';

export const onAgreementDone = () => {
  $api
    .put(`${apiPath}/user/agreement`)
    .then(() => {
      queryClient.invalidateQueries(['user']);
    })
    .catch(({ response }) => {
      console.error(response);
    });
};

export const onLogin = (values, updateErrorText, onSuccess) => {
  axios
    .post(`${apiPath}/auth`, {
      username: values.username,
      password: values.password,
    })
    .then(({ data }) => {
      document.cookie = `accessToken=${data?.accessToken};path=/;`;
      updateErrorText(null);
      storage.setItem('startTimeToken', String(new Date().getTime()));
      onSuccess?.();

      queryClient.invalidateQueries(['user']);
    })
    .catch(() => updateErrorText('Некорректный логин или пароль'));
};

export const onLogout = () => {
  deleteCookie('accessToken');
  queryClient.invalidateQueries(['user']);
};

export const refreshToken = async () => {
  try {
    const { data } = await $api.post(`/auth/refresh`);

    if (data?.accessToken) {
      document.cookie = `accessToken=${data?.accessToken}; Path=/;`;
      storage.setItem('startTimeToken', String(Date.now()));
      queryClient.invalidateQueries(['user']);
    }
  } catch ({ response }) {
    console.error(response);
  }
};
