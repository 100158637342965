import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Header.module.scss';
import { onLogout } from '../../../api';
import { ProfileModal } from './ProfileModal/ProfileModal';
import { Menu } from '../Menu/Menu';
import { useIsFullscreen } from '../PageContent/useIsFullscreen';
import { hasRole, Role } from '../../../shared/utils/hasRole';
import { isDev } from '../../../shared/utils/isDev';
import { Notifications } from './Notifications/Notifications';
import { getRole } from '../../../shared/utils/getRole';
import { ROLES } from '../../../shared/constants';

import menuIcon from './wired-outline-320-menu-7-morph.json';
import { useLottie } from 'lottie-react';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import {
  useAuthorizedUser,
  User,
  UserFlags,
} from '../../../shared/hooks/useUser';
import { Icon } from '../Icon/Icon';
import { ReportQueueInHeader } from '../../ReportQueue/ReportQueue';

const MobileMenuIcon: React.FC<{
  onClick: () => void;
  isOpened: boolean;
}> = ({ onClick, isOpened }) => {
  const {
    View: Icon,
    goToAndPlay,
    getDuration,
    setSpeed,
  } = useLottie(
    {
      animationData: menuIcon,
      autoplay: false,
      loop: false,
      onClick,
    },
    {
      width: 32,
      height: 32,
    },
  );

  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (isOpened) {
      setSpeed(1);
      goToAndPlay(0, true);
    } else {
      const duration = getDuration(true);
      if (duration) {
        setSpeed(-1);
        goToAndPlay(duration, true);
      }
    }
  }, [isOpened]);

  return Icon;
};

export type Item = {
  title: string;
  adminTitle?: string;
  path: string;
  testId?: string;
  hasSubdirectories?: boolean;
  onClick?: () => void;
  roles?: Role[];
  isVisibleInEventsForm?: boolean;
  userFlagKey?: UserFlags | UserFlags[];
  subItems?: Item[];
};

export const MENU_ITEMS_MAP: Record<string, Item> = {
  dashboard: {
    title: 'Дашборд',
    testId: 'dashboard',
    path: '/',
    roles: ['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'],
    isVisibleInEventsForm: true,
    userFlagKey: 'moduleAOrB',
  },
  reports: {
    title: 'Отчёты',
    path: '/reports',
    hasSubdirectories: true,
    roles: ['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'],
    isVisibleInEventsForm: true,
    userFlagKey: 'moduleAOrB',
  },
  uploads: {
    title: 'Выгрузки',
    path: '/uploads',
    roles: ['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'],
    isVisibleInEventsForm: true,
    userFlagKey: 'moduleAOrB',
  },
  dictionaries: {
    title: 'Справочники',
    path: '/dictionaries',
    roles: ['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'],
    hasSubdirectories: true,
    userFlagKey: 'moduleB',
    isVisibleInEventsForm: true,
  },
  linkAddress: {
    title: 'Линкование адресов',
    path: '/link-address',
    userFlagKey: 'linkAddress',
  },
  linkGtin: {
    title: 'Линкование GTIN',
    path: '/link-gtin',
    userFlagKey: 'linkGtin',
  },
  files: {
    title: 'Файлы',
    adminTitle: '📁 Файлы',
    path: '/files',
  },
};

export const MENU_ITEMS_FOR_REDIRECT: Item[] = [
  MENU_ITEMS_MAP.dashboard,
  MENU_ITEMS_MAP.reports,
  MENU_ITEMS_MAP.uploads,
  MENU_ITEMS_MAP.dictionaries,
  MENU_ITEMS_MAP.linkAddress,
  MENU_ITEMS_MAP.linkGtin,
  MENU_ITEMS_MAP.files,
];

export const isItemAvailable = (item: Item, userInfo: User): boolean => {
  if (item.roles && !hasRole(item.roles, userInfo)) {
    return false;
  }
  if (item.userFlagKey && !Array.isArray(item.userFlagKey)) {
    return !!userInfo?.[item.userFlagKey];
  }
  if (item.userFlagKey && Array.isArray(item.userFlagKey)) {
    return item.userFlagKey.some((key) => !!userInfo?.[key]);
  }

  return true;
};

export const MENU_ITEMS: Item[] = [
  MENU_ITEMS_MAP.dashboard,
  MENU_ITEMS_MAP.reports,
  MENU_ITEMS_MAP.uploads,
  MENU_ITEMS_MAP.dictionaries,
  {
    title: 'Линкование',
    path: '/link-address',
    userFlagKey: ['linkAddress', 'linkGtin'],
    subItems: [MENU_ITEMS_MAP.linkAddress, MENU_ITEMS_MAP.linkGtin],
  },
  {
    title: 'Линкование GTIN',
    adminTitle: '📦 Линкование GTIN',
    path: '/link-gtin',
    roles: ['ADMIN'],
  },
  MENU_ITEMS_MAP.files,
  {
    title: 'Пользователи',
    adminTitle: '👥 Пользователи',
    path: '/accesses',
    roles: ['ADMIN'],
  },
  {
    title: 'Уведомления',
    adminTitle: '🔔 Уведомления',
    path: '/events-admin',
    roles: ['ADMIN'],
  },
  {
    path: '/support-admin',
    title: 'Поддержка',
    adminTitle: '📚 Поддержка',
    roles: ['ADMIN'],
  },
  isDev() && {
    title: '👁',
    path: '/adhoc-screenshoter',
    hasSubdirectories: true,
  },
].filter(Boolean) as Item[];

export const Header: React.FC = () => {
  const userInfo = useAuthorizedUser();
  const userRoles = userInfo.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [isProfileOpened, setIsProfileOpened] = useState(false);
  const profileButtonRef = useRef<HTMLDivElement>(null);
  const [isFullscreen] = useIsFullscreen();

  const [submenu, setSubmenu] = useState<null | {
    target: HTMLAnchorElement;
    items: Item[];
  }>(null);

  const breakpoints = useBreakpoint();

  const shouldShowItem = (item: Item) => {
    return isItemAvailable(item, userInfo);
  };

  const menuItemsToShow = MENU_ITEMS.filter((item) => {
    if (!shouldShowItem(item)) {
      return false;
    }
    return true;
  })
    .map((item) => {
      const itemCopy = { ...item };
      if (item.subItems) {
        itemCopy.subItems = item.subItems.filter(shouldShowItem);
      }
      if (itemCopy.subItems && itemCopy.subItems.length === 0) {
        return null;
      }
      if (itemCopy.subItems && itemCopy.subItems.length === 1) {
        return itemCopy.subItems[0];
      }
      return itemCopy;
    })
    .filter(Boolean) as Item[];

  return (
    <>
      <header
        className={classNames(styles.header, {
          [styles.isFullscreen]: isFullscreen,
        })}
      >
        {submenu && (
          <Menu
            testId="header-submenu"
            open
            placement="bottom-start"
            onClose={() => {
              setSubmenu(null);
            }}
            target={submenu.target}
            items={submenu.items.map((item) => ({
              title: isAdmin && item.adminTitle ? item.adminTitle : item.title,
              onClick: () => {
                navigate(item.path);
              },
            }))}
          />
        )}
        {breakpoints.isSmall && (
          <div
            className={classNames(styles.mobileMenu, {
              [styles.opened]: isMobileMenuOpened,
            })}
          >
            {menuItemsToShow.flatMap((item, index) => {
              const items = item.subItems || [item];
              return items.map((item, innerIndex) => {
                return (
                  <a
                    key={`${index}_${innerIndex}`}
                    className={classNames(styles.menuItem, {
                      [styles.active]: item.hasSubdirectories
                        ? location.pathname.includes(item.path)
                        : location.pathname === item.path,
                    })}
                    href={item.path}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMobileMenuOpened(false);
                      if (item.onClick) {
                        item.onClick();
                      } else {
                        navigate(item.path);
                      }
                    }}
                    data-test-id={`header-mobile-menu-item-${
                      item.testId || item.path.replace('/', '')
                    }`}
                  >
                    {isAdmin && item.adminTitle ? item.adminTitle : item.title}
                  </a>
                );
              });
            })}
          </div>
        )}
        <div className={styles.mobileMenuButton}>
          <MobileMenuIcon
            onClick={() => {
              setIsMobileMenuOpened(!isMobileMenuOpened);
            }}
            isOpened={isMobileMenuOpened}
          />
        </div>
        <div className={styles.mobileMenuButtonSpacer} />
        <div
          className={styles.logo}
          onClick={() => {
            navigate('/');
          }}
        />
        <div className={styles.spacer} />
        <div className={styles.menu}>
          {menuItemsToShow.map((item, index) => {
            const isSelectedItself = location.pathname === item.path;
            const isSelectedSubItem =
              item.subItems &&
              item.subItems.some((subItem) => {
                return location.pathname === subItem.path;
              });
            const isSelectedSubPath =
              item.hasSubdirectories && location.pathname.includes(item.path);
            const isSelected =
              isSelectedItself || isSelectedSubItem || isSelectedSubPath;

            return (
              <a
                key={index}
                className={classNames(styles.menuItem, {
                  [styles.active]: isSelected,
                })}
                href={item.path}
                onClick={(e) => {
                  e.preventDefault();
                  if (item.onClick) {
                    item.onClick();
                  } else {
                    if (item.subItems) {
                      setSubmenu({
                        target: e.currentTarget,
                        items: item.subItems,
                      });
                    } else {
                      navigate(item.path);
                    }
                  }
                }}
                data-test-id={`header-menu-item-${
                  item.testId || item.path.replace('/', '')
                }`}
                data-has-submenu={!!item.subItems}
              >
                {isAdmin && item.adminTitle ? item.adminTitle : item.title}
              </a>
            );
          })}
        </div>
        <div className={styles.spacer} />
        {!isAdmin && <ReportQueueInHeader />}
        <a
          className={styles.help}
          href="/support"
          onClick={(e) => {
            e.preventDefault();
            navigate('/support');
          }}
        >
          <Icon size={21} clickable name="info" />
        </a>
        {!isAdmin && <Notifications />}
        <div className={styles.divider} />
        <div
          className={styles.profile}
          ref={profileButtonRef}
          onClick={() => {
            setIsMenuOpened(!isMenuOpened);
          }}
        >
          <div className={styles.profileName}>{userInfo?.name}</div>
          <div
            className={classNames(styles.profileArrow, {
              [styles.opened]: isMenuOpened,
            })}
          >
            <Icon size={20} name="expand" />
          </div>
        </div>
        <Menu
          testId="header"
          open={isMenuOpened}
          onClose={() => {
            setIsMenuOpened(false);
          }}
          target={profileButtonRef.current}
          items={[
            {
              title: 'Профиль',
              onClick: () => {
                setIsProfileOpened(!isProfileOpened);
                setIsMenuOpened(false);
              },
            },
            {
              title: 'Пользователи',
              onClick: () => {
                navigate('/accesses');
                setIsMenuOpened(false);
              },
              roles: ['CLIENT', 'CLIENT_ADMIN', 'ADMIN'],
            },
            {
              title: 'Делегирование',
              onClick: () => {
                setIsProfileOpened(!isProfileOpened);
                setIsMenuOpened(false);
              },
              roles: ['CLIENT', 'CLIENT_ADMIN'],
            },
            {
              divider: true,
            },
            {
              title: 'Выйти',
              onClick: () => {
                onLogout();
              },
            },
          ]}
        />
        <ProfileModal
          visible={isProfileOpened}
          onClose={() => {
            setIsProfileOpened(false);
          }}
        />
      </header>
    </>
  );
};
