export type TCustomGetter<TDataItem extends Record<string, any>> = (config: {
  item: TDataItem;
  key: keyof TDataItem;
  groupBy: keyof TDataItem | (keyof TDataItem)[];
  rawData: TDataItem[];
}) => number | undefined;

export const getGrouppedData = <TDataItem extends Record<string, any>>(
  summariseFields: string | number | (keyof TDataItem | RegExp)[],
  data: TDataItem[],
  groupBy: keyof TDataItem | (keyof TDataItem)[],
  rawData: TDataItem[],
  customGetter?: TCustomGetter<TDataItem>,
) => {
  const allUniqueKeys = new Set<string>();
  for (const item of data) {
    const keys = Object.keys(item);
    for (const key of keys) {
      allUniqueKeys.add(key);
    }
  }
  const allUniqueKeysArray = [...allUniqueKeys] as (keyof TDataItem)[];
  const summariseFieldsArray = Array.isArray(summariseFields)
    ? ([
        ...new Set(
          summariseFields
            .map((field) => {
              const isRegExp = field instanceof RegExp;
              if (!isRegExp) {
                return field;
              }
              const matchingKeys = allUniqueKeysArray.filter((key) =>
                field.test(key as string),
              );
              return matchingKeys;
            })
            .flat(),
        ),
      ] as (keyof TDataItem)[])
    : ([summariseFields] as (keyof TDataItem)[]);

  const groupByArray = Array.isArray(groupBy) ? groupBy : [groupBy];
  const getKey = (item: TDataItem): string =>
    groupByArray.map((key) => item[key]).join('-');

  const uniqueKeys = [...new Set(data.map((item) => getKey(item)))] as string[];
  const keysRecord = uniqueKeys.reduce<Record<string, TDataItem[]>>(
    (acc, key) => {
      acc[key] = [];
      return acc;
    },
    {},
  );
  for (const item of data) {
    keysRecord[getKey(item)].push(item);
  }
  const result = Object.values(keysRecord).map((items) => {
    const preparedFirstItem = { ...items[0] };
    for (const field of summariseFieldsArray) {
      // @ts-expect-error - we know that the field is a number
      preparedFirstItem[field] = 0;
    }
    const summarisedItem = items.reduce<TDataItem>((acc, item) => {
      for (const field of summariseFieldsArray) {
        // @ts-expect-error - we know that the field is a number
        acc[field] =
          ((acc[field] as number) || 0) + ((item[field] as number) || 0);
      }
      return acc;
    }, preparedFirstItem);
    return summarisedItem;
  });
  if (customGetter) {
    return result.map((item) => {
      for (const key of Object.keys(item)) {
        const value = customGetter({
          item,
          key,
          groupBy,
          rawData,
        });
        if (value !== undefined) {
          // @ts-expect-error - we know that the field is a number
          item[key] = value;
        }
      }
      return item;
    });
  }
  return result;
};
