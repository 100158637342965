import React, { useEffect, useMemo, useState } from 'react';
import {
  createSupportPage,
  deleteSupportPage,
  useSupportPages,
} from '../../shared/queries/support';
import { withRestriction } from '../../shared/hocs/withRestriction';
import { PageHeader } from '../common/PageHeader/PageHeader';
import { PageContent } from '../common/PageContent/PageContent';
import { Button } from '../../uikit/Button/Button';
import { Icon } from '../common/Icon/Icon';
import { Modal } from '../../uikit/Modal/Modal';
import { Input } from '../../uikit/Input/Input';
import { Spacer } from '../common/Spacer/Spacer';
import { withLoader } from '../common/FullscreenLoader/FullscreenLoader';
import { withErrorHandling } from '../../shared/utils/withErrorHandling';
import { TTableColumn, Table } from '../common/Table/Table';
import { SupportDto } from '../../api/backend/Api';
import { sorter } from '../common/Table/sorter';
import { Link, useNavigate } from 'react-router-dom';
import { DEFAULT_NUMERIC_FORMATTER } from '../../shared/utils/formatters';
import { Select } from '../common/Select/Select';

export const useUniqueChapters = (pages: SupportDto[]) => {
  const existingChapters = useMemo(() => {
    const uniqueChapters = new Set<string>();

    pages.forEach((page) => {
      uniqueChapters.add(page.chapter);
    });

    return Array.from(uniqueChapters);
  }, [pages]);

  return existingChapters;
};

export const SupportAdminList: React.FC = withRestriction(['ADMIN'], () => {
  const listQueryData = useSupportPages();
  const [createTitle, setCreateTitle] = useState('');
  const [createChapter, setCreateChapter] = useState('');
  const [createOrder, setCreateOrder] = useState(0);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const existingChapters = useUniqueChapters(listQueryData.data || []);

  useEffect(() => {
    if (!isCreateModalOpen) {
      setCreateTitle('');
      setCreateChapter('');
      setCreateOrder(0);
    }
  }, [isCreateModalOpen]);

  const navigate = useNavigate();

  const COLS: TTableColumn<SupportDto>[] = [
    {
      key: 'order',
      title: 'Порядок',
      sort: sorter('order', true),
      size: 'min-content',
    },
    {
      key: 'name',
      title: 'Название',
      sort: sorter('name'),
    },
    {
      key: 'chapter',
      title: 'Раздел',
      sort: sorter('chapter'),
    },
    {
      key: 'userCondition',
      title: 'Условие показа',
      sort: sorter('userCondition'),
    },
    {
      key: 'report',
      title: 'Связь',
      sort: sorter('report'),
      render: (item) => {
        if (!item.report) {
          return '-';
        }

        const [section, report] = item.report.split('-');

        if (!isNaN(Number(section)) && !isNaN(Number(report))) {
          return (
            <Link to={`/reports/report-ds${section}-r${report}`}>
              Отчёт {item.report}
            </Link>
          );
        }

        return item.report;
      },
    },
    {
      key: 'textLength',
      title: 'Длина контента',
      render: (item) => DEFAULT_NUMERIC_FORMATTER(item.text.length),
      sort: (a, b) => a.text.length - b.text.length,
    },
    {
      key: 'edit',
      title: '',
      size: 'min-content',
      render: (item) => (
        <Button
          testId="edit"
          onClick={() => {
            navigate(`/support-admin/${item.guid}`);
          }}
          reportPanel
        >
          <Icon name="edit" /> Редактировать
        </Button>
      ),
    },
    {
      key: 'delete',
      title: '',
      size: 'min-content',
      render: (item) => (
        <Button
          testId="delete"
          onClick={() => {
            setDeleteId(item.guid);
            setIsDeleteModalOpen(true);
          }}
          reportPanel
          color="black"
        >
          Удалить
        </Button>
      ),
    },
  ];

  const [chapterFilter, setChapterFilter] = useState<string>('');

  const data = useMemo(() => {
    if (!listQueryData.data) {
      return [];
    }

    if (!chapterFilter) {
      return listQueryData.data;
    }

    return listQueryData.data.filter((page) => page.chapter === chapterFilter);
  }, [listQueryData.data, chapterFilter]);

  return (
    <>
      <PageHeader
        withGap
        title="Поддержка"
        rightTitleContent={
          <Button
            testId="create"
            onClick={() => {
              setIsCreateModalOpen(true);
            }}
            reportPanel
          >
            <Icon name="add" /> Создать
          </Button>
        }
      >
        <Select
          label="Раздел"
          small
          value={chapterFilter}
          valueLabel={chapterFilter || 'Все разделы'}
          options={[
            {
              value: '',
              label: 'Все разделы',
            },
            ...existingChapters.map((chapter) => ({
              value: chapter,
              label: chapter,
            })),
          ]}
          onChange={(value) => {
            setChapterFilter(value);
          }}
        />
      </PageHeader>
      <Modal
        testId="delete-modal"
        isOpened={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <Modal.Title gap>Удалить страницу?</Modal.Title>
        <Modal.Body>
          <p>Вы уверены, что хотите удалить страницу?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            testId="cancel"
            color="grey"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            Отмена
          </Button>
          <Spacer />
          <Button
            testId="delete"
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  await deleteSupportPage(deleteId!);
                  setIsDeleteModalOpen(false);
                }),
              );
            }}
          >
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        testId="create-modal"
        isOpened={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
      >
        <Modal.Title gap>Создать страницу</Modal.Title>
        <Modal.Body>
          <datalist id="chapters">
            {existingChapters.map((chapter) => (
              <option key={chapter} value={chapter} />
            ))}
          </datalist>
          <Input
            testId="create-title"
            label="Название"
            value={createTitle}
            onChange={(e) => {
              setCreateTitle(e.target.value);
            }}
          />
          <Input
            testId="create-chapter"
            label="Раздел"
            value={createChapter}
            list="chapters"
            onChange={(e) => {
              setCreateChapter(e.target.value);
            }}
          />
          <Input
            testId="create-order"
            label="Порядок"
            type="number"
            value={`${createOrder}`}
            onChange={(e) => {
              setCreateOrder(Number(e.target.value));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            testId="cancel"
            color="grey"
            onClick={() => {
              setIsCreateModalOpen(false);
            }}
          >
            Отмена
          </Button>
          <Spacer />
          <Button
            testId="create"
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  const newPage = await createSupportPage({
                    name: createTitle.trim(),
                    chapter: createChapter.trim(),
                    text: '',
                    report: '',
                    order: createOrder,
                    userCondition: '',
                  });
                  setIsCreateModalOpen(false);
                  navigate(`/support-admin/${newPage.guid}`);
                }),
              );
            }}
            disabled={!createTitle || !createChapter}
          >
            Создать
          </Button>
        </Modal.Footer>
      </Modal>
      <PageContent
        queryData={listQueryData}
        notFound={listQueryData.data?.length === 0}
        notFoundCustomText="Нет страниц"
      >
        <Table
          cols={COLS}
          defaultSort={{ key: 'order', direction: 'asc' }}
          rows={data}
        />
      </PageContent>
    </>
  );
});
