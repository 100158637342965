import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { $api } from '../../../../api/utils';
import { hasRole } from '../../../../shared/utils/hasRole';
import { queryClient } from '../../../../store/query';
import { Button } from '../../../../uikit/Button/Button';
import { Input } from '../../../../uikit/Input/Input';
import { Modal } from '../../../../uikit/Modal/Modal';
import { withLoader } from '../../../common/FullscreenLoader/FullscreenLoader';
import { Spacer } from '../../../common/Spacer/Spacer';
import { useAuthorizedUser } from '../../../../shared/hooks/useUser';

type TChangeRoleModalProps = {
  item: {
    login: string;
    roles: string[];
  };
  onClose: () => void;
};

export const ChangeRoleModal: React.FC<TChangeRoleModalProps> = ({
  item,
  onClose,
}) => {
  const userInfo = useAuthorizedUser();

  const [selectedRole, setSelectedRole] = useState<
    'CLIENT_ADMIN' | 'CLIENT_MANAGER'
  >(() => {
    return item.roles.includes('CLIENT_ADMIN')
      ? 'CLIENT_ADMIN'
      : 'CLIENT_MANAGER';
  });

  return (
    <Modal testId="role-change" isOpened onClose={onClose}>
      <Modal.Title gap>Смена роли пользователя</Modal.Title>
      <Modal.Body>
        <Input
          testId="login"
          label="E-mail"
          value={item.login}
          disabled
          block
        />
        <Input
          testId="role"
          label="Роль"
          type="select"
          value={selectedRole}
          onChange={(e) => {
            setSelectedRole(
              e.target.value as 'CLIENT_ADMIN' | 'CLIENT_MANAGER',
            );
          }}
          block
        >
          <option
            value="CLIENT_ADMIN"
            disabled={
              selectedRole === 'CLIENT_MANAGER' &&
              !hasRole(['CLIENT', 'ADMIN'], userInfo)
            }
          >
            Менеджер
          </option>
          <option value="CLIENT_MANAGER">Пользователь</option>
        </Input>
      </Modal.Body>
      <Modal.Footer>
        <Button testId="cancel" color="grey" onClick={onClose}>
          Отменить
        </Button>
        <Spacer />
        <Button
          testId="save"
          onClick={() => {
            withLoader(async () => {
              try {
                await $api.put('/user/role', {
                  userLogin: item.login,
                  newRole: selectedRole,
                });
                queryClient.invalidateQueries(['users']);
                onClose();
              } catch (e) {
                toast.error((e as Error).message);
              }
            });
          }}
        >
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
