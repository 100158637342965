import { ModalForm } from './ModalForm';
import { useState } from 'react';
import { useFormik } from 'formik';
import { getInitialValuesByAdminAndType, validateFields } from '../utils';
import { formTypes } from '../constants';
import { getRole } from '../../../../shared/utils/getRole';
import { ROLES } from '../../../../shared/constants';
import { Modal } from '../../../../uikit/Modal/Modal';
import { Button } from '../../../../uikit/Button/Button';
import { useAuthorizedUser } from '../../../../shared/hooks/useUser';

export const UserModal = ({ show, onClose, onFinish, type, selectedItem }) => {
  const [formErrors, setFormErrors] = useState();
  const userInfo = useAuthorizedUser();
  const modalHeaderText = type === 'edit' ? 'Редактирование' : 'Создание';
  const isAdmin = getRole(userInfo?.roles, ROLES.admin);
  const formTypeByRole = isAdmin ? formTypes.client : formTypes.officer;

  const initValues = !selectedItem
    ? {
        ...getInitialValuesByAdminAndType(isAdmin, type),
      }
    : {
        name: selectedItem?.name,
        login: selectedItem?.login,
        active: selectedItem?.active,
      };

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      if (validateFields(values, setFormErrors)) {
        onFinish({ ...values, userLogin: selectedItem?.login });
      }
    },
  });

  return (
    <Modal isOpened={show} onClose={() => onClose(false)}>
      <Modal.Title>
        {modalHeaderText} {isAdmin ? 'клиента' : 'сотрудника'}
      </Modal.Title>
      <Modal.Body>
        <ModalForm
          formik={formik}
          formErrors={formErrors}
          formTypeByRole={formTypeByRole}
          modalType={type}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button fullWidth color="grey" onClick={() => onClose(false)}>
          Закрыть
        </Button>
        <Button fullWidth onClick={() => formik.submitForm()}>
          {type === 'edit' ? 'Сохранить' : 'Создать'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
