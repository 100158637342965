export const normalizeString = (str: string): string => {
  const normalized = str.replace(/[^a-zа-яёй0-9]/gi, '').toLowerCase();
  return normalized;
};

export const hasSubstring = (
  searchText: string,
  text: string,
  isSearchNormalized = false,
): boolean => {
  const normalizedSearchText = isSearchNormalized
    ? searchText
    : normalizeString(searchText);
  const normalizedText = normalizeString(text);

  return normalizedText.includes(normalizedSearchText);
};
