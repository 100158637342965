import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Wiki.module.scss';
import { Button } from '../../../uikit/Button/Button';

import { Loader } from '../../common/Loader/Loader';
import copy from 'copy-text-to-clipboard';
import { toast } from 'react-toastify';
import { useSupportPages } from '../../../shared/queries/support';
import { useUniqueChapters } from '../../SupportAdmin/SupportAdminList';
import supportStyles from './supportPageContainer.module.scss';
import { sorter } from '../../common/Table/sorter';
import { useDocumentTitle } from '../../common/PageHeader/PageHeader';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';
import { ROLES } from '../../../shared/constants';
import { getRole } from '../../../shared/utils/getRole';
import { Gap } from '../../../uikit/Gap/Gap';
import { Icon } from '../../common/Icon/Icon';

const CONTACTS = [
  {
    title: 'По договорам и тарифам',
    role: 'Отдел сопровождения',
    email: 'ya@retail-data.center',
  },
  {
    role: 'Тех. поддержка',
    email: 'help@retail-data.center',
    title: 'По вопросам работоспособности сервиса',
  },
  {
    title: 'По вопросам о содержании данных',
    role: 'Выделенная линия ЦРПТ',
    email: 'support_analytics@crpt.ru',
  },
];

export const AppWiKi = () => {
  const userInfo = useAuthorizedUser();
  const userRoles = userInfo.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);

  const [expandedSections, setExpandedSections] = React.useState({});

  const listQueryData = useSupportPages();
  const existingChapters = useUniqueChapters(
    listQueryData.data?.sort?.(sorter('order', true)) || [],
  );
  const { guid } = useParams();
  const currentSupportPage =
    listQueryData.data?.find((page) => page.guid === guid) ||
    listQueryData.data?.[0] ||
    null;

  useEffect(() => {
    if (currentSupportPage) {
      setExpandedSections((expandedSections) => ({
        ...expandedSections,
        [currentSupportPage.chapter]: true,
      }));
    }
  }, [currentSupportPage]);

  useDocumentTitle(currentSupportPage?.name || 'Поддержка');

  const [currentHeaders, setCurrentHeaders] = React.useState([]);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) {
      setCurrentHeaders([]);
      return;
    }

    const headers = content.querySelectorAll('h1, h2');

    setCurrentHeaders(
      [...headers].map((header) => {
        return {
          text: header.innerText,
          top: header.tagName === 'H1',
          element: header,
        };
      }),
    );
  }, [currentSupportPage]);

  const navigate = useNavigate();
  const contentRef = useRef(null);
  const contentsRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    contentsRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentHeaders]);

  if (listQueryData.isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <div className={styles.support}>
      <div className={styles.leftBar} ref={menuRef}>
        {existingChapters.map((section) => {
          const pagesBySection = listQueryData.data
            ?.filter((page) => page.chapter === section)
            .sort(sorter('order', true));
          return (
            <div className={styles.section} key={section}>
              <div
                className={styles.title}
                onClick={() => {
                  setExpandedSections((expandedSections) => ({
                    ...expandedSections,
                    [section]: !expandedSections[section],
                  }));
                }}
              >
                <Icon
                  className={classNames(styles.expandIcon, {
                    [styles.expanded]: expandedSections[section],
                  })}
                  name="expand"
                  size={18}
                />
                <span className={styles.titleName}>{section}</span>
              </div>
              {expandedSections[section] &&
                pagesBySection.map((item, index) => {
                  const isCurrent = currentSupportPage?.guid === item?.guid;
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={classNames(styles.link, {
                          [styles.active]: isCurrent,
                        })}
                        onClick={() => {
                          navigate(`/support/${item.guid}`);
                        }}
                      >
                        {item.name}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          );
        })}
        <div className={styles.divider} />
        <div className={styles.contacts}>
          <div className={styles.title}>Контакты:</div>
          {CONTACTS.map((item, index) => (
            <div className={styles.contact} key={index}>
              <div className={styles.contactInfo}>
                {item.title && (
                  <div className={styles.contactTitle}>{item.title}</div>
                )}
                {item.role && (
                  <div className={styles.contactRole}>{item.role}</div>
                )}
                {item.email && (
                  <div
                    className={classNames(styles.contactEmail, styles.copiable)}
                    onClick={() => {
                      copy(item.email);
                      toast('E-mail скопирован в буфер обмена');
                    }}
                  >
                    {item.email}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          styles.content,
          supportStyles.supportPageContainer,
        )}
      >
        <div ref={contentsRef} />
        {isAdmin && (
          <div>
            <Button
              testId="edit-button"
              reportPanel
              onClick={() => {
                navigate(`/support-admin/${currentSupportPage.guid}`);
              }}
            >
              Редактировать
            </Button>
            <Gap />
          </div>
        )}
        {currentHeaders.length > 1 && (
          <div className={styles.contents}>
            {currentHeaders.map((header, index) => {
              return (
                <div
                  key={index}
                  className={classNames({
                    [styles.subLink]: header.top,
                    [styles.subSubLink]: !header.top,
                  })}
                  onClick={() => {
                    header.element.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                >
                  {header.text}
                </div>
              );
            })}
          </div>
        )}
        <div
          onClick={(e) => {
            const isClickOnLink = e.target.tagName === 'A';
            if (!isClickOnLink) {
              return;
            }

            const isLinkRelative = e.target.href.startsWith(
              window.location.origin,
            );
            if (!isLinkRelative) {
              return;
            }

            e.preventDefault();
            const link = e.target.href.replace(window.location.origin, '');
            navigate(link);
          }}
          ref={contentRef}
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: currentSupportPage?.text || '' }}
        ></div>
      </div>
    </div>
  );
};
