import convertCsvToXslxWorker from 'workerize-loader!./csvToXslx.worker';
import type { ConvertFileWorkerFunction } from './csvToXslx.worker';
import { withLoader } from '../../../newComponents/common/FullscreenLoader/FullscreenLoader';

const workerInstance = convertCsvToXslxWorker();

export const convertCsvToXslx: ConvertFileWorkerFunction = (config: {
  filename: string;
  csvString: string;
}) => withLoader(() => workerInstance.convertCsvToXslx(config));
