import React, { useMemo } from 'react';
import { $api } from '../../../../../../../api/utils';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '../../../../../../common/Loader/Loader';

import styles from './Chart.module.scss';
import EChartsReact from 'echarts-for-react';
import { DEFAULT_ECHARTS_PROPS } from '../../../../../../../shared/charts';
import {
  DEFAULT_NUMERIC_FORMATTER,
  EXACT_NUMERIC_FORMATTER,
} from '../../../../../../../shared/utils/formatters';

type TItem = {
  month: string;
  value: number;
  color: string;
};

export type TFormatter = (data: any) => TItem[];

type TChartProps = {
  backendUrl: string;
  dataFormatter: TFormatter;
  hideLabels?: boolean;
  fixedPosition?: boolean;
};

const getData = async (backendUrl: string) => {
  const { data } = await $api.get(`${backendUrl}?limit=12`);
  return data;
};

const useData = (backendUrl: string) => {
  return useQuery(['dashboard', backendUrl], () => getData(backendUrl));
};

export const ChartWidget: React.FC<TChartProps> = ({
  backendUrl,
  dataFormatter,
  hideLabels,
  fixedPosition,
}) => {
  const { data, isLoading } = useData(backendUrl);

  const preparedData = useMemo(() => {
    if (!data) {
      return [];
    }

    return dataFormatter(data);
  }, [data, dataFormatter]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (!preparedData.length || preparedData.every(({ value }) => !value)) {
    return (
      <div className={styles.loader}>
        <div className={styles.noData}>
          <p>Данных для отображения нет.</p>
          <p>Информация появится после загрузки ваших данных.</p>
        </div>
      </div>
    );
  }

  return (
    <EChartsReact
      {...DEFAULT_ECHARTS_PROPS}
      className={styles.chart}
      option={{
        grid: {
          top: 20,
          left: 0,
          right: 0,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: preparedData.map(({ month }) => month),
          axisLabel: {
            formatter: (value: string) => {
              if (hideLabels) {
                return '';
              }

              return value;
            },
            color: '#353535',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value: number) => {
              return DEFAULT_NUMERIC_FORMATTER(value, true);
            },
            color: '#353535',
          },
          splitLine: {
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            data: preparedData.map(({ value, color }) => {
              return {
                value,
                itemStyle: {
                  color,
                  borderRadius: [4, 4, 0, 0],
                },
              };
            }),
            type: 'bar',
          },
        ],
        tooltip: {
          trigger: 'axis',
          position: fixedPosition ? [0, 0] : undefined,
          formatter: (params: any) => {
            const { name, value } = params[0];
            return `
              <div class="${styles.tooltip}">
                  <div>
                      <strong>${name}</strong>   
                  </div>
                  <div>
                      <span>${EXACT_NUMERIC_FORMATTER(value)}</span>
                  </div>
              </div>
          `;
          },
        },
      }}
    />
  );
};
