import { DS1R12Response } from '../../../../../api/reports/Api';
import { OPERATION_TYPES } from '../../../../../shared/constants';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'Вывод из оборота по типам';

type TItem = {
  sellerInn: string;
  sellerName: string;
  total: number;
  [key: string]: number | string;
};

export const ds1o12Config: TReportConfigType<TItem> = {
  title: NAME,
  backendKey: 'ds1r12',

  // @ts-ignore
  prepareData: (data: Record<string, DS1R12Response>) => Object.values(data),

  // @ts-ignore
  prepareDataItem: (item: DS1R12Response) => ({
    sellerInn: item.sellerInn,
    sellerName: item.sellerName,
    ...OPERATION_TYPES.reduce(
      (acc, type) => ({
        ...acc,
        [type]: item.operationTypes[type] || 0,
      }),
      {},
    ),
    total: OPERATION_TYPES.map((type) => item.operationTypes[type] || 0).reduce(
      (acc, value) => acc + value,
      0,
    ),
  }),

  dataFeatures: [],

  viewFeatures: [
    getTableFeature<TItem>({
      filename: NAME,
      canHideEmptyColumns: true,
      defaultSort: 'LAST',
      baseColumns: [
        {
          key: 'sellerInn',
          title: 'ИНН',
          sort: sorter('sellerInn'),
          fixed: true,
        },
        {
          key: 'sellerName',
          title: 'Название продавца',
          sort: sorter('sellerName'),
          fixed: true,
        },
        ...OPERATION_TYPES.map((type) => ({
          key: type,
          title: type,
          sort: sorter(type, true),
          isNumeric: true,
          render: (row: TItem) => {
            return EXACT_NUMERIC_FORMATTER(row[type] as number);
          },
          renderForExport: (row: TItem) => row[type] as string,
        })),
        {
          key: 'total',
          title: 'Итого',
          sort: sorter('total', true),
          isNumeric: true,
          render: (row: TItem) => {
            return EXACT_NUMERIC_FORMATTER(row.total as number);
          },
          renderForExport: (row: TItem) => row.total as unknown as string,
        },
      ],
    }),
    getBarChartFeature<TItem>({
      nameKey: 'sellerName',
      innKey: 'sellerInn',
      valueKey: 'total',
      filename: 'Вывод из оборота',
      subValueKeys: OPERATION_TYPES,
    }),
    getTreeMapFeature<TItem>({
      topName: 'Вывод из оборота',
      parentNameKey: 'sellerInn',
      childrenNameKey: 'sellerName',
      innKey: 'sellerInn',
      valueKey: 'total',
      filename: 'Вывод из оборота',
      unwrapDataItem: (item) =>
        OPERATION_TYPES.map((type) => ({
          ...item,
          operation_type: type,
          total: Number(item[type]) || 0,
        })),
      customGroups: [
        {
          key: 'inn',
          label: 'ИНН',
          parentKey: 'sellerInn',
          parentNameKey: 'sellerName',
          childrenNameKey: 'operation_type',
          innKey: 'sellerInn',
          valueKey: 'total',
        },
        {
          key: 'operation_type',
          label: 'Типы вывода',
          parentKey: 'operation_type',
          parentNameKey: 'operation_type',
          childrenNameKey: 'sellerName',
          innKey: 'sellerInn',
          valueKey: 'total',
          hideTop: true,
        },
      ],
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      label: 'ИНН',
      commonDataKey: 'retailSellers',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'GTIN',
      icon: 'package',
      commonDataKey: 'gtins',
      mode: 'gtins',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['retailSellers'],

  isRequiredFieldsFilled: () => true,
};
