import { useEffect, useState } from 'react';

export const useAsyncMemo = <TValue>(
  asyncFn: () => Promise<TValue>,
  deps: any[],
): [boolean, TValue | null, Error | null] => {
  const [ready, setReady] = useState(false);
  const [value, setValue] = useState<TValue | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    asyncFn()
      .then((result) => {
        setValue(result);
        setReady(true);
      })
      .catch((err) => {
        setError(err);
        setReady(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [ready, value, error];
};
