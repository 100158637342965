import React, { memo, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { TReportConfigType, ViewFeature } from './reportConfigType';
import { useLineSelect } from '../../../../../shared/hooks/useLineSelect';
import classNames from 'classnames';
import styles from './AllPurposeReport.module.scss';
import { TLineSelectProps } from '../../../../common/LineSelect/LineSelect';

type TViewSelectProps<TItem extends Record<string, any>> = {
  headerElementTarget: HTMLDivElement | null;
  viewFeatures: TReportConfigType<TItem>['viewFeatures'];
  viewComponentsProps: ViewFeature<TItem>['component']['propTypes'];
  headerRefs: React.MutableRefObject<HTMLDivElement[]>;
  dataMemoized: any;
};

const LINE_SELECT_PROPS: Partial<TLineSelectProps<any>> = {
  mobileIcon: 'viewCarousel',
};

function ViewSelectRaw<TItem extends Record<string, any>>({
  headerElementTarget,
  headerRefs,
  viewFeatures,
  viewComponentsProps,
  dataMemoized,
}: TViewSelectProps<TItem>) {
  const [shouldRenderDelayedViewsState, setShouldRenderDelayedViews] =
    useState(false);

  const prevData = useRef(dataMemoized);
  const shouldRenderDelayedViews =
    shouldRenderDelayedViewsState && prevData.current === dataMemoized;
  prevData.current = dataMemoized;

  useEffect(() => {
    if (!shouldRenderDelayedViews) {
      const timeout = setTimeout(() => {
        setShouldRenderDelayedViews(true);
      }, 700);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [shouldRenderDelayedViews]);

  useEffect(() => {
    setShouldRenderDelayedViews(false);
  }, [dataMemoized]);

  const [viewFeature, , viewFeatureSelect] = useLineSelect(
    'view',
    viewFeatures[0].key,
    viewFeatures.map((feature) => ({
      value: feature.key,
      label: feature.title,
      icon: feature.icon,
      hint: feature.hint,
    })),
    LINE_SELECT_PROPS,
  );

  const viewFeatureCurrentIndex = viewFeatures.findIndex(
    (feature) => feature.key === viewFeature,
  );

  const currentViewFeature = viewFeatures[viewFeatureCurrentIndex];
  const ViewComponents = viewFeatures.map((feature) => feature.component);

  const [viewFeatureState, , viewFeatureStateSelect] = useLineSelect(
    'view-state',
    currentViewFeature?.defaultState ||
      currentViewFeature?.lineSelectOptions?.[0]?.value ||
      '',
    currentViewFeature?.lineSelectOptions || [],
  );

  const headerElements = (
    <>
      <div className={styles.portalTargetsGroup}>
        {viewFeatures.map((feature, index) => (
          <div
            className={classNames(styles.portalTarget, {
              [styles.active]: viewFeature === feature.key,
            })}
            ref={(headerElement) => {
              // @ts-ignore
              headerRefs.current[index] = headerElement;
            }}
            data-test-id={`view-controls-${feature.key}`}
            data-test-visible={viewFeature === feature.key}
            key={`view-controls-${feature.key}`}
          />
        ))}
      </div>
      {viewFeatureStateSelect}
      <div
        className={styles.viewFeatureSelect}
        onMouseEnter={() => {
          setShouldRenderDelayedViews(true);
        }}
      >
        {viewFeatureSelect}
      </div>
    </>
  );

  const [, setIsRendered] = useState(false);
  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <>
      {headerElementTarget &&
        ReactDOM.createPortal(headerElements, headerElementTarget)}
      {ViewComponents.map((ViewComponent, index) => {
        const shouldRender =
          shouldRenderDelayedViews || viewFeatureCurrentIndex === index;
        return (
          <div
            key={index}
            className={classNames(styles.viewComponent, {
              [styles.active]: viewFeature === viewFeatures[index].key,
              [styles.toTheLeft]: viewFeatureCurrentIndex > index,
              [styles.toTheRight]: viewFeatureCurrentIndex < index,
            })}
            data-test-id={`view-${viewFeatures[index].key}`}
            data-test-visible={viewFeature === viewFeatures[index].key}
          >
            {shouldRender && (
              // @ts-ignore
              <ViewComponent
                {...viewComponentsProps}
                state={viewFeatureState}
                headerElement={headerRefs.current[index] as HTMLDivElement}
              />
            )}
          </div>
        );
      })}
    </>
  );
}

export const ViewSelect = memo(ViewSelectRaw);
