import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasRole, Role } from '../utils/hasRole';
import { useAuthorizedUser, UserFlags, useUserFlag } from '../hooks/useUser';

export const withRestriction = (
  roles: Role | Role[],
  Component: React.FC<any>,
): React.FC => {
  const RestrictionWrapper: React.FC = (props) => {
    const userInfo = useAuthorizedUser();
    const userHasRole = hasRole(roles, userInfo);
    const navigate = useNavigate();
    useEffect(() => {
      if (!!userInfo && !userHasRole) {
        navigate('/');
      }
    }, [navigate, userHasRole, userInfo]);
    if (!userHasRole) {
      return null;
    }
    return <Component {...props} />;
  };

  return RestrictionWrapper;
};

export const withUserFlagRestriction = (
  flag: UserFlags,
  Component: React.FC<any>,
): React.FC => {
  const RestrictionWrapper: React.FC = (props) => {
    const navigate = useNavigate();
    const flagValue = useUserFlag(flag);

    useEffect(() => {
      if (!flagValue) {
        navigate('/');
      }
    }, [flagValue, navigate]);

    if (!flagValue) {
      return null;
    }
    return <Component {...props} />;
  };

  return RestrictionWrapper;
};
