import { ReportFilter } from '../../../../../../api/reports/types';

export type TMode = 'inns' | 'gtins' | 'codes' | 'addresses';

export type TModeKeys = {
  singleValueKey: keyof ReportFilter | string;
  multipleValueKey: keyof ReportFilter | string;

  dictionaryNameKey: keyof ReportFilter | string;
  dictionaryNamesKey: keyof ReportFilter | string;

  networkNameKey?: keyof ReportFilter | string;
  multipleNetworkNameKey?: keyof ReportFilter | string;
};

export type TModeKeysWithoutMultipleNetworkNameKey = {
  valueKey: keyof ReportFilter | string;
  dictionaryNameKey: keyof ReportFilter | string;
  networkNameKey?: keyof ReportFilter | string;
};

type TModeConfig = TModeKeys & {
  inverted: TModeKeys;

  dictionaryKey: keyof ReportFilter | string;
  defaultDatasetKey: string;
  networkDatasetKey?: string;

  dictionaryDataKey?:
    | 'innDictionaries'
    | 'gtinDictionaries'
    | 'addressDictionaries';
};

export const SELECT_MODES: Record<TMode, TModeConfig> = {
  inns: {
    singleValueKey: 'inn',
    multipleValueKey: 'inns',
    dictionaryKey: 'dictionaryInn',
    dictionaryNameKey: 'dictInnName',
    dictionaryNamesKey: 'dictInnNames',
    networkNameKey: 'netName',
    multipleNetworkNameKey: 'netNames',

    inverted: {
      singleValueKey: 'notInn',
      multipleValueKey: 'notInns',
      dictionaryNameKey: 'notDictInnName',
      dictionaryNamesKey: 'notDictInnNames',

      networkNameKey: 'notNetName',
      multipleNetworkNameKey: 'notNetNames',
    },

    dictionaryDataKey: 'innDictionaries',
    defaultDatasetKey: 'inn',
    networkDatasetKey: 'network',
  },

  gtins: {
    singleValueKey: 'gtin',
    multipleValueKey: 'gtins',
    dictionaryKey: 'dictionaryGtin',
    dictionaryNameKey: 'dictGtinName',
    dictionaryNamesKey: 'dictGtinNames',

    inverted: {
      singleValueKey: 'notGtin',
      multipleValueKey: 'notGtins',
      dictionaryNameKey: 'notDictGtinName',
      dictionaryNamesKey: 'notDictGtinNames',
    },

    dictionaryDataKey: 'gtinDictionaries',
    defaultDatasetKey: 'gtin',
  },

  codes: {
    singleValueKey: 'rfCode',
    multipleValueKey: 'rfCodes',
    dictionaryKey: 'dictionaryRfSubjectCode',
    dictionaryNameKey: 'dictRfSubjectCodeName',
    dictionaryNamesKey: 'dictRfSubjectCodeNames',

    inverted: {
      singleValueKey: 'notRfCode',
      multipleValueKey: 'notRfCodes',
      dictionaryNameKey: 'notDictRfSubjectCodeName',
      dictionaryNamesKey: 'notDictRfSubjectCodeNames',
    },

    defaultDatasetKey: 'rfSubjectCode',
  },

  addresses: {
    singleValueKey: 'salesPoint',
    multipleValueKey: 'salesPoints',
    dictionaryKey: 'dictionaryAddress',
    dictionaryNameKey: 'dictAddressName',
    dictionaryNamesKey: 'dictAddressNames',

    inverted: {
      singleValueKey: 'notSalesPoint',
      multipleValueKey: 'notSalesPoints',
      dictionaryNameKey: 'notDictAddressName',
      dictionaryNamesKey: 'notDictAddressNames',
    },

    dictionaryDataKey: 'addressDictionaries',
    defaultDatasetKey: 'innAddressHash',
  },
};
