import { DS1R2Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

export const ds1o2Config: TReportConfigType<DS1R2Response> = {
  title: 'Оптовые покупатели',
  backendKey: 'ds1r2',

  dataFeatures: [],

  viewFeatures: [
    getTableFeature<DS1R2Response>({
      defaultSort: 'buyingsCnt',
      filename: 'Оптовые покупатели',
      baseColumns: [
        {
          key: 'buyerInn',
          title: 'ИНН покупателя',
          sort: sorter('buyerInn'),
        },
        {
          key: 'buyerName',
          title: 'Название покупателя',
          sort: sorter('buyerName'),
        },
        {
          key: 'buyingsCnt',
          title: 'Объем покупок, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('buyingsCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.buyingsCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.buyingsCnt);
          },
        },
      ],
    }),
    getBarChartFeature<DS1R2Response>({
      nameKey: 'buyerName',
      innKey: 'buyerInn',
      valueKey: 'buyingsCnt',
      filename: 'Оптовые покупатели',
    }),
    getBarChartFeature<DS1R2Response>({
      nameKey: 'buyerName',
      innKey: 'buyerInn',
      valueKey: 'buyingsCnt',
      withPercents: true,
      accumulateBy: 'sharePercent',
      icon: 'barChartWithLine',
      filename: 'Оптовые покупатели',
    }),
    getTreeMapFeature<DS1R2Response>({
      topName: 'Оптовые покупатели',
      parentNameKey: 'buyerInn',
      childrenNameKey: 'buyerName',
      innKey: 'buyerInn',
      valueKey: 'buyingsCnt',
      filename: 'Оптовые покупатели',
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      label: 'Покупатели',
      commonDataKey: 'buyers',
    }),
  ],
  requiredData: {
    availablePeriod: true,
  },
  requiredCommonData: ['buyers'],

  isRequiredFieldsFilled: () => true,
};
