import { EChartsInstance } from '../newComponents/common/Chart/components/DownloadChart/DownloadChart';

export const DEFAULT_ECHARTS_PROPS = {
  style: {
    height: '100%',
  },
  onChartReady: (instance: any) => {
    EChartsInstance.current = instance;
  },
  shouldSetOption: (prev: any, next: any) => {
    const areOptionsEqual =
      JSON.stringify(prev.option) === JSON.stringify(next.option);
    return !areOptionsEqual;
  },
};

export const DEFAULT_GRID = {
  top: 60,
  left: 50,
  right: 50,
  bottom: 50,
  containLabel: true,
};

export const MOBILE_GRID = {
  top: 5,
  left: 5,
  right: 5,
  bottom: 5,
  containLabel: false,
};

const DEFAULT_TOOLTIP = {
  trigger: 'axis',
};

const DEFAULT_LEGEND = {
  type: 'plain',
};

export const DEFAULT_VALUE_AXIS = {
  type: 'value',
};

export const DEFAULT_PERCENT_AXIS = {
  type: 'value',
  min: 0,
  max: 100,
  axisLabel: {
    formatter: '{value}%',
  },
};

export const DEFAULT_ZOOM = {
  type: 'inside',
  show: true,
  xAxisIndex: [0],
  start: 0,
  end: 10,
  zoomOnMouseWheel: true,
  brushSelect: false,
};

export const DEFAULT_CATEGORY_AXIS = {
  type: 'category',
  data: [],
  axisLabel: {
    rotate: -90,
    fontSize: 10,
    show: true,
    overflow: 'truncate',
    width: 200,
  },
};

export const DEFAULT_CONFIG = {
  grid: DEFAULT_GRID,
  tooltip: DEFAULT_TOOLTIP,
  legend: DEFAULT_LEGEND,
  yAxis: DEFAULT_VALUE_AXIS,
  backgroundColor: '#fff',
};
