import { EXACT_NUMERIC_FORMATTER } from '../../../../../../shared/utils/formatters';
import { sorter } from '../../../../../common/Table/sorter';
import { TTableColumn } from '../../../../../common/Table/Table';
import { DataFeature } from '../reportConfigType';

export type TWithTransposedFields<TDataItem> = TDataItem & {
  [key: `transpose_${string}_values`]: string[];
  [key: `transposed_${string}`]: number;
  'transpose-label'?: string;
  'transpose-key'?: string;
};

export type TTransposeProps<TDataItem> = {
  /** Индекс, определяет группировку */
  transposeByKeys: Array<keyof TDataItem>;
  /** Ключ, по которому брать столбцы */
  transposeColumnKey: keyof TDataItem;
  /** Поля, которые нужно суммировать */
  measures: {
    valueKey: keyof TDataItem;
    label: string;
  }[];
  key: string;
  getColumnTitle?: (data: TDataItem[], value: string) => string;
  sortColumns?: (colAName: string, colBname: string) => number;
};

const getKey = <TDataItem>(item: TDataItem, keys: Array<keyof TDataItem>) => {
  return keys.map((key) => item[key]).join('_');
};

export const getTransposeFeature = <TDataItem extends Record<string, any>>(
  props: TTransposeProps<TDataItem>,
): DataFeature<TDataItem> => {
  const key = `transpose_${props.key}`;
  const commonLabel =
    props.measures.length <= 1 ? props.measures[0].label : null;
  const needLabelColumn = props.measures.length > 1;
  return {
    key,

    getLineSelectOptions: () => [],

    tableColumnsMiddleware: (
      columns,
      _state,
      _setState,
      data: TWithTransposedFields<TDataItem>[],
    ) => {
      const values = new Set<string>();
      data.forEach((item) => {
        const itemValues =
          item[`transpose_${props.transposeColumnKey as string}_values`];
        if (itemValues && Array.isArray(itemValues)) {
          itemValues.forEach((value) => values.add(value));
        }
      });
      const valuesArray = Array.from(values);
      if (valuesArray.length === 0) {
        return columns;
      }
      const sortColumns = props.sortColumns;
      if (sortColumns) {
        valuesArray.sort((a, b) => sortColumns(a, b));
      }
      return [
        ...columns,
        needLabelColumn && {
          key: 'transpose-label',
          title: 'Показатель',
        },
        ...valuesArray.map((value) => {
          const enrichedValue = props.getColumnTitle
            ? props.getColumnTitle(data, value)
            : value;
          return {
            key: `transposed_${value}`,
            title: enrichedValue,
            sort: sorter(`transposed_${value}`, true),
            isNumeric: true,
            hint: `${enrichedValue} ${commonLabel || ''}`.trim(),
            render: (row: TWithTransposedFields<TDataItem>) => {
              if (!row[`transposed_${value}`]) {
                return '0';
              }
              return EXACT_NUMERIC_FORMATTER(row[`transposed_${value}`]);
            },
            renderForExport: (row: TWithTransposedFields<TDataItem>) => {
              if (!row[`transposed_${value}`]) {
                return 0;
              }
              return row[`transposed_${value}`] as unknown as string;
            },
          };
        }),
      ].filter(Boolean) as TTableColumn<TWithTransposedFields<TDataItem>>[];
    },

    tableDataMiddleware: (data): TWithTransposedFields<TDataItem>[] => {
      const index: Record<string, TDataItem[]> = {};
      for (const item of data) {
        const key = getKey(item, props.transposeByKeys);
        if (!index[key]) {
          index[key] = [];
        }
        index[key].push(item);
      }
      const result: TWithTransposedFields<TDataItem>[] = [];
      for (const key in index) {
        const items = index[key];
        const firstItem = items[0];
        const newItems: TWithTransposedFields<TDataItem>[] = props.measures.map(
          () => ({
            ...firstItem,
          }),
        );
        for (const item of items) {
          for (const measureIndex in props.measures) {
            const measure = props.measures[measureIndex];
            // @ts-ignore
            newItems[measureIndex][
              `transposed_${item[props.transposeColumnKey] as string}`
            ] =
              // @ts-ignore
              item[measure.valueKey];

            // @ts-ignore
            newItems[measureIndex]['transpose-label'] = measure.label;
            // @ts-ignore
            newItems[measureIndex]['transpose-key'] = measure.valueKey;
          }
        }
        for (const measureIndex in props.measures) {
          // @ts-ignore
          newItems[measureIndex][
            `transpose_${props.transposeColumnKey as string}_values`
          ] = items.map((item) => item[props.transposeColumnKey]);
        }
        result.push(...newItems);
      }

      return result;
    },
  };
};
