import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { TTransposeProps } from '../dataFeatures/transpose';

const DEFAULT_MEASURES = [
  {
    label: 'Объем продаж',
    valueKey: 'salesCnt',
  },
];

export const getIntervalTransposeConfig = <
  TDataItem extends Record<string, any>,
>(
  dateFormat: string = 'YYYY-MM-DD',
  mainKey: string = 'sellerInn',
  measures: {
    valueKey: string;
    label: string;
  }[] = DEFAULT_MEASURES,
): TTransposeProps<TDataItem> => ({
  transposeByKeys: [mainKey],
  transposeColumnKey: 'reportInterval',
  key: mainKey,
  measures,
  sortColumns: (colAName, colBname) => {
    const dateA = dayjs(colAName, dateFormat);
    const dateB = dayjs(colBname, dateFormat);
    return dateA.diff(dateB);
  },
  getColumnTitle: (data, value) => {
    const firstItem = data[0];
    const intervalType = firstItem?.intervalType?.toLowerCase?.() || '';
    if (intervalType === 'm' || intervalType === 'w') {
      const date = dayjs(value, dateFormat);
      if (intervalType === 'm') {
        const title = date.format('MMM YYYY');
        return capitalize(title);
      }
      // @ts-ignore
      const weekNumber = date.week();
      return `${weekNumber} неделя ${date.year()} г.`;
    }
    return value;
  },
});
