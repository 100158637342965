import React from 'react';
import { useSearchQueryState } from '../../../../../../shared/hooks/useSearchQueryState';
import { ViewFeature } from '../reportConfigType';
import ReactDOM from 'react-dom';
import { LineSelect } from '../../../../../common/LineSelect/LineSelect';
import { Chart } from '../../../../../common/Chart/Chart';
import Sankey from '../../../../../../components/Graphs/Sankey';
import { useIsFullscreen } from '../../../../../common/PageContent/useIsFullscreen';
import { cloneDeep } from 'lodash';
import { DS1R6Response } from '../../../../../../api/reports/Api';

import rightClickIcon from './icons/wired-outline-1317-computer-mouse-right-click.json';
import { useLottie } from 'lottie-react';

import styles from './RightClickHint.module.scss';
import { useBreakpoint } from '../../../../../../uikit/hooks/useBreakpoint';

const RightClickHint = () => {
  const { View: Icon, goToAndPlay } = useLottie(
    {
      animationData: rightClickIcon,
      loop: false,
      autoplay: false,
    },
    {
      width: 30,
      height: 30,
    },
  );
  return (
    <div
      className={styles.rightClickHint}
      onMouseEnter={() => {
        goToAndPlay(0);
      }}
    >
      <span className={styles.rightClickHintLabel}>
        Щелкните правой кнопкой по участнику, чтобы открыть отчёт по нему
      </span>
      <span className={styles.rightClickHintIcon}>{Icon}</span>
    </div>
  );
};

export const getSankeyFeature = <TDataItem,>(): ViewFeature<
  TDataItem,
  string,
  false
> => {
  const SankeyComponent: ViewFeature<TDataItem, string, false>['component'] = ({
    data,
    headerElement,
    dataUpdatedAt,
  }) => {
    const sankeyData = data as DS1R6Response;
    const [align, setAlign] = useSearchQueryState<
      'left' | 'justify' | 'center' | 'right'
    >('align', 'center');
    const [isFullscreen] = useIsFullscreen();
    const breakpoints = useBreakpoint();

    return (
      <>
        {headerElement &&
          ReactDOM.createPortal(
            <>
              {breakpoints.isLarge && <RightClickHint />}
              <LineSelect
                testId="align"
                whiteBackground
                value={align}
                onChange={setAlign}
                options={[
                  { value: 'left', label: 'Влево' },
                  { value: 'justify', label: 'По ширине' },
                  { value: 'center', label: 'По центру' },
                  { value: 'right', label: 'Вправо' },
                ]}
                mobileIcon="alignIcon"
              />
            </>,
            headerElement,
          )}
        <Chart fullHeight testId="sankey" name="Sankey">
          <Sankey
            key={`${dataUpdatedAt}-${isFullscreen}-new`}
            align={align}
            data={cloneDeep({
              nodes: sankeyData.data.map((name) => ({ id: name, name })),
              links: sankeyData.links,
            })}
          />
        </Chart>
      </>
    );
  };

  return {
    key: 'sankey',
    title: 'Sankey',
    icon: 'map',
    component: SankeyComponent,
  };
};
