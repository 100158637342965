import { DS2R1Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { getGroupByFeature } from '../AllPurposeReport/dataFeatures/groupBy';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getMapFeature } from '../AllPurposeReport/viewFeatures/map';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

export const ds2o1Config: TReportConfigType<DS2R1Response> = {
  title: 'Продажи ИНН по регионам',
  backendKey: 'ds2r1',

  dataFeatures: [
    getGroupByFeature<DS2R1Response>({
      options: [
        {
          groupBy: 'sellerAddrSfCode',
          label: 'Код субъекта РФ',
          additionalColumns: [
            {
              key: 'sellerAddrSfCode',
              title: 'Код субъекта РФ',
              sort: sorter('sellerAddrSfCode'),
            },
            {
              key: 'sellerAddrSfName',
              title: 'Субъект РФ',
              sort: sorter('sellerAddrSfName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt'],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS2R1Response>({
      defaultSort: 'salesCnt',
      filename: 'Продажи ИНН по регионам',
      baseColumns: [
        {
          key: 'salesCnt',
          title: 'Объем продаж, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('salesCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
        },
      ],
    }),
    getMapFeature<DS2R1Response>({
      filename: 'Продажи ИНН по регионам',
      regionCodeKey: 'sellerAddrSfCode',
      valueKey: 'salesCnt',
      valueLabel: 'Продажи',
      valueFormatter: EXACT_NUMERIC_FORMATTER,
    }),
    getTreeMapFeature<DS2R1Response>({
      topName: 'Продажи ИНН по регионам',
      parentNameKey: 'sellerAddrSfCode',
      childrenNameKey: 'sellerAddrSfName',
      innKey: 'sellerAddrSfCode',
      valueKey: 'salesCnt',
      filename: 'Продажи ИНН по регионам',
      groupKeyToNameMap: {
        sellerAddrSfCode: 'sellerAddrSfName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: true,
      shouldUseDictionary: true,
      commonDataKey: 'retailSellers',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    innDictionaries: true,
  },

  requiredCommonData: ['retailSellers', 'retailSellerNetworks'],

  isRequiredFieldsFilled: () => true,
};
