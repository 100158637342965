import { useState } from 'react';
import { sendUploadRequest } from '../../../api/uploads/uploads';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { AddReportRequest } from '../../../api/backend/Api';

export const useAppUploads = (queryClient: QueryClient) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onSubmitModal = (values: AddReportRequest) => {
    sendUploadRequest(values)
      .then(() => {
        toast.success('Выгрузка добавлена');
        setVisibleModal(false);
        queryClient.invalidateQueries(['uploads']);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
  };

  return { setVisibleModal, visibleModal, onSubmitModal };
};
