import React from 'react';
import { $api } from '../../../../../../../api/utils';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '../../../../../../common/Loader/Loader';
import { Event, TEventSimple } from '../../../../../../common/Event/Event';

import styles from './Events.module.scss';
import dayjs from 'dayjs';
import classNames from 'classnames';

const getEvents = async () => {
  const { data } = await $api.get<TEventSimple[]>(`/events/short?limit=300`);
  return data.map((item) => ({
    ...item,
    publishDttm: dayjs(item.publishDttm)
      .utc(true)
      .local()
      .format('YYYY-MM-DDTHH:mm'),
  }));
};

export const useEvents = () => {
  return useQuery(['events'], getEvents, {
    refetchInterval: 30000,
  });
};

export const EventsWidget: React.FC<{
  wrapperClassName?: string;
}> = ({ wrapperClassName }) => {
  const { data, isLoading } = useEvents();

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (!data || !data.length) {
    return (
      <div className={styles.loader}>
        <div className={styles.noData}>
          <p>Для вас пока нет уведомлений.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.eventsWrapper, wrapperClassName)}>
      <div className={styles.events}>
        {data.map((event, index) => (
          <Event key={index} event={event} />
        ))}
      </div>
    </div>
  );
};
