export const ACCESS_TABS = [
  {
    key: 'userList',
    title: 'Список пользователей',
  },
  {
    key: 'usersAction',
    title: 'Действия пользователей',
  },
];

export const actionEntities = {
  modal: 'modal',
  tab: 'tab',
  modalType: 'modalType',
  selectedItem: 'selectedItem',
  sidebar: 'sidebar',
};

export const initialValues = {
  name: '',
  login: '',
  password: '',
};

export const formTypes = {
  client: 'client',
  officer: 'officer',
};
