import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { Button } from '../../../uikit/Button/Button';

import styles from './LinkAddress.module.scss';
import { PageContent } from '../../common/PageContent/PageContent';
import { TTableColumn, Table } from '../../common/Table/Table';
import { EXACT_NUMERIC_FORMATTER } from '../../../shared/utils/formatters';
import {
  downloadDictionary,
  downloadSample,
  downloadUnlinkedPoints,
  uploadSample,
  useInfo,
  useIsExist,
} from './queries';
import dayjs from 'dayjs';
import { getDeclarator } from '../../../shared/utils/declOfNum';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';
import { Page404 } from '../../../pages/404';
import { getRole } from '../../../shared/utils/getRole';
import { ROLES } from '../../../shared/constants';
import { convertXslxToCsv } from '../../../shared/utils/worker/xslxToCsv';
import { isDev } from '../../../shared/utils/isDev';
import * as Sentry from '@sentry/react';

type TTableItem = {
  label: string;
  value: number;
  percent?: string;
};

const TABLE_COLUMNS: TTableColumn<TTableItem>[] = [
  {
    key: 'label',
    title: 'Наименование',
    size: 'min-content',
  },
  {
    key: 'value',
    title: 'Количество',
    render: (item) => EXACT_NUMERIC_FORMATTER(item.value),
    isNumeric: true,
    withBar: true,
    hideTopBar: true,
    size: '300px',
  },
  {
    key: 'percent',
    title: '% линкования',
  },
];

const formatPercent = (value: number): string => {
  return `${Math.round(value * 10) / 10}`;
};

const useNow = () => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return now;
};

const secondsDeclarator = getDeclarator(['секунду', 'секунды', 'секунд']);

export const LinkAddress: React.FC = () => {
  const now = useNow();
  const isExistQueryResult = useIsExist();
  const infoQueryResult = useInfo(isExistQueryResult.data?.isExist ?? false);
  const userInfo = useAuthorizedUser();
  const userRoles = userInfo.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);

  const totalCount = infoQueryResult.data?.addressCount ?? 0;
  const linkingPercent = totalCount
    ? (100 *
        ((infoQueryResult.data?.crptLinked ?? 0) +
          (infoQueryResult.data?.crptPartiallyLinked ?? 0))) /
      totalCount
    : 0;

  const tableData: TTableItem[] = [
    {
      label: 'Адресов в справочнике',
      value: totalCount,
      percent: totalCount
        ? `Процент линкования: ${formatPercent(linkingPercent)}%`
        : '',
    },

    {
      label: 'В обработке',
      value: infoQueryResult.data?.inProcess ?? 0,
    },
    {
      label: 'Не прошло обработку',
      value: infoQueryResult.data?.notProcessed ?? 0,
    },

    {
      label: 'Адрес не залинкован с ЦРПТ',
      value: infoQueryResult.data?.crptNotLinked ?? 0,
    },
    {
      label: 'Адрес залинкован с ЦРПТ с точностью до дома',
      value: infoQueryResult.data?.crptLinked ?? 0,
    },
    {
      label: 'Адрес залинкован с ЦРПТ (возможно отличие корпуса)',
      value: infoQueryResult.data?.crptPartiallyLinked ?? 0,
    },
  ];

  const fileRef = useRef<HTMLInputElement>(null);

  if (!userInfo.linkAddress && !isAdmin) {
    return <Page404 />;
  }

  const lastUpdate = infoQueryResult.dataUpdatedAt
    ? dayjs(infoQueryResult.dataUpdatedAt)
    : null;

  const secondsDiff = lastUpdate ? dayjs(now).diff(lastUpdate, 'second') : null;

  const shouldShowUpdate = secondsDiff !== null;
  const shouldShowUpdateInSeconds = shouldShowUpdate && secondsDiff! < 60;
  const updatedAtString = shouldShowUpdate
    ? secondsDiff === 0
      ? 'Обновлено только что'
      : shouldShowUpdateInSeconds
      ? `Обновлено ${secondsDeclarator(secondsDiff!)} назад`
      : `Обновлено ${lastUpdate?.fromNow()}`
    : null;

  return (
    <>
      <PageHeader
        title="Линкование адресов"
        rightTitleContent={
          <div className={styles.headerItems}>
            <Button
              testId="download-example"
              onClick={downloadSample}
              color="grey"
              reportPanel
            >
              Скачать шаблон
            </Button>
            <Button
              testId="upload-example"
              color="grey"
              onClick={() => {
                fileRef.current?.click();
              }}
              reportPanel
            >
              Загрузить заполненный шаблон
            </Button>
            <input
              type="file"
              ref={fileRef}
              className={styles.hiddenInput}
              accept=".xlsx, .csv"
              onChange={async (e) => {
                let file = e.target.files?.[0];
                if (!file) {
                  return;
                }
                const isXslx = file?.name.endsWith('.xlsx');
                if (isXslx) {
                  try {
                    const csv = await convertXslxToCsv({
                      file,
                    });
                    file = csv;
                    if (isDev()) {
                      const downloadUrl = URL.createObjectURL(csv);
                      const link = document.createElement('a');
                      link.href = downloadUrl;
                      link.download = csv.name;
                      link.click();
                    }
                  } catch (e) {
                    console.error(e);
                    alert('Не удалось конвертировать файл в csv');
                    Sentry.captureException(e, {
                      tags: {
                        feature: 'link-address',
                        action: 'convert-xslx-to-csv',
                      },
                    });
                    return;
                  }
                }
                if (file) {
                  uploadSample(file);
                }
                e.target.value = '';
              }}
            />
            <Button
              disabled={
                isExistQueryResult.isLoading ||
                !isExistQueryResult.data?.isExist
              }
              onClick={downloadDictionary}
              testId="download-dictionary"
              color="yellow"
              reportPanel
            >
              Скачать справочник
              {linkingPercent ? ` (${formatPercent(linkingPercent)}%)` : ''}
            </Button>
            <Button
              disabled={
                isExistQueryResult.isLoading ||
                !isExistQueryResult.data?.isExist
              }
              onClick={downloadUnlinkedPoints}
              testId="download-unlinked-points"
              color="yellow"
              reportPanel
            >
              Скачать точки без связей
            </Button>
          </div>
        }
        withGap
        supportKey="l-a"
      ></PageHeader>
      {isExistQueryResult.data?.isExist && (
        <PageContent queryData={infoQueryResult}>
          <Table
            hideHeader
            cols={TABLE_COLUMNS}
            rows={tableData}
            afterTable={<div className={styles.hint}>{updatedAtString}</div>}
          />
        </PageContent>
      )}
      {!isExistQueryResult.data?.isExist && (
        <PageContent notFound="Данных по линкованию адресов не найдено. Пожалуйста, загрузите файл с адресами." />
      )}
    </>
  );
};
