import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { storage } from '../../shared/utils/storage';

export type TQueueItem = {
  queryKey: string;
  queryFn: (filter: { queryKey: string[] }) => unknown;
  url: string;
  title: string;
  meta: string[];

  createdAt: number;
  loadedAt?: number;

  isHistory?: boolean;
};

type TQueueItemCreate = Omit<TQueueItem, 'createdAt'>;

type TQueue = TQueueItem[];

const ACTIVE_QUEUE_LIMIT = 30;
const HISTORY_QUEUE_LIMIT = 300;

const useReportQueueShared = () => {
  const [queue, setQueue] = useState<TQueue>(() => {
    try {
      const storedQueue = storage.getItem('reportQueue');
      if (storedQueue) {
        const parsed = JSON.parse(storedQueue);
        return parsed.map((item: TQueueItem) => ({
          ...item,
          queryFn: () => null,
        }));
      }
      return [];
    } catch {
      return [];
    }
  });

  const addToQueue = useCallback((item: TQueueItemCreate) => {
    setQueue((queue) => {
      const oldItem = queue.find(
        (queueItem) => queueItem.queryKey === item.queryKey,
      );
      return [
        ...queue.filter((queueItem) => queueItem.queryKey !== item.queryKey),
        {
          ...item,
          createdAt: oldItem?.createdAt || Date.now(),
          loadedAt: oldItem?.loadedAt,
        },
      ];
    });
  }, []);

  const markAsHistory = useCallback((queryKey: string) => {
    setQueue((queue) =>
      queue.map((queueItem) => {
        if (queueItem.queryKey === queryKey) {
          return {
            ...queueItem,
            isHistory: true,
            loadedAt: queueItem.loadedAt || Date.now(),
          };
        }

        return queueItem;
      }),
    );
  }, []);

  const removeFromQueue = useCallback((queryKey: string) => {
    setQueue((queue) =>
      queue.filter((queueItem) => queueItem.queryKey !== queryKey),
    );
  }, []);

  useEffect(() => {
    const activeQueries = queue.filter((queueItem) => !queueItem.isHistory);
    const historyQueries = queue
      .filter((queueItem) => queueItem.isHistory)
      .filter((queueItem) => {
        return Date.now() - queueItem.loadedAt! > 1000 * 60 * 60 * 24;
      });

    if (
      activeQueries.length > ACTIVE_QUEUE_LIMIT ||
      historyQueries.length > HISTORY_QUEUE_LIMIT
    ) {
      const sortedActiveQueries = activeQueries.sort(
        (a, b) => b.createdAt - a.createdAt,
      );
      const sortedHistoryQueries = historyQueries.sort(
        (a, b) => b.createdAt - a.createdAt,
      );

      const newQueue = [
        ...sortedActiveQueries.slice(0, ACTIVE_QUEUE_LIMIT),
        ...sortedHistoryQueries.slice(0, HISTORY_QUEUE_LIMIT),
      ];

      setQueue(newQueue);
    }
  }, [queue]);

  useEffect(() => {
    const historyQueries = queue.filter((queueItem) => queueItem.isHistory);

    storage.setItem(
      'reportQueue',
      JSON.stringify(
        historyQueries.map((queueItem) => ({
          ...queueItem,
          queryFn: undefined,
        })),
      ),
    );
  }, [queue]);

  const resetQueue = useCallback(() => {
    setQueue([]);
    storage.removeItem('reportQueue');
  }, []);

  return {
    queue,
    addToQueue,
    removeFromQueue,
    markAsHistory,
    resetQueue,
  };
};

export const ReportQueue = createContainer(useReportQueueShared);

export const useReportQueue = () => ReportQueue.useContainer();
