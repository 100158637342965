import React from 'react';
import classNames from 'classnames';

import styles from './Switcher.module.scss';

type TSwitcherProps = {
  value: string;
  onChange: (indexIncrement: 1 | -1) => void;
  testId?: string;
};

export const Switcher: React.FC<TSwitcherProps> = ({
  value,
  onChange,
  testId = 'no-test-id',
}) => {
  const handleOnChange = (indexIncrement: 1 | -1) => () => {
    onChange(indexIncrement);
  };

  return (
    <div className={styles.switcher} data-test-id={`switcher-${testId}`}>
      <div
        className={classNames(styles.button, styles.left)}
        onClick={handleOnChange(-1)}
        data-test-id={`switcher-${testId}-left`}
      />
      <span className={styles.value} data-test-id={`switcher-${testId}-value`}>
        {value}
      </span>
      <div
        className={classNames(styles.button, styles.right)}
        onClick={handleOnChange(1)}
        data-test-id={`switcher-${testId}-right`}
      />
    </div>
  );
};
