import { DS1R9Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import {
  getGroupByFeature,
  PRESET_SHOULD_HIDE_CHECKERS,
} from '../AllPurposeReport/dataFeatures/groupBy';
import {
  getTransposeFeature,
  TWithTransposedFields,
} from '../AllPurposeReport/dataFeatures/transpose';
import { getIntervalFilter } from '../AllPurposeReport/filters/interval';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { getIntervalTransposeConfig } from '../AllPurposeReport/presets/transposeIntervals';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getLinesChartFeature } from '../AllPurposeReport/viewFeatures/linesChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Динамика продаж розничных продавцов';

export const ds1o9Config: TReportConfigType<DS1R9Response> = {
  title: NAME,
  backendKey: 'ds1r9',

  dataFeatures: [
    getTransposeFeature(getIntervalTransposeConfig('YYYY-MM-DD')),
    getGroupByFeature({
      options: [
        {
          groupBy: 'sellerInn',
          label: 'ИНН',
          additionalColumns: [
            {
              key: 'sellerInn',
              title: 'ИНН',
              sort: sorter('sellerInn'),
            },
            {
              key: 'sellerName',
              title: 'Наименование',
              sort: sorter('sellerName'),
            },
          ],
        },
        {
          groupBy: 'sellerNetName',
          label: 'Сети',
          additionalColumns: [
            {
              key: 'sellerNetName',
              title: 'Наименование сети',
              sort: sorter('sellerNetName'),
            },
          ],
        },
        {
          groupBy: 'dictName',
          shouldHide: PRESET_SHOULD_HIDE_CHECKERS.notDataset('inns', [
            'inn',
            'network',
          ]),
          label: 'Справочник',
          additionalColumns: [
            {
              key: 'dictName',
              title: 'Название в справочнике',
              sort: sorter('dictName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', /^transposed_.*/],
    }),
  ],

  viewFeatures: [
    getTableFeature<TWithTransposedFields<DS1R9Response>>({
      defaultSort: 'LAST',
      filename: NAME,
      baseColumns: [],
    }),
    getLinesChartFeature<TWithTransposedFields<DS1R9Response>>({
      filename: NAME,
      nameKey: 'sellerName',
      innKey: 'sellerInn',
      valuesPrefix: 'transposed_',
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
        sellerNetName: 'sellerNetName',
      },
      icon: 'barChartWithLine',
      dateFormat: 'YYYY-MM-DD',
    }),
  ],

  filters: [
    getIntervalFilter({
      single: false,
    }),
    getSelectFilter({
      shouldUseNetwork: true,
      shouldUseDictionary: true,
      commonDataKey: 'retailSellers',
      mode: 'inns',
      label: 'Продавцы',
      icon: 'contact',
    }),
  ],
  requiredData: {
    innDictionaries: true,
  },
  requiredCommonData: ['retailSellers', 'retailSellerNetworks'],

  autoApplyOnFilterReady: true,

  isRequiredFieldsFilled: (filter) => Boolean(filter.periods?.length),
};
