import { DS2R7Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import { getGroupByFeature } from '../AllPurposeReport/dataFeatures/groupBy';
import { getTransposeFeature } from '../AllPurposeReport/dataFeatures/transpose';
import { getIntervalFilter } from '../AllPurposeReport/filters/interval';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { getIntervalTransposeConfig } from '../AllPurposeReport/presets/transposeIntervals';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Продажи по адресам';

type DS2R7ResponseWithIndex = DS2R7Response & {
  __index: number;
};

export const ds2o7Config: TReportConfigType<DS2R7ResponseWithIndex> = {
  title: NAME,
  backendKey: 'ds2r7',

  prepareDataItem: (item, index) => ({
    ...item,
    __index: index,
  }),

  dataFeatures: [
    getTransposeFeature(getIntervalTransposeConfig('YYYY-MM-DD')),
    getGroupByFeature<DS2R7ResponseWithIndex>({
      options: [
        {
          groupBy: '__index',
          label: 'Адрес',
          additionalColumns: [
            {
              key: 'sellerAddrSfCode',
              title: 'Код СФ',
              size: `130px`,
              sort: sorter('sellerAddrSfCode', true),
              isNumeric: true,
            },
            {
              key: 'sellerAddrSfName',
              title: 'Субъект РФ',
              sort: sorter('sellerAddrSfName'),
            },
            {
              key: 'sellerAddr',
              title: 'Адрес',
              sort: sorter('sellerAddr'),
            },
            {
              key: 'sellerInn',
              title: 'ИНН продавца',
              sort: sorter('sellerInn'),
            },
            {
              key: 'sellerName',
              title: 'Наименование продавца',
              sort: sorter('sellerName'),
            },
          ],
        },
        {
          groupBy: 'dictName',
          shouldHide: (filter: any) => {
            return (
              !filter.filterState.addresses?.datasetKey ||
              filter.filterState.addresses?.datasetKey === 'innAddressHash'
            );
          },
          label: 'Справочник',
          additionalColumns: [
            {
              key: 'dictName',
              title: 'Справочник',
              sort: sorter('dictName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', /^transposed_.*/],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS2R7ResponseWithIndex>({
      defaultSort: 'salesCnt',
      filename: NAME,
      baseColumns: [],
    }),
    getBarChartFeature<DS2R7ResponseWithIndex>({
      nameKey: 'sellerAddr',
      innKey: 'sellerInn',
      valueKey: 'salesCnt',
      filename: NAME,
      groupKeyToNameMap: {
        __index: 'sellerName',
      },
      tooltipFormatter: (item) => {
        return `${item.data.fullItem.sellerInn}<br /><strong>${item.data.fullItem.sellerName}</strong><br />${item.data.fullItem.sellerAddr}<br /><div>${item.data.fullItem.reportInterval} - <strong>${item.data.fullItem.salesCnt}</strong></div>`;
      },
    }),
  ],

  filters: [
    getIntervalFilter({
      single: false,
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'Товар',
      commonDataKey: 'gtins',
      icon: 'package',
      mode: 'gtins',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'rfCodes',
      icon: 'location',
      label: 'Регион',
      mode: 'codes',
    }),
    getSelectFilter({
      shouldUseNetwork: true,
      shouldUseDictionary: true,
      commonDataKey: 'retailSellers',
    }),
    getSelectFilter({
      label: 'Точка продаж',
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'addresses',
      icon: 'location',
      mode: 'addresses',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    addressDictionaries: true,
    innDictionaries: true,
    gtinDictionaries: true,
  },
  requiredCommonData: [
    'gtins',
    'addresses',
    'retailSellers',
    'retailSellerNetworks',
  ],

  autoApplyOnFilterReady: true,

  isRequiredFieldsFilled: (filter) => Boolean(filter.periods?.length),
};
