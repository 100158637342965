import { DS1R3Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import {
  getGroupByFeature,
  PRESET_SHOULD_HIDE_CHECKERS,
} from '../AllPurposeReport/dataFeatures/groupBy';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'Розничные продажи по GTIN';

export const ds1o3Config: TReportConfigType<DS1R3Response> = {
  title: NAME,
  backendKey: 'ds1r3',

  dataFeatures: [
    getGroupByFeature<DS1R3Response>({
      options: [
        {
          groupBy: 'gtin',
          label: 'GTIN',
          additionalColumns: [
            {
              key: 'gtin',
              title: 'GTIN',
              sort: sorter('gtin'),
            },
            {
              key: 'gtinName',
              title: 'Наименование товара',
              sort: sorter('gtinName'),
            },
          ],
        },
        {
          groupBy: 'dictName',
          shouldHide: PRESET_SHOULD_HIDE_CHECKERS.notDataset('gtins', 'gtin'),
          label: 'Справочник',
          additionalColumns: [
            {
              key: 'dictName',
              title: 'Название в справочнике',
              sort: sorter('dictName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', 'sharePercent'],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS1R3Response>({
      defaultSort: 'salesCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'salesCnt',
          title: 'Объем продаж, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('salesCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
        },
      ],
    }),
    getBarChartFeature<DS1R3Response>({
      nameKey: 'gtinName',
      valueKey: 'salesCnt',
      innKey: 'gtin',
      filename: NAME,
      groupKeyToNameMap: {
        gtin: 'gtinName',
      },
    }),
    getBarChartFeature<DS1R3Response>({
      nameKey: 'gtinName',
      valueKey: 'salesCnt',
      innKey: 'gtin',
      withPercents: true,
      accumulateBy: 'sharePercent',
      icon: 'barChartWithLine',
      filename: NAME,
      groupKeyToNameMap: {
        gtin: 'gtinName',
      },
    }),
    getTreeMapFeature<DS1R3Response>({
      topName: NAME,
      parentNameKey: 'gtin',
      childrenNameKey: 'gtinName',
      innKey: 'gtin',
      valueKey: 'salesCnt',
      filename: NAME,
      groupKeyToNameMap: {
        gtin: 'gtinName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: true,
      shouldUseDictionary: true,
      label: 'Продавцы',
      commonDataKey: 'retailSellers',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'Товар',
      commonDataKey: 'gtins',
      icon: 'package',
      mode: 'gtins',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    innDictionaries: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['retailSellers', 'gtins', 'retailSellerNetworks'],

  isRequiredFieldsFilled: () => true,
};
