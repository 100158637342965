import React from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { $apiBase } from '../../api/utils';

import styles from './Disclaimers.module.scss';

type TDisclaimer = {
  text: string;
  type: 'WARNING' | 'INFORMATION' | 'ERROR';
};

const queryConfig = {
  staleTime: 1000 * 60,
  cacheTime: 1000 * 60,
  refetchInterval: 1000 * 60 * 5,
};

const useDisclaimers = () => {
  return useQuery<TDisclaimer[]>(
    ['disclaimers'],
    async () => {
      const { data } = await $apiBase.get('/api/v1/disclaimers/active');
      return data;
    },
    queryConfig,
  );
};

export const Disclaimers = () => {
  const { data } = useDisclaimers();
  if (data?.length) {
    return (
      <div className={styles.disclaimers}>
        {data.map((disclaimer, index) => {
          return (
            <div
              key={index}
              className={classNames(
                styles.disclaimer,
                styles[disclaimer.type.toLowerCase()],
              )}
            >
              {disclaimer.text}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
