import dayjs from 'dayjs';
import { formatDate } from '../../../../../../shared/utils/formatDate';
import { getDateRangeTags } from '../../../../../../shared/utils/getTags';
import { TFilterCreator, TFilterPreparer } from '../reportConfigType';
import {
  TDateRangeFilter,
  TDateRangeFilterOptions,
} from '../../../../../common/PageWithFilter/filter';

const preparePeriodFilterValue: TFilterPreparer = (filterState) => {
  return {
    from: formatDate(filterState.period[0]),
    to: formatDate(filterState.period[1]),
  };
};

export const periodFilter: TFilterCreator<any> = (props) => {
  const availablePeriod = props.availablePeriod;
  let to = availablePeriod ? availablePeriod.to : new Date();
  let from = availablePeriod
    ? dayjs(availablePeriod.to).subtract(6, 'days').toDate()
    : new Date();

  const hash = window.location.hash;
  const hashValues = hash
    .replace('#', '')
    .split('&')
    .map((item) => item.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as Record<
    string,
    string
  >;

  const modeValues = hashValues.period as string | undefined;
  const defaultValues =
    typeof modeValues === 'string'
      ? modeValues
          .split(',')
          .map((value) => +value)
          .filter(Boolean)
      : [];
  if (defaultValues.length === 2) {
    from = new Date(defaultValues[0]);
    to = new Date(defaultValues[1]);
  }

  return {
    filter: {
      label: 'Отчетный период',
      icon: 'calendar',
      key: 'period',
      initialValue: availablePeriod ? [from, to] : undefined,
      getTags: getDateRangeTags,
      filter: {
        type: 'dateRange',
        defaultSelectType: 'period',
        uniqueKey: 'p',
        availablePeriod: availablePeriod as {
          from: Date;
          to: Date;
        },
      },

      getQueryKeyPart: (filterState) => [
        formatDate(filterState.period[0]),
        formatDate(filterState.period[1]),
      ],
    },

    preparer: preparePeriodFilterValue,

    descriptionPreparer: (filterState) => {
      const value = filterState.period;
      const tags = getDateRangeTags(value);
      return tags;
    },
  };
};

export const customPeriodFilter = ({
  enabledOptions,
  defaultSelectType,
}: {
  enabledOptions?: TDateRangeFilterOptions;
  defaultSelectType?: keyof TDateRangeFilterOptions;
}) => {
  const creator: TFilterCreator<any> = (props) => {
    const config = periodFilter(props);

    const configFilterConfig = config.filter.filter as TDateRangeFilter;
    const rangeFilterConfig: TDateRangeFilter = {
      ...configFilterConfig,
      enabledOptions: enabledOptions || configFilterConfig.enabledOptions,
      defaultSelectType:
        defaultSelectType || configFilterConfig.defaultSelectType,
    };

    return {
      ...config,
      filter: {
        ...config.filter,
        filter: rangeFilterConfig,
      },
    };
  };

  return creator;
};
