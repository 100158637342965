import { useAppUploads } from './useAppUploads';
import { COLUMNS, statusList } from './constants';
import { Table } from '../../common/Table/Table';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { Button } from '../../../uikit/Button/Button';
import { PageContent } from '../../common/PageContent/PageContent';
import {
  PageWithFilter,
  useFilterState,
} from '../../common/PageWithFilter/PageWithFilter';
import { fetchInns, fetchUploadList } from '../../../api/uploads/uploads';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getSelection } from '../../common/PageWithFilter/utils/getSelection';
import { withRestriction } from '../../../shared/hocs/withRestriction';
import { UploadModal } from './components/UploadModal/UploadModal';
import { DownloadReport } from '../../common/Table/components/DownloadReport/DownloadReport';

import styles from './Uploads.module.scss';
import { Icon } from '../../common/Icon/Icon';

const getRowKey = (row) => row.guid;

const FILTERS = [
  {
    key: 'inns',
    label: 'ИНН',
    icon: 'contact',
    initialValue: {},
    getTags: (value) => {
      const values = getSelection(value);
      if (values.length) {
        return [values.length];
      }
      return [];
    },
    filter: {
      type: 'select',
      withSearch: true,
      canSelectAll: true,
      canSelectMany: false,
      searchPlaceholder: 'ИНН…',
      uniqueKey: 'globalInns',
      getOptions: () =>
        fetchInns().then((list) =>
          list.map((item) => ({
            value: item.inn,
            fields: item,
            label: `${item.inn} ${item.name}`,
          })),
        ),
      optionFields: [
        {
          key: 'inn',
          label: 'ИНН',
        },
        {
          key: 'name',
          label: 'Наименование',
        },
      ],
    },
  },
  {
    key: 'status',
    label: 'Статусы',
    icon: 'sort',
    initialValue: {
      ALL: true,
    },
    getTags: (value) => {
      const values = getSelection(value);
      if (values.length && !values.includes('ALL')) {
        return [values.length];
      }
      return [];
    },
    filter: {
      type: 'select',
      withSearch: false,
      canSelectAll: false,
      canSelectMany: false,
      uniqueKey: 'globalStatuses',
      getOptions: () =>
        Promise.resolve(
          statusList.map((item) => ({ value: item.type, label: item.name })),
        ),
    },
  },
];

const querySettings = {
  staleTime: 1000 * 60,
  refetchInterval: 1000 * 60,
  getNextPageParam: (lastPage, pages) => {
    if (lastPage?.length < 10) {
      return undefined;
    }
    return pages.length;
  },
};

export const Uploads = withRestriction(
  ['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'],
  () => {
    const queryClient = useQueryClient();
    const { setVisibleModal, visibleModal, onSubmitModal } =
      useAppUploads(queryClient);

    const [filterState] = useFilterState(FILTERS);
    const queryData = useInfiniteQuery(
      ['uploads', filterState],
      ({ pageParam = 0 }) => {
        return fetchUploadList({
          settings: {
            inn: {
              inn: Object.keys(filterState.inns)[0],
            },
            status: {
              type: Object.keys(filterState.status)[0],
            },
          },
          sort: 'date',
          direction: 'desc',
          page: pageParam,
        });
      },
      querySettings,
    );
    const data = queryData.data ? queryData.data.pages.flat() : [];

    return (
      <PageWithFilter filters={FILTERS} withBorder>
        <PageHeader
          supportKey="upl"
          withGap
          title="Выгрузки"
          rightTitleContent={
            <div className={styles.row}>
              <DownloadReport white={false} filename="Выгрузки" />
              <Button
                onClick={() => {
                  setVisibleModal(true);
                }}
                color="yellow"
                testId="create-upload"
                reportPanel
              >
                <Icon name="add" /> Создать
              </Button>
            </div>
          }
        ></PageHeader>
        {visibleModal && (
          <UploadModal
            show={visibleModal}
            onClose={() => setVisibleModal(false)}
            onSubmit={onSubmitModal}
          />
        )}

        <PageContent queryData={queryData} notFound={data?.length === 0}>
          <Table
            rows={data}
            cols={COLUMNS}
            getRowKey={getRowKey}
            withInfinityScroll
            fetchNextPage={queryData.fetchNextPage}
            isFetchingNextPage={queryData.isFetchingNextPage}
            hasNextPage={queryData.hasNextPage}
          />
        </PageContent>
      </PageWithFilter>
    );
  },
);
