import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { $api } from '../../api/utils';
import { queryClient } from '../../store/query';
import { DictionariesType } from '../constants';

export type TDictionariesData = {
  dictionaries: Record<string, string>;
  values: Record<
    string,
    {
      [key: string]: string;
      name: string;
    }
  >;
};

const getDictionariesData = async (
  type: DictionariesType,
): Promise<TDictionariesData> => {
  const { data } = await $api.get(`/dictionaries/${type}`);
  return data;
};

export const getDictionaryOptions = (
  data: TDictionariesData,
  guid: string,
): { label: string; value: string; realItemsCount: number }[] => {
  const { values } = data;
  const uniqueValues: Record<string, number> = {};
  Object.keys(values).forEach((key) => {
    const value = values[key][guid];
    if (value) {
      if (!uniqueValues[value]) {
        uniqueValues[value] = 0;
      }
      uniqueValues[value] += 1;
    }
  });
  return Object.keys(uniqueValues).map((key) => ({
    label: key,
    value: key,
    realItemsCount: uniqueValues[key],
  }));
};

export const useDictionariesData = (type: DictionariesType) => {
  const queryData = useQuery(
    ['dictionariesData', type],
    // @ts-ignore
    () => getDictionariesData(type),
  );

  useEffect(() => {
    queryClient.invalidateQueries(['filterDataset']);
  }, [queryData.dataUpdatedAt]);

  return queryData;
};
