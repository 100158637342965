import { useQuery } from '@tanstack/react-query';
import { $api } from '../../../../api/utils';

export type TInn = {
  active: boolean;
  inn: string;
  name: string;
};

const getInns = async () => {
  const { data } = await $api.get<TInn[]>('/inns');
  return data;
};

const CONFIG = {
  cacheTime: 60000,
  staleTime: 0,
};

export const useInns = (isActive: boolean) => {
  return useQuery(['inns'], getInns, {
    ...CONFIG,
    enabled: isActive,
  });
};
