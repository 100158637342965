import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './Table.module.scss';
import { usePagination } from './usePagination';
import { useData } from './useData';
import { Tooltip } from '../Tooltip/Tooltip';
import { Loader } from '../Loader/Loader';
import { useInViewRef, useWindowSize } from 'rooks';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import { useColumns } from './useColumns';
import {
  getMaxOfArray,
  getMinOfArray,
} from '../../../shared/utils/getMaxOfArray';

export type TTableColumn<TDataItem> = {
  key: keyof TDataItem | string;
  title: string;
  sort?: (a: TDataItem, b: TDataItem) => number;
  render?: (item: TDataItem) => React.ReactNode;
  renderForExport?: (item: TDataItem) => string;
  hideForExport?: boolean;
  size?: string;
  isNumeric?: boolean;
  overflowVisible?: boolean;
  withBar?: boolean;
  barValue?: (item: TDataItem) => number;
  hideTopBar?: boolean;
  hint?: string;
  grow?: boolean;
  showOnHover?: boolean;
  fixed?: boolean;
};

type TTableProps<TDataItem> = {
  rows: TDataItem[];
  cols: Array<TTableColumn<TDataItem>>;
  getRowKey?: (item: TDataItem) => string;
  withPagination?: boolean;
  withInfinityScroll?: boolean;
  defaultSort?: {
    key: keyof TDataItem;
    direction: 'asc' | 'desc';
  };
  noMargin?: boolean;
  onRowClick?: (item: TDataItem) => void;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  canHideEmptyColumns?: boolean;
  hideHeader?: boolean;
  afterTable?: React.ReactNode;
};

const useSortedData = <TDataItem,>(
  data: TDataItem[],
  sortFn?: null | ((a: TDataItem, b: TDataItem) => number),
  order?: 'asc' | 'desc',
) => {
  const [sortedData, setSortedData] = React.useState(data);

  useEffect(() => {
    if (sortFn) {
      setSortedData(
        [...data].sort((a, b) => {
          if (order === 'asc') {
            return sortFn(a, b);
          } else {
            return sortFn(b, a);
          }
        }),
      );
    } else {
      setSortedData(data);
    }
  }, [data, order, sortFn]);

  return sortedData;
};

const MOBILE_MULTIPLIER = 0.5;

export const Table = <TDataItem,>({
  rows,
  cols,
  getRowKey,
  withPagination = false,
  withInfinityScroll = false,
  defaultSort,
  noMargin = false,
  onRowClick,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  canHideEmptyColumns,
  hideHeader,
  afterTable,
}: TTableProps<TDataItem>) => {
  const [viewDetectorRef, inView] = useInViewRef();
  useEffect(() => {
    if (withInfinityScroll) {
      if (inView && !isFetchingNextPage && hasNextPage) {
        fetchNextPage?.();
      }
    }
  }, [
    fetchNextPage,
    hasNextPage,
    inView,
    isFetchingNextPage,
    withInfinityScroll,
  ]);

  const filteredCols = useMemo(() => {
    if (!canHideEmptyColumns) {
      return cols;
    }
    return cols.filter((col) =>
      rows.some((row) => {
        return (
          typeof row[col.key as keyof TDataItem] !== 'undefined' &&
          row[col.key as keyof TDataItem] !== 0
        );
      }),
    );
  }, [canHideEmptyColumns, cols, rows]);

  const gridTemplateColumns = filteredCols
    .map((column) => column.size || '1fr')
    .join(' ');
  const [isSortedByKey, setSortedByKey] = React.useState<
    keyof TDataItem | null
  >(() => {
    if (defaultSort) {
      return defaultSort.key;
    }
    return null;
  });
  const isSortedWith =
    filteredCols.find((col) => col.key === isSortedByKey)?.sort || null;
  const [sortingOrder, setSortingOrder] = React.useState<'asc' | 'desc'>(
    defaultSort?.direction || 'asc',
  );
  const data = useSortedData(rows, isSortedWith, sortingOrder);

  const { setTotal, pageSize, currentPage, setCurrentPage } = usePagination();

  useEffect(() => {
    setTotal(data.length);
    return () => {
      setTotal(0);
    };
  }, [data.length, setTotal]);

  const [, setData] = useData();
  useEffect(() => {
    setData(data);
    return () => {
      setData([]);
    };
  }, [data, setData]);

  const [, setColumns] = useColumns();
  useEffect(() => {
    setColumns(cols);
    return () => {
      setColumns([]);
    };
  }, [cols, setColumns]);

  const numericColsMinMax = useMemo(() => {
    return filteredCols.map((col) => {
      if (col.isNumeric && col.withBar) {
        const values = data.map(
          (item) => item[col.key as keyof TDataItem] as number,
        );
        return {
          min: getMinOfArray(values),
          max: getMaxOfArray(values),
        };
      }
      return {
        min: 0,
        max: 0,
      };
    });
  }, [cols, data]);

  const dataWithPagination = useMemo(() => {
    if (withPagination) {
      return data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    } else {
      return data;
    }
  }, [data, currentPage, pageSize, withPagination]);

  const cellsRefs = useRef<HTMLDivElement[]>([]);
  const { innerWidth: windowWidth } = useWindowSize();
  const [cellsOffsets, setCelsOffsets] = useState<number[]>([]);
  useEffect(() => {
    const cellsWidths = cellsRefs.current.map((cell) => cell?.offsetWidth || 0);
    let total = 0;
    const cellsOffsets = [];
    for (let i = 0; i < cellsWidths.length; i++) {
      cellsOffsets.push(total);
      total += cellsWidths[i];
    }
    setCelsOffsets(cellsOffsets);
  }, [windowWidth, sortingOrder, isSortedByKey, dataWithPagination]);

  const breakpoints = useBreakpoint();
  const currentMultiplier = breakpoints.isMobile ? MOBILE_MULTIPLIER : 1;

  return (
    <div
      className={styles.wrapper}
      style={{
        width: `${100 / currentMultiplier}%`,
        height: `${100 / currentMultiplier}%`,
        transform: `scale(${currentMultiplier})`,
      }}
    >
      <div
        className={classNames(styles.table, {
          [styles.noMargin]: noMargin,
        })}
        style={{
          gridTemplateColumns,
        }}
        data-test-id="table"
      >
        {!hideHeader && (
          <div className={styles.head} data-test-id="table-head">
            {filteredCols.map((col, index) => {
              return (
                <Tooltip
                  testId={'table-cell'}
                  key={index}
                  hidden={!col.hint}
                  text={col.hint || ''}
                >
                  <div
                    data-test-id="table-cell"
                    data-test-title={col.title}
                    data-test-tooltip={col.hint}
                    data-test-sortable={!!col.sort}
                    data-test-numeric={!!col.isNumeric}
                    className={classNames(styles.cell, {
                      [styles.sortable]: col.sort,
                      [styles.numeric]: col.isNumeric,
                      [styles.fixed]: col.fixed,
                      [styles.last]: col.fixed && !cols[index + 1]?.fixed,
                    })}
                    style={{
                      left: col.fixed ? cellsOffsets[index] : undefined,
                    }}
                    ref={(element) => {
                      cellsRefs.current[index] = element as HTMLDivElement;
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      const sortFn = col.sort;
                      if (sortFn) {
                        if (sortFn === isSortedWith) {
                          setSortingOrder(
                            sortingOrder === 'asc' ? 'desc' : 'asc',
                          );
                        } else {
                          setSortedByKey(col.key as keyof TDataItem);
                          setSortingOrder('asc');
                        }
                      }
                    }}
                  >
                    {col.title}
                    {col.sort && (
                      <div
                        className={classNames(styles.sort, {
                          [styles.asc]:
                            isSortedWith === col.sort && sortingOrder === 'asc',
                          [styles.desc]:
                            isSortedWith === col.sort &&
                            sortingOrder === 'desc',
                        })}
                      />
                    )}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
        <div className={styles.body} data-test-id="table-body">
          {dataWithPagination.map((row, index) => {
            const key = getRowKey ? getRowKey(row) : index.toString();
            return (
              <div className={styles.row} data-test-id="table-row" key={key}>
                {filteredCols.map((col, index) => (
                  <div
                    data-test-id="table-cell"
                    data-test-numeric={!!col.isNumeric}
                    className={classNames(styles.cell, {
                      [styles.numeric]: col.isNumeric,
                      [styles.clickable]: !!onRowClick,
                      [styles.withSize]: !!col.size,
                      [styles.withBar]: !!col.withBar,
                      [styles.showOnHover]: !!col.showOnHover,
                      [styles.fixed]: col.fixed,
                      [styles.last]: col.fixed && !cols[index + 1]?.fixed,
                    })}
                    style={{
                      left: col.fixed ? cellsOffsets[index] : undefined,
                    }}
                    key={index}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row);
                      }
                    }}
                    title={
                      !col.render
                        ? (row[col.key as keyof TDataItem] as string)
                        : ''
                    }
                  >
                    {col.isNumeric && !col.withBar && (
                      <div className={styles.spacer} />
                    )}
                    {col.isNumeric && col.withBar && (
                      <div
                        className={styles.bar}
                        style={{
                          width: `${
                            col.barValue
                              ? col.barValue(row)
                              : ((row[col.key as keyof TDataItem] as number) /
                                  numericColsMinMax[index].max) *
                                  200 +
                                5
                          }px`,
                          visibility:
                            col.hideTopBar &&
                            (row[col.key as keyof TDataItem] as number) ===
                              numericColsMinMax[index].max
                              ? 'hidden'
                              : undefined,
                        }}
                      />
                    )}
                    <span
                      className={classNames(styles.value, {
                        [styles.grow]: col.grow,
                        [styles.overflowVisible]: col.overflowVisible,
                      })}
                    >
                      {col.render
                        ? col.render(row)
                        : (row[col.key as keyof TDataItem] as React.ReactNode)}
                    </span>
                  </div>
                ))}
              </div>
            );
          })}
          {withInfinityScroll && (
            <>
              <div className={styles.viewDetector} ref={viewDetectorRef} />
              {isFetchingNextPage && (
                <div className={styles.fullRow}>
                  <Loader />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {afterTable}
    </div>
  );
};
