import React, { useEffect, useRef, useState } from 'react';
import { onAgreementDone } from '../../api';
import { Button } from '../../uikit/Button/Button';
import { Modal } from '../../uikit/Modal/Modal';
import { Tooltip } from '../common/Tooltip/Tooltip';

import styles from './AgreementModal.module.scss';
import { AGREEMENT } from './constants';
import { useAuthorizedUser } from '../../shared/hooks/useUser';

export const AgreementModal: React.FC = () => {
  const [shouldShow, setShouldShow] = useState(false);
  const userInfo = useAuthorizedUser();

  const bodyRef = useRef<HTMLDivElement>(null);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  useEffect(() => {
    const bodyElement = bodyRef.current;
    if (!bodyElement) {
      return;
    }
    const handleScroll = () => {
      const scrollLeft = Math.abs(
        bodyElement.scrollHeight -
          bodyElement.scrollTop -
          bodyElement.clientHeight,
      );
      if (scrollLeft < 100) {
        setIsScrolledDown(true);
      }
    };
    bodyElement.addEventListener('scroll', handleScroll);
    return () => {
      bodyElement.removeEventListener('scroll', handleScroll);
    };
  }, [bodyRef, userInfo?.agreement, shouldShow]);

  useEffect(() => {
    if (!userInfo?.agreement) {
      const t = setTimeout(() => {
        setShouldShow(true);
      }, 4000);
      return () => {
        clearTimeout(t);
      };
    } else {
      setShouldShow(false);
    }
  }, [userInfo?.agreement]);

  if (!shouldShow) {
    return null;
  }

  return (
    <Modal testId="agreement" isOpened>
      <Modal.Title>Пользовательское соглашение</Modal.Title>
      <Modal.Body>
        <div
          className={styles.content}
          ref={bodyRef}
          dangerouslySetInnerHTML={{
            __html: AGREEMENT,
          }}
        ></div>
      </Modal.Body>
      <Modal.Footer>
        <Tooltip
          testId={'agreement-done'}
          hidden={isScrolledDown}
          text="Прокрутите текст соглашения вниз, чтобы закрыть"
        >
          <Button
            testId="agreement-done-button"
            disabled={!isScrolledDown}
            onClick={() => {
              if (isScrolledDown) {
                onAgreementDone();
              }
            }}
          >
            Принять
          </Button>
        </Tooltip>
      </Modal.Footer>
    </Modal>
  );
};
