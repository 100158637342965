import { useQuery } from '@tanstack/react-query';
import { $api } from '../../api/utils';
import { AxiosError } from 'axios';

enum Roles {
  CLIENT = 'CLIENT',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
  ADMIN = 'ADMIN',
}

export type UserFromBackend = {
  guid: string;
  login: string;
  name: string;
  roles: Roles[];
  clientLogin: string;
  agreement: string;
  moduleA: boolean;
  moduleB: boolean;
  linkAddress: boolean;
  linkGtin: boolean;
};

export type User = UserFromBackend & {
  moduleAOrB: boolean;
  moduleAAndB: boolean;
};

export type UserFlags = {
  [key in keyof User]: User[key] extends boolean ? key : never;
}[keyof User];

type UserData =
  | {
      userData: User;
      isAuthorized: true;
    }
  | {
      userData: null;
      isAuthorized: false;
    };

const getUser = async (): Promise<UserData> => {
  try {
    const { data } = await $api.get<User>('/user');
    return {
      userData: {
        ...data,
        moduleAOrB: data.moduleA || data.moduleB,
        moduleAAndB: data.moduleA && data.moduleB,
      },
      isAuthorized: true,
    };
  } catch (e: unknown) {
    const axiosError = e as AxiosError;
    if (axiosError.response?.status === 401) {
      return {
        userData: null,
        isAuthorized: false,
      };
    }
    throw e;
  }
};

const QUERY_OPTIONS = {
  staleTime: 1000 * 60 * 2,
  refetchOnWindowFocus: true,
  refetchInterval: 1000 * 60,
  refetchOnMount: true,
  cacheTime: 1000 * 60 * 20,
} as const;

export const useUser = () =>
  useQuery<UserData, AxiosError>(['user'], getUser, QUERY_OPTIONS);

export const useAuthorizedUser = () => {
  const { data } = useUser();
  if (!data?.isAuthorized) {
    throw new Error('User is not authorized');
  }
  return data.userData;
};

export const useUserFlag = (flag: UserFlags) => {
  const user = useAuthorizedUser();
  return user[flag];
};

const getUserFlagHook = (flag: UserFlags) => {
  const useFlag = () => {
    const user = useAuthorizedUser();
    return user[flag];
  };

  return useFlag;
};

export const useModuleA = getUserFlagHook('moduleA');
export const useModuleB = getUserFlagHook('moduleB');
export const useModuleAOrB = getUserFlagHook('moduleAOrB');
export const useModuleAAndB = getUserFlagHook('moduleAAndB');
export const useLinkAddress = getUserFlagHook('linkAddress');
export const useLinkGtin = getUserFlagHook('linkGtin');

export const USER_FLAGS_LIST: UserFlags[] = [
  'moduleA',
  'linkAddress',
  'linkGtin',
  'moduleB',
  'moduleAAndB',
  'moduleAOrB',
];
