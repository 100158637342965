import React, { useEffect } from 'react';
import { checkTimeToken } from '../shared/utils/checkTimeToken';
import { getAuthToken } from '../shared/utils/getAuthToken';
import { Loader } from './common/Loader/Loader';
import { Pagination } from './common/Table/usePagination';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { AppRoutes } from './Routes';

import styles from './main.module.scss';
import { Data } from './common/Table/useData';
import { IsFullscreen } from './common/PageContent/useIsFullscreen';
import { AgreementModal } from './AgreementModal/AgreementModal';
import { ForceProdButton } from './ForceProdButton/ForceProdButton';

import { isDev, isTestMode } from '../shared/utils/isDev';
import { FullscreenLoader } from './common/FullscreenLoader/FullscreenLoader';

import packageJSON from '../../package.json';
import { ContextMenu, ContextMenuContainer } from './ContextMenu/ContextMenu';
import { resetCache } from '../api/utils';
import { useUser } from '../shared/hooks/useUser';
import { useQueryClient } from '@tanstack/react-query';
import { Columns } from './common/Table/useColumns';
import { useReportQueue } from './ReportQueue/useReportQueue';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

export const Main: React.FC = () => {
  const [showDevtools, setShowDevtools] = React.useState(isDev());
  const { resetQueue } = useReportQueue();

  const userQueryResult = useUser();
  const isAuth = userQueryResult.data?.isAuthorized;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (userQueryResult.data?.isAuthorized) {
      checkTimeToken();
      const interval = setInterval(() => {
        checkTimeToken();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [userQueryResult.data?.isAuthorized]);

  useEffect(() => {
    if (!userQueryResult.data || userQueryResult.isFetching) {
      return;
    }
    if (!userQueryResult.data?.isAuthorized) {
      const timeout = setTimeout(() => {
        queryClient
          .getQueryCache()
          .getAll()
          .forEach((query) => {
            const { queryKey } = query;
            if (queryKey[0] !== 'user') {
              queryClient.resetQueries(queryKey);
            }
          });
        resetCache().then();
        resetQueue();
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    queryClient,
    resetQueue,
    userQueryResult.data,
    userQueryResult.data?.isAuthorized,
    userQueryResult.isFetching,
  ]);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  if (userQueryResult.isLoading) {
    return (
      <div className={styles.loader}>
        <Loader fullScreen />
      </div>
    );
  }

  let content = null;

  const shouldShowLoginPage = !isAuth || !getAuthToken('accessToken');
  if (shouldShowLoginPage) {
    content = <LoginPage />;
  } else {
    content = (
      <>
        <AgreementModal />
        <AppRoutes />
      </>
    );
  }

  return (
    <IsFullscreen.Provider>
      <Pagination.Provider>
        <ContextMenuContainer.Provider>
          <Data.Provider>
            <Columns.Provider>
              <ForceProdButton />
              {content}
              {showDevtools && (
                <React.Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction />
                </React.Suspense>
              )}
              <FullscreenLoader />
              <ContextMenu />
              {!isTestMode() && (
                <div className={styles.version}>{packageJSON.version}</div>
              )}
            </Columns.Provider>
          </Data.Provider>
        </ContextMenuContainer.Provider>
      </Pagination.Provider>
    </IsFullscreen.Provider>
  );
};
