import React from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

type TLoaderProps = {
  fullScreen?: boolean;
};

export const Loader: React.FC<TLoaderProps> = ({ fullScreen }) => {
  return (
    <div
      className={classNames(styles.loader, {
        [styles.fullScreen]: fullScreen,
      })}
    >
      <UseAnimations loop autoplay animation={loading} size={40} />
    </div>
  );
};
