import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onLogin } from '../../../api';
import { Button } from '../../../uikit/Button/Button';
import { Gap } from '../../../uikit/Gap/Gap';
import { Input } from '../../../uikit/Input/Input';
import { Modal } from '../../../uikit/Modal/Modal';
import { Disclaimers } from '../../Disclaimers/Disclaimers';

import styles from './LoginPage.module.scss';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import { queryClient } from '../../../store/query';
import { useUser } from '../../../shared/hooks/useUser';

const PATH_REPLACE_MAP = new Map<RegExp, string>();
PATH_REPLACE_MAP.set(
  /^\/dictionaries\/gtin\/.+$/,
  '/dictionaries?dictType="gtin"',
);
PATH_REPLACE_MAP.set(
  /^\/dictionaries\/inn\/.+$/,
  '/dictionaries?dictType="inn"',
);
PATH_REPLACE_MAP.set(
  /^\/dictionaries\/address\/.+$/,
  '/dictionaries?dictType="address"',
);

const PATH_REPLACE_MAP_ENTRIES = Array.from(PATH_REPLACE_MAP.entries());

const prepareWantedPath = (path: string) => {
  for (const [regExp, replace] of PATH_REPLACE_MAP_ENTRIES) {
    if (regExp.test(path)) {
      return replace;
    }
  }
  if (path.startsWith('/dictionaries')) {
    return path;
  }
  if (path.startsWith('/link-gtin')) {
    return path;
  }
  return path.split('?')[0];
};

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching } = useUser();
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    const href = location.pathname + location.search;
    const wantedPath = prepareWantedPath(href);
    if (wantedPath !== href) {
      navigate(wantedPath, {
        replace: true,
      });
    }
  }, [location.pathname, location.search, navigate]);

  const [errorText, setErrorText] = useState<string | null>(null);
  const loginRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleSubmit: React.FormEventHandler = (e) => {
    setIsAuthorizing(true);
    e.preventDefault();
    const loginInput = loginRef.current;
    const passwordInput = passwordRef.current;

    if (!loginInput || !passwordInput) {
      return;
    }

    const login = loginInput.value;
    const password = passwordInput.value;

    if (!login) {
      loginInput.focus();
      return;
    }

    if (!password) {
      passwordInput.focus();
      return;
    }

    const stopAuthorizing = () => {
      setTimeout(() => {
        setIsAuthorizing(false);
      }, 500);
    };

    const onSuccess = () => {
      stopAuthorizing();
      queryClient.invalidateQueries(['user']);
    };

    onLogin(
      {
        username: login,
        password,
      },
      (errorText: string) => {
        setErrorText(errorText);
        stopAuthorizing();
      },
      onSuccess,
    );
  };

  const breakpoints = useBreakpoint();

  return (
    <div className={styles.page}>
      <Modal
        testId="password-change"
        onClose={() => {
          setIsModalOpened(false);
        }}
        isOpened={isModalOpened}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>Смена пароля</div>
        <div className={styles.text}>
          Чтобы сменить пароль обратитесь в поддержку или отправьте письмо на
          почту{' '}
          <a href="mailto:help@retail-data.center">help@retail-data.center</a>
        </div>
        <div className={styles.modalButtons}>
          <Button
            testId="password-modal-cancel"
            fullWidth
            onClick={() => {
              setIsModalOpened(false);
            }}
            color="grey"
          >
            Отмена
          </Button>
          <Button
            testId="password-modal-send"
            fullWidth
            onClick={() => {
              setIsModalOpened(false);
              window.open('mailto:help@retail-data.center');
            }}
            color="yellow"
          >
            Написать письмо
          </Button>
        </div>
      </Modal>
      <div className={styles.leftSide} data-test-id="left-side">
        <div className={styles.logo} data-test-id="left-side-logo"></div>
        <div className={styles.title} data-test-id="left-side-title">
          <div>Эффективная</div>
          <div>работа</div>
          <div>с&nbsp;данными системы</div>
          <div>Честный Знак</div>
        </div>
        <div className={styles.image} data-test-id="left-side-image"></div>
      </div>
      <div className={styles.rightSide} data-test-id="right-side">
        <form
          className={styles.form}
          data-test-id="login-form"
          onSubmit={handleSubmit}
        >
          <div className={styles.formTitle} data-test-id="page-title">
            Добро&nbsp;пожаловать в&nbsp;РИДУ
          </div>
          <Disclaimers />
          <div className={styles.formSubtitle} data-test-id="page-subtitle">
            Заполните&nbsp;данные, чтобы&nbsp;войти
          </div>
          <Input
            error={errorText}
            ref={loginRef}
            block
            label="E-mail"
            placeholder="abcde@company.ru"
            testId="login"
          />
          <Gap />
          <Input
            error={errorText}
            ref={passwordRef}
            block
            label="Пароль"
            type="password"
            placeholder="●●●●●●●●"
            testId="password"
          />
          <Gap size={3} />
          <div className={styles.forgot}>
            <div
              className={styles.forgotText}
              role="button"
              onClick={() => {
                setIsModalOpened(true);
              }}
            >
              Забыли пароль?
            </div>
          </div>
          <Gap size={5} />
          <Button
            testId="login-submit"
            type="submit"
            width={breakpoints.isMobile ? undefined : 307}
            fullWidth={breakpoints.isMobile}
            disabled={isFetching || isAuthorizing}
          >
            {isFetching || isAuthorizing ? 'Загрузка...' : 'Войти'}
          </Button>
        </form>
      </div>
    </div>
  );
};
