import { formatNumericNames } from '../utils/formatNumericNames';

export const GRID_TOP_OPTIONS = [10, 20, 30, 50];

type TItem = {
  name: string;
  inn?: string;
  value: number;
  path: string;
  visibleMin?: number;
  upperLabel?: {
    show: boolean;
  };
  children?: TItem[];
  childrenCount?: number;
};

const COLORS = [
  '#FFF2AA',
  '#F7E5E6',
  '#D5EED8',
  '#D4CDF7',
  '#FFD4AF',
  '#FFD8F6',
  '#D5EDFF',
  '#94D49C',
  '#D4BF54',
];

// const COLORS_DEEP = [
//   '#FE8888',
//   '#619500',
//   '#B588FE',
//   '#537A00',
//   '#48D3FF',
//   '#FEC888',
//   '#88D4FE',
//   '#F588FE',
//   '#415DF3',
//   '#FFDF38',
//   '#DAF279',
//   '#88A2FE',
//   '#FF9548',
//   '#8459FD',
//   '#E25C3B',
// ];

const RICH = {
  name: {
    fontSize: 12,
    lineHeight: 20,
    color: '#333333',
    fontWeight: 'bold',
  },
  value: {
    fontSize: 12,
    lineHeight: 20,
    color: '#333333',
    fontWeight: 'thin',
  },
};

const LIMIT = 500;
const FORCE_LABEL_LIMIT = 100;

function prepareData(data: TItem[]): TItem[] {
  return data.map((item, index) => ({
    ...item,
    upperLabel: {
      show:
        index < FORCE_LABEL_LIMIT ||
        Boolean(item.children && item.children.length > 1),
    },
  }));
}

function withLimit(data: TItem[], canDrill = false, top = LIMIT): TItem[] {
  if (data.length < top) {
    return data;
  }

  const other = data.slice(top - 1);
  const otherValue = other.reduce((acc, item) => acc + item.value, 0);
  const rest = data.slice(0, top - 1);

  return [
    ...prepareData(rest),
    {
      name: 'Другие',
      value: otherValue,
      path: 'Другие',
      children: canDrill ? withLimit(other, canDrill, LIMIT * 4) : undefined,
      childrenCount: other.length,
      visibleMin: canDrill ? 0 : Infinity,
    },
  ];
}

export const getTreemapConfig = (
  data: TItem[],
  top: 'all' | number = 'all',
  canDrill = false,
  name: string,
  countItemsLabel: string = 'ИНН',
) => {
  const dataWithLimit =
    top === 'all' ? withLimit(data, canDrill) : prepareData(data.slice(0, top));
  return {
    backgroundColor: '#fff',
    tooltip: {
      formatter: function (params: any) {
        return `${params.name}: ${formatNumericNames(params.value)}`;
      },
    },
    series: [
      {
        roam: 'move',
        left: 5,
        top: 5,
        bottom: 5,
        right: 5,
        squareRatio: 2,
        name,
        type: 'treemap',
        data: dataWithLimit,
        visibleMin: 0,
        leafDepth: canDrill ? 1 : undefined,
        label: {
          show: true,
          formatter: function (params: any) {
            const values = [
              params.name && `{name|${params.name}}`,
              params.value && `{value|${formatNumericNames(params.value)}}`,
              params.data?.inn && `{value|${params.data?.inn}}`,
              params.data?.childrenCount &&
                `{value|${params.data
                  ?.childrenCount} ${countItemsLabel}, среднее значение: ${formatNumericNames(
                  params.data?.value / params.data?.childrenCount,
                )}}`,
            ];
            return values.filter(Boolean).join('\n ');
          },
          rich: RICH,
        },
        levels: canDrill
          ? [
              {
                color: COLORS,
              },
            ]
          : [
              {
                color: COLORS,
                itemStyle: {
                  gapWidth: 3,
                },
              },
              {
                itemStyle: {
                  gapWidth: 1,
                  borderColor: '#F0F0F0',
                },
                upperLabel: {
                  show: true,
                  color: '#000',
                  shadowColor: 'transparent',
                  height: 20,
                  formatter: function (params: any) {
                    return `{name|${params.name}} {value|${formatNumericNames(
                      params.value,
                    )}}`;
                  },
                  rich: RICH,
                },
              },
            ],
        itemStyle: {
          gapWidth: 3,
        },
        sort: null,
      },
    ],
  };
};
