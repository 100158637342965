import React, { useEffect, useMemo, useState } from 'react';
import { PageContent } from '../../common/PageContent/PageContent';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';
import { getRole } from '../../../shared/utils/getRole';
import { ROLES } from '../../../shared/constants';
import { $api } from '../../../api/utils';
import { useQuery } from '@tanstack/react-query';
import { Button } from '../../../uikit/Button/Button';
import { Modal } from '../../../uikit/Modal/Modal';
import { Spacer } from '../../common/Spacer/Spacer';
import { Input } from '../../../uikit/Input/Input';
import { toast } from 'react-toastify';
import { queryClient } from '../../../store/query';
import { TTableColumn, Table } from '../../common/Table/Table';
import dayjs from 'dayjs';
import { sorter } from '../../common/Table/sorter';
import styles from './Files.module.scss';
import { Menu } from '../../common/Menu/Menu';
import { TurboSelect } from 'react-turbo-select';

type TFileData = {
  createdAt: string;
  description: string;
  filename: string;
  guid: string;
  link: string;
};

type TFileDataWithExtension = TFileData & {
  extension: string;
};

type TClient = {
  name: string;
  login: string;
  guid: string;
};

const getClients = async (): Promise<TClient[]> => {
  const { data } = await $api.get('/clients');
  return data;
};

const useClients = (enabled: boolean) => {
  return useQuery(['clients'], getClients, { enabled });
};

const getFiles = async (clientGuid?: string): Promise<TFileData[]> => {
  const { data } = await $api.get(`/filehosting?clientGuid=${clientGuid}`);
  return data;
};

const uploadFile = async (
  file: File,
  clientGuid: string,
  description: string,
): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);
  await $api.post(`/filehosting/client/${clientGuid}/file/upload`, formData);
};

const updateFile = async (
  file: File,
  clientGuid: string,
  description: string,
  fileGuid: string,
): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);
  await $api.put(
    `/filehosting/client/${clientGuid}/file/${fileGuid}/upload`,
    formData,
  );
};

const deleteFile = async (
  clientGuid: string,
  fileGuid: string,
): Promise<void> => {
  await $api.delete(`/filehosting/client/${clientGuid}/file/${fileGuid}`);
};

const useFiles = (clientGuid: string, enabled: boolean) => {
  return useQuery(['files', clientGuid], () => getFiles(clientGuid), {
    enabled,
  });
};

export const Files: React.FC = () => {
  const userInfo = useAuthorizedUser();
  const userRoles = userInfo.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);
  const [userGuid, setUserGuid] = useState<string>('');

  const clientsQueryData = useClients(isAdmin);
  const filesQueryData = useFiles(userGuid, !isAdmin || !!userGuid);

  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [selectedFileGuid, setSelectedFileGuid] = useState<string>('');

  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string>('');
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);

  const [moreButtonTarget, setMoreButtonTarget] = useState<HTMLElement | null>(
    null,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] =
    useState<TFileDataWithExtension | null>(null);

  const filesWithExtensions: Array<TFileDataWithExtension> = useMemo(() => {
    return (
      filesQueryData.data?.map((file) => {
        const filenameParts = file.filename.split('.');
        const ext = filenameParts.pop() || '';
        return {
          ...file,
          filename: filenameParts.join('.'),
          extension: ext,
        };
      }) || []
    );
  }, [filesQueryData.data]);

  const fileColumns: TTableColumn<TFileDataWithExtension>[] = [
    {
      key: 'description',
      title: 'Описание',
      sort: sorter('description'),
      size: 'auto',
    },
    {
      key: 'filename',
      title: 'Имя файла',
      sort: sorter('filename'),
      size: 'auto',
    },
    {
      key: 'extension',
      title: 'Расширение',
      size: 'auto',
      sort: sorter('extension'),
    },
    {
      key: 'createdAt',
      title: 'Дата загрузки',
      size: 'auto',
      sort: sorter('createdAt'),
      render: (data) => {
        const date = dayjs(data.createdAt).format('DD.MM.YYYY');
        return date;
      },
    },
    {
      key: 'link',
      size: '100px',
      title: '',
      render: (data) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className={styles.downloadLink}
            href={data.link}
            download
            target="_blank"
            rel="noreferrer"
          />
        );
      },
    },
  ];

  if (isAdmin) {
    fileColumns.push({
      key: 'actions',
      title: '',
      render: (row) => {
        return (
          <div
            className={styles.more}
            onClick={(e) => {
              setSelectedItem(row);
              setMoreButtonTarget(e.target as HTMLElement);
              setIsMenuOpen(true);
            }}
          />
        );
      },
      size: '60px',
    });
  }

  useEffect(() => {
    if (!isUpdateModalVisible && !isDeleteModalVisible) {
      setFile(null);
      setDescription('');
      setSelectedFileGuid('');
    }
  }, [isUpdateModalVisible, isDeleteModalVisible]);

  useEffect(() => {
    if (!isAdmin) {
      setUserGuid(userInfo.guid);
    }
  }, [isAdmin, userInfo]);

  let adminSelect = null;

  if (isAdmin) {
    const selectOptions = clientsQueryData.data?.map((client) => ({
      label: client.name,
      value: client.guid,
    }));

    const currentOption = selectOptions?.find(
      (option) => option.value === userGuid,
    );

    adminSelect = (
      <div>
        <TurboSelect
          key={clientsQueryData.data?.length}
          isSearchable
          options={selectOptions}
          onChange={(value) => {
            setUserGuid(value?.value || '');
          }}
          placeholder="Выберите клиента"
          defaultValue={currentOption}
        />
      </div>
    );
  }

  if (isAdmin && !userGuid) {
    return (
      <>
        <PageHeader title="Файлы">{adminSelect}</PageHeader>
        <PageContent
          queryData={clientsQueryData}
          notFound={clientsQueryData.data?.length === 0 || !userGuid}
          notFoundCustomText={
            clientsQueryData.data?.length === 0 ? (
              <div>Нет клиентов</div>
            ) : (
              <div>Выберите клиента</div>
            )
          }
        ></PageContent>
      </>
    );
  }

  let userName = '';
  if (isAdmin) {
    const client = clientsQueryData.data?.find(
      (client) => client.guid === userGuid,
    );
    userName = client?.name || '';
  }

  return (
    <>
      <PageHeader
        supportKey="files"
        title={userName ? `Файлы ${userName}` : 'Файлы'}
        withGap
        children={
          isAdmin ? (
            <>
              {adminSelect}
              {isAdmin && (
                <Button
                  testId="add-file"
                  onClick={() => setIsUpdateModalVisible(true)}
                >
                  Добавить
                </Button>
              )}
            </>
          ) : undefined
        }
      />
      <Menu
        testId="admin-file-actions"
        open={isMenuOpen}
        target={moreButtonTarget}
        onClose={() => setIsMenuOpen(false)}
        items={[
          {
            title: 'Редактировать',
            onClick: () => {
              setIsUpdateModalVisible(true);
              setDescription(selectedItem?.description || '');
              setSelectedFileGuid(selectedItem?.guid || '');
            },
          },
          {
            title: 'Удалить',
            onClick: () => {
              setIsDeleteModalVisible(true);
              setSelectedFileGuid(selectedItem?.guid || '');
            },
          },
        ]}
      />
      <Modal
        testId="add-file-modal"
        isOpened={isUpdateModalVisible}
        onClose={() => setIsUpdateModalVisible(false)}
      >
        <Modal.Title gap>
          {selectedFileGuid ? 'Обновить файл' : 'Добавить файл'}
        </Modal.Title>
        <Modal.Body>
          <Input
            testId="file"
            block
            custom={
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (!file) {
                    return;
                  }
                  setFile(file);
                }}
              />
            }
            label="Файл"
          />
          <Input
            testId="description"
            block
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Описание"
            placeholder="Отчёт по заказу №123"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            testId="cancel-button"
            onClick={() => setIsUpdateModalVisible(false)}
            color="grey"
            disabled={isFileLoading}
          >
            Отмена
          </Button>
          <Spacer />
          <Button
            testId="save-button"
            disabled={isFileLoading}
            onClick={async () => {
              if (!file) {
                toast.error('Файл не выбран');
                return;
              }

              setIsFileLoading(true);
              try {
                if (selectedFileGuid) {
                  await updateFile(
                    file,
                    userGuid,
                    description,
                    selectedFileGuid,
                  );
                } else {
                  await uploadFile(file, userGuid, description);
                }
                toast.success('Файл успешно загружен');
                queryClient.invalidateQueries(['files', userGuid]);
                setIsUpdateModalVisible(false);
              } catch (e) {
                toast.error('Ошибка при загрузке файла');
                toast.error((e as Error).toString());
              }
              setIsFileLoading(false);
            }}
          >
            {isFileLoading ? 'Загрузка...' : 'Сохранить'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        testId="delete-file-modal"
        isOpened={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
      >
        <Modal.Title gap>Удалить файл</Modal.Title>
        <Modal.Body>
          <div>Вы действительно хотите удалить файл?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            testId="cancel-button"
            onClick={() => setIsDeleteModalVisible(false)}
            color="grey"
            disabled={isFileLoading}
          >
            Отмена
          </Button>
          <Spacer />
          <Button
            testId="save-button"
            disabled={isFileLoading}
            onClick={async () => {
              setIsFileLoading(true);
              try {
                await deleteFile(userGuid, selectedFileGuid);
                toast.success('Файл успешно удалён');
                queryClient.invalidateQueries(['files', userGuid]);
                setIsDeleteModalVisible(false);
              } catch (e) {
                toast.error('Ошибка при удалении файла');
                toast.error((e as Error).toString());
              }
              setIsFileLoading(false);
            }}
          >
            {isFileLoading ? 'Удаление...' : 'Удалить'}
          </Button>
        </Modal.Footer>
      </Modal>
      <PageContent
        queryData={filesQueryData}
        notFound={filesQueryData.data?.length === 0}
        notFoundCustomText={
          <>
            {!isAdmin && (
              <div className={styles.placeholder}>
                <div>
                  На этой странице будут отображаться файлы, отправленные вам
                </div>
                <div>
                  Для уточнения деталей обратитесь к{' '}
                  <a href="mailto:ya@retail-data.center">
                    ya@retail-data.center
                  </a>
                </div>
              </div>
            )}
            {isAdmin && <div>Нет файлов</div>}
            {isAdmin && (
              <Button
                testId="add-file-from-placeholder"
                onClick={() => setIsUpdateModalVisible(true)}
              >
                Добавить
              </Button>
            )}
          </>
        }
      >
        <Table cols={fileColumns} rows={filesWithExtensions || []} />
      </PageContent>
    </>
  );
};
