import React, { useMemo } from 'react';
import { DEFAULT_ECHARTS_PROPS } from '../../../../../../shared/charts';
import { getBarConfig } from '../../../../../../shared/chartsConfigs/bar';
import { useAccumulatedFields } from '../../../../../../shared/utils/useAccumulatedFields';
import { Chart } from '../../../../../common/Chart/Chart';
import { ViewFeature } from '../reportConfigType';
import ReactECharts from 'echarts-for-react';
import { getBarWithLineConfig } from '../../../../../../shared/chartsConfigs/barWithLine';
import { ICONS } from '../../../../../common/LineSelect/LineSelect';

type TGetBarChartFeatureConfig<TDataItem> = {
  nameKey: keyof TDataItem;
  innKey?: keyof TDataItem;
  valueKey: keyof TDataItem;
  accumulateBy?: keyof TDataItem;
  withPercents?: boolean;
  icon?: keyof typeof ICONS;
  filename: string;
  groupKeyToNameMap?: Partial<Record<keyof TDataItem, keyof TDataItem>>;
  subValueKeys?: (keyof TDataItem)[];
  tooltipFormatter?: (item: {
    data: {
      fullItem: TDataItem;
    };
  }) => string;
};

const preparePercent = (value: number) => value / 100;

export const getBarChartFeature = <TDataItem extends Record<string, any>>(
  config: TGetBarChartFeatureConfig<TDataItem>,
): ViewFeature<TDataItem> => {
  const groupKeyToNameMap =
    config.groupKeyToNameMap ||
    ({} as Record<keyof TDataItem, keyof TDataItem>);
  const getConfig = config.withPercents ? getBarWithLineConfig : getBarConfig;

  const ChartComponent: ViewFeature<TDataItem>['component'] = ({
    data,
    dataFeatures,
  }) => {
    const groupKey =
      dataFeatures.find((feature) => feature.key === 'groupBy')?.state ||
      config.nameKey;

    const nameKey = groupKeyToNameMap[groupKey as keyof TDataItem] || groupKey;

    const chartData = useAccumulatedFields<TDataItem>(
      // @ts-ignore
      data,
      config.accumulateBy || 'sharePercent',
      preparePercent,
    );

    const preparedData = useMemo(() => {
      return chartData
        .map((item) => {
          const name = item[nameKey as keyof TDataItem];
          const inn = config.innKey && item[config.innKey];
          const nameFull = [nameKey === config.nameKey && inn, name]
            .filter(Boolean)
            .join('\n');
          return {
            name: nameFull,
            value: item[config.valueKey || 'salesCnt'],
            percent: item[config.accumulateBy || 'sharePercent'],
            subValues: config.subValueKeys
              ? config.subValueKeys.map((key) => item[key])
              : undefined,
            fullItem: item,
          };
        })
        .sort((a, b) => b.value - a.value);
    }, [chartData, nameKey]);

    const testId = [
      'barChart',
      config.withPercents && 'withPercents',
      !config.withPercents && 'withoutPercents',
      !!config.subValueKeys && 'withSubValues',
      !config.subValueKeys && 'withoutSubValues',
    ]
      .filter(Boolean)
      .join('-');

    return (
      <>
        <Chart
          fullHeight
          testId={testId}
          name={
            config.withPercents
              ? 'Столбчатая диаграмма с накоплением'
              : 'Столбчатая диаграмма'
          }
        >
          <ReactECharts
            {...DEFAULT_ECHARTS_PROPS}
            option={getConfig(
              preparedData,
              config.subValueKeys as string[],
              // @ts-ignore
              config.tooltipFormatter,
            )}
          />
        </Chart>
      </>
    );
  };

  const withSubValuesKey = config.subValueKeys ? '_sub' : '';

  return {
    key: config.withPercents
      ? `chart-2${withSubValuesKey}`
      : `chart-1${withSubValuesKey}`,
    title: config.withPercents
      ? 'Столбчатая диаграмма с накоплением'
      : 'Столбчатая диаграмма',
    hint: config.withPercents
      ? 'Столбчатая диаграмма с накоплением'
      : 'Столбчатая диаграмма',
    icon: config.icon || 'barChart',
    component: ChartComponent,
  };
};
