import { isSmall } from '../../uikit/hooks/useBreakpoint';
import {
  DEFAULT_CATEGORY_AXIS,
  DEFAULT_CONFIG,
  DEFAULT_GRID,
  DEFAULT_PERCENT_AXIS,
  DEFAULT_ZOOM,
  MOBILE_GRID,
} from '../charts';
import { CHART_COLORS } from '../constants';
import { formatNumericNames } from '../utils/formatNumericNames';
import { roundTo } from '../utils/roundTo';

type TItem = {
  name: string;
  value: number;
  percent: number;
};

export const getBarWithLineConfig = (
  data: TItem[],
  _subValuesKeys?: string[],
  tooltipFormatter?: (item: any) => string,
) => {
  return {
    ...DEFAULT_CONFIG,
    grid: isSmall() ? MOBILE_GRID : DEFAULT_GRID,
    xAxis: {
      ...DEFAULT_CATEGORY_AXIS,
      data: data.map((item) => item.name),
    },
    yAxis: [
      {
        id: 'salesCnt',
        name: 'Кол-во продаж',
        type: 'value',
        axisLabel: {
          formatter: function (value: number) {
            return formatNumericNames(value);
          },
        },
      },
      {
        ...DEFAULT_PERCENT_AXIS,
        id: 'sharePercent',
        name: 'Доля продаж накопленным итогом',
      },
    ],
    dataZoom: [
      {
        ...DEFAULT_ZOOM,
        end: 3000 / data.length,
      },
      {
        ...DEFAULT_ZOOM,
        type: 'slider',
        end: 3000 / data.length,
      },
    ],
    series: [
      {
        id: 'salesCnt',
        data,
        type: 'bar',
        name: 'Кол-во продаж',
        yAxisIndex: 0,
        itemStyle: {
          color: CHART_COLORS[0],
        },
        tooltip: {
          formatter: tooltipFormatter,
          valueFormatter: function (value: number) {
            return formatNumericNames(value);
          },
        },
      },
      {
        id: 'sharePercent',
        data: data.map((item: any) => roundTo(item.percent * 100, 0)),
        type: 'line',
        name: 'Доля продаж накопленным итогом',
        tooltip: {
          valueFormatter: function (value: number) {
            return value + '%';
          },
        },
        yAxisIndex: 1,
        lineStyle: {
          color: CHART_COLORS[1],
        },
        itemStyle: {
          color: CHART_COLORS[1],
        },
      },
    ],
  };
};
