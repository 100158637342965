import { ModalInfo, TModalInfoProps } from '../ModalInfo';
import { Modal } from '../../../../../uikit/Modal/Modal';
import { Button } from '../../../../../uikit/Button/Button';
import { TTableColumn, Table } from '../../../../common/Table/Table';

import styles from './ReportModal.module.scss';
import { Checkbox } from '../../../../../uikit/Checkbox/Checkbox';
import { Input } from '../../../../../uikit/Input/Input';
import { Spacer } from '../../../../common/Spacer/Spacer';
import React, { useState } from 'react';

type TRow = {
  key: string;
  value: string;
  active: boolean;
};

export const ReportModal: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (values: Record<string, string>) => void;
  possibleValues: Record<string, string>;
  initialValue: Record<string, string>;
  modalInfo: TModalInfoProps;
}> = ({ show, onClose, onSubmit, possibleValues, initialValue, modalInfo }) => {
  const [selectedValues, setSelectedValues] = useState<TRow[]>(() => {
    const someSelected = Object.keys(initialValue).length !== 0;
    return Object.entries(possibleValues).map(([key, value]) => {
      return {
        key,
        value,
        active: !someSelected || initialValue[key] !== undefined,
      };
    });
  });

  const updateItem = (key: string, item: Partial<TRow>) => {
    setSelectedValues(
      selectedValues.map((value) => {
        if (value.key === key) {
          return {
            ...value,
            ...item,
          };
        }
        return value;
      }),
    );
  };

  const COLUMNS: TTableColumn<TRow>[] = [
    {
      key: 'active',
      title: 'Активность',
      render: ({ key, active }) => {
        return (
          <Checkbox
            label=""
            name={key}
            onChange={() => {
              updateItem(key, { active: !active });
            }}
            checked={active}
          />
        );
      },
      size: '130px',
    },
    {
      key: 'name',
      title: 'Название поля в файле',
      render: ({ key, value, active }) => {
        return (
          <Input
            value={value}
            disabled={!active}
            onChange={(e) => {
              updateItem(key, { value: e.target.value });
            }}
            placeholder={possibleValues[key] || key}
            block
            noGap
            testId={`input_${key}`}
          />
        );
      },
      grow: true,
    },
  ];

  const hasSomethingChecked = selectedValues.some(({ active }) => active);

  return (
    <Modal testId={'report-modal'} isOpened={show} onClose={onClose}>
      <Modal.Title left>Выбор полей</Modal.Title>
      <Modal.Body>
        <ModalInfo {...modalInfo} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={styles.tableContainer}
        >
          <Table noMargin cols={COLUMNS} rows={selectedValues} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Spacer />
        <Button
          disabled={!hasSomethingChecked}
          testId={'report-modal-submit'}
          onClick={() => {
            const onlyActive = selectedValues.filter(({ active }) => active);
            const valuesAsObject = onlyActive.reduce((acc, { key, value }) => {
              acc[key] = value || possibleValues[key] || key;
              return acc;
            }, {} as Record<string, string>);
            onSubmit(valuesAsObject);
            onClose();
          }}
        >
          Подтвердить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
