import React from 'react';

import styles from './Queue.module.scss';
import { ReportQueue } from '../../../../../../ReportQueue/ReportQueue';

export const QueueWidget: React.FC = () => {
  return (
    <div className={styles.queueWrapper}>
      <div className={styles.queue}>
        <ReportQueue />
      </div>
    </div>
  );
};
