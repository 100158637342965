import { useQuery } from '@tanstack/react-query';
import { SupportDto, SupportPage } from '../../api/backend/Api';
import { $api } from '../../api/utils';
import { formatError } from '../utils/withErrorHandling';
import { queryClient } from '../../store/query';
import { UserFlags, useAuthorizedUser } from '../hooks/useUser';
import { getRole } from '../utils/getRole';
import { ROLES } from '../constants';

const getSupportPages = async (): Promise<SupportDto[]> => {
  try {
    const { data } = await $api.get('/support');
    return data;
  } catch (e) {
    throw formatError(e);
  }
};

export const createSupportPage = async (
  data: SupportPage,
): Promise<SupportDto> => {
  try {
    const { data: supportPage } = await $api.post('/support', data);
    queryClient.resetQueries(['support']);
    return supportPage;
  } catch (e) {
    throw formatError(e);
  }
};

export const updateSupportPage = async (
  guid: string,
  data: SupportPage,
): Promise<SupportDto> => {
  try {
    const { data: supportPage } = await $api.put(`/support/${guid}`, data);
    queryClient.resetQueries(['support']);
    return supportPage;
  } catch (e) {
    throw formatError(e);
  }
};

export const deleteSupportPage = async (guid: string): Promise<void> => {
  try {
    await $api.delete(`/support/${guid}`);
    queryClient.resetQueries(['support']);
  } catch (e) {
    throw formatError(e);
  }
};

export const useSupportPages = () => {
  const userData = useAuthorizedUser();
  const userRoles = userData.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);
  return useQuery(
    ['support'],
    async () => {
      const pages = await getSupportPages();
      return pages.filter((page) => {
        if (page.userCondition && !isAdmin) {
          return !!userData[page.userCondition as UserFlags];
        }
        return true;
      });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );
};

export const useSupportPage = (key?: string): SupportDto | null => {
  const { data } = useSupportPages();
  return data?.find((page) => page.report === key) || null;
};
