export const AGREEMENT = `<div>
<p>
    1 сентября 2022 года.
</p>
<p>
    Настоящее Пользовательское соглашение (далее &mdash; &laquo;
    <strong>
        Соглашение&raquo;
    </strong>
    ) регламентирует отношения между
    <strong>
        ОБЩЕСТВОМ С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
    </strong>
    &laquo;Ритейл Дата-Центр Технологии&raquo;, юридическим лицом, существующим по законодательству Российской Федерации и зарегистрированным по адресу: Россия город Москва, ул. Большая Садовая, д. 10, пом. III, эт. 5, комн. 7 (далее &mdash; &laquo;
    <strong>
        Фирма&raquo;
    </strong>
    ), и любым лицом (далее &mdash; &laquo;
    <strong>
        Пользователь&raquo;
    </strong>
    ), посещающим (использующим) сайт в сети Интернет по адресу: https://rida.retail-data.center/; https://rida.retaildata.center/ (далее &mdash; &laquo;
    <strong>
        Сайт&raquo;
    </strong>
    ). Используя Сайт любым способом, Пользователь тем самым принимает нижеизложенные условия настоящего Соглашения в полном объёме, присоединяется к настоящему Соглашению и принимает на себя все описанные ниже обязанности и риски.
</p>
<p>
    ВЫ ЯВЛЯЕТЕСЬ ПОЛЬЗОВАТЕЛЕМ И ОБЯЗАНЫ ОЗНАКОМИТЬСЯ С НАСТОЯЩИМ СОГЛАШЕНИЕМ ДО НАЧАЛА РАБОТЫ С САЙТАМИ. ЕСЛИ ВЫ НЕ СОГЛАСНЫ С ЭТИМ СОГЛАШЕНИЕМ, НЕ ИСПОЛЬЗУЙТЕ САЙТ.
</p>
<h4>
    1. Общие положения
</h4>
<p>
    1.1. Фирма является владельцем настоящего Сайта и администратором доменного имени https://rida.retail-data.center/; https://rida.retaildata.center/. По настоящему Соглашению Фирма предоставляет доступ к Сайту, а Пользователь обязуется использовать Сайт надлежащим образом в пределах, установленных настоящим Соглашением.
</p>
<p>
    1.2. Основное назначение Сайт состоит в информировании широкого круга Пользователей о деятельности Фирмы, а также обеспечении коммуникации между Пользователями и Фирмой посредством веб-форм (
    <strong>
        &laquo;Формы обратной связи&raquo;
    </strong>
    ) и почтовых рассылок. Сведения о Фирме могут обновляться время от времени без публикации предупреждений.[ЭФ1]&nbsp;
</p>
<p>
    1.3. Сайт не содержит публичных оферт на оказание Фирмой услуг.[ЭФ2]&nbsp;
</p>
<p>
    1.4. Информация, размещённая на Сайте носит исключительно справочный характер. Фирма не несёт ответственности за использование данной информации Пользователем. Перед совершением каких-либо действий или отказом (воздержанием) от совершения каких-либо действий, основанных на информации, содержащейся на Сайте, необходимо обратиться за консультацией к соответствующему специалисту Фирмы.
</p>
<p>
    1.5. Сайт содержит (может содержать) сведения об офисах (включая филиалы и обособленные подразделения), аффилированных лицах и деловых партнёрах Фирмы. Фирма прилагает все усилия для обеспечения актуальности этой информации. Тем не менее, Пользователю рекомендуется самостоятельно обращаться к указанным офисам / лицам для уточнения вопросов об их деятельности, используя приведённые на Сайте контактные данные.
</p>
<p>
    1.6. Изменение условий настоящего Соглашения Фирмой допускается в одностороннем порядке без отдельного уведомления Пользователя. На Сайте всегда опубликована действующая версия Соглашения. Пользователь обязан время от времени проверять актуальные условия Соглашения.
</p>
<p>
    1.7. Настоящее Соглашение составлено на русском и английском языках[ЭФ3]&nbsp;. При возникновении разногласий между русским и английским текстами, текст на русском языке имеет приоритетную силу.
</p>
<h4>
    2. Права интеллектуальной собственности
</h4>
<p>
    2.1. Фирма является обладателем надлежащим образом оформленных исключительных прав или прав использования в отношении Сайта в целом, а также всех размещённых на них результатов интеллектуальной деятельности, среди прочего, текстов, фотографий, схем, графических рисунков, прочих изображений, программ для ЭВМ, включая исходные тексты и объектные коды, видео-, аудиозаписей, иных объектов авторского права, баз данных, товарных знаков и знаков обслуживания, а равно компоновки и расположения перечисленных выше объектов на страницах Сайта, дизайна Сайт, в том числе их внешнего вида, подбора цветовой палитры, структуры и шрифтов (далее совместно &mdash;
    <strong>
        &laquo;Контент&raquo;
    </strong>
    ).
</p>
<p>
    2.2. Пользователь обязан соблюдать права Фирмы на Контент и воздержаться от любых действий, которые прямо или косвенно могут привести к нарушению прав Фирмы на Контент либо способствовать совершению такого нарушения третьим лицом.
</p>
<h4>
    3. Использование Сайтов
</h4>
<p>
    3.1. Фирма предоставляет Пользователю неисключительное безвозмездное право на использование Контента на территории всего мира следующими способами:
</p>
<p>
    &mdash; осуществлять доступ к Контенту и знакомиться с ним путём запуска Сайта в окне браузера в течение интернет-сессии Пользователя;
</p>
<p>
    &mdash; записывать страницы Сайта или их фрагменты на машинный носитель информации (например, жёсткий диск или флэш-накопитель) или распечатывать их на бумажном носителе в целях использования по основному назначению Сайта (п.1.2 Соглашения) при условии одновременного соблюдения Пользователем всех следующих ограничений:
</p>
<p>
    (i) запрещается осуществлять распространение копий (распечаток) страниц Сайта или их фрагментов, а равно многократное копирование (распечатывание) страниц Сайта, неоправданное указанной выше целью их использования;
</p>
<p>
    (ii) запрещается удалять, изменять или скрывать знаки охраны интеллектуальных прав или уведомления об интеллектуальных правах;
</p>
<p>
    (iii) запрещается записывать (распечатывать) страницы Сайта или их фрагменты, если они содержат уведомление о запрете подобных действий или программные средства, препятствующие подобным действиям;
</p>
<p>
    (iv) запрещается вносить изменения или дополнения в Контент;
</p>
<p>
    &mdash; цитировать Контент в объёме, оправданном правомерной целью цитирования, при условии, что Сайт будет указан как источники цитаты (путём проставления гиперссылки).
</p>
<p>
    3.2. Пользователь не уполномочен предоставлять сублицензии на права, указанные в п.3.1 Соглашения.
</p>
<p>
    3.3. Права, предусмотренные в п.3.1 Соглашения, предоставляются Пользователю при условии, что он соблюдает все положения настоящего Соглашения. В случае нарушения любого из этих положений права Пользователя на использование Сайта считаются прекращёнными автоматически (без специального уведомления со стороны Фирмы) с момента подобного нарушения.
</p>
<p>
    3.4. Фирма вправе по своему усмотрению прекращать или ограничивать доступ к Сайту отдельных Пользователей или групп Пользователей без указания причин.
</p>
<p>
    3.5. Пользователю запрещается:
</p>
<p>
    &mdash; воспроизведение Контента в периодическом печатном издании и последующее распространение экземпляров этого издания, сообщение Контента в эфир или по кабелю и доведение его до всеобщего сведения без предварительного согласия Фирмы;
</p>
<p>
    &mdash; доступ к настройкам Сайта, которые не предназначены для всех Пользователей или неавторизованных Пользователей;
</p>
<p>
    &mdash; использование логинов и паролей других Пользователей и совершение иных действий, направленных на то, чтобы выдать себя за другого Пользователя;
</p>
<p>
    &mdash; использование Контента любыми способами, не перечисленными в п.3.1 Соглашения, включая, но не ограничиваясь, переработку, декомпиляцию и включение в составные произведения;
</p>
<p>
    &mdash; создание фишинговых веб-ресурсов, а равно любых других веб-ресурсов, сходных до степени смешения с Сайтом или Контентом;
</p>
<p>
    &mdash; любые действия с исходным кодом Сайта;
</p>
<p>
    &mdash; обход навигационной системы Сайта и совершение действий, направленных на то, чтобы вызвать некорректное функционирование Сайта, замедление или затруднение их работы, искусственное увеличение нагрузки на серверы и каналы связи;
</p>
<p>
    &mdash; обход антивирусов, файерволов и иных средств защиты, которыми снабжены Сайт и его хостинг;
</p>
<p>
    &mdash; поиск и использование уязвимостей Сайта;
</p>
<p>
    &mdash; осуществление доступа к Сайту с использованием веб-ботов, кроме официальных поисковых роботов, принадлежащих поисковым системам;
</p>
<p>
    &mdash; использование Форм обратной связи не по назначению, установленному Фирмой;
</p>
<p>
    &mdash; направление через Формы обратной связи вредоносных программ, рекламы, спама или мошеннических сообщений;
</p>
<p>
    &mdash; отслеживание действий иных Пользователей Сайта и установление их личности;
</p>
<p>
    &mdash; совершение любых действий, которые противоречат законодательству Российской Федерации и (или) государства, в котором находится Пользователь;
</p>
<p>
    &mdash; подстрекание других лиц к совершению указанных выше действий; и
</p>
<p>
    &mdash; совершение иных действий, воспрещённых условиями настоящего Соглашения.
</p>
<h4>
    4. Формы обратной связи
</h4>
<p>
    4.1. Фирма размещает на Сайте Формы обратной связи для подачи заявок на вакантные должности, оформления подписки на информационные и маркетинговые рассылки, связи с сотрудниками Фирмы и направления иных сообщений (далее совместно &mdash;
    <strong>
        &laquo;Сообщения&raquo;
    </strong>
    ).
</p>
<p>
    4.2. В некоторых Формах обратной связи имеется поле для указания организации, которую представляет Пользователь. Если это поле заполнено, Фирма рассматривает Пользователя как уполномоченного представителя указанной организации. Во всех остальных случаях Пользователь направляет Сообщения от своего собственного имени, в собственных интересах и Фирма не обязана отвечать на такие запросы.
</p>
<p>
    4.3. Сообщения, несоответствующие назначению Формы обратной связи (указывается в заголовке), не содержащие исходной информации для осуществления профессиональной консультации могут быть удалены без рассмотрения.
</p>
<p>
    4.4. На Сайте размещена Форма обратной связи с сотрудниками Фирмы. Пользователь должен учитывать, что Сообщения, адресованные конкретному сотруднику, могут быть доступны и иным сотрудникам (например, ассистентам или сотрудникам, отвечающим за обработку корреспонденции). В связи с этим Пользователю следует воздержаться от направления Сообщений личного характера или строго конфиденциальных материалов, непредназначенных для раскрытия кому-либо, кроме непосредственного адресата.
</p>
<p>
    4.5. Формы обратной связи не предназначены для направления любых материалов, содержащих охраняемую законом тайну (адвокатскую тайну, тайну следствия, банковскую тайну и т.д.).
</p>
<p>
    4.6. Клиенты, подрядчики и иные контрагенты Фирмы не должны использовать Формы обратной связи для ведения переписки в ходе работы по заключённым с Фирмой договорам.
</p>
<h4>
    5. Рассылки
</h4>
<p>
    5.1. На Сайте реализована функция информационных и маркетинговых рассылок. Обладателем распространяемой информации является Фирма. При необходимости любые заинтересованные лица могут руководствоваться сведениями о наименовании, месте нахождения и адресе, а также адресе электронной почты Фирмы, приведёнными в настоящем Соглашении.
</p>
<p>
    5.2. Пользователи имеют возможность в любое время изменить условия подписки и отписаться в соответствующих разделах Сайта. Фирма вправе по своему усмотрению отменить подписку любого Пользователя или отказать ему в оформлении подписки без объяснения причин. Фирма не имеет права в одностороннем порядке изменять условия рассылки в части тематики направляемых сообщений.
</p>
<p>
    5.3. При оформлении подписки запрещается использовать адрес электронной почты, не принадлежащий Пользователю.
</p>
<h4>
    6. Личный кабинет
</h4>
<p>
    6.1. На Сайте поддерживаются раздел с ограниченным доступом, предназначенный для клиентов Фирмы (далее &mdash;
    <strong>
        &laquo;Личный кабинет&raquo;
    </strong>
    ). Для доступа в Личный кабинет Пользователю необходимо пройти процедуры регистрации и аутентификации.
</p>
<p>
    6.2. Процедура регистрации выполняется один раз и состоит в создании уникальных логина и пароля для Пользователя &mdash; клиента Фирмы. Логин и пароль предоставляются по договорённости с Фирмой. Фирма может вводить правила по периодической замене логинов и паролей в целях безопасности.
</p>
<p>
    6.3. Процедура аутентификации выполняется каждый раз, когда Пользователь намеревается воспользоваться Личным кабинетом. Она заключается в том, что Пользователь вводит присвоенные ему логин и пароль в поля веб-формы, сведения о чем передаются в Фирму после нажатия на соответствующую кнопку веб-формы. С этого момента Пользователь признается уполномоченным представителем клиента Фирмы и все действия на Сайтах совершает от имени указанного клиента вплоть до момента выхода из Личного кабинета.
</p>
<p>
    6.4. Пользователь &mdash; клиент Фирмы не вправе делать снимки экрана, а также сохранять или распечатывать страницы Личного кабинета, если только подобные функции не предусмотрены на соответствующих страницах.
</p>
<h4>
    7. Внешние ссылки
</h4>
<p>
    7.1. Сайт может содержать ссылки на веб-ресурсы третьих лиц. Фирма делает все возможное для исключения любых ссылок на ненадёжные, мошеннические и иные подобные веб-ресурсы. Тем не менее, их работа не регламентируется настоящим Соглашением. Фирма не несёт ответственности за содержание веб-ресурсов третьих лиц и особенности их функционирования, в том числе за возможный вред, причинённый Пользователям на указанных веб-ресурсах.
</p>
<h4>
    8. Отсутствие гарантий
</h4>
<p>
    8.1. Доступ к сайту предоставляется пользователю на условиях &laquo;как есть&raquo;. Фирма настоящим отказывается от любых гарантий в отношении контента и сайтов. Среди прочего, фирма не гарантирует пользователю, что:
</p>
<p>
    (i) Сайт не содержит программных ошибок, будут работать без перебоев, являются совместимыми с устройствами Пользователя и будут корректно отображаться на таких устройствах;
</p>
<p>
    (ii) Сайт и его отдельные страницы будут постоянно доступны для Пользователя;
</p>
<p>
    (iii) Сайт содержит полные, исчерпывающие, точные, безошибочные и подходящие для нужд Пользователя сведения о Фирме и её деятельности и справочные, новостные и аналитические материалы по юридическим и иным вопросам;
</p>
<p>
    (iv) При распечатывании или записи на машинный носитель страницы Сайта будут выглядеть так же, как они выглядят в режиме онлайн;
</p>
<p>
    (v) Сайт не содержат информации, доступ к которой запрещён или ограничен в стране местонахождения Пользователя;
</p>
<p>
    (vi) Сайт не содержит сведений, которые, по мнению Пользователя, могут являться некорректными или оскорбительными.
</p>
<p>
    8.2. При использовании Сайта Пользователь обязан полагаться на его собственные антивирусы, файерволы и иные средства информационной безопасности. Фирма прилагает все возможные усилия для обеспечения защиты Сайта от хакерских атак, кибер-преступлений и иных вредных и опасных для Пользователя действий посторонних лиц. Тем не менее, Фирма отказывается от гарантий того, что третьи лица не смогут противоправно использовать Сайт и его Контент для причинения вреда Пользователю.
</p>
<h4>
    9. Ограничение ответственности
</h4>
<p>
    9.1. Фирма не несёт ответственности за любое нарушение обязательств по Соглашению и за возникновение у Пользователя убытков в результате использования Сайта, кроме случаев наличия умысла у Фирмы. Фирма не несёт ответственности за причинение Пользователю любого иного вреда, если только он не причинён по вине Фирме.
</p>
<p>
    9.2. Пользователь несёт ответственность за нарушение настоящего соглашения, а равно интеллектуальных и иных прав фирмы в полном объёме согласно нормам применимого законодательства (п.11 соглашения), а также нормам законодательства, действующего в месте нахождения пользователя (если действия пользователя образуют состав преступления или иного правонарушения по законодательству этого государства).
</p>
<h4>
    10. Конфиденциальность
</h4>
<p>
    10.1. Логин и пароль Пользователя (для входа в Личный кабинет) являются конфиденциальной информацией. Пользователь не вправе разглашать логины и пароли третьим лицам, а также использовать и хранить их таким образом, что третье лицо может получить к ним доступ.
</p>
<p>
    10[ЭФ4]&nbsp;.2. Персональные данные Пользователя являются конфиденциальной информацией и обрабатываются в соответствии с Политикой ООО &laquo;Ритейл Дата-Центр Технологии&raquo;.
</p>
<p>
    10.3. Сообщения, отправленные через Форму обратной связи, являются конфиденциальной информацией и не подлежат разглашению Фирмой, за исключением следующих случаев:
</p>
<p>
    (i) Пользователь, направивший Сообщение, сам в письменной форме попросил о раскрытии Сообщения третьему лицу или его обнародовании;
</p>
<p>
    (ii) Сообщение подлежит раскрытию по указанию компетентного суда, правомерному требованию правоохранительных и иных органов государственной власти, а равно в силу норм применимого законодательства (п.11 Соглашения);
</p>
<p>
    (iii) Доступ к Сообщению предоставляется подрядчикам Фирмы, осуществляющим техническую поддержку и обслуживание Сайтов и информационных систем Фирмы при условии, что с ними заключено соглашение о конфиденциальности, распространяющееся на Сообщения;
</p>
<p>
    (iv) Сообщения содержат сведения о совершенных или готовящихся преступлениях или административных правонарушениях либо их признаки.
</p>
<h4>
    11. Применимое законодательство и разрешение споров
</h4>
<p>
    11.1. Настоящее Соглашение и все отношения Сторон в связи с использованием Сайтов регламентируются законодательством Российской Федерации.
</p>
<p>
    11.2. Все споры, вытекающие из настоящего Соглашения или связанные с использованием Сайтов, подлежат разрешению в судебном порядке по месту нахождения Фирмы.
</p>
<h4>
    12. Обратная связь
</h4>
<p>
    12.1. Обращения по вопросам, связанным с работой Сайтов, и заявления о нарушении авторских и (или) смежных прав следует направлять по электронной почте: _____@____ru или по почтовому адресу: Россия, _____________________________________________________________________________
</p>
<p>
    ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
</p>
<p>
    &laquo;Ритейл Дата-Центр Технологии&raquo;
</p>
<p>
    ИНН: 9710090862
</p>
<p>
    ОГРН: 1217700331045
</p>
<p>
    Юридический Адрес: 123001, г. Москва,
</p>
<p>
    ул. Большая Садовая, д. 10,
</p>
<p>
    пом. III, эт. 5, комн. 7
</p>
<p>
    Банк: ПАО &laquo;РАЙФФАЙЗЕНБАНК&raquo;
</p>
<p>
    БИК: 044525700
</p>
<p>
    Р/с: 40702810300000190499
</p>
<p>
    К/с: 30101810200000000700
</p>
<p>
    Факт. адрес: 123001, г. Москва,
</p>
<p>
    ул. Большая Садовая, д. 10,
</p>
<p>
    пом. III, эт. 5, комн. 7
</p>
<p>
    Эл.почта: ___________
</p>
<p>
    <strong>
        Генеральный директор
    </strong>
</p>
<p>
    Гусейнова Яна Муслумовна / ___________.
</p>
</div>
`;
