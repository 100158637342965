import React, { useState } from 'react';
import { ModalInfo, TModalInfoProps } from '../ModalInfo';
import { Modal } from '../../../../../uikit/Modal/Modal';
import { Button } from '../../../../../uikit/Button/Button';
import { Checkbox } from '../../../../../uikit/Checkbox/Checkbox';

import styles from './PeriodsModal.module.scss';
import { Spacer } from '../../../../common/Spacer/Spacer';
import { PeriodType } from '../../../../../api/uploads/uploads';

export const PeriodsModal: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (values: number[]) => void;
  possiblePeriods: PeriodType[];
  initialValue: number[];
  modalInfo: TModalInfoProps;
}> = ({
  show,
  onClose,
  possiblePeriods,
  onSubmit,
  initialValue,
  modalInfo,
}) => {
  const [selectedPeriods, setSelectedPeriods] = useState<Record<number, true>>(
    () => {
      return initialValue.reduce((acc, period) => {
        acc[period] = true;
        return acc;
      }, {} as Record<number, true>);
    },
  );

  const onSelectAll = () => {
    setSelectedPeriods(
      possiblePeriods.reduce((acc, period) => {
        acc[period.period] = true;
        return acc;
      }, {} as Record<number, true>),
    );
  };

  const isSelectedAll = possiblePeriods.every(
    (period) => selectedPeriods[period.period],
  );
  const isSelectedNone = Object.keys(selectedPeriods).length === 0;

  return (
    <Modal testId={'periods'} isOpened={show} onClose={onClose}>
      <Modal.Title>Выбор периодов</Modal.Title>
      <Modal.Body>
        <ModalInfo {...modalInfo} />
        <div className={styles.periodsList}>
          <Checkbox
            onChange={
              !isSelectedAll ? onSelectAll : () => setSelectedPeriods([])
            }
            checked={isSelectedAll}
            indeterminate={!isSelectedAll && !isSelectedNone}
            label="Выбрать все"
            alignCenter
          />
          {possiblePeriods?.map((period, index) => (
            <Checkbox
              key={`period_${period.period}`}
              onChange={() => {
                if (selectedPeriods[period.period]) {
                  const newSelectedPeriods = { ...selectedPeriods };
                  delete newSelectedPeriods[period.period];
                  setSelectedPeriods(newSelectedPeriods);
                } else {
                  setSelectedPeriods({
                    ...selectedPeriods,
                    [period.period]: true,
                  });
                }
              }}
              checked={selectedPeriods[period.period]}
              label={period.name}
              name={`period_${period.period}`}
              alignCenter
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Spacer />
        <Button
          onClick={() => {
            onSubmit?.(
              Object.keys(selectedPeriods).map((period) => Number(period)),
            );
            onClose();
          }}
          disabled={!Object.keys(selectedPeriods).length}
          testId={'confirm-periods'}
        >
          Подтвердить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
