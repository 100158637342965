import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
import ReactDOM from 'react-dom';

import styles from './PageHeader.module.scss';
import { useIsFullscreen } from '../PageContent/useIsFullscreen';
import { Link, useLocation } from 'react-router-dom';
import { useBoundingclientrectRef, useOutsideClickRef } from 'rooks';
import { PAGES_LINKS } from '../../SupportAdmin/SupportAdminEditor';
import { useSupportPage } from '../../../shared/queries/support';
import { Tooltip } from '../Tooltip/Tooltip';

type TPageHeaderProps = {
  title: string;
  supportKey?: keyof typeof PAGES_LINKS;
  documentTitle?: string;
  noBackground?: boolean;
  rightTitleContent?: React.ReactNode;
  leftTitleContent?: React.ReactNode;
  secondLine?: React.ReactNode;
  align?: 'left' | 'right';
  withGap?: boolean;
  onTitleEdit?: (newTitle: string) => void;
  titleOptions?: {
    name: string;
    hint?: string;
    href: string;
  }[];
  onBackClick?: () => void;
  backButton?: string;
};

const TITLE_PREFIX = 'RIDA';

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `${TITLE_PREFIX} - ${title}`;
    return () => {
      document.title = TITLE_PREFIX;
    };
  }, [title]);
};

export const PageHeader: React.FC<
  React.PropsWithChildren<TPageHeaderProps>
> = ({
  title,
  rightTitleContent,
  leftTitleContent,
  secondLine,
  noBackground,
  children,
  documentTitle,
  align = 'right',
  withGap,
  onTitleEdit,
  titleOptions,
  onBackClick,
  backButton,
  supportKey,
}) => {
  const [isFullscreen] = useIsFullscreen();
  useDocumentTitle(documentTitle || title);

  const supportPage = useSupportPage(supportKey);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const location = useLocation();
  const [titleRef, titleRect] = useBoundingclientrectRef();
  const [optionsRef] = useOutsideClickRef((e) => {
    e.stopImmediatePropagation();
    setIsOptionsVisible(false);
  });
  const hasOptions = !!titleOptions?.length;

  return (
    <div
      className={classNames(styles.header, {
        [styles.noBackground]: noBackground,
        [styles.isFullscreen]: isFullscreen,
        [styles.withGap]: withGap,
      })}
    >
      {hasOptions &&
        isOptionsVisible &&
        ReactDOM.createPortal(
          <div
            ref={optionsRef}
            className={styles.optionsList}
            style={{
              transform: `translate(calc(${titleRect?.left}px), ${titleRect?.bottom}px)`,
            }}
          >
            {titleOptions?.map(({ name, href, hint }) => (
              <Link
                key={href}
                to={href}
                className={classNames(styles.option, {
                  [styles.active]: location.pathname === href,
                })}
                onClick={() => setIsOptionsVisible(false)}
              >
                {name}
                {hint && <div className={styles.hint}>{hint}</div>}
              </Link>
            ))}
          </div>,
          document.body,
        )}
      <div className={styles.titleLine}>
        <div className={styles.titlePlaceholder}></div>
        <div
          className={classNames(styles.title, {
            [styles.editable]: !!onTitleEdit,
            [styles.withOptions]: hasOptions,
            [styles.optionsVisible]: isOptionsVisible,
          })}
          contentEditable={!!onTitleEdit}
          onClick={() => {
            if (hasOptions) {
              setIsOptionsVisible(!isOptionsVisible);
            }
          }}
          suppressContentEditableWarning
          onBlur={(e) => {
            if (onTitleEdit) {
              onTitleEdit(e.target.textContent || title);
              e.target.innerText = e.target.textContent || title;
            }
          }}
          onKeyUp={(e) => {
            const element = e.target as HTMLElement;
            if (e.key === 'Enter') {
              e.preventDefault();
              element.blur();
            }
          }}
          ref={titleRef}
        >
          {!!onBackClick && (
            <>
              <div
                className={styles.back}
                onClick={onBackClick}
                data-test-id="header-back-button"
              >
                <Icon
                  name="collapse"
                  size={18}
                  className={styles.backIcon}
                  clickable
                />
                <div className={styles.backText}>{backButton}</div>
              </div>
            </>
          )}
          <span>{title}</span>
          {!!supportPage && (
            <Tooltip
              testId={'open-support-page'}
              text="Перейти в инструкцию"
              placement="left"
            >
              <a
                href={`/support/${supportPage.guid}`}
                target="_blank"
                rel="noreferrer"
              >
                <Icon size={24} name="info" clickable />
              </a>
            </Tooltip>
          )}
          {hasOptions && (
            <Icon
              size={32}
              className={styles.expandIcon}
              name={isOptionsVisible ? 'collapse' : 'expand'}
            />
          )}
          {leftTitleContent && <span>{leftTitleContent}</span>}
        </div>
        {rightTitleContent && (
          <div className={styles.rightTitleContent}>{rightTitleContent}</div>
        )}
      </div>
      {children && (
        <div
          className={classNames(styles.content, {
            [styles.alignLeft]: align === 'left',
            [styles.alignRight]: align === 'right',
          })}
        >
          {children}
        </div>
      )}
      {secondLine && <div>{secondLine}</div>}
    </div>
  );
};
