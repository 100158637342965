import React, { useRef } from 'react';

export const useRefMemo = <TValue>(
  value: TValue,
): React.MutableRefObject<TValue> => {
  const ref = useRef<TValue>(value);

  ref.current = value;

  return ref;
};
