import React, { useEffect, useMemo, useState } from 'react';
import { ViewFeature } from '../reportConfigType';
import {
  ICONS,
  TLineSelectProps,
  TOptionsList,
} from '../../../../../common/LineSelect/LineSelect';
import ReactDOM from 'react-dom';
import {
  COLORS,
  getLinesConfig,
  LINES_TOP_OPTIONS,
} from '../../../../../../shared/chartsConfigs/lines';
import { Chart } from '../../../../../common/Chart/Chart';
import { Checkbox } from '../../../../../../uikit/Checkbox/Checkbox';
import { getByIndexFromArray } from '../../../../../../shared/utils/getRandomFromArray';
import { Legend } from '../../../../../common/Legend/Legend';
import { useBreakpoint } from '../../../../../../uikit/hooks/useBreakpoint';
import { useLineSelect } from '../../../../../../shared/hooks/useLineSelect';

import styles from './linesChart.module.scss';
import { useThreadMemo } from '../../../../../../shared/hooks/useThreadMemo';
import { Loader } from '../../../../../common/Loader/Loader';
import { ManipulateType } from 'dayjs';
import EChartsReact from 'echarts-for-react';
import { DEFAULT_ECHARTS_PROPS } from '../../../../../../shared/charts';

type TLinesChartFeatureConfig<TDataItem> = {
  nameKey: keyof TDataItem;
  innKey?: keyof TDataItem;
  filename: string;
  valuesPrefix: string;
  groupKeyToNameMap?: Partial<Record<keyof TDataItem, keyof TDataItem>>;
  icon?: keyof typeof ICONS;
  dateFormat?: string;
};

const TOP_SELECT_PROPS: Partial<TLineSelectProps<any>> = {
  mobileIcon: 'layersIcon',
};

export const getLinesChartFeature = <TDataItem extends Record<string, any>>(
  config: TLinesChartFeatureConfig<TDataItem>,
): ViewFeature<TDataItem> => {
  const groupKeyToNameMap =
    config.groupKeyToNameMap ||
    ({} as Record<keyof TDataItem, keyof TDataItem>);

  const ChartComponent: ViewFeature<TDataItem>['component'] = ({
    data,
    dataFeatures,
    headerElement,
    filterState,
  }) => {
    const breakpoints = useBreakpoint();
    const gridTopOptions: TOptionsList<any> = useMemo(() => {
      return [
        {
          value: 'all',
          label: 'Все',
        },
        ...LINES_TOP_OPTIONS.map((item) => ({
          value: `${item}`,
          label: breakpoints.isSmall ? `${item}` : `Топ-${item}`,
        })),
      ];
    }, [breakpoints.isSmall]);

    const [topState, , topStateSelect] = useLineSelect(
      'top',
      'all',
      gridTopOptions,
      TOP_SELECT_PROPS,
    );

    const groupKey =
      dataFeatures.find((feature) => feature.key === 'groupBy')?.state ||
      config.nameKey;

    const datePeriodDiff =
      filterState?.interval?.datasetKey === 'w' ? 'week' : 'month';

    const nameKey = groupKeyToNameMap[groupKey as keyof TDataItem] || groupKey;

    const [visibleInns, setVisibleInns] = useState<Record<string, true>>({});

    useEffect(() => {
      if (!data) {
        setVisibleInns({});
        return;
      }
      const inns = new Set<string>();
      data.forEach((item: any) => {
        inns.add(item[groupKey]);
      });
      const selectedInns: Record<string, true> = {
        other: true,
      };
      [...inns].forEach((inn: string) => {
        selectedInns[inn] = true;
      });
      setVisibleInns(selectedInns);
    }, [data, topState, groupKey]);

    const [chartData, isChartDataReady, isChartDataInitial] = useThreadMemo(
      (
        data: TDataItem[],
        groupKey: keyof TDataItem,
        nameKey: keyof TDataItem,
        valuesPrefix: string,
        visibleInns: Record<string, true>,
        dateFormat: string,
        datePeriodDiff: ManipulateType,
        topState: string,
      ) => {
        const LIMIT = 300;

        if (!data) {
          return [];
        }

        // eslint-disable-next-line no-undef
        const dayjs = (globalThis as any).dayjs;

        const getDate = (key: string, prefix: string) => {
          return key.replace(prefix, '').split('_')[0];
        };

        type Item = any;

        const inns: Record<
          string,
          {
            name: string;
            data: Record<string, Item>;
            inn: string;
            lastSum: number;
          }
        > = {};

        data.forEach((item: Item) => {
          if (!inns[item[groupKey]]) {
            inns[item[groupKey]] = {
              inn: item[groupKey],
              // @ts-ignore
              name: item[nameKey],
              data: [],
              lastSum: 0,
            };
          }
          inns[item[groupKey]].data.push(item);
          const dataColumns = Object.keys(item).filter((key) =>
            key.startsWith(valuesPrefix),
          );
          const lastKey = dataColumns[dataColumns.length - 1];
          inns[item[groupKey]].lastSum = item[lastKey];
        });

        const innsSorted = Object.values(inns).sort(
          (a, b) => b.lastSum - a.lastSum,
        );

        const innsSliced = innsSorted.slice(
          0,
          topState === 'all' ? undefined : +topState,
        );

        const result = innsSliced.map((innValue) => {
          return {
            name: innValue.name,
            inn: innValue.inn,
            visible: !!visibleInns[innValue.inn],
            values: Object.values(innValue.data)
              .map((item) => {
                const dataColumns = Object.keys(item).filter((key) =>
                  key.startsWith(valuesPrefix),
                );
                return dataColumns
                  .sort((a, b) => {
                    const dateA = getDate(a, valuesPrefix);
                    const dateB = getDate(b, valuesPrefix);
                    return dayjs(dateA, dateFormat).diff(
                      dayjs(dateB, dateFormat),
                    );
                  })
                  .map((key) => {
                    const date = getDate(key, valuesPrefix);
                    return {
                      y: item[key],
                      x: `${dayjs(date, dateFormat).format(
                        'DD.MM.YYYY',
                      )} - ${dayjs(date, dateFormat)
                        .add(1, datePeriodDiff)
                        .subtract(1, 'day')
                        .format('DD.MM.YYYY')}`,
                    };
                  });
              })
              .flat(),
          };
        });

        if (result.length > LIMIT) {
          const resultFirstPart = result.slice(0, LIMIT);
          const resultSecondPart = result.slice(LIMIT);

          const summarySecondPartResult = resultSecondPart
            .reduce(
              (acc, item) => {
                item.values.forEach((value, index) => {
                  if (!acc[index]) {
                    acc[index] = {
                      x: value.x,
                      y: 0,
                    };
                  }

                  acc[index].y += value.y;
                });

                return acc;
              },
              [] as { x: string; y: number }[],
            )
            .map((item) => {
              return {
                x: item.x,
                y: Math.round(item.y * 100) / 100 / resultSecondPart.length,
              };
            });

          resultFirstPart.push({
            name: 'Другие',
            inn: 'other',
            visible: !!visibleInns.other,
            values: summarySecondPartResult,
          });

          return resultFirstPart;
        }

        return result;
      },
      [],
      [data, groupKey, nameKey, topState, visibleInns],
      [
        data,
        groupKey,
        nameKey,
        config.valuesPrefix,
        visibleInns,
        config.dateFormat,
        datePeriodDiff,
        topState,
      ],
      ['dayjs.min.js'],
    );

    const chartConfig = useMemo(
      () => getLinesConfig(chartData, null),
      [chartData],
    );

    const eChartsInstance = React.useRef<any>(null);
    const innHoverTimeout = React.useRef<any>(null);

    return (
      <>
        <div className={styles.container}>
          <Chart
            testId={`lines-chart`}
            fullHeight
            key={`lines-chart-${groupKey.toString()}`}
            withLegend={isChartDataReady}
            name="Линейный график"
          >
            {!isChartDataReady && <Loader fullScreen />}
            {isChartDataReady && (
              <EChartsReact
                {...DEFAULT_ECHARTS_PROPS}
                key={`${topState}`}
                onChartReady={(instance) => {
                  eChartsInstance.current = instance;
                }}
                option={chartConfig}
              />
            )}
          </Chart>
          {!isChartDataInitial && (
            <Legend>
              <Legend.Item>
                <Checkbox
                  checked={chartData.every((item) => visibleInns[item.inn])}
                  onChange={(checked) => {
                    const newVisibleInns: Record<string, true> = {};
                    if (checked) {
                      chartData.forEach((item) => {
                        newVisibleInns[item.inn] = true;
                      });
                    }
                    setVisibleInns(newVisibleInns);
                  }}
                  label="Выбрать все"
                  color="#000"
                />
              </Legend.Item>
              {chartData.map((item, index) => {
                const color = getByIndexFromArray(COLORS, index);
                return (
                  <Legend.Item
                    key={item.inn}
                    onMouseOver={(e) => {
                      if (visibleInns[item.inn]) {
                        clearTimeout(innHoverTimeout.current);
                        innHoverTimeout.current = setTimeout(() => {
                          const newConfig = getLinesConfig(chartData, item.inn);
                          eChartsInstance.current?.setOption(newConfig);
                        }, 500);
                      }

                      (
                        e.currentTarget as HTMLDivElement
                      ).style.backgroundColor = `${color}30`;
                    }}
                    onMouseOut={(e) => {
                      clearTimeout(innHoverTimeout.current);
                      const newConfig = getLinesConfig(chartData, null);
                      eChartsInstance.current?.setOption(newConfig);

                      (
                        e.currentTarget as HTMLDivElement
                      ).style.backgroundColor = 'transparent';
                    }}
                  >
                    <Checkbox
                      checked={visibleInns[item.inn]}
                      onChange={(checked) => {
                        if (checked) {
                          setVisibleInns({
                            ...visibleInns,
                            [item.inn]: true,
                          });
                        } else {
                          const newVisibleInns = { ...visibleInns };
                          delete newVisibleInns[item.inn];
                          setVisibleInns(newVisibleInns);
                        }
                      }}
                      label={
                        groupKey === config.innKey
                          ? `${item.inn !== 'other' ? item.inn : ''} ${
                              item.name
                            }`
                          : item.name
                      }
                      color={color}
                    />
                  </Legend.Item>
                );
              })}
            </Legend>
          )}
        </div>
        {headerElement &&
          ReactDOM.createPortal(<>{topStateSelect}</>, headerElement)}
      </>
    );
  };

  return {
    key: 'liesChart',
    title: 'График',
    hint: 'Линейный график',
    icon: config.icon || 'barChart',
    component: ChartComponent,
    defaultState: LINES_TOP_OPTIONS[0].toString(),
  };
};
