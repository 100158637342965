import { useIsFetching, useQuery } from '@tanstack/react-query';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { getReportApi } from '../../../../../api/reports/getReportApi';
import { useLineSelect } from '../../../../../shared/hooks/useLineSelect';
import { getReportsTitleOptions } from '../../../../../shared/utils/getReportsTitleOptions';
import { PageContent } from '../../../../common/PageContent/PageContent';
import { PageHeader } from '../../../../common/PageHeader/PageHeader';
import {
  PageWithFilter,
  useFilterState,
} from '../../../../common/PageWithFilter/PageWithFilter';
import { Spacer } from '../../../../common/Spacer/Spacer';
import { TReportConfigType } from './reportConfigType';
import {
  withAvailablePeriod,
  TAvailablePeriodProps,
} from '../../../../../shared/hocs/withAvailablePeriod';
import {
  withRestriction,
  withUserFlagRestriction,
} from '../../../../../shared/hocs/withRestriction';
import {
  TDictionariesData,
  useDictionariesData,
} from '../../../../../shared/hooks/useDictionariesData';
import { DictionariesType } from '../../../../../shared/constants';
import { FullscreenButton } from '../../../../common/PageContent/components/FullscreenButton/FullscreenButton';

import styles from './AllPurposeReport.module.scss';
import { Icon } from '../../../../common/Icon/Icon';
import { Tooltip } from '../../../../common/Tooltip/Tooltip';
import { useUsageCount } from '../../../../../shared/hooks/useUsageCount';
import { withCommonData } from '../../../../../shared/hocs/withCommonData';
import { deepMemo, useDeepMemo } from '../../../../../shared/hooks/deepMemo';
import { ViewSelect } from './ViewSelect';
import { useBreakpoint } from '../../../../../uikit/hooks/useBreakpoint';
import { TLineSelectProps } from '../../../../common/LineSelect/LineSelect';
import { FavoriteButton } from '../../../../common/FavoriteButton/FavoriteButton';
import { REPORTS } from '../../constants';
import { DownloadReport } from '../../../../common/Table/components/DownloadReport/DownloadReport';
import { useAuthorizedUser } from '../../../../../shared/hooks/useUser';
import { useSupportPages } from '../../../../../shared/queries/support';
import { Loading } from './Loading';
import { useReportQueue } from '../../../../ReportQueue/useReportQueue';

declare global {
  interface Window {
    lastFilterState: Record<string, any>;
  }
}

const LINE_SELECT_PROPS: Partial<TLineSelectProps<any>> = {
  mobileIcon: 'dataTable',
};

const reportKeyRegex = /ds(\d+)r(\d+)/;

export const getAllPurposeReport = <TDataItem extends Record<string, any>>(
  config: TReportConfigType<TDataItem>,
): React.FC<{}> => {
  const reportKeyMatch = config.backendKey.match(reportKeyRegex);
  let reportSectionKey = 0;
  let reportReportKey = 0;
  if (reportKeyMatch) {
    reportSectionKey = +reportKeyMatch[1];
    reportReportKey = +reportKeyMatch[2];
  }

  const reportDescription = REPORTS.find(
    (report) =>
      report.sectionKey === reportSectionKey &&
      report.reportKey === reportReportKey,
  );

  const viewFeatures = config.viewFeatures.map((feature, index) => ({
    ...feature,
    key: `v-${feature.key}`,
    component: deepMemo(
      // @ts-ignore
      feature.component,
      feature.key,
    ) as TReportConfigType<TDataItem>['viewFeatures'][number]['component'],
  }));
  const api = getReportApi<TDataItem>(config.backendKey);

  const ReportComponent = withCommonData(
    config.requiredCommonData,
    withUserFlagRestriction(
      'moduleAOrB',
      withRestriction(
        ['CLIENT', 'CLIENT_ADMIN', 'CLIENT_MANAGER'],
        (props: TAvailablePeriodProps) => {
          const { addToQueue } = useReportQueue();

          const listQueryData = useSupportPages();
          const reportSupportKey = `${reportSectionKey}-${reportReportKey}`;
          const supportPage = listQueryData.data?.find(
            (page) => page.report === reportSupportKey,
          );
          const instructionLink = supportPage?.guid;

          const userData = useAuthorizedUser();
          const isLoadingFilters = useIsFetching({
            queryKey: ['filterDataset'],
          });
          const [isInitialFiltersLoading, setIsInitialFiltersLoading] =
            useState(!!config.autoApplyOnFilterReady);
          const isInitialLoading = useRef(true);

          useEffect(() => {
            if (
              isInitialLoading.current &&
              isLoadingFilters === 0 &&
              config.autoApplyOnFilterReady
            ) {
              const timeout = setTimeout(() => {
                isInitialLoading.current = false;
                const applyFilterButton = document.querySelector(
                  '[data-test-id="button_apply-filter-button"]:not([disabled])',
                );
                if (applyFilterButton) {
                  (applyFilterButton as HTMLButtonElement).click();
                }
                setIsInitialFiltersLoading(false);
              }, 100);

              return () => {
                clearTimeout(timeout);
              };
            }
          }, [isLoadingFilters]);

          const viewSelectElementsTargetRef =
            React.useRef<HTMLDivElement>(null);
          const headerRefs = React.useRef<HTMLDivElement[]>([]);
          useUsageCount(config.backendKey);

          let availablePeriod: { from: Date; to: Date } | undefined = undefined;
          let innDictionaries: TDictionariesData | undefined = undefined;
          let gtinDictionaries: TDictionariesData | undefined = undefined;
          let addressDictionaries: TDictionariesData | undefined = undefined;

          if (config.requiredData?.availablePeriod) {
            availablePeriod = props.availablePeriod;
          }

          if (config.requiredData?.innDictionaries) {
            const innDictionariesQueryResult = useDictionariesData(
              DictionariesType.inn,
            );
            innDictionaries = innDictionariesQueryResult.data;
          }

          if (config.requiredData?.gtinDictionaries) {
            const gtinDictionariesQueryResult = useDictionariesData(
              DictionariesType.gtin,
            );
            gtinDictionaries = gtinDictionariesQueryResult.data;
          }

          if (config.requiredData?.addressDictionaries) {
            const addressDictionariesQueryResult = useDictionariesData(
              DictionariesType.address,
            );
            addressDictionaries = addressDictionariesQueryResult.data;
          }

          const filtersFeatures = config.filters.map((filterCreator) =>
            filterCreator({
              availablePeriod,
              innDictionaries,
              addressDictionaries,
              gtinDictionaries,
              reportApi: api,
              reportKey: config.backendKey,
            }),
          );

          const filters = filtersFeatures.map((feature) => feature.filter);

          const filtersPreparers = filtersFeatures.map(
            (feature) => feature.preparer,
          );

          const [filterState] = useFilterState(filters);
          useEffect(() => {
            window.lastFilterState = filterState;
          }, [filterState]);

          const queryKey = [
            'report',
            config.backendKey,
            ...filters
              .map((filter) => {
                if (filter.getQueryKeyPart) {
                  return filter.getQueryKeyPart(filterState);
                }
                return [];
              })
              .flat(),
          ];

          const queryKeyString = JSON.stringify(queryKey);

          const [filter, areRequiredFiltersFilled] = useMemo(() => {
            let filter = {};
            filtersPreparers.forEach((preparer) => {
              filter = {
                ...filter,
                ...preparer(filterState),
              };
            });
            return [filter, config.isRequiredFieldsFilled(filter)];
          }, [filterState, filtersPreparers]);

          const queryFunction = ({ queryKey }: { queryKey: string[] }) => {
            if (!areRequiredFiltersFilled) {
              return [];
            }
            return api.fetchReportInfo(filter, config.api, queryKey);
          };
          const dataQueryResult = useQuery(queryKey, queryFunction);

          useEffect(() => {
            if (!areRequiredFiltersFilled) {
              return;
            }
            const currentPathName = window.location.pathname;
            const currentQueryKey = window.location.search;
            if (
              currentPathName !==
              `/reports/report-ds${reportSectionKey}-r${reportReportKey}`
            ) {
              return;
            }

            const meta = [];

            for (const filter of filtersFeatures) {
              const descriptionPreparer = filter.descriptionPreparer;
              if (descriptionPreparer) {
                meta.push(...descriptionPreparer(filterState));
              }
            }

            addToQueue({
              meta: meta.filter(Boolean),
              queryKey: queryKeyString,
              queryFn: queryFunction,
              url: `${currentPathName}${currentQueryKey}`,
              title: config.title,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [addToQueue, queryKeyString, areRequiredFiltersFilled]);

          const dataFeatureStates = config.dataFeatures.map((feature) => {
            const lineSelectOptions = feature.getLineSelectOptions({
              filterState,
            });
            return useLineSelect(
              feature.key,
              lineSelectOptions[0]?.value,
              lineSelectOptions,
              LINE_SELECT_PROPS,
            );
          });

          const dataFeatureStatesMemoized = useDeepMemo(
            dataFeatureStates,
            'report.dataFeatureStates',
          );

          const [data, rawData] = useMemo(() => {
            let rawData = dataQueryResult.data;
            if (!rawData) {
              return [[], []];
            }
            let data = rawData;
            const prepareData = config.prepareData;
            if (prepareData) {
              data = prepareData(data);
            }
            const prepareDataItem = config.prepareDataItem;
            if (prepareDataItem) {
              data = data.map((item, index) =>
                prepareDataItem(item, index, data),
              );
            }
            rawData = data;
            config.dataFeatures.forEach((feature, index) => {
              data = feature.tableDataMiddleware(
                data,
                dataFeatureStatesMemoized[index][0],
                dataFeatureStatesMemoized[index][1],
                rawData || [],
              );
            });
            return [data, rawData];
          }, [dataFeatureStatesMemoized, dataQueryResult.data]);

          const dataMemoized = useDeepMemo(data, 'report.data');

          const breakpoints = useBreakpoint();

          return (
            <PageWithFilter
              filters={filters}
              forceExpanded={dataQueryResult.isFetching || data?.length === 0}
            >
              <PageHeader
                title={config.title}
                titleOptions={getReportsTitleOptions(userData)}
                rightTitleContent={
                  !breakpoints.isSmall && (
                    <div className={styles.rightTitleRow}>
                      <FavoriteButton
                        reportKey={config.backendKey}
                        key={config.backendKey}
                        tooltipPlacement="left"
                      />
                      {instructionLink && (
                        <Tooltip
                          testId={'open-support-page'}
                          text={
                            `${reportDescription?.description}\n\nКликните, чтобы открыть инструкцию` ||
                            'Перейти в инструкцию'
                          }
                          placement="left"
                        >
                          <a
                            href={`/support/${instructionLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon size={24} name="info" clickable />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  )
                }
                noBackground
              >
                {dataFeatureStatesMemoized.map((state, index) => (
                  <Fragment key={index}>{state[2]}</Fragment>
                ))}
                <Spacer />
                <div
                  className={styles.viewSelectElements}
                  ref={viewSelectElementsTargetRef}
                />
                <DownloadReport filename={config.title} />
                <FullscreenButton />
              </PageHeader>
              <PageContent
                LoadingComponent={Loading}
                forceLoading={isInitialFiltersLoading}
                notFound={data?.length === 0}
                queryData={dataQueryResult}
                canMakeScreenshot
                queryKeyForPercent={queryKeyString}
                notFilledFilters={!areRequiredFiltersFilled}
              >
                <ViewSelect
                  dataMemoized={dataMemoized}
                  headerElementTarget={viewSelectElementsTargetRef.current}
                  // @ts-ignore
                  viewFeatures={viewFeatures}
                  headerRefs={headerRefs}
                  viewComponentsProps={
                    {
                      data,
                      dataUpdatedAt: dataQueryResult.dataUpdatedAt,
                      rawData: rawData || [],
                      dataFeatures: config.dataFeatures.map(
                        (feature, index) => ({
                          ...feature,
                          state: dataFeatureStatesMemoized[index][0],
                          setState: dataFeatureStatesMemoized[index][1],
                        }),
                      ),
                      filterState: filterState,
                    } as any
                  }
                />
              </PageContent>
            </PageWithFilter>
          );
        },
      ),
    ),
  );

  if (config.requiredData?.availablePeriod) {
    return withAvailablePeriod(config.backendKey, ReportComponent);
  }

  return ReportComponent;
};
