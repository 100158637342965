import React, { useEffect, useMemo, useState } from 'react';
import { ViewFeature } from '../reportConfigType';
import { ICONS } from '../../../../../common/LineSelect/LineSelect';
import ReactDOM from 'react-dom';
import { Switcher } from '../../../../../common/Chart/components/Switcher/Switcher';
import { usePreviousImmediate } from 'rooks';
import isEqual from 'lodash/isEqual';
import { formatNumericNames } from '../../../../../../shared/utils/formatNumericNames';
import { Chart } from '../../../../../common/Chart/Chart';
import EChartsReact from 'echarts-for-react';
import {
  DEFAULT_CATEGORY_AXIS,
  DEFAULT_CONFIG,
  DEFAULT_ECHARTS_PROPS,
  DEFAULT_GRID,
  DEFAULT_VALUE_AXIS,
  MOBILE_GRID,
} from '../../../../../../shared/charts';
import { COLORS } from '../../../../../../shared/chartsConfigs/lines';
import dayjs from 'dayjs';
import { isSmall } from '../../../../../../uikit/hooks/useBreakpoint';

type TTwoLinesChartFeatureConfig<TDataItem> = {
  measures: Array<{
    valueKey: keyof TDataItem & string;
    label: string;
  }>;
  selectByKey: keyof TDataItem & string;
  nameByKey: keyof TDataItem & string;
  filename: string;
  groupKeyToNameMap?: Partial<Record<keyof TDataItem, keyof TDataItem>>;
  icon?: keyof typeof ICONS;
  valuesPrefix: string;
  dateFormat?: string;
};

const getDate = (key: string, prefix: string) => {
  return key.replace(prefix, '').split('_')[0];
};

export const getTwoLinesChartFeature = <TDataItem extends Record<string, any>>(
  config: TTwoLinesChartFeatureConfig<TDataItem>,
): ViewFeature<TDataItem> => {
  const ChartComponent: ViewFeature<TDataItem>['component'] = ({
    data,
    filterState,
    headerElement,
  }) => {
    const datePeriodDiff =
      filterState?.interval?.datasetKey === 'w' ? 'week' : 'month';

    const regionsList = useMemo(() => {
      if (!data) {
        return [];
      }
      const regions = [
        ...new Set(
          data
            .sort((a, b) => {
              const aDataColumns = Object.keys(a).filter((key) =>
                key.startsWith(config.valuesPrefix),
              );
              const bDataColumns = Object.keys(b).filter((key) =>
                key.startsWith(config.valuesPrefix),
              );
              const aLastKey = aDataColumns[aDataColumns.length - 1];
              const bLastKey = bDataColumns[bDataColumns.length - 1];
              if (!a[aLastKey] || !b[bLastKey]) {
                return 0;
              }
              // @ts-ignore
              return b[aLastKey] - a[bLastKey];
            })
            .map((item) => item[config.nameByKey]),
        ),
      ];
      return regions;
    }, [data]);
    const [currentRegionIndex, setCurrentRegionIndex] = useState(0);
    const prevData = usePreviousImmediate(data);
    useEffect(() => {
      if (isEqual(prevData, data)) {
        return;
      }
      if (regionsList.length) {
        setCurrentRegionIndex(0);
      }
    }, [data, prevData, regionsList]);

    const currentRegion = regionsList[currentRegionIndex];
    const currentRegionRows = useMemo(() => {
      if (!currentRegion) {
        return [];
      }
      return data.filter((item) => item[config.nameByKey] === currentRegion);
    }, [currentRegion, data]);

    const measuresWithData = useMemo(() => {
      return config.measures
        .map((measure) => {
          const data = currentRegionRows.find(
            (item) => item['transpose-key'] === measure.valueKey,
          );
          if (!data) {
            return false;
          }
          const dataColumns = Object.keys(data).filter((key) =>
            key.startsWith(config.valuesPrefix),
          );
          return {
            ...measure,
            data: dataColumns.map((key) => {
              const date = getDate(key, config.valuesPrefix);
              return {
                key: `${dayjs(date, config.dateFormat).format(
                  'DD.MM.YYYY',
                )} - ${dayjs(date, config.dateFormat)
                  .add(1, datePeriodDiff)
                  .subtract(1, 'day')
                  .format('DD.MM.YYYY')}`,
                value: data[key],
              };
            }),
          };
        })
        .filter(Boolean) as Array<{
        valueKey: keyof TDataItem & string;
        label: string;
        data: Array<{
          key: string;
          value: number;
        }>;
      }>;
    }, [currentRegionRows]);
    const eChartsInstance = React.useRef<any>(null);

    return (
      <>
        <div>
          <Chart fullHeight testId="two-lines-chart" name="Линейный график">
            {currentRegion && (
              <EChartsReact
                {...DEFAULT_ECHARTS_PROPS}
                onChartReady={(instance) => {
                  eChartsInstance.current = instance;
                }}
                option={{
                  ...DEFAULT_CONFIG,
                  grid: isSmall() ? MOBILE_GRID : DEFAULT_GRID,
                  xAxis: {
                    ...DEFAULT_CATEGORY_AXIS,
                    axisLabel: {
                      ...DEFAULT_CATEGORY_AXIS.axisLabel,
                      rotate: 0,
                    },
                    data: measuresWithData[0].data.map((item) => item.key),
                  },
                  yAxis: measuresWithData.map((measure) => ({
                    ...DEFAULT_VALUE_AXIS,
                    name: measure.label,
                    axisLabel: {
                      formatter: function (value: number) {
                        return formatNumericNames(value);
                      },
                    },
                    boundaryGap: ['20%', '20%'],
                  })),
                  series: measuresWithData.map((measure, index) => ({
                    type: 'line',
                    name: measure.label,
                    data: measure.data.map((item) => item.value),
                    yAxisIndex: index,
                    lineStyle: {
                      color: COLORS[index],
                    },
                    itemStyle: {
                      color: COLORS[index],
                    },
                  })),
                }}
              />
            )}
          </Chart>
        </div>
        {headerElement &&
          ReactDOM.createPortal(
            <>
              <Switcher
                testId="region"
                value={currentRegion || ''}
                onChange={(indexDiff) => {
                  setCurrentRegionIndex((prevIndex) => {
                    const newIndex = prevIndex + indexDiff;
                    if (newIndex < 0) {
                      return regionsList.length - 1;
                    }
                    if (newIndex >= regionsList.length) {
                      return 0;
                    }
                    return newIndex;
                  });
                }}
              />
            </>,
            headerElement,
          )}
      </>
    );
  };

  return {
    key: 'twoLinesChart',
    title: 'Линейный график',
    hint: 'Линейный график',
    icon: config.icon || 'barChartWithLine',
    component: ChartComponent,
  };
};
