import { getMaxOfArray } from '../../../shared/utils/getMaxOfArray';

type TRegionRect = {
  code: number;
  label: string;
  x: number;
  y: number;
};

export const REGIONS_LIST: TRegionRect[] = [
  { code: 39, label: 'Калин', y: 1, x: 1 },
  { code: 78, label: 'СПБ', y: 1, x: 3 },
  { code: 10, label: 'Карелия', y: 1, x: 4 },
  { code: 51, label: 'Мурман', y: 1, x: 5 },
  { code: 60, label: 'Псков', y: 2, x: 1 },
  { code: 53, label: 'Новгор', y: 2, x: 2 },
  { code: 47, label: 'Ленин', y: 2, x: 3 },
  { code: 29, label: 'Арханг', y: 3, x: 6 },
  { code: 35, label: 'Волог', y: 3, x: 5 },
  { code: 83, label: 'Ненецк', y: 3, x: 10 },
  { code: 67, label: 'Смолен', y: 3, x: 1 },
  { code: 69, label: 'Тверск', y: 3, x: 2 },
  { code: 50, label: 'МО', y: 3, x: 3 },
  { code: 76, label: 'Ярослав', y: 2, x: 4 },
  { code: 44, label: 'Костром', y: 3, x: 4 },
  { code: 11, label: 'Коми', y: 3, x: 9 },
  { code: 87, label: 'Чукотка', y: 3, x: 16 },
  { code: 32, label: 'Брянск', y: 4, x: 1 },
  { code: 40, label: 'Калуж', y: 4, x: 2 },
  { code: 77, label: 'Москва', y: 4, x: 3 },
  { code: 33, label: 'Владим', y: 4, x: 4 },
  { code: 37, label: 'Иванов', y: 4, x: 5 },
  { code: 43, label: 'Киров', y: 3, x: 8 },
  { code: 21, label: 'Чуваш', y: 4, x: 7 },
  { code: 12, label: 'МарЭ', y: 4, x: 8 },
  { code: 18, label: 'Удму', y: 4, x: 9 },
  { code: 89, label: 'ЯНАО', y: 3, x: 11 },
  { code: 24, label: 'Красноя', y: 4, x: 14 },
  { code: 14, label: 'Якутия', y: 3, x: 15 },
  { code: 49, label: 'Магадан', y: 3, x: 17 },
  { code: 41, label: 'Камчат', y: 4, x: 17 },
  { code: 46, label: 'Курск', y: 5, x: 2 },
  { code: 57, label: 'Орлов', y: 5, x: 3 },
  { code: 71, label: 'Тульск', y: 5, x: 4 },
  { code: 62, label: 'Рязан', y: 5, x: 5 },
  { code: 52, label: 'Нижег', y: 5, x: 6 },
  { code: 13, label: 'Морд', y: 4, x: 6 },
  { code: 16, label: 'Татар', y: 5, x: 8 },
  { code: 2, label: 'Башк', y: 5, x: 9 },
  { code: 59, label: 'Пермь', y: 4, x: 10 },
  { code: 66, label: 'Сверд', y: 5, x: 10 },
  { code: 86, label: 'ХМАО', y: 4, x: 11 },
  { code: 70, label: 'Томск', y: 5, x: 12 },
  { code: 42, label: 'Кемеров', y: 5, x: 13 },
  { code: 38, label: 'Иркутс', y: 5, x: 14 },
  { code: 27, label: 'Хабар', y: 4, x: 16 },
  { code: 65, label: 'Сахалин', y: 5, x: 17 },
  { code: 31, label: 'Белгор', y: 6, x: 1 },
  { code: 48, label: 'Липец', y: 6, x: 3 },
  { code: 36, label: 'Ворон', y: 6, x: 2 },
  { code: 68, label: 'Тамбов', y: 6, x: 4 },
  { code: 58, label: 'Пенз', y: 6, x: 5 },
  { code: 73, label: 'Ульян', y: 6, x: 6 },
  { code: 63, label: 'Самар', y: 5, x: 7 },
  { code: 56, label: 'Орен', y: 6, x: 8 },
  { code: 74, label: 'Челяб', y: 6, x: 9 },
  { code: 45, label: 'Курган', y: 6, x: 10 },
  { code: 72, label: 'Тюмень', y: 5, x: 11 },
  { code: 55, label: 'Омск', y: 6, x: 12 },
  { code: 54, label: 'Новосиб', y: 7, x: 12 },
  { code: 19, label: 'Хакасия', y: 6, x: 14 },
  { code: 28, label: 'Амур', y: 4, x: 15 },
  { code: 25, label: 'Примор', y: 5, x: 16 },
  { code: 61, label: 'Ростов', y: 7, x: 3 },
  { code: 34, label: 'Волгог', y: 7, x: 4 },
  { code: 64, label: 'Сарат', y: 7, x: 5 },
  { code: 22, label: 'АлКрай', y: 6, x: 13 },
  { code: 4, label: 'АлРесп', y: 7, x: 13 },
  { code: 17, label: 'Тыва', y: 7, x: 14 },
  { code: 3, label: 'Бурятия', y: 6, x: 15 },
  { code: 75, label: 'Забайк', y: 7, x: 15 },
  { code: 79, label: 'Еврейс', y: 5, x: 15 },
  { code: 92, label: 'Севас', y: 8, x: 1 },
  { code: 91, label: 'Крым', y: 8, x: 2 },
  { code: 23, label: 'Краснод', y: 8, x: 3 },
  { code: 26, label: 'Ставр', y: 8, x: 4 },
  { code: 8, label: 'Калм', y: 8, x: 5 },
  { code: 30, label: 'Астра', y: 8, x: 6 },
  { code: 5, label: 'Дагест', y: 8, x: 7 },
  { code: 1, label: 'Адыгея', y: 9, x: 2 },
  { code: 9, label: 'Крч-ч', y: 9, x: 3 },
  { code: 7, label: 'Кабар', y: 9, x: 4 },
  { code: 15, label: 'Осетия', y: 9, x: 5 },
  { code: 6, label: 'Ингуш', y: 9, x: 6 },
  { code: 20, label: 'Чечня', y: 9, x: 7 },
];

export const REGIONS_ROWS_COUNT = getMaxOfArray(REGIONS_LIST.map((r) => r.y));
export const REGIONS_COLS_COUNT = getMaxOfArray(REGIONS_LIST.map((r) => r.x));
