import { DS2R8Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import {
  PRESET_SHOULD_HIDE_CHECKERS,
  getGroupByFeature,
} from '../AllPurposeReport/dataFeatures/groupBy';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'Ассортимент продаж GTIN по адресам';

type DS2R8ResponseWithIndex = DS2R8Response & {
  __index: number;
  sharePercent: number;
};

const summsCache = new Map<DS2R8Response[], number>();

export const ds2o8Config: TReportConfigType<DS2R8ResponseWithIndex> = {
  title: NAME,
  backendKey: 'ds2r8',

  prepareDataItem: (item, index, data) => {
    const totalSalesCnt =
      summsCache.get(data) ??
      data.reduce((acc, item) => acc + item.salesCnt, 0);
    summsCache.set(data, totalSalesCnt);
    const sharePercent = (100 * item.salesCnt) / totalSalesCnt;
    return {
      ...item,
      __index: index,
      sharePercent,
    };
  },

  dataFeatures: [
    getGroupByFeature<DS2R8ResponseWithIndex>({
      options: [
        {
          groupBy: '__index',
          label: 'GTIN',
          additionalColumns: [
            {
              key: 'gtin',
              title: 'GTIN',
              sort: sorter('gtin'),
            },
            {
              key: 'gtinName',
              title: 'Наименование товара',
              sort: sorter('gtinName'),
            },
          ],
        },
        {
          groupBy: 'dictName',
          shouldHide: PRESET_SHOULD_HIDE_CHECKERS.notDataset('gtins', ['gtin']),
          label: 'Справочник',
          additionalColumns: [
            {
              key: 'dictName',
              title: 'Справочник',
              sort: sorter('dictName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', 'sharePercent'],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS2R8ResponseWithIndex>({
      defaultSort: 'salesCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'salesCnt',
          title: 'Объем продаж',
          isNumeric: true,
          sort: sorter('salesCnt', true),
        },
      ],
    }),
    getBarChartFeature<DS2R8ResponseWithIndex>({
      nameKey: 'gtinName',
      innKey: 'gtin',
      valueKey: 'salesCnt',
      filename: NAME,
      groupKeyToNameMap: {
        __index: 'gtinName',
      },
      tooltipFormatter: (item) => {
        return `${item.data.fullItem.gtin}<br /><strong>${item.data.fullItem.gtinName}</strong><br /><strong>${item.data.fullItem.salesCnt}</strong>`;
      },
    }),
    getBarChartFeature<DS2R8ResponseWithIndex>({
      nameKey: 'gtinName',
      valueKey: 'salesCnt',
      innKey: 'gtin',
      withPercents: true,
      accumulateBy: 'sharePercent',
      icon: 'barChartWithLine',
      filename: NAME,
      groupKeyToNameMap: {
        __index: 'gtinName',
      },
    }),
    getTreeMapFeature<DS2R8ResponseWithIndex>({
      topName: NAME,
      parentNameKey: '__index',
      childrenNameKey: 'gtin',
      innKey: 'gtin',
      valueKey: 'salesCnt',
      filename: NAME,
      groupKeyToNameMap: {
        __index: 'gtinName',
      },
      countItemsLabel: 'GTIN',
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      label: 'Точка продаж',
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'addresses',
      icon: 'location',
      mode: 'addresses',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'Товар',
      commonDataKey: 'gtins',
      icon: 'package',
      mode: 'gtins',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    addressDictionaries: true,
    innDictionaries: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['gtins', 'addresses', 'retailSellers'],

  isRequiredFieldsFilled: (filter) => !!filter.from && !!filter.to,
};
