import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Main } from './newComponents/Main';
import { isDevHost } from './shared/utils/isDev';
import { queryClient } from './store/query';
import { onlineManager, QueryClientProvider } from '@tanstack/react-query';
import { MonitorModal } from './newComponents/MonitorModal/MonitorModal';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './shared/styles/style.css';
import packageJSON from '../package.json';

import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ru from 'dayjs/locale/ru';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ContentLengthProvider } from './shared/contexts/contentLength';
import { GlobalTestIds } from './newComponents/GlobalTestIds/GlobalTestIds';
import { ReportQueue } from './newComponents/ReportQueue/useReportQueue';
import { MapDev } from './pages/MapDev/MapDev';
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.locale(ru);
dayjs.extend(utc);

window.isOnline = () => {
  return onlineManager.isOnline();
};

const getEnvironment = () => {
  if (isDevHost()) {
    return 'dev-stand';
  }
  return 'production';
};

const environment = getEnvironment();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c705fd672a8144d3abf7e8d4104b4e4b@o4504219591442432.ingest.sentry.io/4504219592884224',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment,
    release: `${environment}@${packageJSON.version}`,
    ignoreErrors: ['Request failed with status code 401'],
  });
}

const pathname = window.location.pathname;
const MAP_DEV_PATHNAME = '/map-dev';

if (pathname === MAP_DEV_PATHNAME) {
  ReactDOM.render(<MapDev />, document.getElementById('root'));
} else {
  ReactDOM.render(
    <ContentLengthProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GlobalTestIds />
          <MonitorModal />
          <ReportQueue.Provider>
            <Main />
          </ReportQueue.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </ContentLengthProvider>,
    document.getElementById('root'),
  );
}
