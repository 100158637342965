import { useQuery } from '@tanstack/react-query';
import {
  ReportsTypes,
  TUploadItem,
} from '../../newComponents/pages/Uploads/constants';
import { formatError } from '../../shared/utils/withErrorHandling';
import { AddReportRequest } from '../backend/Api';
import { $api } from '../utils';

export const fetchInns = async (): Promise<
  Array<{
    inn: string;
    active: boolean;
    name: string;
  }>
> => {
  try {
    const { data } = await $api.get('/inns');

    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

export const fetchUploadList = async ({
  settings,
  page = 0,
}: {
  settings: {
    inn: { inn: string | null };
    status: { type: string };
  };
  page?: number;
}): Promise<Array<TUploadItem>> => {
  try {
    const { data } = await $api.get('/report/list', {
      params: {
        inn: settings.inn.inn ?? undefined,
        statusReport:
          settings.status.type !== 'ALL' ? settings.status.type : undefined,
        sort: 'date',
        direction: 'desc',
        limit: 10,
        page,
      },
    });

    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

type DefaultFieldsType = {
  [key in ReportsTypes]: {
    [key: string]: string;
  };
};

type InnType = {
  inn: string;
  name: string;
};

export type PeriodType = {
  period: number;
  name: string;
};

type PeriodsType = {
  [key in ReportsTypes]: {
    [key: string]: PeriodType[];
  };
};

type ReportConfig = {
  defaultFields: DefaultFieldsType;
  inns: InnType[];
  periods: PeriodsType;
};

const fetchReportsConfig = async (): Promise<ReportConfig> => {
  try {
    const { data } = await $api.get('/report/config');

    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

export const useReportsConfig = () => {
  return useQuery(['uploads', 'config'], fetchReportsConfig, {
    placeholderData: {
      defaultFields: {},
      inns: [],
      periods: {},
    } as unknown as ReportConfig,
  });
};

export const sendUploadRequest = async (values: AddReportRequest) => {
  try {
    await $api.post(`report`, values);
  } catch (error) {
    throw new Error(formatError(error));
  }
};
