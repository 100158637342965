import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useDataShared = (): [any[], (newData: any[]) => void] => {
  const [data, setData] = useState([] as any[]);
  return [data, setData];
};

export const Data = createContainer(useDataShared);

export const useData = () => Data.useContainer();
