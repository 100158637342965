const IGNORED_KEYS: Record<string, true> = {
  datasetKey: true,
  isInverted: true,
};

export const getSelection = (value: Record<string, boolean>) => {
  const selection = Object.keys(value).filter(
    (key) => !IGNORED_KEYS[key] && value[key],
  );
  return selection;
};
