export const ROLES = {
  admin: 'ADMIN',
  client: 'CLIENT',
  client_admin: 'CLIENT_ADMIN',
  client_manager: 'CLIENT_MANAGER',
};

export const ROLE_NAMES = {
  ADMIN: 'Администратор',
  CLIENT: 'Клиент',
  CLIENT_ADMIN: 'Менеджер',
  CLIENT_MANAGER: 'Пользователь',
};

export const DEFAULT_SELECT_VALUE = 'unselected';

export const CHART_COLORS = ['#C0DDAC', '#FFDF2A'];

export enum DictionariesType {
  // eslint-disable-next-line no-unused-vars
  gtin = 'gtin',
  // eslint-disable-next-line no-unused-vars
  inn = 'inn',
  // eslint-disable-next-line no-unused-vars
  address = 'address',
}

export const OPERATION_TYPES = [
  'Продажа конечному потребителю в точке продаж',
  'Дистанционная продажа конечному потребителю',
  'Продажа конечному потребителю через вендинговый аппарат',
  'Конечная продажа организации',
  'Продажа по государственному контракту',
  'Продажи за пределы РФ',
  'Прочий тип вывода из оборота',
  'Списание / Вывод из оборота без получателя',
];

export const OPERATION_TYPES_COLORS = [
  '#FFF2AA',
  '#F7E5E6',
  '#D5EED8',
  '#D4CDF7',
  '#FFD4AF',
  '#FFD8F6',
  '#D5EDFF',
  '#94D49C',
  '#D4BF54',
];
