import React, { createContext, useCallback, useState } from 'react';

type TContentLengthContext = {
  contentLengthsPercents: Record<string, number>;
  setContentLengthsPercent: (queryKey: string, percent: number) => void;
  deleteContentLengthsPercents: (queryKey: string) => void;
};

const ContentLengthContext = createContext<TContentLengthContext>({
  contentLengthsPercents: {},
  setContentLengthsPercent: () => {},
  deleteContentLengthsPercents: () => {},
});

export const contextLengthApi: {
  current: Pick<
    TContentLengthContext,
    'setContentLengthsPercent' | 'deleteContentLengthsPercents'
  >;
} = {
  current: {
    setContentLengthsPercent: () => {},
    deleteContentLengthsPercents: () => {},
  },
};

export const ContentLengthProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [contentLengthsPercents, setContentLengthsPercents] = useState<
    Record<string, number>
  >({});

  const deleteContentLengthsPercents = useCallback((queryKey: string) => {
    setContentLengthsPercents((prev) => {
      const clone = { ...prev };
      delete clone[queryKey];
      return clone;
    });
  }, []);

  const setContentLengthsPercent = useCallback(
    (queryKey: string, percent: number) => {
      setContentLengthsPercents((prev) => {
        const clone = { ...prev };
        clone[queryKey] = percent;
        return clone;
      });
    },
    [],
  );

  contextLengthApi.current = {
    setContentLengthsPercent,
    deleteContentLengthsPercents,
  };

  const value = {
    contentLengthsPercents,
    setContentLengthsPercent,
    deleteContentLengthsPercents,
  };

  return (
    <ContentLengthContext.Provider value={value}>
      {children}
    </ContentLengthContext.Provider>
  );
};

export const useLoadedPercent = (queryKey?: string) => {
  const { contentLengthsPercents } = React.useContext(ContentLengthContext);
  if (!queryKey) {
    return 0;
  }
  if (contentLengthsPercents[queryKey] === undefined) {
    return 0;
  }
  return contentLengthsPercents[queryKey] || 0;
};
