import { useEffect, useMemo, useState } from 'react';

export const useFilename = (filename: string, ext?: string) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  const fileNameWithDate = useMemo(() => {
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${filename
      .replace(/[^a-zа-яё0-9]/gi, '_')
      .replace(/\s/g, '_')}_${year}-${month}-${day}_${hours}-${minutes}${
      ext ? `.${ext}` : ''
    }`;
  }, [filename, ext, now]);

  return fileNameWithDate;
};
