import React, { useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOutsideClickRef, useToggle } from 'rooks';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import styles from './Select.module.scss';

type TOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

type TSelectProps = {
  options: string[] | TOption[];
  onChange: (value: string) => void;
  label: React.ReactNode;
  value: string;
  valueLabel?: string;
  noValueLabel?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  small?: boolean;
};

export const Select: React.FC<TSelectProps> = ({
  options,
  onChange,
  value,
  valueLabel,
  label,
  noValueLabel,
  onOpen,
  onClose,
  small,
}) => {
  const [isOpened, toggleIsOpened] = useToggle();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [selectRef] = useOutsideClickRef((e) => {
    if (optionsRef.current?.contains(e.target as Node)) {
      return;
    }
    toggleIsOpened();
    onClose?.();
  }, isOpened);
  const labelValueOptions: TOption[] = useMemo(() => {
    if (typeof options[0] === 'string') {
      return (options as string[]).map((option) => ({
        label: option,
        value: option,
      }));
    }
    return options as TOption[];
  }, [options]);

  const selectRefForPopper = useRef<HTMLDivElement>();
  const { styles: popperStyles, attributes } = usePopper(
    selectRefForPopper.current,
    optionsRef.current,
  );

  return (
    <div
      className={classNames(styles.select, {
        [styles.small]: small,
      })}
      data-test-id="select"
      data-test-expanded={isOpened}
      data-test-value={valueLabel || value}
      ref={(element) => {
        if (!element) {
          return;
        }
        selectRef(element);
        selectRefForPopper.current = element;
      }}
      onClick={() => {
        toggleIsOpened();
        if (isOpened) {
          onClose?.();
        } else {
          onOpen?.();
        }
      }}
    >
      <span className={styles.label} data-test-id="select-label">
        {label}
        {!noValueLabel && <>: {valueLabel || value}</>}
      </span>
      <div
        className={classNames(styles.expand, {
          [styles.expanded]: isOpened,
        })}
      />
      {ReactDOM.createPortal(
        <div
          className={classNames(styles.options, {
            [styles.expanded]: isOpened,
          })}
          data-test-id="select-options"
          style={popperStyles.popper}
          {...attributes.popper}
          ref={optionsRef}
        >
          {labelValueOptions.map(({ disabled, label, value: optionValue }) => (
            <div
              data-test-id="select-option"
              key={optionValue}
              className={classNames(styles.option, {
                [styles.active]: optionValue === value,
                [styles.disabled]: disabled,
              })}
              onClick={() => {
                if (disabled) {
                  return;
                }
                onChange(optionValue);
              }}
            >
              {label}
            </div>
          ))}
        </div>,
        document.body,
      )}
    </div>
  );
};
