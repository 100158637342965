/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unused-modules */
/* eslint-disable no-console */

import { useEffect, useState } from 'react';
import {
  fetchAddCertificate,
  useKey,
} from '../../../../../api/delegation/delegation';
import {
  createAttachedSignature,
  getSystemInfo,
  getUserCertificates,
  isValidSystemSetup,
} from 'crypto-pro';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Modal } from '../../../../../uikit/Modal/Modal';
import { Loader } from '../../../../common/Loader/Loader';
import { Button } from '../../../../../uikit/Button/Button';
import { Spacer } from '../../../../common/Spacer/Spacer';
import classNames from 'classnames';

import styles from './Cert.module.scss';
import dayjs from 'dayjs';
import { Loggers, getLogger } from '../../../../../shared/utils/logger';
import { queryClient } from '../../../../../store/query';
import { useAsyncMemo } from '../../../../../shared/hooks/useAsyncMemo';
import { Input } from '../../../../../uikit/Input/Input';

const removeSpecialSymbols = (value) => value?.replace(/['"]+/g, '');

const certificateLogger = getLogger(Loggers.certificate);

const getInfo = (certificate) => {
  if (!certificate.getOwnerInfo) {
    certificateLogger(typeof certificate);
    certificateLogger(JSON.stringify(certificate));
    return [];
  }
  return certificate.getOwnerInfo();
};

const getCertificateName = async (certificate) => {
  certificateLogger('Trying to get certificate name');
  const info = await getInfo(certificate);
  const NAME_KEYS = ['Владелец'];
  let foundName = null;
  NAME_KEYS.find((key) => {
    return info.find((item) => {
      if (key === item.title) {
        certificateLogger(
          `Found key ${item.title} with value ${item.description}`,
        );
        foundName = item.description;
        return item;
      }
    });
  });
  if (foundName) {
    return foundName;
  }

  certificateLogger(`Didn't found key, checking subjectName`);
  const name = certificate?.subjectName?.split('O=')[1]?.split(',')[0];
  if (name) {
    certificateLogger(`Found name`);
  }

  return removeSpecialSymbols(name);
};

const getCertificateInn = async (certificate) => {
  certificateLogger('Trying to get certificate inn');
  const info = await getInfo(certificate);
  const INN_KEYS = ['ИНН ЮЛ', 'INNLE', '1.2.643.100.4', 'OID.1.2.643.100.4'];
  let foundInn = null;
  INN_KEYS.find((key) => {
    return info.find((item) => {
      if (key === item.title) {
        certificateLogger(
          `Found key ${item.title} with value ${item.description}`,
        );
        foundInn = item.description;
        return item;
      }
    });
  });
  if (foundInn) {
    return foundInn;
  }

  certificateLogger(`Didn't found key, checking subjectName`);
  const innUl = certificate?.subjectName?.split('ЮЛ=')[1]?.split(',')[0];
  if (innUl) {
    certificateLogger(`Found innUL`);
  }
  const innURL = certificate?.subjectName
    ?.split('1.2.643.100.4=')[1]
    ?.split(',')[0];
  if (innURL) {
    certificateLogger('Found innURL');
  }
  const innBase = certificate?.subjectName?.split('ИНН=')[1]?.split(',')[0];
  if (innBase) {
    certificateLogger('Found innBase');
  }

  return removeSpecialSymbols(innUl ?? innURL ?? innBase);
};

const TABLE_FIELDS = ['Должность', 'ОГРН', 'СНИЛС', 'Email'];
const FormattedInfo = ({ info, certificate }) => {
  const [inn, setInn] = useState(null);
  useEffect(() => {
    const updateInn = async () => {
      const inn = await getCertificateInn(certificate);
      setInn(inn);
    };
    updateInn();
  }, [certificate]);
  if (!info) {
    return <Loader />;
  }
  const indexedInfo = info.reduce((acc, item) => {
    acc[item.title] = item.description;
    return acc;
  }, {});
  const now = dayjs();
  const isValidNow =
    now.isAfter(dayjs(certificate.validFrom)) &&
    now.isBefore(dayjs(certificate.validTo));

  return (
    <div className={styles.info}>
      <div className={styles.infoTitle}>
        {indexedInfo['Компания'] || 'Нет названия'}
      </div>
      <div className={styles.infoSubtitle}>
        {indexedInfo['Фамилия'] || ''} {indexedInfo['Имя Отчество'] || ''}
      </div>
      <div className={styles.infoSubtitle}>
        {indexedInfo['Отдел/подразделение'] || ''}
      </div>
      <div className={styles.infoItems}>
        <div className={styles.infoItemTitle}>ИНН</div>
        <div className={styles.infoItemValue}>{inn || 'Нет данных'}</div>
        {TABLE_FIELDS.map((field) => {
          return (
            <>
              <div className={styles.infoItemTitle}>{field}</div>
              <div className={styles.infoItemValue}>
                {indexedInfo[field] || 'Нет данных'}
              </div>
            </>
          );
        })}
        <div className={styles.infoItemTitle}>Сертификат</div>
        <div className={styles.infoItemValue}>{certificate.thumbprint}</div>
        <div className={styles.infoItemTitle}>Срок действия</div>
        <div className={styles.infoItemValue}>
          с {dayjs(certificate.validFrom).format('DD.MM.YYYY')} по{' '}
          {dayjs(certificate.validTo).format('DD.MM.YYYY')}{' '}
          <span
            className={classNames(styles.dateLabel, {
              [styles.valid]: isValidNow,
              [styles.invalid]: !isValidNow,
            })}
          >
            ({isValidNow ? 'действителен' : 'просрочен'})
          </span>
        </div>
      </div>
    </div>
  );
};

export const CreatingCertificateSignatureModal = ({
  type,
  selectedItem,
  show,
  onClose,
}) => {
  const { data: key } = useKey();
  const [systemInfo, setSystemInfo] = useState({});

  useEffect(() => {
    getSystemInfo()
      .then((info) => {
        setSystemInfo(info);
        Sentry.captureMessage('System info', {
          extra: {
            info: JSON.stringify(info),
          },
        });
        certificateLogger({ info });
      })
      .catch(console.error);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [certificatesInfo, setCertificatesInfo] = useState([]);

  useEffect(() => {
    setCertificatesInfo([]);
    const updateNames = async () => {
      try {
        setCertificatesInfo(
          await Promise.all(
            certificates.map(async (cert) => {
              const info = await getInfo(cert);
              return info;
            }),
          ),
        );
      } catch (err) {
        console.error(err);
      }
    };
    updateNames();
  }, [certificates]);

  const updateUserCertificates = async () => {
    isValidSystemSetup()
      .then((isValid) => {
        Sentry.captureMessage('isValidSystemSetup', {
          extra: {
            isValid,
            systemInfo: JSON.stringify(systemInfo),
          },
        });
        certificateLogger({ isValid });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    try {
      const data = await getUserCertificates();

      try {
        data.forEach((cert) => {
          // eslint-disable-next-line no-console
          certificateLogger(
            getInfo(cert).then((data) =>
              certificateLogger(JSON.stringify(data)),
            ),
          );
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        certificateLogger(e);
      }

      if (type === 'add') {
        setCertificates(data);
      }

      if (type === 'activate') {
        const inns = await Promise.all(
          data.map(async (item) => await getCertificateInn(item)),
        );
        const activeCertificates = data?.filter(
          (_item, index) => inns[index] === selectedItem.inn,
        );
        setCertificates(activeCertificates);
      }

      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    updateUserCertificates();
  }, []);

  useEffect(() => {
    if (certificates?.length) {
      formik.setFieldValue('certificate', certificates?.[0].thumbprint);
    }
  }, [certificates]);

  const getGenerateKey = async (currentCertificate) => {
    Sentry.captureMessage('Trying to generate key', {
      extra: {
        c: JSON.stringify(currentCertificate),
        key: JSON.stringify(key.data),
      },
    });
    certificateLogger(`Trying to generate key`);
    certificateLogger({
      c: JSON.stringify(currentCertificate),
      key: JSON.stringify(key.data),
    });
    try {
      const result = await createAttachedSignature(
        currentCertificate.thumbprint,
        key.data,
      );
      certificateLogger(`result = ${JSON.stringify(result)}`);
      Sentry.captureMessage('Key was generated', {
        extra: {
          result: JSON.stringify(result),
        },
      });
      return result;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e, {
        extra: {
          systemInfo: JSON.stringify(systemInfo),
        },
      });
      toast.error(e.message);
    }
  };

  const onFinish = async (certificate) => {
    setForceCompanyNameTouched(true);

    certificateLogger(`certificate = ${certificate}`);
    certificateLogger(`certificates = ${JSON.stringify(certificates)}`);

    const currentCertificate = certificates.find(
      (item) => item?.thumbprint === certificate,
    );

    certificateLogger(
      `currentCertificate = ${JSON.stringify(currentCertificate)}`,
    );

    const info = getInfo(currentCertificate);

    Sentry.captureMessage(`Certificate was added`, {
      extra: {
        certificateName: currentCertificate?.subjectName,
        info: JSON.stringify(info),
      },
    });

    if (!(await getCertificateInn(currentCertificate)) && !forceInn) {
      throw new Error(
        'ИНН ЮЛ не был найден в выбранном токене, обратитесь к администратору',
      );
    }

    const data = {
      uuid: key.uuid,
      inn: forceInn || (await getCertificateInn(currentCertificate)),
      name: forceCompanyName || (await getCertificateName(currentCertificate)),
      data: await getGenerateKey(currentCertificate),
    };

    if (!data.name) {
      throw new Error(
        'Наименование компании не было найдено в выбранном токене, обратитесь к администратору',
      );
    }

    if (!data.data) {
      throw new Error(
        'Не удалось получить ключ для выбранного токена, обратитесь к администратору',
      );
    }

    if (!data.inn) {
      throw new Error(
        'ИНН ЮЛ не был найден в выбранном токене, обратитесь к администратору',
      );
    }

    Sentry.captureMessage(`Certificate prepared`, {
      extra: {
        data: JSON.stringify(data),
      },
    });

    try {
      await fetchAddCertificate(data);
      queryClient.invalidateQueries(['delegation']);
      queryClient.invalidateQueries(['inns']);
    } catch (e) {
      Sentry.captureException(e);
      toast.error(e.toString());
    }
  };

  const formik = useFormik({
    initialValues: {
      certificate: '',
    },
    onSubmit: ({ certificate }) => {
      onFinish(certificate)
        .then(() => onClose())
        .catch((error) => {
          Sentry.captureException(error);
          toast.error(error.message);
        });
    },
  });

  const currentCertificate = certificates.find(
    (item) => item?.thumbprint === formik.values.certificate,
  );

  const [forceInn, setForceInn] = useState('');
  const [forceCompanyName, setForceCompanyName] = useState('');
  const [forceCompanyNameTouched, setForceCompanyNameTouched] = useState(false);
  const [innFocused, setInnFocused] = useState(false);
  const [companyNameFocused, setCompanyNameFocused] = useState(false);
  const [currentCertificateInnReady, currentCertificateInn] =
    useAsyncMemo(async () => {
      if (!currentCertificate) {
        return null;
      }
      return await getCertificateInn(currentCertificate);
    }, [currentCertificate]);

  console.log({ currentCertificateInnReady, currentCertificateInn });
  const isInnInvalid =
    ![10, 12].includes(forceInn.length) || !/^\d+$/.test(forceInn);

  return (
    <Modal isOpened={show} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Title gap left>
          {type === 'add' && 'Добавить сертификат'}
          {type === 'activate' && 'Активировать сертификат'}
        </Modal.Title>
        <Modal.Title gap left sub>
          Подсоедините к ПК носитель с УКЭП с помощью которого осуществляется
          <br />
          администрирование в личном кабинете системе Честный знак
          <div>
            <small>
              На компьютере необходимо установить КриптоПроCSP и<br />
              плагин для браузера.
            </small>
          </div>
        </Modal.Title>
        {isLoading && (
          <Modal.Body>
            <Loader />
          </Modal.Body>
        )}
        {!isLoading && (
          <Modal.Body>
            {!certificates?.length ? (
              <label className={styles.label}>
                Усиленная квалифицированная подпись (УКЭП) не найдена
              </label>
            ) : (
              <>
                <label className={styles.label}>Выберите сертификат:</label>
                <div className={styles.certsList}>
                  {certificates?.map((item, index) => {
                    return (
                      <div
                        key={item.thumbprint}
                        className={classNames(styles.certItem, {
                          [styles.active]:
                            formik.values.certificate === item.thumbprint,
                        })}
                        onClick={() => {
                          formik.setFieldValue('certificate', item.thumbprint);
                        }}
                      >
                        <FormattedInfo
                          info={certificatesInfo[index]}
                          certificate={item}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {currentCertificateInnReady && !currentCertificateInn && (
              <>
                <Input
                  testId="certificate-inn"
                  label="ИНН"
                  value={forceInn}
                  onFocus={() => setInnFocused(true)}
                  onBlur={() => setInnFocused(false)}
                  onChange={(e) => setForceInn(e.target.value)}
                  error={
                    !innFocused && forceInn && isInnInvalid
                      ? 'ИНН должен содержать 10 или 12 цифр'
                      : ''
                  }
                />
                <Input
                  testId="certificate-company-name"
                  label="Наименование компании"
                  value={forceCompanyName}
                  onChange={(e) => setForceCompanyName(e.target.value)}
                  onFocus={() => {
                    setCompanyNameFocused(true);
                    setForceCompanyNameTouched(true);
                  }}
                  onBlur={() => setCompanyNameFocused(false)}
                  error={
                    !companyNameFocused &&
                    !forceCompanyName &&
                    forceCompanyNameTouched
                      ? 'Наименование компании не должно быть пустым'
                      : ''
                  }
                />
              </>
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button small color="grey" onClick={onClose}>
            Закрыть
          </Button>
          <Spacer />
          <Button
            small
            type="submit"
            disabled={
              !formik.values.certificate ||
              isLoading ||
              (!forceInn && !currentCertificateInn) ||
              (forceInn && isInnInvalid) ||
              (!currentCertificateInn && !forceCompanyName)
            }
          >
            Добавить{' '}
            {currentCertificate && (
              <span className={styles.namePreview}>
                {removeSpecialSymbols(currentCertificate.name)}
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
