/* eslint-disable no-unused-vars */

import debug from 'debug';
import { isDev } from './isDev';

const APP_PREFIX = 'rida';

export enum Loggers {
  cache = 'cache',
  certificate = 'certificate',
  deepMemo = 'deepMemo',
  api = 'api',
}

const ENUM_KEYS = Object.keys(Loggers);

export const getLogger = (name: Loggers) => {
  return debug(`${APP_PREFIX}:${name}`);
};

// eslint-disable-next-line no-console
console.log(
  `Чтобы увидеть логи, нужно установить переменную localStorage.debug и включить фильтр Verbose в консоли.

Все логи приложения: localStorage.debug = "${APP_PREFIX}:*"

${ENUM_KEYS.map((key) => {
  return `Логи для ${key}: localStorage.debug = "${APP_PREFIX}:${key}"`;
}).join('\n')}

Логи для всего, кроме какого-то модуля: localStorage.debug = "${APP_PREFIX}:*,-${APP_PREFIX}:module"
Чтобы принудительно выключить: localStorage.debug = "-*"
`.trim(),
);

if (isDev()) {
  if (!localStorage.debug) {
    localStorage.debug = `${APP_PREFIX}:*`;
    // eslint-disable-next-line no-console
    console.log(
      `Включены логи для всего приложения: localStorage.debug = "${APP_PREFIX}:*"`,
    );
  }
}
