/* eslint-disable no-console */
import { QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
      retry: (failureCount, error) => {
        if ((error as Error)?.message?.startsWith('REEDDA-05:')) {
          return false;
        }
        if ((error as Error)?.message?.includes('with status code 429')) {
          return false;
        }
        return failureCount < 3;
      },
      onError: (error) => {
        console.error(error);
        Sentry.captureException(error);
      },
    },
  },
});
