import React, { useEffect, useMemo } from 'react';
import {
  LineSelect,
  TLineSelectProps,
  TOptionsList,
} from '../../newComponents/common/LineSelect/LineSelect';
import { useSearchQueryState } from './useSearchQueryState';
import { useDeepMemo } from './deepMemo';

export const useLineSelect = <EOptions extends string>(
  key: string,
  defaultValue: EOptions,
  optionsList: TOptionsList<EOptions>,
  lineSelectProps?: Partial<TLineSelectProps<EOptions>>,
): [EOptions, (value: EOptions) => void, React.ReactNode] => {
  const [mode, setMode] = useSearchQueryState<EOptions>(key, defaultValue);
  const optionsListMemo = useDeepMemo(optionsList, 'optionsList');

  useEffect(() => {
    if (optionsListMemo.length === 0) {
      return;
    }
    if (!optionsListMemo.find((option) => option.value === mode)) {
      setMode(defaultValue);
    }
  }, [defaultValue, mode, optionsListMemo, setMode]);

  const component = useMemo(() => {
    return (
      <LineSelect
        testId={key}
        primaryColor
        whiteBackground
        value={mode}
        onChange={setMode}
        options={optionsListMemo}
        {...lineSelectProps}
      />
    );
  }, [key, lineSelectProps, mode, optionsListMemo, setMode]);

  return [mode, setMode, component];
};
