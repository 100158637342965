import React from 'react';
import { TFilter } from '../filter';
import { DateRangeFilter } from './filters/DateRangeFilter/DateRangeFilter';
import {
  SelectFilter,
  SelectFilterPrefetcher,
} from './filters/SelectFilter/SelectFilter';

const FILTERS_VIEWS: Record<TFilter['type'], React.FC<TFilterContentProps>> = {
  // @ts-ignore
  select: SelectFilter,
  // @ts-ignore
  dateRange: DateRangeFilter,
};

const FILTERS_PREFETCHERS: Partial<
  Record<TFilter['type'], React.FC<TFilterContentProps>>
> = {
  // @ts-ignore
  select: SelectFilterPrefetcher,
};

type TFilterContentProps = {
  filter: TFilter;
  value: any;
  onChange: (value: any) => void;
};

export const FilterContent: React.FC<TFilterContentProps> = ({
  filter,
  value,
  onChange,
}) => {
  const FilterView = FILTERS_VIEWS[filter.type];

  return <FilterView value={value} onChange={onChange} filter={filter} />;
};

export const FilterPrefetcher: React.FC<TFilterContentProps> = ({
  filter,
  onChange,
  value,
}) => {
  const FilterPrefetcher = FILTERS_PREFETCHERS[filter.type];

  if (!FilterPrefetcher) {
    return null;
  }

  return <FilterPrefetcher value={value} filter={filter} onChange={onChange} />;
};
