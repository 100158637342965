import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $api, resetUserEditableCache } from '../../../api/utils';
import { DictionariesType } from '../../../shared/constants';
import { useAllDictionariesNames } from '../../../shared/hooks/useAllDictionariesNames';
import { useSearchQueryState } from '../../../shared/hooks/useSearchQueryState';
import {
  getDictionaries,
  TDictionary,
} from '../../../shared/utils/getDictionaries';
import {
  formatError,
  withErrorHandling,
} from '../../../shared/utils/withErrorHandling';
import { queryClient } from '../../../store/query';
import { Button } from '../../../uikit/Button/Button';
import { Input } from '../../../uikit/Input/Input';
import { Modal } from '../../../uikit/Modal/Modal';
import { withLoader } from '../../common/FullscreenLoader/FullscreenLoader';
import { Icon } from '../../common/Icon/Icon';
import { PageContent } from '../../common/PageContent/PageContent';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { Spacer } from '../../common/Spacer/Spacer';
import { sorter } from '../../common/Table/sorter';
import { Table, TTableColumn } from '../../common/Table/Table';

import styles from './Dictionaries.module.scss';
import { Gap } from '../../../uikit/Gap/Gap';
import { toast } from 'react-toastify';

const createDictionary = async (
  name: string,
  type: DictionariesType,
): Promise<TDictionary> => {
  try {
    const { data } = await $api.post(`/dictionaries`, {
      name: name.trim(),
      type,
    });
    await resetUserEditableCache();
    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

const useDictionaries = () => {
  return useQuery(['dictionaries'], () => getDictionaries());
};

const deleteDictionary = async (guid: string) => {
  const { data } = await $api.delete(`/dictionaries/${guid}`);
  await resetUserEditableCache();
  return data;
};

const DICT_TYPE_NAMES: Record<DictionariesType, string> = {
  [DictionariesType.gtin]: 'Товары',
  [DictionariesType.inn]: 'ИНН',
  [DictionariesType.address]: 'Адреса',
};

const DICT_TYPE_BG_COLORS: Record<DictionariesType, string> = {
  [DictionariesType.gtin]: '#b3eefb',
  [DictionariesType.inn]: '#ffbaba',
  [DictionariesType.address]: '#ffeb3b',
};

const DictTypeLabel: React.FC<{
  type: DictionariesType;
}> = ({ type }) => {
  return (
    <div
      className={styles.typeTag}
      style={{
        backgroundColor: DICT_TYPE_BG_COLORS[type],
      }}
    >
      <span>{DICT_TYPE_NAMES[type]}</span>
    </div>
  );
};

export const Dictionaries = () => {
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState<false | string>(false);

  const dictionariesTableColumns = useMemo<TTableColumn<TDictionary>[]>(
    () => [
      {
        key: 'type',
        title: 'Тип',
        sort: sorter('type'),
        render: (row) => (
          <DictTypeLabel type={row.type?.toLowerCase() as DictionariesType} />
        ),
      },
      {
        key: 'name',
        title: 'Название справочника',
        sort: sorter('name'),
        render: (row) => (
          <span data-test-id={`dict-name-${row.name}`}>{row.name}</span>
        ),
      },
      {
        key: 'createAuthor',
        title: 'Автор справочника',
        sort: sorter('createAuthor'),
      },
      {
        key: 'createdAt',
        title: 'Дата создания',
        sort: sorter('createdAt'),
        render: (row) => dayjs(row.createdAt).format('DD.MM.YYYY HH:mm'),
      },
      {
        key: 'updatedAt',
        title: 'Дата изменения',
        sort: sorter('updatedAt'),
        render: (row) => dayjs(row.updatedAt).format('DD.MM.YYYY HH:mm'),
      },
      {
        key: 'fillPercent',
        title: '% заполнения справочника',
        sort: sorter('fillPercent', true),
        isNumeric: true,
        render: (row) => `${row.fillPercent}%`,
        withBar: true,
        barValue: (row) => row.fillPercent * 2,
      },
      {
        key: 'guid',
        title: '',
        size: '65px',
        isNumeric: true,
        render: (row) => (
          <Icon
            testId={`edit-dict-${row.name}`}
            name="edit"
            clickable
            onClick={() => {
              navigate(`/dictionaries/${row.type.toLowerCase()}/${row.guid}`);
            }}
          />
        ),
      },
      {
        key: 'guid',
        title: '',
        isNumeric: true,
        size: '65px',
        overflowVisible: true,
        render: (row) => (
          <Icon
            testId={`delete-dict-${row.name}`}
            name="trash"
            clickable
            size={18}
            onClick={() => {
              setShowDeleteModal(row.guid);
            }}
          />
        ),
      },
    ],
    [navigate],
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newDictName, setNewDictName] = useState<string>('');
  const [newDictType, setNewDictType] = useState<DictionariesType>(
    DictionariesType.gtin,
  );

  const [dictType, setDictType] = useSearchQueryState<DictionariesType>(
    'dictType',
    DictionariesType.gtin,
  );

  useEffect(() => {
    if (!isCreateModalOpen) {
      setNewDictName('');
      setNewDictType(dictType);
    }
  }, [dictType, isCreateModalOpen]);

  const useDictionariesResult = useDictionaries();
  const { data: dictionaries } = useDictionariesResult;

  const names = useAllDictionariesNames();
  const newDictIsNotUnique =
    newDictName && names?.includes(newDictName.toLowerCase());

  const createButton = (
    <Button
      testId="create-dict"
      onClick={() => {
        setIsCreateModalOpen(true);
      }}
      reportPanel
    >
      <Icon name="add" /> Создать
    </Button>
  );

  const invalidate = () => {
    queryClient.invalidateQueries(['dictionaries']);
    queryClient.invalidateQueries(['dictionariesData', dictType]);
    queryClient.invalidateQueries(['filterDataset']);
    queryClient.invalidateQueries(['allDictionariesNames']);
  };

  return (
    <>
      <PageHeader
        withGap
        title="Справочники"
        rightTitleContent={createButton}
        supportKey="dicts"
      />
      <PageContent
        queryData={useDictionariesResult}
        notFound={dictionaries?.length === 0}
        notFoundCustomText={
          <>На данный момент не создано ни одного справочника{createButton}</>
        }
      >
        <Table
          rows={dictionaries || []}
          cols={dictionariesTableColumns}
          withPagination
        />
      </PageContent>
      <Modal
        testId="delete-dict"
        isOpened={!!showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <Modal.Title gap>
          Вы уверены, что хотите удалить справочник?
        </Modal.Title>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowDeleteModal(false);
            }}
            color="grey"
            fullWidth
            testId="cancel"
          >
            Отменить
          </Button>
          <Gap horizontal size={16} />
          <Button
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  await deleteDictionary(showDeleteModal as string);
                  invalidate();
                  toast.success('Справочник успешно удален');
                  setShowDeleteModal(false);
                }),
              );
            }}
            fullWidth
            testId="delete"
          >
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        isOpened={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        testId="create-dict"
      >
        <Modal.Title gap>Новый справочник</Modal.Title>
        <Modal.Body>
          <Input
            testId="dict-name"
            placeholder="Название справочника"
            value={newDictName}
            onChange={(e) => {
              setNewDictName(e.target.value);
            }}
            block
            error={
              newDictIsNotUnique
                ? 'Справочник с таким названием уже существует'
                : undefined
            }
          />
          <Input
            testId="dict-type"
            label="Тип справочника"
            type="select"
            name="role"
            block
            value={newDictType}
            onChange={(e) => {
              setNewDictType(e.target.value as DictionariesType);
            }}
          >
            {Object.values(DictionariesType).map((type) => (
              <option key={type} value={type}>
                {DICT_TYPE_NAMES[type]}
              </option>
            ))}
          </Input>
        </Modal.Body>
        <Modal.Footer>
          <Button
            testId="cancel"
            onClick={() => {
              setIsCreateModalOpen(false);
            }}
            color="grey"
          >
            Отмена
          </Button>
          <Spacer />
          <Button
            testId="create"
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  const newDict = await createDictionary(
                    newDictName,
                    newDictType,
                  );
                  setIsCreateModalOpen(false);
                  setDictType(newDictType);
                  queryClient.invalidateQueries(['dictionaries']);
                  queryClient.invalidateQueries(['dictionariesData']);
                  navigate(
                    `/dictionaries/${newDict.type.toLowerCase()}/${
                      newDict.guid
                    }`,
                  );
                }),
              );
            }}
            disabled={!newDictName || !!newDictIsNotUnique}
          >
            Создать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
