import React, { useState, useEffect } from 'react';
import styles from './Loading.module.scss';
import { Loader } from '../../../../common/Loader/Loader';

export const Loading: React.FC<{
  percent: number | null;
  queryKey?: string;
}> = ({ percent }) => {
  const [isRenderedForAWhile, setIsRenderedForAWhile] = useState(false);
  const [wasPercentShown, setWasPercentShown] = useState(false);
  const [dotsCount, setDotsCount] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsRenderedForAWhile(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (percent) {
      setWasPercentShown(true);
      return;
    }
    const interval = setInterval(() => {
      setDotsCount((count) => {
        if (count === 3) {
          return 1;
        }
        return count + 1;
      });
    }, 500);
    return () => clearInterval(interval);
  }, [percent]);

  if (percent) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.title}>Отчёт готов</div>
        <div className={styles.body}>
          Загружаем данные, загружено {percent}%
        </div>
      </div>
    );
  }

  if (!percent && wasPercentShown) {
    return <Loader />;
  }

  if (!isRenderedForAWhile) {
    return <Loader />;
  }

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.title}>
        Отчёт готовится{dotsCount === 0 ? '' : '.'.repeat(dotsCount)}
      </div>
      <div className={styles.body}>
        Вы можете продолжать работу в системе.
        <br />
        Результат будет доступен в меню в правом верхнем углу.
      </div>
    </div>
  );
};
