import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { sorter } from '../../common/Table/sorter';

import styles from './Uploads.module.scss';
import { TTableColumn } from '../../common/Table/Table';

export const statusList = [
  {
    name: 'Все статусы',
    type: 'ALL',
  },
  {
    name: 'Отчет сформирован успешно',
    type: 'SUCCESS',
  },
  {
    name: 'Ошибка',
    type: 'FAILED',
  },
  {
    name: 'Отчет в процессе формирования',
    type: 'PROCESS',
  },
  {
    name: 'Отчет удален',
    type: 'DELETED',
  },
] as const;

type StatusTypes = typeof statusList[number]['type'];

export const reportsList = [
  {
    type: 'DS1',
    name: 'Оптовые и розничные продажи в разрезе ИНН',
  },
  {
    type: 'DS2',
    name: 'Объем розничных продаж в разрезе по ИНН и адресам',
  },
  {
    type: 'DS3',
    name: 'Средневзвешенная цена в разрезе по GTIN по неделям',
  },
  {
    type: 'DS3_MONTH',
    name: 'Средневзвешенная цена в разрезе по GTIN по месяцам',
  },
  {
    type: 'DS4',
    name: 'Оборачиваемость товара в разрезе по периоду реализации',
  },
  {
    type: 'DS4_MONTH',
    name: 'Оборачиваемость товара в разрезе по месяцу производства',
  },
] as const;

export type ReportsTypes = typeof reportsList[number]['type'];

export type TUploadItem = {
  guid: string;
  downloadLink: string;
  createdAt: string;
  report: ReportsTypes;
  periods: number[];
  fields: {
    period: string;
    prid: string;
    name_prid: string;
    subject_rf_code: string;
    subject_rf_name: string;
    seller_inn: string;
    name_seller: string;
    name_seller_net: string;
    tnved10: string;
    gtin: string;
    product_name: string;
    cnt_store_point: string;
    sales_volume: string;
    wheighted_average_price: string;
  };
  status: StatusTypes;
  inn: string;
};

const getReport = (report: typeof reportsList[number]['type']) => {
  return reportsList.find((item) => item.type === report);
};

const getDownloadFileName = (link: string) => {
  return link?.split('/')[link?.split('/')?.length - 1];
};

export const COLUMNS: Array<TTableColumn<TUploadItem>> = [
  {
    key: 'report',
    title: 'Название отчета',
    render: (row) => getReport(row.report)?.name,
    renderForExport: (row) => getReport(row.report)?.name || '',
    sort: (a, b) => {
      const reportA = getReport(a.report)?.name ?? '';
      const reportB = getReport(b.report)?.name ?? '';
      return reportA.localeCompare(reportB);
    },
  },
  {
    key: 'date',
    title: 'Дата создания',
    render: (row) => dayjs(row.createdAt).format('DD.MM.YYYY'),
    renderForExport: (row) => dayjs(row.createdAt).format('DD.MM.YYYY'),
    sort: sorter('createdAt'),
    isNumeric: true,
  },
  {
    key: 'inn',
    title: 'ИНН',
  },
  {
    key: 'status',
    title: 'Статус',
    render: (row) => (
      <span
        className={classNames(styles.status, {
          [styles.success]: row.status === 'SUCCESS',
          [styles.failed]: row.status === 'FAILED',
          [styles.process]: row.status === 'PROCESS',
          [styles.deleted]: row.status === 'DELETED',
        })}
      >
        {statusList.find((status) => status.type === row?.status)?.name || ''}
      </span>
    ),
    renderForExport: (row) => {
      return (
        statusList.find((status) => status.type === row?.status)?.name || ''
      );
    },
  },
  {
    key: 'downloadLink',
    title: '',
    hideForExport: true,
    render: (row) =>
      row.downloadLink ? (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={`${window.location.origin}${row.downloadLink}`}
          target="_blank"
          download={getDownloadFileName(row.downloadLink)}
          rel="noreferrer"
          className={styles.downloadLink}
        />
      ) : null,
  },
];
