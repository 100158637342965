export const getMaxOfArray = (numArray: number[]): number => {
  let max = numArray[0];
  const len = numArray.length;
  for (let i = 1; i < len; i++) {
    if (numArray[i] > max) {
      max = numArray[i];
    }
  }
  return max;
};

export const getMinOfArray = (numArray: number[]): number => {
  let min = numArray[0];
  const len = numArray.length;
  for (let i = 1; i < len; i++) {
    if (numArray[i] < min) {
      min = numArray[i];
    }
  }
  return min;
};
