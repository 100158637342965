import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ColoredUncheckedIcon } from './coloredUnchecked.svg';
import { ReactComponent as ColoredCheckedIcon } from './coloredChecked.svg';

import styles from './Checkbox.module.scss';

type TCheckboxProps = {
  className?: string;
  checked?: boolean;
  indeterminate?: boolean;
  label?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  radio?: boolean;
  color?: string;
  name?: string;
  alignCenter?: boolean;
  testId?: string;
};

export const Checkbox: React.FC<TCheckboxProps> = ({
  className,
  checked,
  indeterminate,
  onChange,
  label,
  radio,
  color,
  alignCenter,
  testId,
  name,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <label
      className={classNames(styles.checkbox, className, {
        [styles.colored]: color,
        [styles.alignCenter]: alignCenter,
      })}
      data-test-id={`ch-${testId}`}
      data-test-is-radio={radio}
    >
      <input
        type={radio ? 'radio' : 'checkbox'}
        checked={!!checked}
        // @ts-ignore
        indeterminate={indeterminate ? 'true' : undefined}
        onChange={handleChange}
        className={styles.input}
        data-test-id={`ch-i__${testId}`}
        name={name}
      />
      <span
        className={classNames(styles.icon, {
          [styles.checked]: checked,
          [styles.radio]: radio,
          [styles.colored]: color,
        })}
        data-test-id={`ch-span__${testId}`}
        style={
          color
            ? {
                color,
              }
            : undefined
        }
      >
        {color && checked && <ColoredCheckedIcon />}
        {color && !checked && <ColoredUncheckedIcon />}
      </span>
      <span className={styles.label}>{label}</span>
    </label>
  );
};
