import React from 'react';
import { getDeclarator } from '../../../../../shared/utils/declOfNum';

import styles from './Widget.module.scss';
import { getVisibleReports } from '../../../Reports/constants';
import { ChartWidget, TFormatter } from './widgets/Chart/Chart';
import {
  GtinCountResponse,
  OutputTypesResponse,
  RetailOutletsResponse,
  RetailSalesResponse,
} from '../../../../../api/reports/Api';
import dayjs from 'dayjs';
import {
  OPERATION_TYPES,
  OPERATION_TYPES_COLORS,
} from '../../../../../shared/constants';
import { capitalize } from '../../../../../shared/utils/capitalize';
import { DataUpdate } from './widgets/DataUpdate/DataUpdate';
import { MyTools } from './widgets/MyTools/MyTools';
import { EventsWidget } from './widgets/Events/Events';
import { User, useAuthorizedUser } from '../../../../../shared/hooks/useUser';
import { QueueWidget } from './widgets/Queue/Queue';

type TWidgetProps = {
  name: string;
};

const TITLES: Record<string, string> = {
  types: 'Типы вывода',
  rSales: 'Розничные продажи',
  gtinCount: 'Количество GTIN',
  rPoints: 'Розничные точки',
  dataUpdate: 'Обновление данных',
  myTools: 'Мои инструменты',
  events: 'События',
  queue: 'Готовность отчетов',
};

const reportsDeclarator = getDeclarator(
  ['отчет', 'отчета', 'отчетов'],
  true,
  false,
);

const chartsDeclarator = getDeclarator(
  ['график', 'графика', 'графиков'],
  true,
  false,
);

const SUBTITLES: Record<string, (user: User) => string> = {
  dataUpdate: () => 'Даты обновления данных',
  myTools: (user) => {
    const reports = getVisibleReports(user);

    return `${reportsDeclarator(reports.length)} · ${chartsDeclarator(
      reports.reduce((acc, { chartsCount }) => acc + chartsCount, 0),
    )}`;
  },
};

const FORMATTERS: Record<string, TFormatter> = {
  gtinCount: (data: GtinCountResponse[]) => {
    return [...data].reverse().map(({ month, gtinCnt }) => ({
      month: capitalize(dayjs(month as string, 'YYYY-MM').format('MMM')),
      value: Number(gtinCnt),
      color: '#FE8888',
    }));
  },
  rPoints: (data: RetailOutletsResponse[]) => {
    return [...data].reverse().map(({ month, outletsCnt }) => ({
      month: capitalize(dayjs(month as string, 'YYYY-MM').format('MMM')),
      value: Number(outletsCnt),
      color: '#C0DDAC',
    }));
  },
  rSales: (data: RetailSalesResponse[]) => {
    return [...data].reverse().map(({ month, salesCnt }) => ({
      month: capitalize(dayjs(month as string, 'YYYY-MM').format('MMM')),
      value: Number(salesCnt),
      color: '#88D4FE',
    }));
  },
  types: (data: OutputTypesResponse[]) => {
    return OPERATION_TYPES.map((type, index) => {
      const value =
        data.find((dataItem) => dataItem.outputType === type)?.salesCnt || 0;
      return {
        month: type,
        value,
        color: OPERATION_TYPES_COLORS[index],
      };
    });
  },
};

const COMPONENTS: Record<string, React.ReactNode> = {
  gtinCount: (
    <ChartWidget
      backendUrl="charts/gtin-count"
      dataFormatter={FORMATTERS.gtinCount}
    />
  ),
  rPoints: (
    <ChartWidget
      backendUrl="charts/retail-outlets"
      dataFormatter={FORMATTERS.rPoints}
    />
  ),
  rSales: (
    <ChartWidget
      backendUrl="charts/retail-sales"
      dataFormatter={FORMATTERS.rSales}
    />
  ),
  types: (
    <ChartWidget
      hideLabels
      fixedPosition
      backendUrl="charts/output-types"
      dataFormatter={FORMATTERS.types}
    />
  ),
  dataUpdate: <DataUpdate />,
  myTools: <MyTools />,
  events: <EventsWidget />,
  queue: <QueueWidget />,
};

export const Widget: React.FC<TWidgetProps> = ({ name }) => {
  const component = COMPONENTS[name];
  const userData = useAuthorizedUser();

  return (
    <div
      className={styles.wrapper}
      style={{
        gridArea: name,
      }}
      data-test-id={`widget-${name}`}
    >
      <div className={styles.widget}>
        <div className={styles.title} data-test-id={`widget-${name}-title`}>
          {TITLES[name] || name}
        </div>
        {SUBTITLES[name] && (
          <div
            className={styles.subTitle}
            data-test-id={`widget-${name}-subtitle`}
          >
            {SUBTITLES[name](userData)}
          </div>
        )}
        <div
          className={styles.component}
          data-test-id={`widget-${name}-component`}
        >
          {component}
        </div>
      </div>
    </div>
  );
};
