import {
  TCustomGetter,
  getGrouppedData,
} from '../../../../../../shared/utils/useGroupBy';
import { TTableColumn } from '../../../../../common/Table/Table';
import { DataFeature } from '../reportConfigType';

export const PRESET_SHOULD_HIDE_CHECKERS = {
  notDataset: (field: string, datasets: string | string[]) => {
    const datasetsArray = Array.isArray(datasets) ? datasets : [datasets];
    return (params: { filterState: Record<string, any> }) => {
      const filterState = params.filterState;
      const datasetKey = filterState[field]?.datasetKey;
      const shouldShow = datasetKey && !datasetsArray.includes(datasetKey);
      return !shouldShow;
    };
  },
};

type TGroupByProps<TDataItem extends Record<string, any>> = {
  options: Array<{
    groupBy: keyof TDataItem;
    label: string;
    additionalColumns?: TTableColumn<TDataItem>[];
    shouldHide?: (params: { filterState: Record<string, any> }) => boolean;
  }>;
  summarizeFields: Array<keyof TDataItem | RegExp>;
  customGetter?: TCustomGetter<TDataItem>;
};

const getCurrentOption = <TDataItem extends Record<string, any>>(
  props: TGroupByProps<TDataItem>,
  state: TGroupByProps<TDataItem>['options'][number]['groupBy'],
) => {
  return (
    props.options.find((option) => option.groupBy === state) || props.options[0]
  );
};

const OPTION_HINTS: Record<string, string> = {
  netName:
    'Наличие справочника по сетям зависит от товарной группы.\nДля уточнения обратитесь к своему клиентскому менеджеру.',
  buyerNetName:
    'Наличие справочника по сетям зависит от товарной группы.\nДля уточнения обратитесь к своему клиентскому менеджеру.',
  sellerNetName:
    'Наличие справочника по сетям зависит от товарной группы.\nДля уточнения обратитесь к своему клиентскому менеджеру.',
};

export const getGroupByFeature = <TDataItem extends Record<string, any>>(
  props: TGroupByProps<TDataItem>,
): DataFeature<TDataItem> => {
  return {
    key: 'groupBy',

    getLineSelectOptions: (filterState) =>
      props.options
        .filter(
          (option) => !option.shouldHide || !option.shouldHide(filterState),
        )
        .map((option) => ({
          value: option.groupBy as string,
          label: option.label,
          hint: OPTION_HINTS[option.groupBy as string],
          testId: option.label,
        })),

    tableColumnsMiddleware: (columns, state) => {
      const currentOption = getCurrentOption(props, state);
      return [...(currentOption.additionalColumns || []), ...columns];
    },

    tableDataMiddleware: (data, state, _setState, rawData) => {
      return getGrouppedData(
        props.summarizeFields,
        data,
        state,
        rawData,
        props.customGetter,
      );
    },
  };
};
