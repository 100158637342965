import { DS1R11Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import {
  getGroupByFeature,
  PRESET_SHOULD_HIDE_CHECKERS,
} from '../AllPurposeReport/dataFeatures/groupBy';
import {
  getTransposeFeature,
  TWithTransposedFields,
} from '../AllPurposeReport/dataFeatures/transpose';
import { getIntervalFilter } from '../AllPurposeReport/filters/interval';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { getIntervalTransposeConfig } from '../AllPurposeReport/presets/transposeIntervals';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getLinesChartFeature } from '../AllPurposeReport/viewFeatures/linesChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Динамика GTIN в рознице';

export const ds1o11Config: TReportConfigType<DS1R11Response> = {
  title: NAME,
  backendKey: 'ds1r11',

  dataFeatures: [
    getTransposeFeature(getIntervalTransposeConfig('YYYY-MM-DD', 'gtin')),
    getGroupByFeature({
      options: [
        {
          groupBy: 'gtin',
          label: 'GTIN',
          additionalColumns: [
            {
              key: 'gtin',
              title: 'GTIN',
              sort: sorter('gtin'),
            },
            {
              key: 'gtinName',
              title: 'Наименование товара',
              sort: sorter('gtinName'),
            },
          ],
        },
        {
          groupBy: 'dictName',
          shouldHide: PRESET_SHOULD_HIDE_CHECKERS.notDataset('gtins', ['gtin']),
          label: 'Справочник',
          additionalColumns: [
            {
              key: 'dictName',
              title: 'Название в справочнике',
              sort: sorter('dictName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', /^transposed_.*/],
    }),
  ],

  viewFeatures: [
    getTableFeature<TWithTransposedFields<DS1R11Response>>({
      defaultSort: 'LAST',
      filename: NAME,
      baseColumns: [],
    }),
    getLinesChartFeature<TWithTransposedFields<DS1R11Response>>({
      filename: NAME,
      nameKey: 'gtinName',
      innKey: 'gtin',
      valuesPrefix: 'transposed_',
      groupKeyToNameMap: {
        gtin: 'gtinName',
      },
      icon: 'barChartWithLine',
      dateFormat: 'YYYY-MM-DD',
    }),
  ],

  filters: [
    getIntervalFilter({
      single: false,
      initialDatasetKey: 'm',
      datasets: [
        {
          label: 'Месяц',
          value: 'm',
        },
      ],
    }),
    getSelectFilter({
      shouldUseNetwork: true,
      shouldUseDictionary: true,
      commonDataKey: 'retailSellers',
      mode: 'inns',
      label: 'Продавцы',
      icon: 'contact',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товары',
      icon: 'package',
    }),
  ],
  requiredData: {
    innDictionaries: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['retailSellers', 'retailSellerNetworks', 'gtins'],

  autoApplyOnFilterReady: true,

  isRequiredFieldsFilled: (filter) => Boolean(filter.periods?.length),
};
