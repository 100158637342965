import React from 'react';
import { Button } from '../../../../../uikit/Button/Button';
import { Modal } from '../../../../../uikit/Modal/Modal';
import { Spacer } from '../../../../common/Spacer/Spacer';
import { TInn } from '../../../../common/Header/ProfileModal/hooks';

export const RevokeModal: React.FC<{
  show: boolean;
  onClose: () => void;
  item: TInn;
  onRevokeInn: (inn: string) => void;
}> = ({ show, onClose, item, onRevokeInn }) => {
  const text = `Подтвердите отзыв ИНН ${item.inn} ${item.name}`;

  return (
    <Modal testId="revoke" isOpened={show} onClose={onClose}>
      <Modal.Title gap left>
        Вы уверены?
      </Modal.Title>
      <Modal.Title sub gap left>
        {text}
      </Modal.Title>
      <Modal.Footer>
        <Button testId="cancel-revoke" color="grey" onClick={onClose}>
          Не отзывать
        </Button>
        <Spacer />
        <Button testId="revoke" onClick={() => onRevokeInn(item.inn)}>
          Отозвать
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
