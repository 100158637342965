import React from 'react';

type TGapsProps = {
  size?: number;
  horizontal?: boolean;
};

export const Gap: React.FC<TGapsProps> = React.memo(
  ({ size = 4, horizontal }) => {
    return (
      <div
        style={{
          height: size,
          width: horizontal ? size : '100%',
        }}
      />
    );
  },
);
