/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useState } from 'react';
import { isDev, isDevHost } from '../../shared/utils/isDev';
import { storage } from '../../shared/utils/storage';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
// @ts-ignore
import FPSStats from 'react-fps-stats';

import styles from './ForceProdButton.module.scss';
import {
  getCachedDataCount,
  getNextInvalidationTime,
  resetCache,
} from '../../api/utils';
import dayjs from 'dayjs';
import { queryClient } from '../../store/query';
import { useIsFetching } from '@tanstack/react-query';
import { useLocalstorageState } from 'rooks';
import { USER_FLAGS_LIST, useUser } from '../../shared/hooks/useUser';

const FETCH_STATUS_EMOJI: Record<string, string> = {
  idle: '😴',
  fetching: '📡',
  paused: '⏸',
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

function getUsedMemory() {
  // @ts-ignore
  if (window.performance && window.performance.memory) {
    // @ts-ignore
    return window.performance.memory.usedJSHeapSize;
  } else {
    console.warn('This feature is not supported in your browser.');
    return null;
  }
}

export const ForceProdButton = () => {
  if (!isDevHost()) {
    return null;
  }

  const { data: user } = useUser();
  const [areMemoryStatsVisible, setAreMemoryStatsVisible] =
    useLocalstorageState('areMemoryStatsVisible', false);

  const isFetching = useIsFetching();
  const [statLines, setStatLines] = useState<string[]>([]);
  useEffect(() => {
    const updateStat = async () => {
      let estimate: any = {};
      try {
        estimate = await navigator.storage.estimate();
      } catch {
        // ignore
      }
      const cachedLength = await getCachedDataCount();
      const nextInvalidationTime = getNextInvalidationTime();
      const dayJsInvalidationTime = dayjs(nextInvalidationTime).utc(true);
      let queryClientQueriesCount = 0;
      const queriesByFetchStatus: Record<string, number> = {};
      try {
        // @ts-ignore
        const queries: any[] = queryClient.getQueryCache().queries;
        queryClientQueriesCount = queries.length;
        queries.forEach((query) => {
          const status = query.state.fetchStatus as string;
          if (!queriesByFetchStatus[status]) {
            queriesByFetchStatus[status] = 0;
          }
          queriesByFetchStatus[status]++;
        });
      } catch {
        // ignore
      }

      const newStatLines: string[] = [];

      const usedMemory = getUsedMemory();

      newStatLines.push(
        `💾 ${cachedLength} | ⏳ ${dayJsInvalidationTime.fromNow(true)}`,
      );
      newStatLines.push(
        `🔎 ${queryClientQueriesCount} | ${Object.keys(queriesByFetchStatus)
          .map(
            (status) =>
              `${FETCH_STATUS_EMOJI[status] || status} ${
                queriesByFetchStatus[status]
              }`,
          )
          .join(' | ')}`,
      );
      if (usedMemory) {
        newStatLines.push(`RAM ${formatBytes(usedMemory)}`);
      }
      newStatLines.push(
        `💽 ${formatBytes(estimate?.usage || 0)} / ${formatBytes(
          estimate?.quota || 0,
        )}`,
      );

      // @ts-ignore
      if (estimate?.usageDetails) {
        // @ts-ignore
        Object.keys(estimate?.usageDetails).forEach((storageKey) => {
          newStatLines.push(
            `${storageKey}: ${formatBytes(
              // @ts-ignore
              estimate?.usageDetails?.[storageKey],
            )}`,
          );
        });
      }

      setStatLines(newStatLines);
    };

    updateStat().then();

    const interval = setInterval(updateStat, 1000);

    return () => clearInterval(interval);
  }, [isFetching]);

  return (
    <>
      {isDev() && (
        <div className={styles.memoryStats}>
          <div>{!user?.isAuthorized && <>❌ Не авторизован</>}</div>
          {user?.isAuthorized &&
            USER_FLAGS_LIST.map((flag) => (
              <div key={flag}>
                {user?.userData?.[flag] ? '✅' : '❌'} {flag}
              </div>
            ))}
          <hr />
          {areMemoryStatsVisible && (
            <>
              {statLines.map((line, index) => (
                <div key={index}>{line}</div>
              ))}
              <button
                onClick={() => {
                  resetCache().then(() => {
                    window.location.reload();
                  });
                }}
              >
                reset
              </button>
            </>
          )}
          <div>
            <Checkbox
              checked={storage.getItem('hideBeta') === 'true'}
              onChange={() => {
                storage.setItem(
                  'hideBeta',
                  String(storage.getItem('hideBeta') !== 'true'),
                );
                window.location.reload();
              }}
              label="Скрыть бета-отчёты"
              alignCenter
            />
          </div>
          <div>
            <button
              onClick={() => {
                setAreMemoryStatsVisible(!areMemoryStatsVisible);
              }}
            >
              {areMemoryStatsVisible ? 'hide' : 'show'}
            </button>
          </div>
        </div>
      )}
      <Checkbox
        className={styles.forceProd}
        checked={storage.getItem('forceProd') === 'true'}
        onChange={() => {
          storage.setItem(
            'forceProd',
            String(storage.getItem('forceProd') !== 'true'),
          );
          window.location.reload();
        }}
        label="Как прод"
        alignCenter
      />
      {isDev() && <FPSStats />}
    </>
  );
};
