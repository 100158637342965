import { DS1R6Response } from '../../../../../api/reports/Api';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { getTopFilter } from '../AllPurposeReport/filters/top';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getSankeyFeature } from '../AllPurposeReport/viewFeatures/sankey';

const NAME = 'Цепочки оптово-розничных продаж';

export const ds1o6Config: TReportConfigType<DS1R6Response> = {
  title: NAME,
  backendKey: 'ds1r6',

  dataFeatures: [],

  viewFeatures: [getSankeyFeature<DS1R6Response>()],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      label: 'ИНН',
      commonDataKey: 'participants',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'Товар',
      commonDataKey: 'gtins',
      icon: 'package',
      mode: 'gtins',
    }),
    getTopFilter(),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['participants', 'gtins'],

  isRequiredFieldsFilled: () => true,
};
