import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { $apiBase } from '../../api/utils';
import { Modal } from '../../uikit/Modal/Modal';

import Lottie from 'lottie-react';
import maintenanceAnimation from './maintenance.json';

const REFETCH_LONG = 10000;
const REFETCH_SHORT = 2000;

declare global {
  interface Window {
    health?: boolean;
  }
}

const useMonitor = () => {
  const [refetchInterval, setRefetchInterval] = useState(REFETCH_LONG);
  const queryData = useQuery(
    ['monitor'],
    async () => {
      if (window.health !== undefined) {
        return window.health;
      }
      if (process.env.NODE_ENV === 'development') {
        return true;
      }
      try {
        const { data } = await $apiBase.get('/actuator/health');
        return data.status === 'UP';
      } catch {
        return false;
      }
    },
    {
      retry: false,
      refetchInterval,
      staleTime: 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (queryData.data) {
      setRefetchInterval(REFETCH_LONG);
    } else {
      setRefetchInterval(REFETCH_SHORT);
    }
  }, [queryData.data]);

  return queryData;
};

export const MonitorModal = () => {
  const { data, isLoading } = useMonitor();
  const [dotsCount, setDotsCount] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setDotsCount(0);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !data) {
      const interval = setInterval(() => {
        setDotsCount((count) => {
          if (count === 3) {
            return 0;
          }
          return count + 1;
        });
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isLoading, data]);

  if (!isLoading && !data) {
    return (
      <Modal testId="monitor" isOpened>
        <Modal.Title gap>
          Ведутся технические работы{'.'.repeat(dotsCount)}
        </Modal.Title>
        <Modal.Body center>
          Пожалуйста, подождите. Сервис скоро возобновит работу.
        </Modal.Body>
        <Modal.Body>
          <Lottie animationData={maintenanceAnimation} />
        </Modal.Body>
      </Modal>
    );
  }

  return null;
};
