import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

import edit from './icons/edit.svg';
import add from './icons/add.svg';
import search from './icons/search.svg';
import expand from './icons/expand.svg';
import collapse from './icons/collapse.svg';
import close from './icons/close.svg';
import invert from './icons/invert.svg';
import expandFilled from './icons/expandFilled.svg';
import collapseFilled from './icons/collapseFilled.svg';
import info from './icons/info.svg';
import save from './icons/save.svg';
import trash from './icons/trash.svg';

const ICONS = {
  edit,
  add,
  search,
  expand,
  collapse,
  close,
  invert,
  expandFilled,
  collapseFilled,
  info,
  save,
  trash,
};

type TIconProps = {
  name: keyof typeof ICONS;
  size?: number;
  clickable?: boolean;
  onClick?: () => void;
  className?: string;
  testId?: string;
};

export const Icon: React.FC<TIconProps> = ({
  name,
  size = 20,
  clickable = false,
  onClick,
  className,
  testId,
}) => {
  return (
    <div
      className={classNames(styles.icon, className)}
      data-test-id={testId}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${ICONS[name]})`,
        cursor: clickable ? 'pointer' : 'default',
      }}
      onClick={onClick}
    />
  );
};
