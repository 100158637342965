import dayjs from 'dayjs';
import { storage } from './storage';

export const isDev = (): boolean => {
  return isDevHost() && storage.getItem('forceProd') !== 'true';
};

export const isTestMode = (): boolean => {
  return +localStorage.testMode === 1;
};

export const getDefaultTestWeek = (): [Date, Date] | null => {
  if (!isTestMode()) {
    return null;
  }
  try {
    const storedTestWeek = localStorage.testWeek;
    if (!storedTestWeek) {
      return null;
    }

    const [from, to] = storedTestWeek.split('_');
    const fromDayJs = dayjs(from, 'YYYY-MM-DD');
    const toDayJs = dayjs(to, 'YYYY-MM-DD');

    if (!fromDayJs.isValid() || !toDayJs.isValid()) {
      return null;
    }

    return [fromDayJs.toDate(), toDayJs.toDate()];
  } catch {
    return null;
  }
};

export const isDevHost = (): boolean => {
  if (isTestMode()) {
    return false;
  }
  return (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'xn----7sbaoeepjszxkqfe5d.xn--p1ai' ||
    window.location.hostname === 'dev.retaildata.center'
  );
};
