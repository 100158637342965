import React, { useMemo, useRef, useState } from 'react';
import { PageContent } from '../../common/PageContent/PageContent';
import { $api } from '../../../api/utils';
import { useQuery } from '@tanstack/react-query';
import { TTableColumn, Table } from '../../common/Table/Table';
import { sorter } from '../../common/Table/sorter';
import dayjs from 'dayjs';
import styles from './ClientFilesList.module.scss';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';
import { getRole } from '../../../shared/utils/getRole';
import { ROLES } from '../../../shared/constants';
import { Button } from '../../../uikit/Button/Button';
import { queryClient } from '../../../store/query';
import { toast } from 'react-toastify';

type TClientsFilesListProps = {
  clientGuid?: string;
  notFoundCustomText: React.ReactNode;
};

type TFile = {
  guid: string;
  requestLink: string;
  requestFilename: string;
  createdAt: string;
  responseLink: string;
  responseFilename: string;
};

const getFilesList = async (clientGuid?: string): Promise<TFile[]> => {
  const { data } = await $api.get(`/link-gtin?clientGuid=${clientGuid}`);
  return data;
};

const useFilesList = (clientGuid?: string) => {
  return useQuery(['linkGtin', 'filesList', clientGuid], () =>
    getFilesList(clientGuid),
  );
};

export const ClientsFilesList: React.FC<TClientsFilesListProps> = ({
  clientGuid,
  notFoundCustomText,
}) => {
  const userInfo = useAuthorizedUser();
  const userRoles = userInfo.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);

  const queryData = useFilesList(clientGuid);

  const [reponseFileGuid, setResponseFileGuid] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const cols: TTableColumn<TFile>[] = useMemo(() => {
    const columns: TTableColumn<TFile>[] = [
      {
        key: 'requestFilename',
        title: 'Имя файла',
        sort: sorter('requestFilename'),
        size: 'min-content',
        render: (row) => {
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          const downloadLink = (
            <a
              className={styles.downloadLink}
              href={row.requestLink}
              download
              target="_blank"
              rel="noreferrer"
            />
          );
          return (
            <div className={styles.fileRow}>
              {downloadLink}
              {row.requestFilename}
            </div>
          );
        },
      },
      {
        key: 'createdAt',
        title: 'Дата загрузки',
        sort: sorter('createdAt'),
        render: (row) => dayjs(row.createdAt).format('DD.MM.YYYY HH:mm'),
        size: 'min-content',
      },
      {
        key: 'responseFilename',
        title: 'Результат линкования',
        sort: sorter('responseFilename'),
        render: (row) => {
          if (!row.responseFilename) {
            return 'В обработке';
          }
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          const downloadLink = (
            <a
              className={styles.downloadLink}
              href={row.responseLink}
              download
              target="_blank"
              rel="noreferrer"
            />
          );
          return (
            <div className={styles.fileRow}>
              {downloadLink}
              {row.responseFilename}
            </div>
          );
        },
      },
    ];

    if (isAdmin) {
      columns.push({
        key: 'actions',
        title: '',
        render: (row) => {
          return (
            <Button
              small
              testId={`send-file-${row.guid}`}
              onClick={() => {
                setResponseFileGuid(row.guid);
                fileInputRef.current?.click();
              }}
              disabled={isLoading}
            >
              {isLoading && reponseFileGuid === row.guid
                ? 'Загрузка...'
                : row.responseFilename
                ? 'Заменить'
                : 'Отправить файл'}
            </Button>
          );
        },
        size: 'min-content',
      });
    }

    return columns;
  }, [isAdmin, isLoading, reponseFileGuid]);

  return (
    <PageContent
      queryData={queryData}
      notFound={!queryData.data?.length}
      notFoundCustomText={
        isAdmin ? 'Нет загруженных файлов' : notFoundCustomText
      }
    >
      <input
        type="file"
        style={{ display: 'none' }}
        id="file"
        name="file"
        ref={fileInputRef}
        multiple={false}
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            setIsLoading(true);
            try {
              const formData = new FormData();
              formData.append('file', file);
              await $api.put(
                `/link-gtin/client/${clientGuid}/file/${reponseFileGuid}/upload`,
                formData,
              );
              queryClient.invalidateQueries([
                'linkGtin',
                'filesList',
                clientGuid,
              ]);
              toast.success(`Файл ${file.name} успешно загружен`);
            } catch (e) {
              toast.error(`Ошибка загрузки файла ${file.name}`);
            }
            e.target.value = '';
            setIsLoading(false);
          }
        }}
      />
      <Table rows={queryData.data || []} cols={cols} />
    </PageContent>
  );
};
