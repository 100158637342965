import { useState } from 'react';
import { ACCESS_TABS, actionEntities } from './constants';
import {
  createUserClient,
  createUserEmploy,
  editUser,
} from '../../../api/accesses/accesses';
import { getRole } from '../../../shared/utils/getRole';
import { ROLES } from '../../../shared/constants';
import './Accesses.scss';
import { toast } from 'react-toastify';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';

export const useAccesses = (queryClient) => {
  const [, setShowSidebar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setActiveTab] = useState(ACCESS_TABS[0].key);
  const [modalType, setModalType] = useState('create');
  const [selectedItem, setSelectedItem] = useState();
  const userInfo = useAuthorizedUser();
  const isAdmin = getRole(userInfo?.roles, ROLES.admin);
  const isClientManager = getRole(userInfo?.roles, ROLES.client_manager);
  const createButtonText = isAdmin ? 'Добавить клиента' : 'Добавить сотрудника';
  const titleText = isAdmin ? 'Компании' : 'Пользователи';

  const onFinish = (values) => {
    if (modalType !== 'edit') {
      if (isAdmin) {
        createUserClient(values)
          .then(() => {
            setShowModal(false);
            setSelectedItem(null);
            queryClient.invalidateQueries(['users']);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      } else {
        createUserEmploy(values, userInfo)
          .then(() => {
            setShowModal(false);
            setSelectedItem(null);
            queryClient.invalidateQueries(['users']);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
    } else {
      editUser(values)
        .then(() => {
          setShowModal(false);
          setSelectedItem(null);
          queryClient.invalidateQueries(['users']);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const onUpdateActionEntities = (value, entity) => {
    switch (entity) {
      case actionEntities.tab: {
        setActiveTab(value);
        break;
      }
      case actionEntities.modal: {
        setShowModal(value);
        break;
      }
      case actionEntities.modalType: {
        setModalType(value);
        break;
      }
      case actionEntities.selectedItem: {
        setSelectedItem(value);
        break;
      }
      case actionEntities.sidebar: {
        setShowSidebar(value);
        break;
      }
      default: {
        break;
      }
    }
  };

  return {
    showModal,
    modalType,
    onFinish,
    selectedItem,
    onUpdateActionEntities,
    isClientManager,
    createButtonText,
    titleText,
    isAdmin,
    setSelectedItem,
  };
};
