import React, { useMemo } from 'react';
import { ViewFeature } from '../reportConfigType';
import {
  TLineSelectProps,
  TOptionsList,
} from '../../../../../common/LineSelect/LineSelect';
import {
  getTreemapConfig,
  GRID_TOP_OPTIONS,
} from '../../../../../../shared/chartsConfigs/treemap';
import { Chart } from '../../../../../common/Chart/Chart';
import EChartsReact from 'echarts-for-react';
import { useGroupForTree } from '../../../../../../shared/utils/useGroupForTree';
import { DEFAULT_ECHARTS_PROPS } from '../../../../../../shared/charts';
import ReactDOM from 'react-dom';
import { useLineSelect } from '../../../../../../shared/hooks/useLineSelect';
import { useDeepMemo } from '../../../../../../shared/hooks/deepMemo';
import { useBreakpoint } from '../../../../../../uikit/hooks/useBreakpoint';

type TGetTreeMapFeatureConfig<TDataItem> = {
  topName: string;
  parentNameKey: keyof TDataItem;
  childrenNameKey: keyof TDataItem;
  innKey: keyof TDataItem;
  valueKey: keyof TDataItem;
  filename: string;
  groupKeyToNameMap?: Partial<Record<keyof TDataItem, keyof TDataItem>>;
  unwrapDataItem?: (data: TDataItem) => TDataItem[];
  customGroups?: Array<
    {
      key: string;
      label: string;
      parentKey: keyof TDataItem;
      hideTop?: boolean;
    } & Pick<
      TGetTreeMapFeatureConfig<TDataItem>,
      'childrenNameKey' | 'innKey' | 'parentNameKey' | 'valueKey'
    >
  >;
  countItemsLabel?: string;
};

type GridTopMode = 'all' | string;

const LINE_SELECT_PROPS: Partial<TLineSelectProps<any>> = {
  mobileIcon: 'dataTable',
};

const TOP_SELECT_PROPS: Partial<TLineSelectProps<any>> = {
  mobileIcon: 'layersIcon',
};

export const getTreeMapFeature = <TDataItem,>(
  config: TGetTreeMapFeatureConfig<TDataItem>,
): ViewFeature<TDataItem> => {
  const groupKeyToNameMap =
    config.groupKeyToNameMap ||
    ({} as Record<keyof TDataItem, keyof TDataItem>);

  const customGroups = config.customGroups || [];
  const customGroupsOptions = customGroups.map((item) => ({
    value: item.key,
    label: item.label,
  }));

  const TreeMapComponent: ViewFeature<TDataItem, GridTopMode>['component'] = ({
    rawData,
    dataFeatures,
    headerElement,
  }) => {
    const groupKey =
      (dataFeatures.find((feature) => feature.key === 'groupBy')
        ?.state as keyof TDataItem) || config.parentNameKey;

    const nameKey = groupKeyToNameMap[groupKey] || groupKey;

    const unwrappedData = useMemo(() => {
      const unwrapDataItem = config.unwrapDataItem;
      if (!unwrapDataItem) {
        return rawData;
      }
      return rawData.flatMap((item) => unwrapDataItem(item)).flat();
    }, [rawData]);

    const [customGroupsState, , customGroupsStateSelect] = useLineSelect(
      'custom-group',
      customGroups[0]?.key || '',
      customGroupsOptions,
      LINE_SELECT_PROPS,
    );

    const breakpoints = useBreakpoint();

    const gridTopOptions: TOptionsList<GridTopMode> = useMemo(() => {
      if (breakpoints.isSmall) {
        return [
          {
            value: 'all',
            label: 'Все',
          },
          ...GRID_TOP_OPTIONS.map((item) => ({
            value: `${item}`,
            label: `${item}`,
          })),
        ];
      }
      return [
        {
          value: 'all',
          label: 'Все',
        },
        ...GRID_TOP_OPTIONS.map((item) => ({
          value: `${item}`,
          label: `Топ-${item}`,
        })),
      ];
    }, [breakpoints.isSmall]);

    const [topState, , topStateSelect] = useLineSelect(
      'top',
      'all',
      gridTopOptions,
      TOP_SELECT_PROPS,
    );

    const currentGroup = customGroupsState
      ? customGroups.find((item) => item.key === customGroupsState)
      : null;
    const currentParentKey = currentGroup?.parentKey || groupKey;
    const currentParentNameKey = currentGroup?.parentNameKey || nameKey;
    const currentChildrenNameKey =
      currentGroup?.childrenNameKey || config.childrenNameKey;
    const currentInnKey = currentGroup?.innKey || config.innKey;
    const currentValueKey = currentGroup?.valueKey || config.valueKey;

    const groupConfig = useDeepMemo(
      {
        parentKey: currentParentKey,
        parentNameKey: currentParentNameKey,
        childrenNameKey: currentChildrenNameKey,
        innKey: currentInnKey,
        valueKey: currentValueKey,
      },
      'tree-map-group-config',
    );

    // @ts-ignore
    const gridData = useGroupForTree<TDataItem>(
      unwrappedData,
      groupConfig,
      true,
      nameKey !== config.parentNameKey || !!currentGroup,
    );

    const eChartsInstance = React.useRef<any>(null);

    return (
      <>
        <Chart fullHeight testId="tree-map" name="Диаграмма структуры">
          <EChartsReact
            {...DEFAULT_ECHARTS_PROPS}
            onChartReady={(instance) => {
              eChartsInstance.current = instance;
            }}
            option={getTreemapConfig(
              gridData,
              topState === 'all' ? topState : +topState,
              topState === 'all' &&
                groupKey === config.parentNameKey &&
                !currentGroup,
              config.topName,
              config.countItemsLabel,
            )}
          />
        </Chart>

        {headerElement &&
          ReactDOM.createPortal(
            <>
              {!currentGroup?.hideTop && topStateSelect}
              {customGroupsStateSelect}
            </>,
            headerElement,
          )}
      </>
    );
  };

  return {
    key: 'tree-map',
    title: 'Диаграмма структуры',
    icon: 'grid',
    hint: 'Диаграмма структуры',
    component: TreeMapComponent,
  };
};
