import React from 'react';
import ReactDOM from 'react-dom';
import { Loader } from '../Loader/Loader';

import styles from './FullscreenLoader.module.scss';

export const withLoader = async <TFnReturnType,>(
  fn: () => Promise<TFnReturnType>,
): Promise<TFnReturnType> => {
  const loader = document.querySelector(
    `.${styles.loaderContainer}`,
  ) as HTMLDivElement;
  loader.style.display = 'flex';
  try {
    const result = await fn();
    loader.style.display = 'none';
    return result;
  } catch (e) {
    loader.style.display = 'none';
    throw e;
  }
};

export const FullscreenLoader: React.FC = () => {
  return ReactDOM.createPortal(
    <div className={styles.loaderContainer}>
      <Loader />
    </div>,
    document.body,
  );
};
