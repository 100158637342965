import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DownloadChart } from '../../common/Chart/components/DownloadChart/DownloadChart';
import { LineSelect } from '../../common/LineSelect/LineSelect';
import { FullscreenButton } from '../../common/PageContent/components/FullscreenButton/FullscreenButton';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { ChordScreenshoter } from './chord/chord';
import { MapScreenshoter } from './map/map';
import { TreeMapScreenshoter } from './treeMap/treeMap';

const pages = [
  {
    path: 'map',
    component: MapScreenshoter,
    label: 'Карта',
  },
  {
    path: 'tree-map',
    component: TreeMapScreenshoter,
    label: 'TreeMap',
  },
  {
    path: 'chord',
    component: ChordScreenshoter,
    label: 'Chord',
    hideDownload: true,
  },
];

export const AdHocScreenshoter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/')[2];
  useEffect(() => {
    if (!path) {
      navigate(`/adhoc-screenshoter/${pages[0].path}`);
    }
  }, [navigate, path]);
  const currentPage = pages.find((page) => page.path === path);
  return (
    <>
      <PageHeader
        title="AdHocScreenshoter"
        align="left"
        rightTitleContent={
          <div
            style={{
              display: 'flex',
            }}
          >
            {!currentPage?.hideDownload && (
              <DownloadChart filename="screenshot" />
            )}
            <FullscreenButton />
            <LineSelect
              testId="report-type"
              options={pages.map((page) => ({
                label: page.label,
                value: page.path,
              }))}
              value={path}
              onChange={(value) => navigate(`/adhoc-screenshoter/${value}`)}
            />
          </div>
        }
      />
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.path}
            element={<page.component />}
            path={page.path}
          />
        ))}
      </Routes>
    </>
  );
};
