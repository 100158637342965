import React, { useEffect, useRef, useState } from 'react';
import { withRestriction } from '../../shared/hocs/withRestriction';
import { useNavigate, useParams } from 'react-router-dom';
import {
  updateSupportPage,
  useSupportPages,
} from '../../shared/queries/support';
import { PageHeader } from '../common/PageHeader/PageHeader';
import { Button } from '../../uikit/Button/Button';
import { PageContent } from '../common/PageContent/PageContent';
import { Input } from '../../uikit/Input/Input';

import styles from './SupportAdminEditor.module.scss';
import { useUniqueChapters } from './SupportAdminList';
import { withLoader } from '../common/FullscreenLoader/FullscreenLoader';
import { withErrorHandling } from '../../shared/utils/withErrorHandling';

import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';

import supportStyles from '../pages/Wiki/supportPageContainer.module.scss';
import classNames from 'classnames';
import TableModule from 'quill1-table';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { REPORTS } from '../pages/Reports/constants';
import { Select } from '../common/Select/Select';
import { toast } from 'react-toastify';
import { USER_FLAGS_LIST, UserFlags } from '../../shared/hooks/useUser';

Quill.register('modules/table', TableModule);

const REPORT_DEFAULT_NAME = 'Название отчёта';
const REPORT_PLACEHOLDER = '{{REPORT_PLACEHOLDER}}';
const REPORT_TEMPLATE = `<p><img src="https://placehold.co/600x400"></p><h1>${REPORT_PLACEHOLDER}</h1><p>Отчёт "${REPORT_PLACEHOLDER}" используется для ___</p><p>Отвечает на вопросы:</p><ol><li>А</li><li>Б</li><li>В</li></ol><h2><a href="https://dev.retaildata.center/support/726b6f55-f0d8-4283-ae88-be35135604b3" rel="noopener noreferrer" target="_blank">Фильтры</a></h2><ul><li>А</li><li>Б</li></ul><p>По умолчанию загружаются данные: ___</p><h2><a href="https://dev.retaildata.center/support/a3090672-29aa-442b-9bd1-ba624b212a4b" rel="noopener noreferrer" target="_blank">Отображения</a></h2><ul><li>Таблица<span style="color: rgb(53, 53, 53);"><img src="https://placehold.co/600x400"></span></li><li>Б<span style="color: rgb(53, 53, 53);"><img src="https://placehold.co/600x400"></span></li><li>В<span style="color: rgb(53, 53, 53);"><img src="https://placehold.co/600x400"></span></li><li>Г<span style="color: rgb(53, 53, 53);"><img src="https://placehold.co/600x400"></span></li></ul>`;

const removeEmptyLines = (text: string) => {
  return text
    .split('<p><br></p>')
    .join('')
    .split('<p></p>')
    .join('')
    .split('<h1><br></h1>')
    .join('')
    .split('<h2><br></h2>')
    .join('');
};

// don't change keys, it will break links
export const PAGES_LINKS = {
  'l-g': 'Линкование GTIN',
  'l-a': 'Линкование адресов',
  files: 'Файлы',
  users: 'Пользователи',
  upl: 'Выгрузки',
  dicts: 'Справочники',
  dict: 'Отдельный справочник',
  rep: 'Отчёты',
  db: 'Дашборд',
} as const;

const optimizeImages = async (content: string) => {
  const htmlString = content;

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const images = doc.querySelectorAll('img');

  const promises = Array.from(images).map((img) => {
    return new Promise((resolve, reject) => {
      const imgSrc = img.src;
      const image = new Image();
      image.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const oldWidth = image.width;
        const oldHeight = image.height;

        if (oldWidth <= 462) {
          resolve(null);
          return;
        }

        const newWidth = 462;
        const newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

        canvas.width = newWidth;
        canvas.height = newHeight;

        if (!ctx) {
          reject('Не удалось оптимизировать изображения');
          return;
        }

        ctx.drawImage(image, 0, 0, newWidth, newHeight);

        // Преобразуйте обратно в base64
        const resizedImgSrc = canvas.toDataURL();

        // Замените исходное изображение на новое
        img.src = resizedImgSrc;
        resolve(null);
      };
      image.src = imgSrc;
    });
  });

  await Promise.all(promises);

  const serializer = new XMLSerializer();
  const updatedHtmlString = serializer.serializeToString(doc);

  return updatedHtmlString;
};

export const SupportAdminEditor: React.FC = withRestriction(['ADMIN'], () => {
  const listQueryData = useSupportPages();
  const existingChapters = useUniqueChapters(listQueryData.data || []);

  const { guid } = useParams<{
    guid: string;
  }>();

  const currentSupportPage = listQueryData.data?.find(
    (page) => page.guid === guid,
  );

  const [title, setTitle] = useState<string>('');
  const [chapter, setChapter] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [order, setOrder] = useState<number>(0);
  const [report, setReport] = useState<string>('');
  const [userCondition, setUserCondition] = useState<UserFlags | ''>('');

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const quillRef = useRef<ReactQuill | null>();

  useEffect(() => {
    if (currentSupportPage?.guid) {
      setTitle(currentSupportPage.name);
      setChapter(currentSupportPage.chapter);
      setContent(currentSupportPage.text);
      setOrder(currentSupportPage.order);
      setReport(currentSupportPage.report);
    }
  }, [currentSupportPage?.guid]);

  const isTitleChanged = currentSupportPage?.name !== title;
  const isChapterChanged = currentSupportPage?.chapter !== chapter;
  const isContentChanged = currentSupportPage?.text !== content;
  const isOrderChanged = currentSupportPage?.order !== order;
  const isReportChanged = currentSupportPage?.report !== report;
  const isUserConditionChanged =
    currentSupportPage?.userCondition !== userCondition;

  const isSomethingChanged =
    isTitleChanged ||
    isChapterChanged ||
    isContentChanged ||
    isOrderChanged ||
    isReportChanged ||
    isUserConditionChanged;

  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title="Редактирование страницы"
        backButton="Назад"
        onBackClick={() => navigate('/support-admin')}
        rightTitleContent={
          <Button
            testId="save-button"
            reportPanel
            disabled={!isSomethingChanged}
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  const withOptimizedImages = await optimizeImages(
                    removeEmptyLines(content),
                  );
                  const guid = currentSupportPage?.guid!;
                  await updateSupportPage(guid || '', {
                    name: title.trim(),
                    chapter: chapter.trim(),
                    text: withOptimizedImages,
                    report: report || '',
                    order: order || 0,
                    userCondition: userCondition || '',
                  });
                  navigate('/support-admin');
                  toast.success(
                    <>
                      Страница успешно обновлена.{' '}
                      <a
                        href={`/support/${guid}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/support/${guid}`);
                        }}
                      >
                        Посмотреть
                      </a>
                    </>,
                  );
                }),
              );
            }}
          >
            Сохранить
          </Button>
        }
      ></PageHeader>
      <PageContent
        queryData={listQueryData}
        notFound={!currentSupportPage}
        notFoundCustomText="Страница не найдена"
      >
        <div className={styles.container}>
          <datalist id="chapters">
            {existingChapters.map((chapter) => (
              <option key={chapter} value={chapter} />
            ))}
          </datalist>
          <div className={styles.inputs}>
            <Input
              testId="title-input"
              label="Название"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              noGap
              small
              size={40}
            />
            <Input
              testId="chapter-input"
              label="Раздел"
              value={chapter}
              onChange={(e) => setChapter(e.target.value)}
              list="chapters"
              noGap
              small
              size={40}
            />
            <Input
              testId="order-input"
              label="Порядок"
              value={`${order}`}
              onChange={(e) => setOrder(+e.target.value)}
              noGap
              small
              size={4}
            />
            <Input
              testId="report-input"
              custom={
                <Select
                  small
                  options={[
                    {
                      value: '',
                      label: 'Не выбрано',
                    },
                    ...Object.keys(PAGES_LINKS).map((key) => ({
                      value: key,
                      label: `Стр: ${
                        PAGES_LINKS[key as keyof typeof PAGES_LINKS]
                      }`,
                      disabled: listQueryData.data?.some(
                        (page) => page.report === key && page.guid !== guid,
                      ),
                    })),
                    ...REPORTS.map((report) => ({
                      value: `${report.sectionKey}-${report.reportKey}`,
                      label: `Отчёт: ${report.name}`,
                      disabled:
                        listQueryData.data &&
                        listQueryData.data.some((page) => {
                          if (page.guid === guid) {
                            return false;
                          }
                          return (
                            page.report ===
                            `${report.sectionKey}-${report.reportKey}`
                          );
                        }),
                    })),
                  ]}
                  value={report}
                  onChange={(value) => setReport(value)}
                  label="Связь"
                  valueLabel={
                    PAGES_LINKS[report as keyof typeof PAGES_LINKS] ||
                    REPORTS.find(
                      (r) => `${r.sectionKey}-${r.reportKey}` === report,
                    )?.name ||
                    'Не выбрано'
                  }
                />
              }
              label="Связь"
              noGap
              small
            />
            <Input
              testId="user-condition-input"
              custom={
                <Select
                  small
                  options={[
                    {
                      value: '',
                      label: 'Не выбрано',
                    },
                    ...USER_FLAGS_LIST.map((flag) => ({
                      value: flag,
                      label: flag,
                    })),
                  ]}
                  value={userCondition}
                  onChange={(value) => setUserCondition(value as UserFlags)}
                  label="Условие"
                  valueLabel={userCondition || 'Не выбрано'}
                />
              }
              label="Условие"
              noGap
              small
            />
          </div>
          <Checkbox
            testId="show-preview-checkbox"
            label="Показать превью"
            checked={showPreview}
            onChange={() => {
              setShowPreview(!showPreview);
            }}
          />
          <div className={styles.hint}>
            <div>
              Рекомендуется использовать H1 и H2 заголовки. Заголовки H1 и H2
              будут отображаться в блоке содержания наверху страницы.
            </div>
            <div>
              Не используйте пустые строки для переносов - они будут{' '}
              <button
                onClick={() => {
                  setContent(removeEmptyLines(content));
                }}
              >
                удалены
              </button>{' '}
              при сохранении. Для организации отступов изпользуйте заголовки.
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                const reportName = title || REPORT_DEFAULT_NAME;
                setContent(
                  REPORT_TEMPLATE.split(REPORT_PLACEHOLDER).join(reportName),
                );
              }}
            >
              Вставить шаблон для отчёта
            </button>
          </div>
          <div className={styles.editor}>
            {!showPreview && (
              <div className={styles.quillContainer}>
                <ReactQuill
                  ref={(el) => {
                    quillRef.current = el;
                  }}
                  className={classNames(
                    styles.quill,
                    supportStyles.supportPageContainer,
                  )}
                  modules={{
                    table: true,
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      [{ header: 1 }, { header: 2 }],
                      ['bold', 'italic', 'underline', 'blockquote'],
                      [
                        {
                          color: [
                            '#353535',
                            '#DA2E0B',
                            'rgba(42, 37, 54, 0.4)',
                          ],
                        },
                        {
                          background: [
                            'transparent',
                            '#353535',
                            '#FFDF2A',
                            '#DA2E0B',
                          ],
                        },
                      ],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                      ],
                      ['link', 'image'],
                      [
                        {
                          table: TableModule.tableOptions(),
                        },
                        {
                          table: [
                            'insert',
                            'append-row-above',
                            'append-row-below',
                            'append-col-before',
                            'append-col-after',
                            'remove-col',
                            'remove-row',
                            'remove-table',
                            'split-cell',
                            'merge-selection',
                            'remove-cell',
                            'remove-selection',
                            'undo',
                            'redo',
                          ],
                        },
                      ],
                      ['clean'],
                    ],
                  }}
                  onChange={(value) => setContent(value)}
                  value={content}
                />
              </div>
            )}
            {showPreview && (
              <div
                className={classNames(
                  styles.preview,
                  supportStyles.supportPageContainer,
                )}
                dangerouslySetInnerHTML={{ __html: removeEmptyLines(content) }}
              />
            )}
          </div>
        </div>
      </PageContent>
    </>
  );
});
