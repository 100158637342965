import React, { memo, useEffect, useRef } from 'react';
import { useLottie } from 'lottie-react';

import styles from './FavoriteButton.module.scss';

import morphAnimation from './animations/system-outline-20-bookmark.json';
import { Tooltip } from '../Tooltip/Tooltip';
import { useUserSetting } from '../../../shared/hooks/useUserSettings';

type TFavoriteButtonProps = {
  reportKey: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};

export const useFavoritesList = () => {
  const [favotiteReports] = useUserSetting(`favoriteReports`, {});

  return favotiteReports;
};

export const FavoriteButton: React.FC<TFavoriteButtonProps> = memo(
  ({ reportKey, tooltipPlacement = 'top' }) => {
    const [favotiteReports, setFavoriteReports] = useUserSetting(
      `favoriteReports`,
      {},
    );

    const isInFavorite = !!favotiteReports[reportKey];

    const setIsInFavorite = (value: boolean) => {
      if (value) {
        setFavoriteReports({
          ...favotiteReports,
          [reportKey]: true,
        });
      } else {
        const { [reportKey]: _, ...rest } = favotiteReports;
        setFavoriteReports(rest);
      }
    };

    const {
      View: Icon,
      goToAndStop,
      goToAndPlay,
      getDuration,
      setSpeed,
    } = useLottie(
      {
        animationData: morphAnimation,
        loop: false,
        autoplay: false,
      },
      {
        width: '24px',
        height: '24px',
        marginTop: '-5px',
      },
    );

    const isFirstRender = useRef(true);
    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        if (isInFavorite) {
          const duration = getDuration(true);
          if (duration) {
            goToAndStop(duration, true);
          }
        }
      }
    }, [isInFavorite]);

    const previousIsInFavorite = useRef(isInFavorite);
    useEffect(() => {
      if (isFirstRender.current) {
        return;
      }

      if (previousIsInFavorite.current === isInFavorite) {
        return;
      }

      if (isInFavorite) {
        goToAndPlay(0);
        setSpeed(2);
      } else {
        setSpeed(-2);
        const duration = getDuration(true);
        if (duration) {
          goToAndPlay(duration, true);
        }
      }
      previousIsInFavorite.current = isInFavorite;
    }, [isInFavorite]);

    return (
      <Tooltip
        testId={'favorites-toggle'}
        text={isInFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
        placement={tooltipPlacement}
      >
        <span
          key={reportKey}
          data-test-id="favorite-button"
          data-test-is-in-favorite={isInFavorite}
          className={styles.button}
          onClick={() => {
            setIsInFavorite(!isInFavorite);
          }}
        >
          {Icon}
        </span>
      </Tooltip>
    );
  },
);
