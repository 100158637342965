import { useCallback, useState } from 'react';
import { storage } from '../utils/storage';

type TState =
  | string
  | number
  | boolean
  | null
  | {
      [key: string]: TState;
    };

export const useLocalStorageState = <TStateType extends TState>(
  key: string,
  defaultState: TStateType,
): [value: TStateType, setValue: (newValue: TStateType) => void] => {
  const [state, setState] = useState<TStateType>(() => {
    const value = storage.getItem(key);
    if (value === null) {
      return defaultState;
    }
    return JSON.parse(value);
  });

  const updateState = useCallback(
    (newState: TStateType) => {
      setState(newState);
      const stateJSON = JSON.stringify(newState);
      storage.setItem(key, stateJSON);
    },
    [key],
  );

  return [state, updateState];
};
