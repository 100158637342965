const LABELS = ['тыс.', 'млн.', 'млрд.'];
const LABELS_SHORT = ['т', 'м', 'м'];

function getRoundedValue(
  value: number,
  digits: number,
  short = false,
): [number, string] {
  if (value < 1000) {
    return [value, ''];
  }
  const labels = short ? LABELS_SHORT : LABELS;
  if (value < 1000000) {
    return [value / 1000.0, labels[0]];
  }
  if (value < 1000000000) {
    return [value / 1000000.0, labels[1]];
  }
  return [value / 1000000000, labels[2]];
}

export function formatNumericNames(
  value: number | null | string,
  short = false,
): string {
  if (value === null) {
    return '';
  }
  if (typeof value === 'string') {
    value = parseFloat(value);
  }
  if (isNaN(value)) {
    return '';
  }
  const [numeric, suffix] = getRoundedValue(value, 1, short);
  const numericFormattedByLocale = new Intl.NumberFormat('ru-RU').format(
    Number(numeric.toFixed(1)),
  );
  if (!suffix) {
    return numericFormattedByLocale;
  }
  return `${numericFormattedByLocale} ${suffix}`;
}
