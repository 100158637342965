import { DS3R8Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import {
  getTransposeFeature,
  TWithTransposedFields,
} from '../AllPurposeReport/dataFeatures/transpose';
import { getIntervalFilter } from '../AllPurposeReport/filters/interval';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { getIntervalTransposeConfig } from '../AllPurposeReport/presets/transposeIntervals';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTwoLinesChartFeature } from '../AllPurposeReport/viewFeatures/twoLinesChart';

const NAME = 'Динамика розничных продаж и цены';

export const ds3o8Config: TReportConfigType<DS3R8Response> = {
  title: NAME,
  backendKey: 'ds3r8',

  dataFeatures: [
    getTransposeFeature<DS3R8Response>(
      getIntervalTransposeConfig('YYYY-MM-DD', 'sellerAddrSfCode', [
        {
          label: 'Объем продаж',
          valueKey: 'salesCnt',
        },
        {
          label: 'Средневзвешенная цена за шт/кг, руб.',
          valueKey: 'avgPrice',
        },
      ]),
    ),
  ],

  viewFeatures: [
    getTableFeature<TWithTransposedFields<DS3R8Response>>({
      defaultSort: 'LAST',
      filename: NAME,
      baseColumns: [
        {
          key: 'sellerAddrSfCode',
          title: 'Код СФ',
          isNumeric: true,
          sort: sorter('sellerAddrSfCode', true),
          size: '130px',
        },
        {
          key: 'sellerAddrSfName',
          title: 'Субъект РФ',
          sort: sorter('sellerAddrSfName'),
        },
      ],
    }),
    getTwoLinesChartFeature<TWithTransposedFields<DS3R8Response>>({
      filename: NAME,
      selectByKey: 'sellerAddrSfCode',
      nameByKey: 'sellerAddrSfName',
      valuesPrefix: 'transposed_',
      measures: [
        {
          label: 'Объем продаж',
          valueKey: 'salesCnt',
        },
        {
          label: 'Средневзвешенная цена за шт/кг, руб.',
          valueKey: 'avgPrice',
        },
      ],
    }),
  ],

  filters: [
    getIntervalFilter({
      single: false,
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товар',
      icon: 'package',
      required: true,
      single: true,
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'rfCodes',
      mode: 'codes',
      label: 'Субъект Федерации',
      icon: 'location',
    }),
  ],
  requiredData: {},
  requiredCommonData: ['gtins', 'rfCodes'],

  isRequiredFieldsFilled: (filter) =>
    Boolean(filter.periods?.length) && Boolean(filter.gtin),
};
