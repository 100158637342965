import { DS2R9Response } from '../../../../../api/reports/Api';
import { toNumberWT } from '../../../../../shared/utils/toNumberWT';
import { toPercent } from '../../../../../shared/utils/toPercent';
import { sorter } from '../../../../common/Table/sorter';
import { customPeriodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getMapFeature } from '../AllPurposeReport/viewFeatures/map';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Дистрибуция товара по регионам';

export const ds2o9Config: TReportConfigType<DS2R9Response> = {
  title: NAME,
  backendKey: 'ds2r9',

  prepareDataItem: (item) => ({
    ...item,
    addrDistrib: item.addrDistrib / 100,
  }),

  dataFeatures: [],

  viewFeatures: [
    getTableFeature<DS2R9Response>({
      defaultSort: 'addrDistrib',
      filename: NAME,
      baseColumns: [
        {
          key: 'sellerAddrSfCode',
          title: 'Код СФ',
          size: `130px`,
          sort: sorter('sellerAddrSfCode', true),
          isNumeric: true,
        },
        {
          key: 'sellerAddrSfName',
          title: 'Название СФ',
          sort: sorter('sellerAddrSfName'),
        },
        {
          key: 'addrCnt',
          title: 'Количество адресов продаж',
          isNumeric: true,
          sort: sorter('addrCnt', true),
        },
        {
          key: 'addrDistrib',
          title: 'Дистрибуция %',
          sort: sorter('addrDistrib', true),
          withBar: true,
          isNumeric: true,
          render: (row: any) => {
            return toPercent(Number(row.addrDistrib), 0);
          },
          renderForExport: (row: any) => {
            return toPercent(Number(row.addrDistrib), 0);
          },
        },
        {
          key: 'salesCnt',
          title: 'Объем розничных продаж, шт',
          sort: sorter('salesCnt', true),
          isNumeric: true,
          render: (row: any) => {
            return toNumberWT(row.salesCnt);
          },
          renderForExport: (row: any) => {
            return toNumberWT(row.salesCnt);
          },
        },
      ],
    }),
    getMapFeature<DS2R9Response>({
      filename: NAME,
      regionCodeKey: 'sellerAddrSfCode',
      valueKey: 'addrDistrib',
      subValueKey: 'addrCnt',
      valueLabel: 'Дистрибуция',
      subValueLabel: 'Кол-во точек продаж',
    }),
  ],

  filters: [
    customPeriodFilter({
      enabledOptions: {
        year: false,
        period: false,
      },
      defaultSelectType: 'month',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товар',
      icon: 'package',
      single: true,
      required: true,
    }),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['gtins'],

  isRequiredFieldsFilled: (filter) =>
    Boolean(filter.gtin) || Boolean(filter.dictGtinName),
};
