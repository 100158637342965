import React from 'react';
import { ReportMeta } from './constants';
import {
  useModuleAOnly,
  useReportAttempt,
} from '../../../shared/hooks/useModuleAOnly';
import { getDeclarator } from '../../../shared/utils/declOfNum';

import styles from './Reports.module.scss';
import { Spacer } from '../../common/Spacer/Spacer';
import { Button } from '../../../uikit/Button/Button';
import { useLottie } from 'lottie-react';
import { FavoriteButton } from '../../common/FavoriteButton/FavoriteButton';

type TReportProps = {
  report: ReportMeta;
  viewMode: 'grid' | 'table';
  navigate: (path: string) => void;
};

const prefixDeclarator = getDeclarator(
  ['Остался', 'Осталось', 'Осталось'],
  false,
  false,
);
const postfixDeclarator = getDeclarator(
  ['тестовый запрос', 'тестовых запроса', 'тестовых запросов'],
  false,
  false,
);

export const Report: React.FC<TReportProps> = ({
  report,
  viewMode,
  navigate,
}) => {
  const reportFavKey = `ds${report.sectionKey}r${report.reportKey}`;

  const { data } = useReportAttempt();
  const isModuleAOnly = useModuleAOnly();

  const key = `${report.sectionKey}-${report.reportKey}`;
  const limitKey = `DS${report.sectionKey}R${report.reportKey}`;
  const used = data?.[limitKey] || 0;
  const limit = 2 - used;

  const { View: Icon, goToAndPlay } = useLottie(
    {
      animationData: report.icon,
      loop: false,
      autoplay: false,
    },
    {
      width: 46,
      height: 46,
    },
  );

  return (
    <div
      key={key}
      data-test-id="report"
      data-test-dataset-key={report.sectionKey}
      data-test-report-key={report.reportKey}
      className={styles.report}
    >
      <div className={styles.left}>
        <div
          className={styles.icon}
          data-test-id="report-icon"
          onMouseEnter={() => goToAndPlay(0)}
        >
          {Icon}
        </div>
        {viewMode === 'grid' && (
          <div className={styles.key} data-test-id="report-code">
            ДС-{report.sectionKey} • О-{report.reportKey}
          </div>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.titleGroup}>
          <div className={styles.title} data-test-id="report-title">
            {report.name}
          </div>
          <div className={styles.tags} data-test-id="report-tags">
            {report.tags.map((tag) => {
              return (
                <div key={tag} className={styles.tag}>
                  {tag}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.description} data-test-id="report-description">
          {Array.isArray(report.simpleDescription) ? (
            <ul>
              {report.simpleDescription.map((line) => (
                <li key={line}>{line}</li>
              ))}
            </ul>
          ) : (
            report.simpleDescription
          )}
        </div>
        {viewMode === 'table' && <Spacer />}
        {viewMode === 'table' && (
          <div className={styles.key} data-test-id="report-code">
            ДС-{report.sectionKey} • О-{report.reportKey}
          </div>
        )}
        <div className={styles.buttonsRow} data-test-id="report-buttons">
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (limit === 0) {
                return;
              }
              navigate(
                `/reports/report-ds${report.sectionKey}-r${report.reportKey}`,
              );
            }}
            disabled={limit === 0}
            as="a"
            href={`/reports/report-ds${report.sectionKey}-r${report.reportKey}`}
            testId={`report-ds${report.sectionKey}-r${report.reportKey}`}
          >
            Открыть
          </Button>
          <div className={styles.favorite} data-test-id="report-favorite">
            <FavoriteButton reportKey={reportFavKey} key={reportFavKey} />
          </div>
        </div>
        {isModuleAOnly && (
          <div
            className={styles.moduleBHint}
            data-test-id="report-module-b-hint"
          >
            {limit > 0 && (
              <div>
                {prefixDeclarator(limit)} {limit} {postfixDeclarator(limit)}
              </div>
            )}
            {limit === 0 && <div>Не осталось тестовых запросов</div>}
          </div>
        )}
      </div>
    </div>
  );
};
