import * as d3 from "d3";
import {nest} from "d3-collection";

// console.log('sankey-lib v2.0v20220603');

export function classSankey()  {
    var sankey = {},
        nodeWidth = 24,
        nodePadding = 8,
        size = [1, 1],
        frameSize = { width: 1, height: 1 },
        nodes = [],
        links = [],
		padding = { top: 10, right: 10, bottom: 10, left: 10 },
		graph_type = 'common',
		unit_nm = '',
		measure = 'value',
		measure_weight = null,
		measure_weight_unit_nm = '',
		measure_value_shift = '',
      	align = 'justify', // left, right, center or justify,
		initialized = 0,
		zoomInfo = null,
        dataDttm = null
		;
		
    sankey.dataDttm = function(_) {
        if (!arguments.length) return dataDttm;
        dataDttm = _;
        return sankey;
    };
    sankey.zoomInfo = function(_) {
        if (!arguments.length) return zoomInfo;
        zoomInfo = _;
        return sankey;
    };
	
    sankey.nodeWidth = function(_) {
        if (!arguments.length) return nodeWidth;
        nodeWidth = +_;
        return sankey;
    };

    sankey.nodePadding = function(_) {
        if (!arguments.length) return nodePadding;
        nodePadding = +_;
        return sankey;
    };

    sankey.nodes = function(_) {
        if (!arguments.length) return nodes;
        nodes = _;
        return sankey;
    };

    sankey.links = function(_) {
        if (!arguments.length) return links;
        links = _;
        return sankey;
    };

    sankey.size = function(_) {
        if (!arguments.length) return size;
        size = _;
        return sankey;
    };

    sankey.frameSize = function(_) {
        if (!arguments.length) return frameSize;
        frameSize = structuredClone(_);
        return sankey;
    };
	
    sankey.padding = function(_) {
        if (!arguments.length) return padding;
        padding = _;
        return sankey;
    };
	
    sankey.graph_type = function(_) {
        if (!arguments.length) return graph_type;
        graph_type = _;
        return sankey;
    };
	
	sankey.align = function(_) {
	    if (!arguments.length) return align;
	    align = _.toLowerCase();
	    return sankey;
	};

	sankey.unit_nm = function(_) {
		if (!arguments.length) return unit_nm;
		unit_nm = _;
		return sankey;
	};

	sankey.measure = function(_) {
		if (!arguments.length) return measure;
		measure = _;
		return sankey;
	};

	sankey.measure_weight = function(_) {
		if (!arguments.length) return measure_weight;
		measure_weight = _;
		return sankey;
	};
		
	sankey.measure_weight_unit_nm = function(_) {
		if (!arguments.length) return measure_weight_unit_nm;
		measure_weight_unit_nm = _;
		return sankey;
	};
	
	sankey.measure_value_shift = function(_) {
		if (!arguments.length) return measure_value_shift;
		measure_value_shift = _;
		return sankey;
	};
	
    sankey.layout = function(iterations) {
		computeNodeLinks();
		computeNodeValues();
		if( !initialized ){
			computeNodeBreadths();
			initialized=1;
			computeNodeLinks();
			computeNodeValues();
		}
		// Whithout circular detection
		// computeNodeBreadths(revers=false);
		computeNodeBreadths(false);
		
        computeNodeDepths(iterations);
        computeLinkDepths();
        return sankey;
    };

    sankey.relayout = function() {
        computeLinkDepths();
        return sankey;
    };

    sankey.link = function() {
        var curvature = .5;

        function link(d) {
        var x0 = d.source.x + d.source.dx,
            x1 = d.target.x,
            xi = d3.interpolateNumber(x0, x1),
            x2 = xi(curvature),
            x3 = xi(1 - curvature),
            y0 = d.source.y + d.sy,
            y1 = d.target.y + d.ty;

        return "M" + x0 + "," + y0
            + "C" + x2 + "," + y0
            + " " + x3 + "," + y1
            + " " + x1 + "," + y1
            // move down for the wanted width
            + "l" + 0  + "," + d.dy
            // draw another path below mirroring the top
            + "C" + x3 + "," + (y1 + d.dy)
            + " " + x2 + "," + (y0 + d.dy)
            + " " + x0 + "," + (y0 + d.dy);
        }

        link.curvature = function(_) {
            if (!arguments.length) return curvature;
            curvature = +_;
            return link;
        };
    

        return link;
    };

	sankey.mvalue = function(l){
		return mvalue(l);
	}
	
	sankey.mwvalue = function(l){return mwvalue(l);}
	
	function mvalue(l){ return l[measure]; }

	function mwvalue(l){ return l[measure_weight]; }

    // Populate the sourceLinks and targetLinks for each node.
    // Also, if the source and target are not objects, assume they are indices.
    function computeNodeLinks() {
        nodes.forEach(function(node) {
            node.sourceLinks = [];
            node.targetLinks = [];
        });
        links.forEach(function(link) {
            var source = link.source,
                target = link.target;
            if (typeof source === "number") source = link.source = nodes[link.source];
            if (typeof target === "number") target = link.target = nodes[link.target];
            source.sourceLinks.push(link);
            target.targetLinks.push(link);
			
			// console.log( 'link: ' + link.source.node + ' -> ' + link.target.node + ' type ' + link.type);
        });
    }

    // Compute the value (size) of each node by summing the associated links.
    function computeNodeValues() {
        nodes.forEach(function(node) {
			// Node value - for visualization, use sum(rounded)
			node.value = Math.max(
		         d3.sum(node.sourceLinks, function(d){ return mvalue(d); }),
		         d3.sum(node.targetLinks, function(d){ return mvalue(d); })
		    );
			
			// Node value In / Out - using link type as is
            node.valueOut = d3.sum(node.sourceLinks.filter(d => d.type===1), function(d){ return mvalue(d); }) 
			              + d3.sum(node.targetLinks.filter(d => d.type===2), function(d){ return mvalue(d); });
            node.valueIn  = d3.sum(node.targetLinks.filter(d => d.type===1), function(d){ return mvalue(d); }) 
						  + d3.sum(node.sourceLinks.filter(d => d.type===2), function(d){ return mvalue(d); });
        });
    }

    // Iteratively assign the breadth (x-position) for each node.
    // Nodes are assigned the maximum breadth of incoming neighbors plus one;
    // nodes with no incoming links are assigned breadth zero, while
    // nodes with no outgoing links are assigned the maximum breadth.
    function computeNodeBreadths(revers=true) {
		
		// function sortBubble(inputArr,sortFunction){
		//     let len = inputArr.length;
		//     let swapped;
		// 	let iter=0;
		//     do {
		//         swapped = false;
		//         for (let i = 0; i < len-1; i++) {
		// 			//console.log('sort (' + iter + '.' + i + ')' + inputArr[i].node + ' vs ' + inputArr[i+1].node);
					
		//             if (sortFunction(inputArr[i], inputArr[i + 1])) {
		//                 //let tmp = inputArr[i];
		//                 //inputArr[i] = inputArr[i + 1];
		//                 //inputArr[i + 1] = tmp;
						
		// 				var item = inputArr[i];
		// 				inputArr.splice(i,1);
		// 				inputArr.splice(i+1,0,item);
						
		//                 swapped = true;
		//             }
		//         }
		// 		iter++;
		//     } while (swapped && iter<1000);
		//     return inputArr;
		// }
		

		// Добавляем сортировку в массиве - чтобы вправо уходили те, у кого разница между входом и выходом больше
		function compareNumbers(a, b) {
			var diff = 0;
		
			if (a.sourceLinks.length === 0 && b.sourceLinks.length > 0 ){
				diff = 1
			} else if (a.sourceLinks.length > 0 && b.sourceLinks.length === 0 ) {
				diff = -1
			} else if (a.targetLinks.length === 0 && b.targetLinks.length > 0 ){
				diff = -1
			} else if (a.targetLinks.length > 0 && b.targetLinks.length === 0 ) {
				diff = 1
			} else {
				// Если есть ссылка A -> B то делаем меньше больше на значение, с учётом направления
				a.sourceLinks.forEach( function(el){ if( el.target === b ){ 
					if (el.type === 1) {
						diff = diff - mvalue(el);
					} else if (el.type === 2) {
						diff = diff + mvalue(el);
					} 
				}; });

				// Если есть ссылка B -> A то делаем меньше на значение, с учётом направления 
				a.targetLinks.forEach( function(el){ if( el.source === b ){ 
					if (el.type === 1) {
						diff = diff + mvalue(el);
					} else if (el.type === 2) {
						diff = diff - mvalue(el);
					} 
				}; });
			}
			
			return diff;
		}
		
        var remainingNodes = nodes.sort(compareNumbers), // sortBubble(nodes, compareNumbers),
            nextNodes = [],
            x = 0;
			
		var passedNodes=[];

        while (remainingNodes.length) {
            nextNodes = [];
			var firstNode = null;
			
			remainingNodes.forEach(function(node) {
				node.sourceLinks.forEach(function(link) {
					link.marker=0;
					return link;
				});
				return node;
			});
			
			remainingNodes.forEach(node => {

                node.x = x;
                node.dx = nodeWidth;
				
				if ( !firstNode ) { 
					firstNode = node;
				}
				
				node.sourceLinks.forEach(function(link) {
					link.marked = 1;
							
					// circular detection - to passed
					if (revers && (passedNodes.indexOf(link.target) >= 0)) {} 
					// circular detection - link to first in iteration
					else if (revers && (link.target === firstNode) ) {
						// source is not in list of passed
					 	if( passedNodes.indexOf(link.source) < 0)   {
							
							//if(link.source.node === 5 && link.target.node === 4){
								// console.log('node 8 index:' + i);
								
								//console.log('CIRCULAR LINK ' + link.source.node + ' -> ' + link.target.node + ' when [' + 
								//	passedNodes.map( function(el){ return el.node; } ).toString() + '] type ' + link.type + ' and source is ' + firstNode.node);
							//}
							
							//console.log('CIRCULAR LINK ' + link.source.node + ' -> ' + link.target.node + ' when [' + 
							//	passedNodes.map( function(el){ return el.node; } ).toString() + '] and source is ' + firstNode.node);
							
							// change link direction and type - prevent circular chain of links 
							link.target=link.source;
							link.source=firstNode;
							link.type=2;
						
							// console.log('changed! ' + link.source.node + ' -> ' + link.target.node + ' type=2');
						} 
							
					} else if (nextNodes.indexOf(link.target) >= 0) {} 
					else {
		                nextNodes.push(link.target);
						// console.log( '( ' + link.source.node + ' -> ' + link.target.node + ', ' + link.type +' ) push -> [' + nextNodes.map( function(el){ return el.node; } ).toString() + ']');
					}
					
					return link;
                });
            });
			
			// DK - по всем узлам отмечаем кто выбыл из списка последующих, вносим их в список невозвратных для контроля циклов
			// Вероятно это можно исключить совсем!
            remainingNodes.forEach( node => {
				if (nextNodes.indexOf(node) < 0) {
					passedNodes.push(node);
				}
			})
			
			if(revers) {
				// Оцениваем вес оставшихся узлов - по кол-ву входящих связей от неприкасаемых
				nextNodes.forEach(function(el){
					el.weight = (el.targetLinks.filter( d => passedNodes.indexOf(d.source) < 0 ).length );
					return el;
				});
							
				nextNodes.sort(compareNumbers);
				
				// Первый переносим в конец - и сортируем, так выправляются ситуации с пропускаемыми в sort() сравнениями связаны вершин 
				// Надо бы это оптимизировать - делать в зависимости от кол-ва узлов и остановиться если первый повторился
				if( nextNodes.length > 1) for( var iter=0; iter<nextNodes.length*2; iter++) {
					var best=nextNodes[0];
					// console.log('best ' + best.node  );
					for( var i=(nextNodes.length-1); i>=0; i--) {
						if( compareNumbers(nextNodes[0],nextNodes[i]) > 0 ) {
							// переносим первый на позицию после того кто меньше
							// var better=nextNodes[i];
							var item=nextNodes.shift();
							nextNodes.splice(i,0,item);
							break;
						}
					}
					if( best === nextNodes[0] ) {
						break;
					}
				}
			}
			
            remainingNodes = nextNodes;
            ++x;
			
			if(x > 1000){
				console.log('STOP')
				remainingNodes=[]
			}
        }
		
	    if (align === 'center') {
	     	moveSourcesRight();
	    } else if (align === 'justify') {
	     	moveSinksRight(x);
	    } else if (align === 'right') {
	     	moveSinksRight(x);
	     	moveSourcesRight();
	    }
		
        // scaleNodeBreadths((size[0] - nodeWidth) / (x - 1));
		scaleNodeBreadths((size[0] - nodeWidth - padding.left - padding.right) / (x - 1), padding.left);
		
		// console.log('[' + nodes.map( function(nd){ return nd.node; } ).toString() + ']' );
    }

    function moveSourcesRight() {
		// Sort nodes Index desc by x value
		var nodesIndexToCheck = nodes.map( function(el){ return el.node; } );
		
		nodesIndexToCheck.sort(function(a,b){return nodes[b].x - nodes[a].x;});
		
		// console.log( nodesIndexToCheck.reverse() );
		
        nodesIndexToCheck.forEach(function(nodeIndex) {
			var node = nodes[nodeIndex];
            if (node.sourceLinks.length) {
                node.x = d3.min(node.sourceLinks, function(d) {
                    return d.target.x;
                }) - 1;
			}
        });
    }
	
    function moveSinksRight(x) {
        nodes.forEach(function(node) {
            if (!node.sourceLinks.length) {
                node.x = x - 1;
            }
        });
    }

    function scaleNodeBreadths(kx,paddingLeft=0) {
        nodes.forEach(function(node) {
			node.x = paddingLeft + node.x * kx;
        });
    }

    function computeNodeDepths(iterations) {

		// nest больше нет
        // var nodesByBreadth = d3.nest()
        //     .key(function(d) {
        //         return d.x;
        //     })
        //     .sortKeys(d3.ascending)
        //     .entries(nodes)
        //     .map(function(d) {
        //         return d.values;
        //     });

        var nodesByBreadth = nest()
            .key(function(d) {
                return d.x;
            })
            .sortKeys(d3.ascending)
            .entries(nodes)
            .map(function(d) {
                return d.values;
            });

		// на новый лад - надо переписать 
		// var nodesByBreadth = d3.groups(data, d => d.x)
		// 	.sort((a, b) => d3.ascending(a[0], b[0]))
		// 	.entries(nodes)

        //
        initializeNodeDepth();
        resolveCollisions();
        for (var alpha = 1; iterations > 0; --iterations) {
            relaxRightToLeft(alpha *= .99);
            resolveCollisions();
            relaxLeftToRight(alpha);
            resolveCollisions();
        }

        function initializeNodeDepth() {
            var ky = d3.min(nodesByBreadth, function(nodes) {
                return (size[1] - padding.top - padding.bottom - (nodes.length - 1) * nodePadding) / 
				       d3.sum(nodes, function(d){ return (d.value>=1 ? d.value : 1); });
            });
			
			// взвешанное значение узла нужно считать от взвешенных значений связей - а не сумму на поправояный коэффициент
            nodesByBreadth.forEach(function(nodes) {
                nodes.forEach(function(node, i) {
                    node.y = i;
					// node.value * ky;
					var valueKy = Math.max(
							         d3.sum(node.sourceLinks, function(d){ return (mvalue(d) * ky >= 1 ? mvalue(d) * ky : 1 ); }),
							         d3.sum(node.targetLinks, function(d){ return (mvalue(d) * ky >= 1 ? mvalue(d) * ky : 1 ); })
							    );
                    node.dy = (valueKy >= 1 ? valueKy : 1 );
                });
            });

			// tmp_dy_in=0;
			// tmp_dy_out=0;
			// tmp_value_in=0;
			// tmp_value_out=0;
			
            links.forEach(function(link) {
				// link.value * ky;
                link.dy = (mvalue(link) * ky >= 1 ? mvalue(link) * ky : 1 );
            });
        }

        function relaxLeftToRight(alpha) {
            nodesByBreadth.forEach(function(nodes, breadth) {
                nodes.forEach(function(node) {
                    if (node.targetLinks.length) {
                        var y = d3.sum(node.targetLinks, weightedSource) / d3.sum(node.targetLinks, function(d){ return (mvalue(d)>=1 ? mvalue(d) : 1); });
                        node.y += (y - center(node)) * alpha;
                    }
                });
            });

            function weightedSource(link) {
				return center(link.source) * (mvalue(link) >= 1 ? mvalue(link) : 1);
            }
        }

        function relaxRightToLeft(alpha) {
            nodesByBreadth.slice().reverse().forEach(function(nodes) {
                nodes.forEach(function(node) {
                    if (node.sourceLinks.length) {
                        var y = d3.sum(node.sourceLinks, weightedTarget) / d3.sum(node.sourceLinks, function(d){ return (mvalue(d)>=1 ? mvalue(d) : 1); });
                        node.y += (y - center(node)) * alpha;
                    }
                });
            });

            function weightedTarget(link) {
                return center(link.target) * (mvalue(link) >= 1 ? mvalue(link) : 1);
            }
        }

        function resolveCollisions() {
            nodesByBreadth.forEach(function(nodes) {
                var node,
                    dy,
                    y0 = 0,
                    n = nodes.length,
                    i;

                // Push any overlapping nodes down.
                nodes.sort(ascendingDepth);
                for (i = 0; i < n; ++i) {
                    node = nodes[i];
                    dy = y0 - node.y;
                    if (dy > 0) node.y += dy;
                    y0 = node.y + node.dy + nodePadding;
                }

                // If the bottommost node goes outside the bounds, push it back up.
                dy = y0 - nodePadding - size[1] + padding.bottom;
                if (dy > 0) {
                    y0 = node.y -= dy;

                    // Push any overlapping nodes back up.
                    for (i = n - 2; i >= 0; --i) {
                        node = nodes[i];
                        dy = node.y + node.dy + nodePadding - y0;
	                    if (dy > 0) node.y -= dy;
						y0 = node.y;
                    }
                }
            });
        }
        function ascendingDepth(a, b) {
            return a.y - b.y;
        }
    }

    function computeLinkDepths() {
        nodes.forEach(function(node) {
            node.sourceLinks.sort(ascendingTargetDepth);
            node.targetLinks.sort(ascendingSourceDepth);
        });
        nodes.forEach(function(node) {
            var sy = 0,
                ty = 0;
            node.sourceLinks.forEach(function(link) {
                link.sy = sy;
                sy += link.dy;
            });
            node.targetLinks.forEach(function(link) {
                link.ty = ty;
                ty += link.dy;
            });
        });

        function ascendingSourceDepth(a, b) {
            return a.source.y - b.source.y;
        }

        function ascendingTargetDepth(a, b) {
            return a.target.y - b.target.y;
        }
    }

    function center(node) {
        return node.y + node.dy / 2;
    }

    // function value(link) {
    //     return (mvalue(link) >= 1 ? mvalue(link) : 1);
    // }

    return sankey;
};

export function sankey_get_changes(sankey){
	// console.log('=== Changes ===');

	if( !sankey ){
		console.log('WARNING: sankey object is empty');
		return null;
	}
	
	var changes = {
		"zoomInfo" : sankey.zoomInfo(),
		"align": sankey.align(),
		nodes : []
	}
	
    sankey.nodes().forEach(function(node) {
        if( node.hasOwnProperty("yr_moved") && node.yr_moved !== null ){
        	// console.log('' + node.node + ' -> ' + node.y_moved);
			changes.nodes.push({"id": node.node, "yr_moved": node.yr_moved});
        }
    });

	return changes;
}