import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { useOutsideClick } from 'rooks';

import styles from './Menu.module.scss';
import { hasRole, Role } from '../../../shared/utils/hasRole';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';

export type TMenuItem = {
  divider?: true;
  title?: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  roles?: Role[];
};

type TMenuProps = {
  items: Array<TMenuItem>;
  target: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  testId: string;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'top'
    | 'top-start'
    | 'top-end';
} & React.HTMLAttributes<HTMLDivElement>;

export const Menu: React.FC<TMenuProps> = ({
  items,
  target,
  open,
  onClose,
  testId,
  placement,
}) => {
  const userInfo = useAuthorizedUser();
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(target, menuRef, {
    placement,
  });
  useOutsideClick(
    {
      current: menuRef,
    },
    () => {
      onClose();
    },
    open,
  );
  if (!open) {
    return null;
  }
  return (
    <div
      className={styles.menu}
      style={popperStyles.popper}
      {...attributes.popper}
      ref={setMenuRef}
      data-test-id={`${testId}_menu`}
    >
      {items.map((item, index) => {
        if (item.divider) {
          return (
            <div
              data-test-id="menu-divider"
              key={index}
              className={styles.divider}
            />
          );
        }

        if (item.roles && !hasRole(item.roles, userInfo)) {
          return null;
        }

        return (
          <div
            key={index}
            className={classNames(styles.item, {
              [styles.disabled]: item.disabled,
            })}
            onClick={(e) => {
              if (!item.disabled) {
                item.onClick?.(e);
                onClose();
              }
            }}
            data-test-id="menu-item"
            data-test-disabled={item.disabled}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};
