/**
 * $middleBreakpoint: 1240px;
 * $smallBreakpoint: 700px;
 * $mobileBreakpoint: 500px;
 */

import { useWindowSize } from 'rooks';

export const isSmall = () => window.innerWidth < 700;

export const useBreakpoint = () => {
  const { innerWidth } = useWindowSize();

  if (!innerWidth)
    return {
      isMobile: false,
      isSmall: false,
      isMiddle: false,
      isLarge: false,
    };

  return {
    isMobile: innerWidth < 500,
    isSmall: innerWidth < 700,
    isMiddle: innerWidth < 1240,
    isLarge: innerWidth >= 1240,
  };
};
