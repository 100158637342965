import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import * as Sentry from '@sentry/react';

import { Loader } from '../Loader/Loader';

import styles from './PageContent.module.scss';
import { useIsFullscreen } from './useIsFullscreen';
import { FullscreenButton } from './components/FullscreenButton/FullscreenButton';
import { UseQueryResult } from '@tanstack/react-query';
import { Button } from '../../../uikit/Button/Button';
import { useNavigate } from 'react-router-dom';
import { hasRole } from '../../../shared/utils/hasRole';
import { SCREENSHOT_CLASSNAME } from '../Chart/components/DownloadChart/DownloadChart';

import imageSrc from './angery.png';
import { isDev } from '../../../shared/utils/isDev';

import notFoundAnimation from './404.json';
import Lottie from 'lottie-react';
import { useLoadedPercent } from '../../../shared/contexts/contentLength';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';

type TPageContentProps = {
  queryData?: UseQueryResult<any, unknown>;
  notFound?: boolean | string;
  notFilledFilters?: boolean;
  canMakeScreenshot?: boolean;
  scroll?: boolean;
  queryKeyForPercent?: string;
  forceLoading?: boolean;
  notFoundCustomText?: React.ReactNode;
  LoadingComponent?: React.FC<{
    percent: number | null;
    queryKey?: string;
  }>;
};

const EMPTY_DATA = {
  isLoading: false,
  isError: false,
  isFetching: false,
  error: null,
} as UseQueryResult<any, unknown>;

class ErrorBoundaryWrapper extends React.Component<{
  children: React.ReactNode;
}> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error, {
      extra: {
        errorInfo: JSON.stringify(errorInfo),
      },
    });
    console.error(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.notFound}>
          <div>
            Произошла ошибка. Попробуйте обновить страницу или обратитесь в
            техподдержку.
          </div>
          <div>
            <Button
              testId="refetch-button"
              onClick={() => {
                window.location.reload();
              }}
            >
              Обновить
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export const PageContent: React.FC<
  React.PropsWithChildren<TPageContentProps>
> = ({
  children,
  notFound,
  canMakeScreenshot,
  queryData = EMPTY_DATA,
  scroll,
  queryKeyForPercent,
  forceLoading,
  notFilledFilters,
  notFoundCustomText,
  LoadingComponent,
}) => {
  const userInfo = useAuthorizedUser();
  const percent = useLoadedPercent(queryKeyForPercent);
  const navigate = useNavigate();
  const [isFullscreen] = useIsFullscreen();
  const contentClassName = classNames(styles.content, {
    [styles.isFullscreen]: isFullscreen,
    [SCREENSHOT_CLASSNAME]: canMakeScreenshot,
    [styles.scroll]: scroll,
  });
  const fullscreenButton = isFullscreen ? (
    <div className={styles.fullscreenButton}>
      <FullscreenButton />
    </div>
  ) : null;

  const { isLoading, isError, error, isFetching } = queryData;

  if (isLoading || forceLoading) {
    if (LoadingComponent) {
      return (
        <div
          className={contentClassName}
          data-test-id="page-content"
          data-test-is-loading={isLoading || forceLoading}
          data-test-is-fetching={isFetching}
          data-test-is-loaded={false}
        >
          {fullscreenButton}
          <div className={styles.loader}>
            <LoadingComponent
              percent={queryKeyForPercent ? percent : null}
              queryKey={queryKeyForPercent}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className={contentClassName}
        data-test-id="page-content"
        data-test-is-loading={isLoading || forceLoading}
        data-test-is-fetching={isFetching}
        data-test-is-loaded={false}
      >
        {fullscreenButton}
        <div className={styles.loader}>
          <Loader />
        </div>
        {!forceLoading && percent !== 0 && (
          <div className={styles.percent}>Загружено {percent}%</div>
        )}
        {!forceLoading && percent === 0 && !!queryKeyForPercent && (
          <div className={styles.percent}>Подготовка отчёта...</div>
        )}
      </div>
    );
  }

  if (isError) {
    const isDelegationError = (error as Error)?.message?.startsWith(
      'REEDDA-05: ',
    );
    const isLimitError = (error as Error)?.message?.includes(
      'with status code 429',
    );
    const hasRightsToEditDelegations = hasRole(
      ['CLIENT', 'CLIENT_ADMIN'],
      userInfo,
    );
    return (
      <div
        className={contentClassName}
        data-test-id="page-content"
        data-test-is-error={isError}
        data-test-error-message={(error as Error)?.message}
        data-test-is-fetching={isFetching}
        data-test-is-loaded={true}
      >
        {fullscreenButton}
        <div className={styles.notFound}>
          <div>При выполнении запроса произошла ошибка</div>
          {!isLimitError && <div>{(error as Error)?.message}</div>}
          {isLimitError && (
            <div>
              Превышен лимит запросов для данного отчета.
              <br />
              Обратитесь к менеджеру для изменения тарифа.
            </div>
          )}
          <div>
            {!isDelegationError && !isLimitError && (
              <Button
                testId="refetch-button"
                onClick={() => {
                  queryData.refetch();
                }}
              >
                Обновить
              </Button>
            )}
            {isDelegationError && hasRightsToEditDelegations && (
              <Button
                testId="delegation-settings-button"
                onClick={() => {
                  navigate('/delegation');
                }}
              >
                Перейти в настройки делегирования
              </Button>
            )}
            {isDelegationError && !hasRightsToEditDelegations && (
              <div>Обратитесь к менеджеру для активации ИНН/УКЭП</div>
            )}
          </div>
        </div>
        {isDev() &&
          ReactDOM.createPortal(
            <img className={styles.devErrorImage} src={imageSrc} alt="Error" />,
            document.body,
          )}
      </div>
    );
  }

  if (notFound || notFilledFilters) {
    return (
      <div
        className={contentClassName}
        data-test-id="page-content"
        data-test-not-found={notFound}
        data-test-not-filled-filters={notFilledFilters}
        data-test-is-fetching={isFetching}
        data-test-is-loaded={true}
      >
        {fullscreenButton}
        <div className={styles.notFound}>
          {typeof notFound === 'string' && notFound}
          {typeof notFound !== 'string' && (
            <>
              {notFoundCustomText ? (
                notFoundCustomText
              ) : (
                <>
                  {notFilledFilters
                    ? 'Заполните обязательные фильтры, чтобы получить отчёт'
                    : 'К сожалению, с выбранными фильтрами ничего не найдено'}
                </>
              )}
            </>
          )}
        </div>
        {isDev() &&
          ReactDOM.createPortal(
            <Lottie
              className={styles.devErrorImage}
              animationData={notFoundAnimation}
              loop
              autoplay
            />,
            document.body,
          )}
      </div>
    );
  }

  return (
    <ErrorBoundaryWrapper>
      <div
        className={contentClassName}
        data-test-id="page-content"
        data-test-is-fetching={isFetching}
        data-test-is-loaded={true}
      >
        {fullscreenButton}
        {isFetching && (
          <div className={styles.fetchingLoader}>
            <div className={styles.line} />
          </div>
        )}
        {children}
      </div>
    </ErrorBoundaryWrapper>
  );
};
