import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as TableIcon } from './icons/table.svg';
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as BarChartIcon } from './icons/barChart.svg';
import { ReactComponent as BarChartWithLineIcon } from './icons/barChartWithLine.svg';
import { ReactComponent as GridIcon } from './icons/grid.svg';

import styles from './LineSelect.module.scss';
import { Tooltip } from '../Tooltip/Tooltip';

import viewCarousel from './lottieIcons/wired-outline-1385-page-view-carousel.json';
import dataTable from './lottieIcons/wired-outline-98-document-gear-settings.json';
import alignIcon from './lottieIcons/wired-outline-1393-align-text-left.json';
import layersIcon from './lottieIcons/wired-outline-12-layers.json';

import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import { Select } from '../Select/Select';
import Lottie, { LottieRef } from 'lottie-react';
import { useRefMemo } from '../../../shared/hooks/useRefMemo';

const MOBILE_ICONS = {
  viewCarousel,
  dataTable,
  alignIcon,
  layersIcon,
};

export const ICONS = {
  table: TableIcon,
  map: MapIcon,
  barChart: BarChartIcon,
  barChartWithLine: BarChartWithLineIcon,
  grid: GridIcon,
};

export type TOptionsList<TValue extends string> = Array<{
  value: TValue;
  label?: string;
  icon?: keyof typeof ICONS;
  hint?: string;
  onClick?: () => void;
  testId?: string;
}>;

export type TLineSelectProps<TValue extends string> = {
  value: TValue;
  onChange: (value: TValue) => void;
  options: TOptionsList<TValue>;
  testId: string;
  whiteBackground?: boolean;
  primaryColor?: boolean;
  fullWidth?: boolean;
  mobileIcon?: keyof typeof MOBILE_ICONS;
};

const BG_PADDING = 2;
const HOVERED_OPTION_OFFSET = 3;

export const LineSelect = <TValue extends string>({
  value,
  onChange,
  options,
  whiteBackground,
  primaryColor,
  fullWidth,
  testId,
  mobileIcon,
}: TLineSelectProps<TValue>) => {
  const breakpoints = useBreakpoint();

  const wasValueChangedFromInside = useRef(false);
  const optionBackgroundRef = useRef<HTMLDivElement | null>(null);
  const [innerValue, setInnerValueRaw] = useState<TValue>(value);

  const memoOnChange = useRefMemo(onChange);
  const memoInnerValue = useRefMemo(innerValue);

  const [backgroundWidth, setBackgroundWidth] = useState<number | null>(null);
  const [backgroundLeft, setBackgroundLeft] = useState<number | null>(null);
  const shouldRenderBackground = backgroundWidth !== null;

  const setInnerValue = useCallback((value: TValue) => {
    setInnerValueRaw(value);
    wasValueChangedFromInside.current = true;
  }, []);

  useEffect(() => {
    setInnerValue(value);
    wasValueChangedFromInside.current = false;
  }, [value, setInnerValue]);

  useEffect(() => {
    const optionBackground = optionBackgroundRef.current;
    if (!optionBackground) {
      return;
    }

    const onTransitionEnd = () => {
      if (wasValueChangedFromInside.current) {
        wasValueChangedFromInside.current = false;
        setTimeout(() => {
          memoOnChange.current(memoInnerValue.current);
        }, 20);
      }
    };

    optionBackground.addEventListener('transitionend', onTransitionEnd);

    return () => {
      optionBackground.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [shouldRenderBackground]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const optionsRefs = useRef<Array<HTMLDivElement | null>>([]);
  const [hasBackgroundHoverOffset, setHasBackgroundHoverOffset] =
    useState<boolean>(false);

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    if (options.length <= 1) {
      return;
    }

    const selectedOptionIndex = options.findIndex(
      (option) => option.value === innerValue,
    );
    const selectedOptionRef = optionsRefs.current[selectedOptionIndex];
    const container = containerRef.current;

    if (!selectedOptionRef || !container) {
      return;
    }

    const isHoveredOptionOnLeftSide =
      hoveredIndex !== null && hoveredIndex < selectedOptionIndex;
    const isHoveredOptionOnRightSide =
      hoveredIndex !== null && hoveredIndex > selectedOptionIndex;
    const hoveredOptionsDiff =
      hoveredIndex !== null ? Math.abs(hoveredIndex - selectedOptionIndex) : 0;

    let hoveredOptionOffset = 0;
    if (isHoveredOptionOnLeftSide) {
      hoveredOptionOffset = -HOVERED_OPTION_OFFSET * hoveredOptionsDiff;
    }
    if (isHoveredOptionOnRightSide) {
      hoveredOptionOffset = HOVERED_OPTION_OFFSET * hoveredOptionsDiff;
    }

    const selectedOptionRect = selectedOptionRef.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const backgroundWidth = selectedOptionRect.width + BG_PADDING * 2;
    const backgroundLeft =
      selectedOptionRect.left -
      containerRect.left -
      BG_PADDING +
      hoveredOptionOffset;

    setBackgroundWidth(backgroundWidth);
    setBackgroundLeft(backgroundLeft);
    setHasBackgroundHoverOffset(hoveredOptionOffset !== 0);
  }, [options, innerValue, hoveredIndex]);

  const lottieRef: LottieRef = useRef(null);

  if (options.length <= 1) {
    return null;
  }

  if (breakpoints.isMobile && mobileIcon) {
    return (
      <Select
        label={
          <Lottie
            lottieRef={lottieRef}
            animationData={MOBILE_ICONS[mobileIcon]}
            loop={false}
            style={{ width: 26, height: 26 }}
            autoplay={false}
          />
        }
        onOpen={() => {
          lottieRef.current?.goToAndPlay(0);
        }}
        options={options.map((option) => ({
          label: option.label || '',
          value: option.value,
        }))}
        value={innerValue}
        onChange={(value) => onChange(value as TValue)}
        noValueLabel
      />
    );
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.whiteBackground]: whiteBackground,
        [styles.primaryColor]: primaryColor,
        [styles.fullWidth]: fullWidth,
      })}
      data-test-id={`line-select-${testId}`}
      ref={containerRef}
    >
      {shouldRenderBackground && (
        <div
          ref={optionBackgroundRef}
          className={classNames(styles.optionBackground, {
            [styles.hasHoverOffset]: hasBackgroundHoverOffset,
          })}
          style={{
            width: backgroundWidth || undefined,
            transform: `translateX(${backgroundLeft}px)`,
          }}
        />
      )}
      {options.map((option, index) => {
        const icon = option.icon;
        const Icon = (icon ? ICONS[icon] : null) as React.FC;
        return (
          <Tooltip
            testId={'line-select-option'}
            hidden={!option.hint}
            text={option.hint || ''}
            key={index}
          >
            <div
              ref={(ref) => {
                optionsRefs.current[index] = ref;
              }}
              className={classNames(styles.option, {
                [styles.selected]: option.value === innerValue,
                [styles.noHint]: !option.hint,
              })}
              onClick={() => {
                const clickHandler = option.onClick;
                if (clickHandler) {
                  clickHandler();
                } else {
                  setInnerValue(option.value);
                }
              }}
              onMouseMove={() => {
                setHoveredIndex(index);
              }}
              onMouseLeave={() => {
                setHoveredIndex(null);
              }}
              data-test-id={`line-select_${option.value}`}
              data-test-id-label={
                option.testId ? `line-select_${option.testId}` : undefined
              }
              data-test-selected={option.value === innerValue}
            >
              {!icon && option.label}
              {icon && (
                <div className={classNames(styles.icon, styles[icon])}>
                  <Icon />
                </div>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
