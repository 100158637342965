import React from 'react';

import styles from './Page404.module.scss';

import notFoundAnimation from './notFound.json';
import Lottie from 'lottie-react';
import { Button } from '../../uikit/Button/Button';
import { useNavigate } from 'react-router-dom';

export const Page404 = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page404}>
      <Lottie
        animationData={notFoundAnimation}
        loop
        autoplay
        style={{ width: 'auto', height: '200px' }}
      />
      <Button
        onClick={() => {
          navigate('/');
        }}
      >
        На главную
      </Button>
    </div>
  );
};
