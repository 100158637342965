import { $api } from '../utils';
import {
  CrptAuthKeyResponse,
  CrptSignInFirstRequest,
  DeactivateRequest,
} from '../backend/Api';
import { formatError } from '../../shared/utils/withErrorHandling';
import { useQuery } from '@tanstack/react-query';

export const fetchRevokeInn = async (inn: DeactivateRequest['inn']) => {
  try {
    const { data } = await $api.put('/inns', { inn });
    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

const fetchKey = async () => {
  try {
    const { data } = await $api.get<CrptAuthKeyResponse>('/crpt/key');
    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

export const useKey = () => {
  return useQuery(['delegation', 'key'], fetchKey);
};

export const fetchAddCertificate = async (data: CrptSignInFirstRequest) => {
  try {
    await $api.post('/crpt/signin', data);
  } catch (error) {
    throw new Error(formatError(error));
  }
};
