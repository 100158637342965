import { useQuery } from '@tanstack/react-query';
import { $api } from '../../api/utils';
import { useModuleB, useModuleA } from './useUser';

export const useModuleAOnly = (): boolean => {
  const moduleA = useModuleA();
  const moduleB = useModuleB();

  return moduleA && !moduleB;
};

const getReportAttempt = async () => {
  const { data } = await $api.get('/report-attempt');
  return data;
};

export const useReportAttempt = () => {
  return useQuery(['reportAttempt'], getReportAttempt, {
    staleTime: 0,
    cacheTime: 0,
  });
};
