import basketIcon from './icons/wired-outline-138-basket-morph.json';
import trolleyIcon from './icons/wired-outline-146-basket-trolley-shopping-card.json';
import randomArrows from './icons/wired-outline-229-arrow-18.json';
import graphChart from './icons/wired-outline-163-graph-line-chart.json';
import map from './icons/wired-outline-106-map.json';
import list from './icons/wired-outline-1386-page-view-headline.json';
import coins from './icons/wired-outline-303-coins-rub.json';
import coin from './icons/wired-outline-294-coin-rub.json';
import order from './icons/wired-outline-1361-purchase-order.json';
import b2b from './icons/wired-outline-1005-b-2-b-business-to-business.json';
import scanner from './icons/wired-outline-1001-shop-scanner.json';
import packageIcon from './icons/wired-outline-1351-package.json';
import growthChart from './icons/wired-outline-161-growth.json';
import orderHistory from './icons/wired-outline-1334-order-history.json';
import pin from './icons/wired-outline-18-location-pin.json';
import { User } from '../../../shared/hooks/useUser';
import md5 from 'md5';
import { isDev } from '../../../shared/utils/isDev';
import { storage } from '../../../shared/utils/storage';

export const TAGS = [
  'Розница',
  'Опт',
  'ИНН',
  'GTIN',
  'Цена',
  'Дистрибуция',
  'Регион',
  'Адрес',
  'Тип операции',
];

type TTag = (typeof TAGS)[number];

export type ReportMeta = {
  sectionKey: number;
  reportKey: number;
  name: string;
  description: string;
  simpleDescription: string | string[];
  tags: TTag[];
  icon: any;
  chartsCount: number;
  beta?: boolean;
};

export const REPORTS: ReportMeta[] = [
  {
    sectionKey: 1,
    reportKey: 1,
    name: 'Розничные продавцы',
    description:
      'Показывает продажи по ИНН розничных продавцов суммарно за один выбранный период.',
    tags: ['Розница', 'ИНН'],
    icon: basketIcon,
    chartsCount: 3,
    simpleDescription: 'Кто продает мои товары в рознице?',
  },
  {
    sectionKey: 1,
    reportKey: 2,
    name: 'Оптовые покупатели',
    description:
      'Показывает закупки ИНН оптовых покупателей суммарно за выбранный период.',
    tags: ['Опт', 'ИНН'],
    icon: trolleyIcon,
    chartsCount: 3,
    simpleDescription: 'Кто покупает мои товары оптом?',
  },
  {
    sectionKey: 1,
    reportKey: 6,
    name: 'Цепочки продаж',
    description:
      'Показывает цепочки закупок и продаж товара от производства до реализации через оптовых покупателей и розничных продавцов суммарно за выбранный период.',
    tags: ['Опт', 'Розница', 'ИНН', 'GTIN'],
    icon: randomArrows,
    chartsCount: 1,
    simpleDescription:
      'Как движется мой товар от производителя до конечного потребителя?',
  },
  {
    sectionKey: 1,
    reportKey: 9,
    name: 'Динамика продаж',
    description:
      'Показывает продажи по ИНН розничных продавцов в динамике за несколько периодов.',
    tags: ['Розница', 'ИНН'],
    icon: graphChart,
    chartsCount: 1,
    simpleDescription: 'Как менялись продажи у конкретных розничных продавцов?',
  },
  {
    sectionKey: 2,
    reportKey: 9,
    name: 'Дистрибуция товара',
    description:
      'Показывает по товарам нумерическую дистрибуцию, рассчитанную от юниверса производителя за один выбранный период.',
    tags: ['Дистрибуция', 'Регион', 'Розница', 'GTIN'],
    icon: map,
    chartsCount: 1,
    simpleDescription: 'В каких регионах и как продавались мои товары?',
    beta: true,
  },
  {
    sectionKey: 2,
    reportKey: 10,
    name: 'Справочник юниверса',
    description:
      'Показывает список адресов розничных торговых точек, в которых продавался товар производителя за один выбранный период.',
    tags: ['Розница', 'Адрес', 'ИНН', 'Регион'],
    icon: list,
    chartsCount: 0,
    simpleDescription: 'В каких точках продавались мои товары?',
    beta: true,
  },
  {
    sectionKey: 3,
    reportKey: 7,
    name: 'Цена на товар по регионам',
    description:
      'Показывает цену на товар по регионам за один выбранный период и динамику цены к предыдущему периоду.',
    tags: ['Цена', 'Регион', 'GTIN', 'Розница'],
    icon: coins,
    chartsCount: 1,
    simpleDescription: 'Какая цена на мои товары в разных регионах?',
  },
  {
    sectionKey: 3,
    reportKey: 8,
    name: 'Динамика продаж и цены',
    description:
      'Показывает зависимость розничных продаж и цены на выбранный товар в разбивке по регионам в динамике за несколько периодов.',
    tags: ['Цена', 'Регион', 'GTIN', 'Розница'],
    icon: coin,
    chartsCount: 1,
    simpleDescription:
      'Как менялась розничная цена на мои товары и их продажи в разных регионах?',
  },
  {
    sectionKey: 1,
    reportKey: 3,
    name: `Розничные продажи по GTIN`,
    description: `Показывает розничные продажи по товарам суммарно за выбранный период по выбранным ИНН продавцов.`,
    tags: ['Розница', 'ИНН', 'GTIN'],
    icon: order,
    chartsCount: 3,
    simpleDescription:
      'Какие мои GTIN продаются в рознице, какие у них продажи?',
  },
  {
    sectionKey: 1,
    reportKey: 4,
    name: 'Покупатели для Продавца',
    description:
      'Показывает по выбранным ИНН продавцов оптовые продажи по ИНН покупателей суммарно за выбранный период.',
    tags: ['Опт', 'ИНН'],
    icon: b2b,
    chartsCount: 3,
    simpleDescription:
      'Кто купил товар у выбранного продавца - кому продает конкретный оптовик?',
  },
  {
    sectionKey: 1,
    reportKey: 5,
    name: 'Продавцы для Покупателя',
    description:
      'Показывает по выбранным ИНН покупателей оптовые закупки по ИНН продавцов суммарно за выбранный период.',
    tags: ['Опт', 'ИНН'],
    icon: b2b,
    chartsCount: 3,
    simpleDescription:
      'Кто является поставщиками для оптовика или розничной сети/магазина?',
  },
  {
    sectionKey: 1,
    reportKey: 7,
    name: `GTIN в рознице`,
    description: `Показывает розничные продажи по ИНН продавцов суммарно за выбранный период по выбранным товарам.`,
    tags: ['Розница', 'ИНН', 'GTIN'],
    icon: scanner,
    chartsCount: 3,
    simpleDescription: 'Какой из продавцов продает какие мои товары?',
  },
  {
    sectionKey: 1,
    reportKey: 8,
    name: 'GTIN в опте',
    description:
      'Показывает оптовые закупки по ИНН покупателей суммарно за выбранный период по выбранным товарам.',
    tags: ['Опт', 'ИНН', 'GTIN'],
    icon: packageIcon,
    chartsCount: 3,
    simpleDescription: 'Какой из продавцов закупает какие мои товары?',
  },
  {
    sectionKey: 3,
    reportKey: 2,
    name: 'Динамика цен на GTIN',
    description:
      'Показывает цены на товары в целом по России или по отдельным регионам в динамике за несколько периодов по выбранному товару.',
    tags: ['Розница', 'Регион', 'GTIN', 'Цена'],
    icon: growthChart,
    chartsCount: 1,
    simpleDescription:
      'Как менялась цена на мои товары в разных регионах или в среднем по России?',
  },
  {
    sectionKey: 1,
    reportKey: 12,
    name: 'Вывод из оборота по типам',
    description:
      'Показывает объемы вывода из оборота товаров за выбранный период в разрезе типов вывода по выбранным продавцам.',
    tags: ['ИНН', 'Тип операции'],
    icon: orderHistory,
    chartsCount: 2,
    simpleDescription:
      'Каким образом и в каких объемах мои товары выводились из оборота?',
  },
  {
    sectionKey: 2,
    reportKey: 6,
    name: 'Продажи по адресам',
    description:
      'Показывает розничные продажи по точкам продаж по выбранным ИНН продавцов и выбранным адресам.',
    tags: ['Регион', 'Розница', 'GTIN', 'Адрес'],
    icon: pin,
    chartsCount: 2,
    simpleDescription: 'В каких точках продавались мои товары?',
    beta: true,
  },
  {
    sectionKey: 1,
    reportKey: 11,
    name: 'Динамика GTIN в рознице',
    description:
      'Показывает динамику розничных продаж товаров по выбранным ИНН продавцов.',
    tags: ['Розница', 'GTIN'],
    icon: graphChart,
    chartsCount: 1,
    simpleDescription: 'Как меняется объем продаж моих GTIN в рознице?',
  },
  {
    sectionKey: 2,
    reportKey: 8,
    name: 'Ассортимент продаж по адресам',
    description: 'Показывает ассортимент товаров в выбранных точках продаж.',
    tags: ['Опт', 'Розница', 'ИНН', 'GTIN'],
    icon: pin,
    chartsCount: 3,
    simpleDescription:
      'Какие из моих товаров продаются в конкретной точке продаж?',
  },
  {
    sectionKey: 2,
    reportKey: 1,
    name: 'Продажи ИНН по регионам',
    description:
      'Показывает розничные продажи по регионам за выбранный период по выбранным ИНН.',
    tags: ['Розница', 'ИНН', 'Регион'],
    icon: pin,
    chartsCount: 3,
    simpleDescription: 'В каких регионах торгует выбранный ИНН/сеть?',
  },
  {
    sectionKey: 2,
    reportKey: 7,
    name: 'Динамика продаж по адресам',
    description:
      'Показывает динамику розничных продаж выбранных GTIN по выбранным точкам продаж.',
    tags: ['Розница', 'GTIN', 'Адрес'],
    icon: pin,
    chartsCount: 1,
    simpleDescription: 'Как продавался товар в выбранной точке продаж?',
  },
];

const betaHide: string[] = [];

export const getVisibleReports = (user: User) => {
  if (!user.clientLogin) {
    return REPORTS;
  }
  const clientLoginHash = md5(user.clientLogin);
  const isDevBeta = isDev() && storage.getItem('hideBeta') === 'true';

  const isBeta = isDevBeta || betaHide.includes(clientLoginHash);

  return isBeta ? REPORTS.filter((report) => !report.beta) : REPORTS;
};
