import React, { useMemo } from 'react';
import { Table, TTableColumn } from '../../../../../common/Table/Table';
import { ViewFeature } from '../reportConfigType';
import ReactDOM from 'react-dom';
import { Pagination } from '../../../../../common/Table/components/Pagination/Pagination';

type TGetTableFeatureConfig<TDataItem> = {
  baseColumns: TTableColumn<TDataItem>[];
  defaultSort?: keyof TDataItem | 'LAST';
  filename: string;
  canHideEmptyColumns?: boolean;
};

export const getTableFeature = <TDataItem,>(
  config: TGetTableFeatureConfig<TDataItem>,
): ViewFeature<TDataItem> => {
  const TableComponent: ViewFeature<TDataItem>['component'] = ({
    data,
    dataFeatures,
    headerElement,
  }) => {
    const enrichedColumns = useMemo(() => {
      let columns = config.baseColumns;
      for (const feature of dataFeatures) {
        columns = feature.tableColumnsMiddleware(
          columns,
          feature.state,
          feature.setState,
          data,
        );
      }
      return columns;
    }, [data, dataFeatures]);
    return (
      <>
        <Table
          cols={enrichedColumns}
          rows={data}
          withPagination
          canHideEmptyColumns={config.canHideEmptyColumns}
          defaultSort={
            config.defaultSort
              ? {
                  key:
                    config.defaultSort === 'LAST'
                      ? (enrichedColumns[enrichedColumns.length - 1]
                          .key as keyof TDataItem)
                      : config.defaultSort,
                  direction: 'desc',
                }
              : undefined
          }
        />
        {headerElement &&
          ReactDOM.createPortal(
            <>
              <Pagination />
            </>,
            headerElement,
          )}
      </>
    );
  };

  return {
    key: 'table',
    title: 'Таблица',
    icon: 'table',
    component: TableComponent,
  };
};
