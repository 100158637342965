import React, { useEffect } from 'react';
import {
  TCommonData,
  useCommonData,
  useCommonDataPercents,
} from '../../api/reports/useCommonData';

import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import checkBox from 'react-useanimations/lib/checkBox';
import * as Sentry from '@sentry/browser';

import styles from './withCommonData.module.scss';

const QUERIES_NAMES: Record<string, string> = {
  addresses: 'Адреса',
  buyerNetworks: 'Сети покупателей',
  buyers: 'Покупатели',
  gtins: 'Товары',
  rfCodes: 'Коды регионов',
  retailSellerNetworks: 'Сети продавцов',
  retailSellers: 'Продавцы',
  participants: 'Участники оборота',
};

export const withCommonData = (
  requiredData: Array<keyof TCommonData> | undefined,
  Component: React.FC<any>,
): React.FC => {
  return (props) => {
    const { queriesStatuses } = useCommonData();
    const percents = useCommonDataPercents();

    const requiredQueries: Record<string, boolean> = requiredData
      ? requiredData.reduce<Record<string, boolean>>((acc, item) => {
          acc[item] = queriesStatuses[item];
          return acc;
        }, {})
      : queriesStatuses;

    const isSomeLoading = Object.values(requiredQueries).some(Boolean);

    useEffect(() => {
      if (isSomeLoading) {
        const timeout = setTimeout(() => {
          Sentry.captureMessage(`Common data took too long to load:`, {
            extra: {
              percents: JSON.stringify(percents),
            },
          });
        }, 20000);

        return () => {
          clearTimeout(timeout);
        };
      }
    }, [isSomeLoading]);

    if (isSomeLoading) {
      return (
        <div className={styles.loader}>
          <div className={styles.container}>
            <div className={styles.title}>Загружаем необходимые данные...</div>
            <div className={styles.statuses}>
              {Object.entries(requiredQueries).map(([key, value]) => {
                return (
                  <div key={`${key}-${value}`} className={styles.line}>
                    <UseAnimations
                      animation={!value ? checkBox : loading}
                      size={20}
                      autoplay
                      wrapperStyle={{ marginTop: -8, overflow: 'visible' }}
                    />
                    {QUERIES_NAMES[key]}
                    {percents[key] !== 0 &&
                      percents[key] !== 100 &&
                      ` (${percents[key]}%)`}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return <Component {...props} />;
  };
};
