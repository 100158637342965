import React, { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './Chart.module.scss';
import { Loader } from '../Loader/Loader';
import { SCREENSHOT_CHART_CONTAINER } from '../Table/components/DownloadReport/DownloadReport';

type TChartProps = React.PropsWithChildren<{
  testId: string;
  fullHeight?: boolean;
  withLegend?: boolean;
  name?: string;
}>;

export const Chart = ({
  children,
  testId,
  fullHeight,
  withLegend,
  name,
}: TChartProps) => {
  const [containerWidthInited, setContainerWidthInited] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let updateTimeout: ReturnType<typeof setTimeout> | null = null;
    const container = containerRef.current;

    if (!container || containerWidthInited) {
      return;
    }

    const handleResize = () => {
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }
      updateTimeout = setTimeout(() => {
        setContainerWidthInited(container.offsetWidth !== 0);
      }, 100);
    };

    handleResize();

    const observer = new ResizeObserver(handleResize);
    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [containerWidthInited]);

  return (
    <div
      className={classNames(
        styles.chart,
        {
          [styles.fullHeight]: fullHeight,
          [styles.withLegend]: withLegend,
        },
        SCREENSHOT_CHART_CONTAINER,
      )}
      data-test-id={`chart-container-${testId}`}
      data-name={name}
      ref={containerRef}
    >
      {containerWidthInited && (
        <div className={styles.container}>{children}</div>
      )}
      {!containerWidthInited && <Loader fullScreen />}
    </div>
  );
};
