import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

const usePaginationShared = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setCurrentPage(1);
  }, [total]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    total,
    setTotal,
  };
};

export const Pagination = createContainer(usePaginationShared);

export const usePagination = () => Pagination.useContainer();
