import { useMemo } from 'react';

const asIs = (value: number): number => value;

export const useAccumulatedFields = <TDataItem extends Record<string, any>>(
  data: TDataItem[] | undefined,
  accumulateField: keyof TDataItem,
  prepareValue: (value: number) => number = asIs,
  enabled = true,
): TDataItem[] => {
  return useMemo(() => {
    if (!data) {
      return [];
    }
    if (!enabled) {
      return data;
    }
    const sortedData = [...data].sort((a, b) => {
      return b[accumulateField] - a[accumulateField];
    });

    const result: TDataItem[] = [];
    let accumulatedValue = 0;
    for (const item of sortedData) {
      const value = prepareValue(item[accumulateField]);
      accumulatedValue += value;
      result.push({
        ...item,
        [accumulateField]: accumulatedValue,
      });
    }
    return result;
  }, [data, enabled, accumulateField, prepareValue]);
};
