import { useIsFetching } from '@tanstack/react-query';
import React from 'react';

const getIsFetchingProps = (key: string, isFetching: number) => ({
  [`data-test-is-fetching-${key}`.toLowerCase()]: !!isFetching,
  [`data-test-fetching-${key}-count`.toLowerCase()]: isFetching,
});

export const GlobalTestIds: React.FC = () => {
  const isFetching = useIsFetching();
  const isFetchingCommonData = useIsFetching(['commonData']);
  const isFetchingDashboad = useIsFetching(['dashboard']);
  const isFetchingUsers = useIsFetching(['users']);
  const isFetchingEvents = useIsFetching(['events']);
  const isFetchingDictionaries = useIsFetching(['dictionaries']);
  const isReportFetching = useIsFetching(['report']);

  return (
    <div
      data-test-id="global-test-ids"
      {...getIsFetchingProps('total', isFetching)}
      {...getIsFetchingProps('commonData', isFetchingCommonData)}
      {...getIsFetchingProps('users', isFetchingUsers)}
      {...getIsFetchingProps('dashboard', isFetchingDashboad)}
      {...getIsFetchingProps('events', isFetchingEvents)}
      {...getIsFetchingProps('dictionaries', isFetchingDictionaries)}
      {...getIsFetchingProps('report', isReportFetching)}
    />
  );
};
