import React, { useEffect, useMemo, useState } from 'react';
import { useFilename } from '../../../../../shared/hooks/useFilename';
import { Button } from '../../../../../uikit/Button/Button';
import { useData } from '../../useData';

import UseAnimations from 'react-useanimations';
import download from 'react-useanimations/lib/download';

import styles from './DownloadReport.module.scss';
import { useColumns } from '../../useColumns';
import html2canvas from 'html2canvas';
import { saveAsFile } from '../../../../../shared/utils/saveAsFile';
import { Tooltip } from '../../../Tooltip/Tooltip';

import prepareFileWorker from 'workerize-loader!./prepareFile.worker';
import type { PrepareFileWorkerFunction } from './prepareFile.worker';

const workerInstance = prepareFileWorker();

const prepareFile: PrepareFileWorkerFunction = async (config) => {
  return workerInstance.prepareFile(config);
};

export const SCREENSHOT_CHART_CONTAINER = 'js-screenshot-chart-container';

type TDownloadReportProps = {
  filename: string;
  white?: boolean;
};

const waitForNextFrame = () =>
  new Promise((resolve) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });

export const DownloadReport: React.FC<TDownloadReportProps> = ({
  filename,
  white = true,
}) => {
  const [data] = useData();
  const [cols] = useColumns();

  const fileNameWithDate = useFilename(filename);

  const visibleCols = useMemo(
    () => cols.filter((col) => !col.hideForExport),
    [cols],
  );

  const [forceAnimation, setForceAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceAnimation(false);
    }, 4500);
    return () => {
      clearTimeout(timeout);
    };
  }, [forceAnimation]);

  const handleClick = async () => {
    setForceAnimation(true);

    const chartsDivs = Array.from(
      document.querySelectorAll(`.${SCREENSHOT_CHART_CONTAINER}`),
    ) as HTMLDivElement[];

    await waitForNextFrame();

    const rects: DOMRect[] = [];

    for (const chartDiv of chartsDivs) {
      const rect = chartDiv.getBoundingClientRect();
      rects.push(rect);
      await waitForNextFrame();
    }

    const names: string[] = [];

    for (const chartDivIndex in chartsDivs) {
      const name =
        chartsDivs[chartDivIndex].getAttribute('data-name') ||
        `График ${chartDivIndex + 1}`;
      names.push(name);
      await waitForNextFrame();
    }

    const images: string[] = [];

    for (const chartDiv of chartsDivs) {
      let canvasInside = chartDiv.querySelector('canvas');
      if (!canvasInside) {
        canvasInside = await html2canvas(chartDiv, {
          backgroundColor: '#f1f1f1',
        });
      }
      await waitForNextFrame();

      const imageData = canvasInside.toDataURL('image/png', 1);

      await waitForNextFrame();

      images.push(imageData);
      await waitForNextFrame();
    }

    const [url, name] = await prepareFile({
      data: data.map((item) => {
        for (const col of visibleCols) {
          if (col.renderForExport) {
            item[col.key] = col.renderForExport(item);
          }
        }

        return item;
      }),
      cols: visibleCols.map((col) => ({
        key: col.key,
        title: col.title,
        hint: col.hint,
        isNumeric: col.isNumeric,
      })),
      images: images.map((image, index) => ({
        name: names[index],
        base64: image,
        rect: rects[index],
      })),
      fileName: fileNameWithDate,
    });

    saveAsFile(url, name);
  };

  return (
    <Tooltip testId="download-report" text="Сохранить в файл">
      <Button
        testId="download-button"
        onClick={handleClick}
        className={styles.withIcon}
        color={white ? 'white' : 'grey'}
        reportPanel
      >
        <UseAnimations
          key={`${forceAnimation}`}
          autoplay={forceAnimation}
          animation={download}
          size={20}
          wrapperStyle={{
            marginTop: -7,
          }}
        />
      </Button>
    </Tooltip>
  );
};
