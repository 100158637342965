import React, { useEffect, useMemo, useState } from 'react';
import { LineSelect } from '../../../../LineSelect/LineSelect';
import { TDateRangeFilter, TDateRangeFilterOptions } from '../../../filter';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateRangeFilter.module.scss';
import { useSearchQueryState } from '../../../../../../shared/hooks/useSearchQueryState';
import dayjs from 'dayjs';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { Select } from '../../../../Select/Select';
import { MONTHS } from '../../../../../../shared/months';
registerLocale('ru', ru);
setDefaultLocale('ru');

const CURRENT_YEAR = new Date().getFullYear();
const YEAR_OPTIONS = Array.from({ length: 10 }, (_, i) => CURRENT_YEAR - i)
  .sort((a, b) => a - b)
  .map((year) => year.toString());

type TValue = [Date, Date];

type TDateRangeFilterProps = {
  filter: TDateRangeFilter;
  value: TValue | [string, string] | [];
  onChange: (value: TValue) => void;
};

const getStartOfWeek = (date: Date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

const getEndOfWeek = (date: Date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? 0 : 7);
  return new Date(date.setDate(diff));
};

const DISPLAY_TYPES: Array<{
  value: keyof TDateRangeFilterOptions;
  label: string;
}> = [
  {
    label: 'День',
    value: 'day',
  },
  {
    label: 'Неделя',
    value: 'week',
  },
  {
    label: 'Месяц',
    value: 'month',
  },
  {
    label: 'Год',
    value: 'year',
  },
  {
    label: 'Период',
    value: 'period',
  },
];

export const DateRangeFilter: React.FC<TDateRangeFilterProps> = ({
  filter,
  value,
  onChange,
}) => {
  const [startDate, endDate] = value;
  const now = useMemo(() => new Date(), []);

  const displayTypes = useMemo(() => {
    if (!filter.enabledOptions) {
      return DISPLAY_TYPES;
    }
    const enabledOptions = filter.enabledOptions;
    return DISPLAY_TYPES.filter((type) => {
      return enabledOptions[type.value] !== false;
    });
  }, []);

  const startDateObject = useMemo(
    () => (startDate ? new Date(startDate) : now),
    [now, startDate],
  );
  const endDateObject = useMemo(
    () => (endDate ? new Date(endDate) : now),
    [endDate, now],
  );

  const [selectType, setSelectType] = useSearchQueryState<string>(
    `pt-${filter.uniqueKey}`,
    filter.defaultSelectType || 'month',
  );
  const [selectedYear, setSelectedYear] = useSearchQueryState<string>(
    `py-${filter.uniqueKey}`,
    now.getFullYear().toString(),
  );

  const [periodStartDate, setPeriodStartDate] = useState<Date | null>(
    startDateObject,
  );
  const [periodEndDate, setPeriodEndDate] = useState<Date | null>(
    endDateObject,
  );

  const handleChange = (newValue: TValue) => {
    onChange(newValue.sort((a, b) => a.getTime() - b.getTime()));
  };

  useEffect(() => {
    if (selectType === 'period' && periodStartDate && periodEndDate) {
      handleChange([periodStartDate, periodEndDate]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodEndDate, periodStartDate, selectType]);

  const filterDate = (date: Date) => {
    if (filter.availablePeriod) {
      return (
        date.getTime() >= filter.availablePeriod.from.getTime() &&
        date.getTime() <= filter.availablePeriod.to.getTime()
      );
    }
    return true;
  };

  const highlightDates = filter.availablePeriod
    ? [
        {
          [styles.availablePeriodEdge]: [
            filter.availablePeriod.from,
            filter.availablePeriod.to,
          ],
        },
      ]
    : undefined;

  const filteredYears = useMemo(() => {
    if (filter.availablePeriod) {
      return YEAR_OPTIONS.filter((year) => {
        const yearNumber = Number(year);
        return (
          yearNumber >= filter.availablePeriod.from.getFullYear() &&
          yearNumber <= filter.availablePeriod.to.getFullYear()
        );
      });
    }
    return YEAR_OPTIONS;
  }, [filter.availablePeriod]);

  return (
    <div className={styles.container}>
      <div className={styles.selectType}>
        <LineSelect
          testId="select-type"
          value={selectType as string}
          onChange={setSelectType}
          options={displayTypes}
          fullWidth
        />
      </div>
      <div className={styles.meta} data-test-id="range-filter_meta">
        Выбранный период: <br />
        {dayjs(startDateObject).format('DD.MM.YYYY')} -{' '}
        {dayjs(endDateObject).format('DD.MM.YYYY')}
      </div>
      <div data-test-id={`range-filter_${selectType}`}>
        {selectType === 'day' && (
          <DatePicker
            disabledKeyboardNavigation
            selected={startDateObject}
            onChange={(date) => {
              if (date) {
                handleChange([date, date]);
              }
            }}
            inline
            filterDate={filterDate}
            highlightDates={highlightDates}
          />
        )}
        {selectType === 'week' && (
          <DatePicker
            disabledKeyboardNavigation
            selected={startDateObject}
            startDate={startDateObject}
            endDate={endDateObject}
            onWeekSelect={(date) => {
              if (date) {
                const startOfWeek = getStartOfWeek(date);
                const endOfWeek = getEndOfWeek(date);
                handleChange([startOfWeek, endOfWeek]);
              }
            }}
            onChange={(date) => {
              if (date) {
                const startOfWeek = getStartOfWeek(date);
                const endOfWeek = getEndOfWeek(date);
                handleChange([startOfWeek, endOfWeek]);
              }
            }}
            showWeekNumbers
            inline
            filterDate={filterDate}
            highlightDates={highlightDates}
          />
        )}
        {selectType === 'month' && (
          <div>
            <div className={styles.yearSelect}>
              <Select
                label="Год"
                options={filteredYears}
                value={selectedYear as string}
                onChange={setSelectedYear}
              />
            </div>
            <div className={styles.list}>
              {MONTHS.map((month, index) => {
                const startOfMonth = dayjs(
                  `${selectedYear}-${index + 1}-01`,
                ).toDate();
                const endOfMonth = dayjs(`${selectedYear}-${index + 1}-01`)
                  .endOf('month')
                  .toDate();

                return (
                  <button
                    key={month}
                    className={classNames(styles.item, {
                      [styles.selected]:
                        (startDateObject.getMonth() === index &&
                          startDateObject.getFullYear() ===
                            Number(selectedYear)) ||
                        (endDateObject.getMonth() === index &&
                          endDateObject.getFullYear() === Number(selectedYear)),
                      [styles.availablePeriodEdge]:
                        filter.availablePeriod &&
                        ((dayjs(filter.availablePeriod.from).isAfter(
                          startOfMonth,
                        ) &&
                          dayjs(filter.availablePeriod.from).isBefore(
                            endOfMonth,
                          )) ||
                          (dayjs(filter.availablePeriod.to).isAfter(
                            startOfMonth,
                          ) &&
                            dayjs(filter.availablePeriod.to).isBefore(
                              endOfMonth,
                            ))),
                      [styles.notAvailable]:
                        filter.availablePeriod &&
                        (dayjs(filter.availablePeriod.from).isAfter(
                          endOfMonth,
                        ) ||
                          dayjs(filter.availablePeriod.to).isBefore(
                            startOfMonth,
                          )),
                    })}
                    onClick={() => {
                      handleChange([startOfMonth, endOfMonth]);
                    }}
                  >
                    {month}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {selectType === 'year' && (
          <div className={styles.list}>
            {filteredYears.map((year) => (
              <button
                key={year}
                className={classNames(styles.item, {
                  [styles.selected]:
                    startDateObject.getFullYear() === Number(year) ||
                    endDateObject.getFullYear() === Number(year),
                })}
                onClick={() => {
                  const startOfYear = dayjs(`${year}-01-01`).toDate();
                  const endOfYear = dayjs(`${year}-12-31`).toDate();
                  handleChange([startOfYear, endOfYear]);
                }}
              >
                {year}
              </button>
            ))}
          </div>
        )}

        {selectType === 'period' && (
          <DatePicker
            disabledKeyboardNavigation
            startDate={periodStartDate}
            endDate={periodEndDate}
            onChange={([startDate, endDate]) => {
              setPeriodEndDate(endDate);
              setPeriodStartDate(startDate);
            }}
            inline
            selectsRange
            filterDate={filterDate}
            highlightDates={highlightDates}
          />
        )}
      </div>
    </div>
  );
};
