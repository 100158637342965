import html2canvas from 'html2canvas';
import { saveAsFile } from './saveAsFile';

const SCREENSHOT_PROCESSING_CLASS = 'js-screenshot-processing';

export const makeScreenshot = async (
  element: HTMLElement | string,
  filename = 'screenshot.png',
) => {
  const el =
    typeof element === 'string'
      ? (document.querySelector(element) as HTMLElement)
      : element;
  if (!el) return;

  el.classList.add(SCREENSHOT_PROCESSING_CLASS);

  const canvas = await html2canvas(el, {
    backgroundColor: '#f1f1f1',
    scale: 2,
  });
  el.classList.remove(SCREENSHOT_PROCESSING_CLASS);

  const imageData = canvas.toDataURL('image/png');
  saveAsFile(imageData, filename);
};
