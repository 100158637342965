
				var addMethods = require("../../../../../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["prepareFile"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "4839b29431dc6d96a49d.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "4839b29431dc6d96a49d.worker.js");
					addMethods(w, methods)
					
					return w
				}
			