import React from 'react';

import styles from './DataUpdate.module.scss';
import { $api } from '../../../../../../../api/utils';
import { useQuery } from '@tanstack/react-query';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import classNames from 'classnames';
import { Tooltip } from '../../../../../../common/Tooltip/Tooltip';
dayjs.extend(relativeTime);

const getDataUpdate = async (url: string) => {
  const { data } = await $api.get('/data-update-info/' + url);
  return data;
};

const useDataUpdate = (url: string) => {
  return useQuery(['dashboard', 'dataUpdate', url], () => getDataUpdate(url));
};

const SECTIONS: Array<{
  label: string;
  url: string;
}> = [
  {
    label: 'Данные скачаны по',
    url: 'last-download-to',
  },
  {
    label: 'Дата следующего обновления',
    url: 'next-download',
  },
  {
    label: 'Данные будут скачаны по',
    url: 'next-download-to',
  },
];

const COLUMNS: Array<{
  label: string;
  key: string;
}> = [
  {
    label: 'ИНН',
    key: 'inns',
  },
  {
    label: 'Точки',
    key: 'outlets',
  },
];

const DataItem: React.FC<{
  sectionIndex: number;
  columnIndex: number;
}> = ({ sectionIndex, columnIndex }) => {
  const { data, isLoading } = useDataUpdate(
    `${COLUMNS[columnIndex].key}/${SECTIONS[sectionIndex].url}`,
  );
  if (isLoading) {
    return (
      <div className={styles.dataItem}>
        <UseAnimations animation={loading} />
      </div>
    );
  }

  const dayjsObject = dayjs(data, 'YYYY-MM-DD');

  if (dayjsObject.isValid()) {
    return (
      <div className={styles.dataItem}>
        <Tooltip testId={'readable-update-date'} text={dayjsObject.fromNow()}>
          <span
            data-test-id={`update-date__${COLUMNS[columnIndex].key}/${SECTIONS[sectionIndex].url}`}
            data-date={data}
          >
            {dayjsObject.format('DD MMM')}
          </span>
        </Tooltip>
      </div>
    );
  }

  return <div className={styles.dataItem}>{data}</div>;
};

export const DataUpdate: React.FC = () => {
  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <div />
        {COLUMNS.map((column, columnIndex) => {
          return (
            <div className={styles.column} key={columnIndex}>
              {column.label}
            </div>
          );
        })}
      </div>
      {SECTIONS.map((section, sectionIndex) => {
        return (
          <div
            className={classNames(styles.section, {
              [styles.last]: sectionIndex === SECTIONS.length - 1,
            })}
            key={sectionIndex}
          >
            <div className={styles.sectionLabel}>{section.label}</div>
            {COLUMNS.map((_column, columnIndex) => {
              return (
                <DataItem
                  key={columnIndex}
                  sectionIndex={sectionIndex}
                  columnIndex={columnIndex}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
