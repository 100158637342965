import { User } from '../hooks/useUser';

export type Role =
  | 'ADMIN'
  | 'CLIENT'
  | 'CLIENT_ADMIN'
  | 'CLIENT_MANAGER'
  | 'CLIENT*';

export const hasRole = (role: Role | Role[], userInfo: User): boolean => {
  if (!Array.isArray(role)) {
    role = [role];
  }
  return role.some((r) => {
    if (r === 'CLIENT*') {
      // @ts-ignore
      return userInfo?.roles?.some((userRole) => userRole.startsWith('CLIENT'));
    }
    // @ts-ignore
    return userInfo?.roles.includes(r);
  });
};
