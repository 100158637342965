import { $api } from '../utils';
import { ROLES } from '../../shared/constants';
import { getRole } from '../../shared/utils/getRole';
import { formatError } from '../../shared/utils/withErrorHandling';
import { User } from '../../shared/hooks/useUser';
import {
  AddClientEmployeeRequest,
  AddClientRequest,
  ChangeUserRequest,
} from '../backend/Api';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../store/query';

export const getUsersData = async () => {
  try {
    const { data } = await $api.get<User[]>('/users');
    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

type TProductGroupConfig = {
  productGroups: Record<string, string>;
};

const fetchProductGroup = async () => {
  try {
    const { data } = await $api.get<TProductGroupConfig>('/user/config');
    return data;
  } catch (error) {
    throw new Error(formatError(error));
  }
};

export const useProductGroup = () => {
  return useQuery(['users', 'productGroup'], fetchProductGroup);
};

export const createUserClient = async (values: AddClientRequest) => {
  try {
    await $api.post('client', values);
    queryClient.invalidateQueries(['users']);
  } catch (error) {
    throw new Error(formatError(error));
  }
};

type TEditUserValues = {
  userLogin: string;
  login: string;
  name: string;
  active: boolean;
};

export const editUser = async (values: TEditUserValues) => {
  try {
    await $api.put<unknown, unknown, ChangeUserRequest>('/user', {
      userLogin: values.userLogin,
      newUserLogin: values.login,
      newUserName: values.name,
      newActive: values.active,
    });
  } catch (error) {
    throw new Error(formatError(error));
  }
};

type TCreateUserValues = {
  login: string;
  password: string;
  name: string;
  role: string;
  productGroup?: string;
  loginClient: string;
  clientLogin?: string;
};

export const createUserEmploy = async (
  values: TCreateUserValues,
  userInfo: User,
) => {
  try {
    const getLogin = () => {
      if (getRole(userInfo?.roles, ROLES.client)) {
        return userInfo?.login;
      }
      if (getRole(userInfo?.roles, ROLES.client_admin)) {
        return userInfo?.clientLogin;
      }

      return values.loginClient;
    };

    await $api.post<unknown, unknown, AddClientEmployeeRequest>(
      '/client/employee',
      {
        loginClient: getLogin(),
        loginEmployee: values.login,
        passwordEmployee: values.password,
        nameEmployee: values.name,
        roleEmployee: values.role,
      },
    );
  } catch (error) {
    throw new Error(formatError(error));
  }
};
