import React from 'react';
import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { Menu, TMenuItem } from '../common/Menu/Menu';

type TContextMenuState = {
  open: boolean;
  target: {
    x: number;
    y: number;
  };
  items: Array<TMenuItem>;
  testId: string;
};

let savedSetState: React.Dispatch<
  React.SetStateAction<TContextMenuState | null>
>;
const useContextMenuRaw = (): [
  TContextMenuState | null,
  React.Dispatch<React.SetStateAction<TContextMenuState | null>>,
] => {
  const [state, setState] = useState<TContextMenuState | null>(null);
  savedSetState = setState;
  return [state, setState];
};

export const showContextMenu = (state: TContextMenuState) => {
  savedSetState(state);
};

export const ContextMenuContainer = createContainer(useContextMenuRaw);

const useContextMenu = () => {
  return ContextMenuContainer.useContainer();
};

export const ContextMenu: React.FC = () => {
  const [state, setState] = useContextMenu();
  if (!state) {
    return null;
  }
  return (
    <Menu
      open={state.open}
      target={{
        // @ts-ignore
        getBoundingClientRect: () => ({
          x: state.target.x,
          y: state.target.y,
          width: 0,
          height: 0,
          top: state.target.y,
          right: state.target.x,
          bottom: state.target.y,
          left: state.target.x,
        }),
      }}
      items={state.items}
      onClose={() => setState(null)}
      testId={state.testId}
    />
  );
};
