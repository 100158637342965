import { DS3R7Response } from '../../../../../api/reports/Api';
import {
  DEFAULT_NUMERIC_FORMATTER,
  DEFAULT_PERCENT_FORMATTER,
} from '../../../../../shared/utils/formatters';
import { toPercent } from '../../../../../shared/utils/toPercent';
import { sorter } from '../../../../common/Table/sorter';
import { getIntervalFilter } from '../AllPurposeReport/filters/interval';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getMapFeature } from '../AllPurposeReport/viewFeatures/map';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Цена на товар по регионам';

export const ds3o7Config: TReportConfigType<DS3R7Response> = {
  title: NAME,
  backendKey: 'ds3r7',

  dataFeatures: [],

  viewFeatures: [
    getTableFeature<DS3R7Response>({
      defaultSort: 'salesCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'sellerAddrSfCode',
          title: 'Код СФ',
          isNumeric: true,
          sort: sorter('sellerAddrSfCode', true),
          size: '130px',
        },
        {
          key: 'sellerAddrSfName',
          title: 'Субъект РФ',
          sort: sorter('sellerAddrSfName'),
        },
        {
          key: 'addrCnt',
          title: 'Кол-во адресов',
          hint: 'Количество адресов продаж',
          isNumeric: true,
          sort: sorter('addrCnt', true),
          render: (row: any) => {
            // Форматирование целочисленного для отображения тысячных разделителей
            try {
              return row.addrCnt.toLocaleString('ru', {
                maximumFractionDigits: 0,
                useGrouping: true,
              });
            } catch (e) {
              return row.addrCnt;
            }
          },
          renderForExport: (row: any) => {
            try {
              return row.addrCnt.toLocaleString('ru', {
                maximumFractionDigits: 0,
                useGrouping: true,
              });
            } catch (e) {
              return row.addrCnt;
            }
          },
        },
        {
          key: 'salesCnt',
          title: 'Объем продаж',
          isNumeric: true,
          sort: sorter('salesCnt', true),
          render: (row: any) => {
            // Форматирование целочисленного для отображения тысячных разделителей
            try {
              return row.salesCnt.toLocaleString('ru', {
                maximumFractionDigits: 0,
                useGrouping: true,
              });
            } catch (e) {
              return row.salesCnt;
            }
          },
          renderForExport: (row: any) => {
            // Форматирование целочисленного для отображения тысячных разделителей
            try {
              return row.salesCnt.toLocaleString('ru', {
                maximumFractionDigits: 0,
                useGrouping: true,
              });
            } catch (e) {
              return row.salesCnt;
            }
          },
        },
        {
          key: 'avgPrice',
          title: 'Средневзвешенная цена',
          hint: 'Средневзвешенная цена за шт/кг, руб',
          isNumeric: true,
          sort: sorter('avgPrice', true),
          render: (row: any) => {
            // Форматирование целочисленного для отображения тысячных разделителей
            try {
              return row.avgPrice.toLocaleString('ru', {
                maximumFractionDigits: 0,
                useGrouping: true,
              });
            } catch (e) {
              return row.avgPrice;
            }
          },
          renderForExport: (row: any) => {
            // Форматирование целочисленного для отображения тысячных разделителей
            try {
              return row.avgPrice.toLocaleString('ru', {
                maximumFractionDigits: 0,
                useGrouping: true,
              });
            } catch (e) {
              return row.avgPrice;
            }
          },
        },
        {
          key: 'dynPrice',
          title: 'Динамика цены',
          hint: 'Динамика цены по сравнению с прошлым периодом',
          isNumeric: true,
          sort: sorter('dynPrice', true),
          render: (row: any) => {
            return toPercent(row.dynPrice, 0);
          },
          renderForExport: (row: any) => {
            return toPercent(row.dynPrice, 0);
          },
        },
      ],
    }),
    getMapFeature<DS3R7Response>({
      filename: NAME,
      regionCodeKey: 'sellerAddrSfCode',
      valueKey: 'avgPrice',
      subValueKey: 'dynPrice',
      valueLabel: 'Цена',
      subValueLabel: '% изменения к предыдущему периоду',
      showPercentArrow: true,
      valueFormatter: DEFAULT_NUMERIC_FORMATTER,
      subValueFormatter: DEFAULT_PERCENT_FORMATTER,
    }),
  ],

  filters: [
    getIntervalFilter({
      single: true,
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товар',
      icon: 'package',
      single: true,
      required: true,
    }),
  ],
  requiredData: {},
  requiredCommonData: ['gtins'],

  isRequiredFieldsFilled: (filter) =>
    Boolean(filter.gtin) && Boolean(filter.period),
};
