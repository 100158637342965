import { EXACT_NUMERIC_FORMATTER } from './formatters';

export const getDeclarator = (
  titles: string[],
  appendNumber = true,
  formatNumber = false,
) => {
  return (number: number) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${
      appendNumber
        ? `${formatNumber ? EXACT_NUMERIC_FORMATTER(number) : number} `
        : ''
    }${
      titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ]
    }`;
  };
};
