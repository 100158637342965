/* eslint-disable import/no-unused-modules */
import axios from 'axios';
import { getAuthToken } from '../shared/utils/getAuthToken';
import { apiPath, apiPathBase, apiPathV2 } from './constants';
import * as Sentry from '@sentry/browser';
import { get, set, clear, del, keys, createStore } from 'idb-keyval';
import JSONbig from 'json-bigint';
import { Loggers, getLogger } from '../shared/utils/logger';
const JSONbigParser = JSONbig({ storeAsString: true });

const cacheLogger = getLogger(Loggers.cache);
const apiLogger = getLogger(Loggers.api);

const cacheStore = createStore('cache-store', 'cache-store');

const cacheableButUserCanEditUrls = [
  // /^\/dictionaries\/?.*/
];

const cacheableUrls = [
  ...cacheableButUserCanEditUrls,
  /^\/data\/.*/,
  /^\/?charts\/.*/,
  /^\/data-update-info\/.*/,
];

const skipWithoutLog = [
  '/user',
  '/report-attempt',
  '/events/short?limit=300',
  '/user/settings',
  '/link-address/info',
  '/link-address/client/exists',
  '/dictionaries/gtin',
  '/dictionaries/inn',
  '/dictionaries/okpd2',
  '/dictionary/address',
  '/dictionaries?type=gtin',
  '/dictionaries?type=inn',
  '/dictionaries?type=address',
];

const isCacheableUrl = (url) => {
  return cacheableUrls.some((regex) => regex.test(url));
};

export const getCachedDataCount = async () => {
  const keysList = await keys(cacheStore);
  return keysList.length;
};

export const resetCache = async () => {
  await clear(cacheStore);
};

export const resetUserEditableCache = async () => {
  const keysList = await keys(cacheStore);
  const keysToDelete = keysList.filter((key) =>
    cacheableButUserCanEditUrls.some((regex) => regex.test(key)),
  );
  await Promise.all(keysToDelete.map((key) => del(key, cacheStore)));
};

export const $apiBase = axios.create({
  baseURL: apiPathBase,
});

export const $api = axios.create({
  baseURL: apiPath,
  timeout: 0,
});

export const $apiV2 = axios.create({
  baseURL: apiPathV2,
  timeout: 0,
});

const isCacheValid = (timestamp) => {
  const now = new Date();
  const currentUTCHours = now.getUTCHours();
  const currentUTCMinutes = now.getUTCMinutes();

  if (currentUTCHours === 1 && currentUTCMinutes >= 30) {
    return (
      timestamp >=
      new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        1,
        30,
      ).getTime()
    );
  }

  if (currentUTCHours > 1 || currentUTCMinutes > 30) {
    return (
      timestamp >=
      new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate() - 1,
        1,
        30,
      ).getTime()
    );
  }

  return (
    timestamp >=
    new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 2,
      1,
      30,
    ).getTime()
  );
};

const clearInvalidCache = async () => {
  const keysList = await keys(cacheStore);
  let countDeleted = 0;
  for (const key of keysList) {
    const cachedData = await get(key, cacheStore);
    if (cachedData?.timestamp && !isCacheValid(cachedData.timestamp)) {
      countDeleted++;
      await del(key, cacheStore);
    }
  }

  cacheLogger(`Deleted ${countDeleted} invalid cache entries`);
};

export const getNextInvalidationTime = () => {
  const now = new Date();
  const currentUTCHours = now.getUTCHours();
  const currentUTCMinutes = now.getUTCMinutes();

  if (currentUTCHours === 1 && currentUTCMinutes >= 30) {
    return new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      1,
      30,
    ).getTime();
  } else {
    return new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + 1,
      1,
      30,
    ).getTime();
  }
};

setInterval(clearInvalidCache, 1000 * 60 * 60);
setTimeout(clearInvalidCache, 1000 * 20);

const getCacheKey = (config) => {
  return `${config.url}|${JSON.stringify(config.params)}`;
};

const requestInterceptors = [
  (config) => {
    const token = getAuthToken('accessToken')?.['accessToken'];
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    config.transformResponse = [];
    return config;
  },
  async (config) => {
    if (config.method.toUpperCase() === 'GET' && isCacheableUrl(config.url)) {
      const token = getAuthToken('accessToken')?.['accessToken'];
      if (!token) {
        return config;
      }
      const cacheKey = getCacheKey(config);
      const cachedData = await get(cacheKey, cacheStore);

      if (cachedData && isCacheValid(cachedData.timestamp)) {
        cacheLogger('Using cached data for', cacheKey);
        config.adapter = () =>
          Promise.resolve({ __cached: true, data: cachedData.data });
      }
    } else {
      if (!skipWithoutLog.includes(config.url)) {
        cacheLogger('Skipping cache for', config.url);
      }
    }

    return config;
  },
];

const responseInterceptors = [
  [
    async (response) => {
      if (response.__cached) {
        return response;
      }

      try {
        response.data = JSONbigParser.parse(response.data);
      } catch (e) {
        try {
          response.data = JSON.parse(response.data);
        } catch (e) {
          // ignore
        }
      }

      if (
        response.config.method.toUpperCase() === 'GET' &&
        isCacheableUrl(response.config.url)
      ) {
        const token = getAuthToken('accessToken')?.['accessToken'];
        if (!token) {
          return response;
        }
        const cacheKey = getCacheKey(response.config);
        const dataToCache = { data: response.data, timestamp: Date.now() };
        await set(cacheKey, dataToCache, cacheStore);
        cacheLogger('Caching data for', cacheKey);
      }

      return response;
    },
    (error) => {
      try {
        error.response.data = JSONbigParser.parse(error.response.data);
      } catch (e) {
        try {
          error.response.data = JSON.parse(error.response.data);
        } catch (e) {
          // ignore
        }
      }
      apiLogger(error);
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  ],
];

const APIS = [$api, $apiV2];

APIS.forEach((api) => {
  requestInterceptors.forEach((interceptor) => {
    api.interceptors.request.use(interceptor);
  });
  responseInterceptors.forEach((interceptor) => {
    api.interceptors.response.use(...interceptor);
  });
});
