import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import styles from './RegionsMap.module.scss';

import {
  REGIONS_COLS_COUNT,
  REGIONS_ROWS_COUNT,
  REGIONS_LIST,
} from './constants';
import { FitToContent } from '../FitToContent/FitToContent';
import { Checkbox } from '../../../uikit/Checkbox/Checkbox';
import {
  DEFAULT_NUMERIC_FORMATTER,
  DEFAULT_PERCENT_FORMATTER,
} from '../../../shared/utils/formatters';
import { SCREENSHOT_CLASSNAME } from '../Chart/components/DownloadChart/DownloadChart';
import {
  getMaxOfArray,
  getMinOfArray,
} from '../../../shared/utils/getMaxOfArray';

export const COLOR_SCHEMES: Record<
  string,
  {
    from: number[];
    to: number[];
    middle?: number[];
  }
> = {
  yellow: {
    from: [255, 251, 213],
    to: [255, 223, 42],
  },
  green: {
    from: [167, 249, 191],
    to: [39, 184, 68],
  },
  red: {
    from: [255, 203, 206],
    to: [255, 106, 114],
  },
  redToGreen: {
    from: [248, 105, 107],
    middle: [241, 231, 133],
    to: [99, 190, 123],
  },
};

type TRegionsMapProps = {
  data: Array<{
    regionCode: number;
    value?: number;
    subValue?: number;
    valuesHistory?: number[];
  }>;
  compact?: boolean;
  showPercentArrow?: boolean;
  valueLabel?: string;
  subValueLabel?: string;
  valueFormatter?: (value: number) => string | React.ReactNode;
  subValueFormatter?: (
    value: number,
    valuesHistory?: number[],
  ) => string | React.ReactNode;
  colorScheme?: keyof typeof COLOR_SCHEMES;
  gradientByHistory?: boolean;
};

const GAP = 4;
const ITEM_SIZE = 75;
const GRID_PROPS: React.CSSProperties = {
  gridTemplateColumns: `repeat(${REGIONS_COLS_COUNT}, ${ITEM_SIZE}px)`,
  gridTemplateRows: `repeat(${REGIONS_ROWS_COUNT}, ${ITEM_SIZE}px)`,
  gap: `${GAP}px`,
  height: `${
    ITEM_SIZE * REGIONS_ROWS_COUNT + GAP * (REGIONS_ROWS_COUNT - 1)
  }px`,
  width: `${ITEM_SIZE * REGIONS_COLS_COUNT + GAP * (REGIONS_COLS_COUNT - 1)}px`,
};

export const RegionsMap: React.FC<TRegionsMapProps> = ({
  data,
  showPercentArrow,
  valueLabel = 'Продажи',
  subValueLabel = 'Процент',
  valueFormatter = DEFAULT_NUMERIC_FORMATTER,
  subValueFormatter = DEFAULT_PERCENT_FORMATTER,
  colorScheme = 'yellow',
  compact,
  gradientByHistory,
}) => {
  const indexedData = useMemo(() => {
    const result: Record<number, TRegionsMapProps['data'][0]> = {};
    data.forEach((item) => {
      result[item.regionCode] = item;
    });
    return result;
  }, [data]);

  const [min, dist] = useMemo(() => {
    if (gradientByHistory) {
      const values = data
        .map((item) => {
          const history = item.valuesHistory || [];
          if (!history.length) return undefined;

          const lastValue = history[history.length - 1];
          const firstValue = history[0];

          return lastValue - firstValue;
        })
        .filter((value) => value !== undefined) as number[];

      const min = getMinOfArray(values);
      const max = getMaxOfArray(values);

      console.log({
        values,
        min,
        max,
      });

      return [min, max - min];
    }

    const values = data
      .map((item) => item.value)
      .filter((value) => value !== undefined) as number[];
    const min = getMinOfArray(values);
    const max = getMaxOfArray(values);
    return [min, max - min];
  }, [data, gradientByHistory]);

  const demoRegion = useMemo(() => {
    const regionsWithData = REGIONS_LIST.filter(
      (region) => indexedData[region.code]?.value,
    );
    if (!regionsWithData.length) {
      return REGIONS_LIST[0];
    }
    const averageValue =
      regionsWithData.reduce(
        (acc, region) => acc + (indexedData[region.code]?.value || 0),
        0,
      ) / regionsWithData.length;
    let closestToAverage = regionsWithData[0];
    let closestToAverageDiff = Infinity;
    regionsWithData.forEach((region) => {
      const diff = Math.abs(
        averageValue - (indexedData[region.code]?.value || 0),
      );
      if (diff < closestToAverageDiff) {
        closestToAverage = region;
        closestToAverageDiff = diff;
      }
    });
    return closestToAverage;
  }, [indexedData]);

  const demoValue = indexedData[demoRegion.code]?.value || 1;
  const demoSubvalue = indexedData[demoRegion.code]?.subValue || 0.2;

  const [showCode, setShowCode] = useState(false);
  const [showSubvalue, setShowSubvalue] = useState(true);
  const [showValue, setShowValue] = useState(true);

  const {
    from: colorFrom,
    to: colorTo,
    middle: colorMiddle,
  } = COLOR_SCHEMES[colorScheme];

  return (
    <FitToContent>
      <div
        className={classNames(styles.regionsMap, SCREENSHOT_CLASSNAME, {
          [styles.compact]: compact,
        })}
        style={GRID_PROPS}
      >
        <div className={styles.legend}>
          <div className={styles.region}>
            <div className={styles.regionName}>
              <span>{demoRegion.label}</span>
              <span>{demoRegion.code}</span>
            </div>
            <div className={styles.regionValue}>
              {valueFormatter(demoValue)}
            </div>
            <div className={styles.regionSubvalue}>
              {subValueFormatter(demoSubvalue)}
              {showPercentArrow && (
                <div
                  className={classNames(styles.arrow, {
                    [styles.arrowUp]: demoSubvalue > 0,
                    [styles.arrowDown]: demoSubvalue < 0,
                  })}
                />
              )}
            </div>
          </div>
          <div className={styles.settings}>
            <div className={styles.line}>
              <div className={styles.arrow} />
              <Checkbox
                label="Код региона"
                checked={showCode}
                onChange={setShowCode}
              />
            </div>
            <div className={styles.line}>
              <div className={styles.arrow} />
              <Checkbox
                label={valueLabel}
                checked={showValue}
                onChange={setShowValue}
              />
            </div>
            <div className={styles.line}>
              <div className={styles.arrow} />
              <Checkbox
                label={subValueLabel}
                checked={showSubvalue}
                onChange={setShowSubvalue}
              />
            </div>
          </div>
        </div>
        {REGIONS_LIST.map((region, index) => {
          const value = indexedData[region.code]?.value || 0;
          const subValue = indexedData[region.code]?.subValue || 0;

          let valueToCompare: number | undefined = value;

          if (gradientByHistory) {
            const history = indexedData[region.code]?.valuesHistory || [];
            if (history.length) {
              const lastValue = history[history.length - 1];
              const firstValue = history[0];
              valueToCompare = lastValue - firstValue;
            } else {
              valueToCompare = undefined;
            }
          }

          const minMaxSubvalue =
            valueToCompare !== undefined && dist !== 0
              ? (valueToCompare - min) / dist
              : 0;

          let color = ![0, undefined].includes(valueToCompare)
            ? colorFrom
                .map((color, index) => {
                  return Math.round(
                    color + (colorTo[index] - color) * minMaxSubvalue,
                  );
                })
                .join(', ')
            : undefined;

          if (colorMiddle && ![0, undefined].includes(valueToCompare)) {
            const positionOnGradient = ((valueToCompare || 0) - min) / dist;
            const isRightSide = positionOnGradient > 0.5;
            const isLeftSide = positionOnGradient < 0.5;
            const isMiddle = positionOnGradient === 0.5;

            const currentFrom = isRightSide ? colorMiddle : colorFrom;
            const currentTo = isLeftSide ? colorMiddle : colorTo;

            const currentPartPosition = positionOnGradient % 0.5;

            const currentColor = currentFrom
              .map((color, index) => {
                return Math.round(
                  color + (currentTo[index] - color) * currentPartPosition,
                );
              })
              .join(', ');

            color = isMiddle ? colorMiddle.join(', ') : currentColor;
          }

          const hasSomeValue = value !== 0 || subValue !== 0;
          return (
            <div
              className={classNames(styles.region)}
              key={index}
              style={{
                gridColumnStart: region.x,
                gridColumnEnd: region.x + 1,
                gridRowStart: region.y,
                gridRowEnd: region.y + 1,
                backgroundColor: `rgb(${color})`,
              }}
            >
              <div className={styles.regionName}>
                <span>{region.label}</span>
                {showCode && <span>{region.code}</span>}
              </div>
              {showValue && hasSomeValue && (
                <div className={styles.regionValue}>
                  {valueFormatter(value || 0)}
                </div>
              )}
              {showSubvalue && hasSomeValue && (
                <div className={styles.regionSubvalue}>
                  {subValueFormatter(
                    subValue || 0,
                    indexedData[region.code]?.valuesHistory,
                  )}
                  {showPercentArrow && subValue !== 0 && (
                    <span
                      className={classNames(styles.arrow, {
                        [styles.arrowUp]: subValue > 0,
                        [styles.arrowDown]: subValue < 0,
                      })}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </FitToContent>
  );
};
