import React, { useEffect, useMemo, useState } from 'react';
import { actionEntities } from './constants';
import { UserModal } from './components/UserModal';
import { useAccesses } from './useAccesses';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';

import styles from './Accesses.module.scss';
import { Button } from '../../../uikit/Button/Button';
import { Table } from '../../common/Table/Table';
import { ROLE_NAMES } from '../../../shared/constants';
import { sorter } from '../../common/Table/sorter';
import { Input } from '../../../uikit/Input/Input';
import { Menu } from '../../common/Menu/Menu';
import { PageContent } from '../../common/PageContent/PageContent';
import { Pagination } from '../../common/Table/components/Pagination/Pagination';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchQueryState } from '../../../shared/hooks/useSearchQueryState';
import { createUserEmploy, getUsersData } from '../../../api/accesses/accesses';
import { withRestriction } from '../../../shared/hocs/withRestriction';
import { hasRole } from '../../../shared/utils/hasRole';
import { ChangeRoleModal } from './components/ChangeRoleModal';
import { Modal } from '../../../uikit/Modal/Modal';
import { Spacer } from '../../common/Spacer/Spacer';
import { toast } from 'react-toastify';
import { getRandomString } from './utils';
import { withLoader } from '../../common/FullscreenLoader/FullscreenLoader';
import copy from 'copy-text-to-clipboard';
import { useBreakpoint } from '../../../uikit/hooks/useBreakpoint';
import { Icon } from '../../common/Icon/Icon';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';
import { DownloadReport } from '../../common/Table/components/DownloadReport/DownloadReport';

const getRowKey = (row) => row.login;

const USER_SEARCH_KEYS = ['login', 'name', 'alias', 'guid', 'parentUserGuid'];

const copySupportData = (userData) => {
  copy(`Данные аккаунта поддержки:
  
E-mail: ${userData.login}
Пароль: ${userData.password}`);
};

export const Accesses = withRestriction(
  ['CLIENT', 'CLIENT_ADMIN', 'ADMIN'],
  () => {
    const userInfo = useAuthorizedUser();

    const supportAccountPreset = useMemo(
      () => ({
        isSupportPreset: true,
        name: 'Поддержка Риды',
        login: `support-${userInfo.guid}@retail-data.center`,
        active: false,
        roles: ['CLIENT_ADMIN'],
      }),
      [userInfo.guid],
    );

    const queryClient = useQueryClient();
    const {
      showModal,
      modalType,
      onFinish,
      selectedItem,
      onUpdateActionEntities,
      isClientManager,
      createButtonText,
      titleText,
      isAdmin,
      setSelectedItem,
    } = useAccesses(queryClient);

    const { data, isLoading, isFetching } = useQuery(['users'], getUsersData, {
      staleTime: 30000,
      cacheTime: 60000 * 2,
    });

    const hasSupportAccount = useMemo(() => {
      if (userInfo.name === 'Поддержка Риды') {
        return true;
      }
      return (
        data && data.some((user) => user.login === supportAccountPreset.login)
      );
    }, [data, userInfo.name, supportAccountPreset.login]);

    const dataWithSupportAccount = useMemo(() => {
      if (!data) {
        return [];
      }
      if (hasSupportAccount) {
        return data;
      }
      return [supportAccountPreset, ...data];
    }, [data, hasSupportAccount, supportAccountPreset]);

    const navigate = useNavigate();
    useEffect(() => {
      if (isClientManager) {
        navigate('/');
      }
    });

    const [search, setSearch] = useSearchQueryState('');

    const [moreButtonTarget, setMoreButtonTarget] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [
      isCreateSupportAccountModalOpen,
      setIsCreateSupportAccountModalOpen,
    ] = useState(false);
    const [isSupportAccountInfoModalOpen, setIsSupportAccountInfoModalOpen] =
      useState(false);

    const createSupportUser = () => {
      withLoader(async () => {
        try {
          const newPassword = getRandomString(Math.random() * 5 + 8);
          const userData = {
            login: supportAccountPreset.login,
            name: supportAccountPreset.name,
            role: supportAccountPreset.roles[0],
            password: newPassword,
          };
          await createUserEmploy(userData, userInfo);
          getUsersData();
          setIsCreateSupportAccountModalOpen(false);
          setIsSupportAccountInfoModalOpen(userData);
          copySupportData(userData);
          queryClient.invalidateQueries('users');
          toast.success(
            'Аккаунт поддержки успешно создан. Данные скопированы в буфер обмена.',
          );
        } catch (error) {
          toast.error(error.message);
        }
      });
    };

    const tableColumns = useMemo(() => {
      const columns = [
        {
          key: 'login',
          title: 'E-mail',
          sort: sorter('login'),
          size: '300px',
          render: (row) => {
            if (row.isSupportPreset) {
              return <span className={styles.hint}>{row.login}</span>;
            }
            return row.login;
          },
        },
        {
          key: 'name',
          title: 'Сотрудник',
          sort: sorter('name'),
          render: (row) => {
            if (row.isSupportPreset) {
              return <span className={styles.hint}>{row.name}</span>;
            }
            if (row.login === userInfo.login) {
              return `${row.name} (Вы)`;
            }
            return row.name;
          },
        },
        {
          key: 'role',
          title: 'Роль',
          render: (row) => {
            const roles = row.roles.map((role) => ROLE_NAMES[role]).join(', ');
            if (row.isSupportPreset) {
              return <span className={styles.hint}>{roles}</span>;
            }
            return roles;
          },
          renderForExport: (row) => {
            if (row.isSupportPreset) {
              return '';
            }
            return row.roles.map((role) => ROLE_NAMES[role]).join(', ');
          },
          sort: (a, b) => {
            const aRole = a.roles[0];
            const bRole = b.roles[0];
            return aRole > bRole ? 1 : -1;
          },
        },
      ];
      if (isAdmin) {
        columns.push(
          {
            key: 'guid',
            title: 'GUID',
            sort: sorter('guid'),
          },
          {
            key: 'parentUserGuid',
            title: 'GUID родителя',
            sort: sorter('parentUserGuid'),
          },
          {
            key: 'alias',
            title: 'Алиас',
            sort: sorter('alias'),
          },
        );
      }
      columns.push(
        {
          key: 'active',
          title: 'Статус',
          render: (row) => {
            if (row.isSupportPreset) {
              return (
                <Button
                  small
                  onClick={() => {
                    setIsCreateSupportAccountModalOpen(true);
                  }}
                >
                  Создать
                </Button>
              );
            }

            return row.active ? (
              <span className={styles.active}>активен</span>
            ) : (
              <span className={styles.notActive}>неактивен</span>
            );
          },
          renderForExport: (row) => (row.active ? 'активен' : 'неактивен'),
          size: '130px',
          sort: sorter('active'),
        },
        {
          key: 'actions',
          hideForExport: true,
          title: '',
          render: (row) => {
            if (row.isSupportPreset) {
              return null;
            }
            return (
              <div
                className={styles.more}
                onClick={(e) => {
                  setSelectedItem(row);
                  setMoreButtonTarget(e.target);
                  setIsMenuOpen(true);
                }}
              />
            );
          },
          size: '60px',
        },
      );
      return columns;
    }, [isAdmin, setSelectedItem]);

    const filteredData = useMemo(() => {
      if (!dataWithSupportAccount) {
        return [];
      }
      if (search) {
        return dataWithSupportAccount.filter((user) => {
          return USER_SEARCH_KEYS.some((key) => {
            return (
              user[key] &&
              user[key].toLowerCase().includes(search.toLowerCase())
            );
          });
        });
      } else {
        return dataWithSupportAccount;
      }
    }, [search, dataWithSupportAccount]);

    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = useState(false);

    const breakpoints = useBreakpoint();
    const [isInputFocused, setIsInputFocused] = useState(false);

    if (isClientManager) {
      return null;
    }

    let canChangeRole = false;
    if (selectedItem && selectedItem.login !== userInfo.login) {
      if (
        !selectedItem.roles.includes('CLIENT') &&
        !selectedItem.roles.includes('ADMIN')
      ) {
        canChangeRole = hasRole(['CLIENT_ADMIN', 'ADMIN', 'CLIENT'], userInfo);
      }
    }

    return (
      <>
        {isChangeRoleModalOpen && (
          <ChangeRoleModal
            item={selectedItem}
            onClose={() => setIsChangeRoleModalOpen(false)}
          />
        )}
        {isSupportAccountInfoModalOpen && (
          <Modal
            isOpened
            onClose={() => {
              setIsSupportAccountInfoModalOpen(false);
            }}
          >
            <Modal.Title gap>
              Передайте эти данные менеджеру поддержки
            </Modal.Title>
            <Modal.Body>
              <p>
                <b>Логин:</b> {isSupportAccountInfoModalOpen.login}
              </p>
              <p>
                <b>Пароль:</b> {isSupportAccountInfoModalOpen.password}
              </p>
              <p>Эти данные уже скопированы в ваш буфер обмена</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="grey"
                onClick={() => {
                  setIsSupportAccountInfoModalOpen(false);
                }}
              >
                Закрыть
              </Button>
              <Spacer />
              <Button
                onClick={() => {
                  copySupportData(isSupportAccountInfoModalOpen);
                  toast.success('Данные скопированы в буфер обмена');
                }}
              >
                Скопировать
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {isCreateSupportAccountModalOpen && (
          <Modal
            isOpened
            onClose={() => {
              setIsCreateSupportAccountModalOpen(false);
            }}
          >
            <Modal.Title gap>Создать аккаунт для поддержки?</Modal.Title>
            <Modal.Body>
              <p>
                Это позволит менеджеру поддержки войти в аккаунт вашей компании
                и помочь вам с настройками.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="grey"
                onClick={() => {
                  setIsCreateSupportAccountModalOpen(false);
                }}
              >
                Отмена
              </Button>
              <Spacer />
              <Button
                color="yellow"
                onClick={() => {
                  createSupportUser();
                }}
              >
                Создать
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <PageHeader
          supportKey="users"
          title={titleText}
          rightTitleContent={
            <div className={styles.header}>
              {!breakpoints.isMobile && (
                <DownloadReport white={false} filename={titleText} />
              )}
              <Pagination />
              <Input
                testId="search"
                className={styles.searchInput}
                noGap
                search
                placeholder="Поиск по сотрудникам"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                block={breakpoints.isMobile}
                width={isInputFocused && !breakpoints.isSmall ? 400 : 260}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                leftIcon={<Icon size={18} name="search" />}
              />
            </div>
          }
        ></PageHeader>
        <Menu
          testId="user-settings"
          open={isMenuOpen}
          target={moreButtonTarget}
          onClose={() => setIsMenuOpen(false)}
          items={[
            {
              title: 'Редактировать',
              onClick: () => {
                onUpdateActionEntities('edit', actionEntities.modalType);
                onUpdateActionEntities(true, actionEntities.modal);
              },
            },
            {
              title: 'Сменить роль',
              disabled: !canChangeRole,
              onClick: () => {
                setIsChangeRoleModalOpen(true);
              },
            },
            {
              title: 'Заморозить',
              onClick: () => {
                onUpdateActionEntities('edit', actionEntities.modalType);
                onUpdateActionEntities(true, actionEntities.modal);
              },
            },
            {
              divider: true,
            },
            {
              title: 'Удалить',
              disabled: true,
            },
          ]}
        />
        <PageContent
          isLoading={isLoading}
          isFetching={isFetching}
          notFound={!data || data.length === 0}
        >
          <div className={styles.createButtonContainer}>
            <Button
              testId="create-user"
              onClick={() => {
                onUpdateActionEntities('create', actionEntities.modalType);
                onUpdateActionEntities(true, actionEntities.modal);
              }}
              color="yellow"
              reportPanel
            >
              <Icon name="add" /> {createButtonText}
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <Table
              withPagination
              getRowKey={getRowKey}
              rows={filteredData}
              cols={tableColumns}
            />
          </div>
        </PageContent>
        {showModal && (
          <UserModal
            show={showModal}
            onClose={() => {
              onUpdateActionEntities(false, actionEntities.modal);
              onUpdateActionEntities(null, actionEntities.selectedItem);
            }}
            type={modalType}
            onFinish={onFinish}
            selectedItem={selectedItem}
          />
        )}
      </>
    );
  },
);
