import React, { useMemo } from 'react';
import { Button } from '../../../../uikit/Button/Button';
import XLSX from 'xlsx';
import { Table } from '../../../common/Table/Table';
import { Chart } from '../../../common/Chart/Chart';
import { PageContent } from '../../../common/PageContent/PageContent';
import { useIsFullscreen } from '../../../common/PageContent/useIsFullscreen';
import { Input } from '../../../../uikit/Input/Input';
import copy from 'copy-text-to-clipboard';

import styles from './chord.module.scss';
import { Gap } from '../../../../uikit/Gap/Gap';
import { Modal } from '../../../../uikit/Modal/Modal';
import { toast } from 'react-toastify';

type TRow = {
  from: string;
  to: string;
  value: number;
};

type TColorRow = {
  node: string;
  color: string;
};

const DATA_EXAMPLE: TRow[] = [
  {
    from: '0',
    to: '10',
    value: 1115693,
  },
  {
    from: '0',
    to: '11',
    value: 639845,
  },
  {
    from: '0',
    to: '14',
    value: 500460,
  },
  {
    from: '0',
    to: '9',
    value: 115131,
  },
  {
    from: '0',
    to: '15',
    value: 7417,
  },
  {
    from: '0',
    to: '13',
    value: 244,
  },
  {
    from: '0',
    to: '12',
    value: 9,
  },
  {
    from: '1',
    to: '9',
    value: 2526924,
  },
  {
    from: '1',
    to: '10',
    value: 941296,
  },
  {
    from: '1',
    to: '8',
    value: 597575,
  },
  {
    from: '1',
    to: '11',
    value: 480366,
  },
  {
    from: '1',
    to: '13',
    value: 138516,
  },
  {
    from: '1',
    to: '14',
    value: 82679,
  },
  {
    from: '1',
    to: '12',
    value: 5598,
  },
  {
    from: '1',
    to: '15',
    value: 985,
  },
  {
    from: '2',
    to: '8',
    value: 1598648,
  },
  {
    from: '2',
    to: '10',
    value: 1003923,
  },
  {
    from: '2',
    to: '9',
    value: 980323,
  },
  {
    from: '2',
    to: '11',
    value: 231785,
  },
  {
    from: '2',
    to: '13',
    value: 166567,
  },
  {
    from: '2',
    to: '12',
    value: 35546,
  },
  {
    from: '2',
    to: '14',
    value: 23269,
  },
  {
    from: '2',
    to: '15',
    value: 4377,
  },
  {
    from: '3',
    to: '8',
    value: 2413260,
  },
  {
    from: '3',
    to: '11',
    value: 1553371,
  },
  {
    from: '3',
    to: '15',
    value: 60403,
  },
  {
    from: '3',
    to: '10',
    value: 2008,
  },
  {
    from: '3',
    to: '9',
    value: 875,
  },
  {
    from: '3',
    to: '13',
    value: 175,
  },
  {
    from: '3',
    to: '14',
    value: 12,
  },
  {
    from: '4',
    to: '8',
    value: 2659301,
  },
  {
    from: '4',
    to: '9',
    value: 403697,
  },
  {
    from: '4',
    to: '10',
    value: 266575,
  },
  {
    from: '4',
    to: '14',
    value: 85972,
  },
  {
    from: '4',
    to: '11',
    value: 43357,
  },
  {
    from: '4',
    to: '13',
    value: 31130,
  },
  {
    from: '4',
    to: '15',
    value: 11485,
  },
  {
    from: '4',
    to: '12',
    value: 5508,
  },
  {
    from: '5',
    to: '10',
    value: 791656,
  },
  {
    from: '5',
    to: '9',
    value: 762869,
  },
  {
    from: '5',
    to: '8',
    value: 703329,
  },
  {
    from: '5',
    to: '13',
    value: 261015,
  },
  {
    from: '5',
    to: '12',
    value: 200707,
  },
  {
    from: '5',
    to: '11',
    value: 83165,
  },
  {
    from: '5',
    to: '14',
    value: 69333,
  },
  {
    from: '5',
    to: '15',
    value: 67632,
  },
  {
    from: '7',
    to: '12',
    value: 499012,
  },
  {
    from: '7',
    to: '15',
    value: 473984,
  },
  {
    from: '7',
    to: '8',
    value: 470819,
  },
  {
    from: '7',
    to: '10',
    value: 452834,
  },
  {
    from: '7',
    to: '11',
    value: 414592,
  },
  {
    from: '7',
    to: '13',
    value: 357386,
  },
  {
    from: '7',
    to: '9',
    value: 233352,
  },
  {
    from: '7',
    to: '14',
    value: 16656,
  },
  {
    from: '6',
    to: '9',
    value: 870602,
  },
  {
    from: '6',
    to: '8',
    value: 393623,
  },
  {
    from: '6',
    to: '10',
    value: 377066,
  },
  {
    from: '6',
    to: '12',
    value: 291152,
  },
  {
    from: '6',
    to: '13',
    value: 78074,
  },
  {
    from: '6',
    to: '11',
    value: 59338,
  },
  {
    from: '6',
    to: '14',
    value: 45147,
  },
  {
    from: '6',
    to: '15',
    value: 35704,
  },
  {
    from: '0',
    to: '21',
    value: 20439,
  },
  {
    from: '0',
    to: '18',
    value: 2011223,
  },
  {
    from: '0',
    to: '17',
    value: 3697813,
  },
  {
    from: '0',
    to: '16',
    value: 494123,
  },
  {
    from: '0',
    to: '19',
    value: 50,
  },
  {
    from: '0',
    to: '20',
    value: 8,
  },
  {
    from: '1',
    to: '18',
    value: 282,
  },
  {
    from: '1',
    to: '17',
    value: 587905,
  },
  {
    from: '1',
    to: '16',
    value: 4027552,
  },
  {
    from: '1',
    to: '19',
    value: 157820,
  },
  {
    from: '1',
    to: '20',
    value: 315,
  },
  {
    from: '1',
    to: '22',
    value: 65,
  },
  {
    from: '2',
    to: '18',
    value: 10,
  },
  {
    from: '2',
    to: '17',
    value: 39546,
  },
  {
    from: '2',
    to: '16',
    value: 2289102,
  },
  {
    from: '2',
    to: '19',
    value: 1552228,
  },
  {
    from: '2',
    to: '20',
    value: 163363,
  },
  {
    from: '2',
    to: '22',
    value: 153,
  },
  {
    from: '2',
    to: '23',
    value: 36,
  },
  {
    from: '3',
    to: '21',
    value: 1281536,
  },
  {
    from: '3',
    to: '18',
    value: 2593978,
  },
  {
    from: '3',
    to: '17',
    value: 96120,
  },
  {
    from: '3',
    to: '16',
    value: 58466,
  },
  {
    from: '3',
    to: '19',
    value: 4,
  },
  {
    from: '4',
    to: '18',
    value: 11731,
  },
  {
    from: '4',
    to: '17',
    value: 3075906,
  },
  {
    from: '4',
    to: '16',
    value: 417929,
  },
  {
    from: '4',
    to: '19',
    value: 117,
  },
  {
    from: '4',
    to: '20',
    value: 5,
  },
  {
    from: '4',
    to: '23',
    value: 1337,
  },
  {
    from: '5',
    to: '17',
    value: 18091,
  },
  {
    from: '5',
    to: '16',
    value: 200779,
  },
  {
    from: '5',
    to: '19',
    value: 1740176,
  },
  {
    from: '5',
    to: '20',
    value: 820862,
  },
  {
    from: '5',
    to: '22',
    value: 159795,
  },
  {
    from: '5',
    to: '23',
    value: 3,
  },
  {
    from: '7',
    to: '21',
    value: 154672,
  },
  {
    from: '7',
    to: '18',
    value: 221220,
  },
  {
    from: '7',
    to: '17',
    value: 25946,
  },
  {
    from: '7',
    to: '16',
    value: 192522,
  },
  {
    from: '7',
    to: '19',
    value: 375329,
  },
  {
    from: '7',
    to: '20',
    value: 540063,
  },
  {
    from: '7',
    to: '22',
    value: 439684,
  },
  {
    from: '7',
    to: '23',
    value: 969199,
  },
  {
    from: '6',
    to: '17',
    value: 37469,
  },
  {
    from: '6',
    to: '16',
    value: 99896,
  },
  {
    from: '6',
    to: '19',
    value: 513379,
  },
  {
    from: '6',
    to: '20',
    value: 1020664,
  },
  {
    from: '6',
    to: '22',
    value: 379676,
  },
  {
    from: '6',
    to: '23',
    value: 99622,
  },
  {
    from: '21',
    to: '11',
    value: 1370937,
  },
  {
    from: '21',
    to: '8',
    value: 43729,
  },
  {
    from: '21',
    to: '15',
    value: 21619,
  },
  {
    from: '21',
    to: '14',
    value: 20361,
  },
  {
    from: '21',
    to: '10',
    value: 1,
  },
  {
    from: '18',
    to: '8',
    value: 3320458,
  },
  {
    from: '18',
    to: '11',
    value: 981033,
  },
  {
    from: '18',
    to: '10',
    value: 522268,
  },
  {
    from: '18',
    to: '15',
    value: 14511,
  },
  {
    from: '18',
    to: '9',
    value: 134,
  },
  {
    from: '18',
    to: '14',
    value: 40,
  },
  {
    from: '17',
    to: '8',
    value: 5953187,
  },
  {
    from: '17',
    to: '10',
    value: 893958,
  },
  {
    from: '17',
    to: '11',
    value: 375645,
  },
  {
    from: '17',
    to: '9',
    value: 218454,
  },
  {
    from: '17',
    to: '14',
    value: 102227,
  },
  {
    from: '17',
    to: '13',
    value: 25835,
  },
  {
    from: '17',
    to: '15',
    value: 9490,
  },
  {
    from: '17',
    to: '12',
    value: 0,
  },
  {
    from: '16',
    to: '9',
    value: 2990187,
  },
  {
    from: '16',
    to: '8',
    value: 1775050,
  },
  {
    from: '16',
    to: '10',
    value: 1541457,
  },
  {
    from: '16',
    to: '14',
    value: 700873,
  },
  {
    from: '16',
    to: '11',
    value: 694598,
  },
  {
    from: '16',
    to: '15',
    value: 57979,
  },
  {
    from: '16',
    to: '12',
    value: 11212,
  },
  {
    from: '16',
    to: '13',
    value: 9013,
  },
  {
    from: '19',
    to: '8',
    value: 1494516,
  },
  {
    from: '19',
    to: '9',
    value: 1124003,
  },
  {
    from: '19',
    to: '10',
    value: 893164,
  },
  {
    from: '19',
    to: '13',
    value: 544790,
  },
  {
    from: '19',
    to: '12',
    value: 200226,
  },
  {
    from: '19',
    to: '11',
    value: 77079,
  },
  {
    from: '19',
    to: '15',
    value: 5301,
  },
  {
    from: '19',
    to: '14',
    value: 24,
  },
  {
    from: '20',
    to: '10',
    value: 1035532,
  },
  {
    from: '20',
    to: '9',
    value: 853844,
  },
  {
    from: '20',
    to: '12',
    value: 360803,
  },
  {
    from: '20',
    to: '15',
    value: 130842,
  },
  {
    from: '20',
    to: '8',
    value: 93754,
  },
  {
    from: '20',
    to: '13',
    value: 67420,
  },
  {
    from: '20',
    to: '11',
    value: 3085,
  },
  {
    from: '22',
    to: '9',
    value: 552654,
  },
  {
    from: '22',
    to: '12',
    value: 185716,
  },
  {
    from: '22',
    to: '13',
    value: 132632,
  },
  {
    from: '22',
    to: '15',
    value: 103972,
  },
  {
    from: '22',
    to: '11',
    value: 2594,
  },
  {
    from: '22',
    to: '10',
    value: 1099,
  },
  {
    from: '22',
    to: '8',
    value: 706,
  },
  {
    from: '23',
    to: '15',
    value: 318273,
  },
  {
    from: '23',
    to: '12',
    value: 279575,
  },
  {
    from: '23',
    to: '13',
    value: 253417,
  },
  {
    from: '23',
    to: '9',
    value: 154497,
  },
  {
    from: '23',
    to: '10',
    value: 63572,
  },
  {
    from: '23',
    to: '11',
    value: 848,
  },
  {
    from: '23',
    to: '8',
    value: 12,
  },
  {
    from: '23',
    to: '14',
    value: 3,
  },
];

const COLOR_DATA_EXAMPLE: TColorRow[] = [
  {
    node: '1',
    color: '#FF0000',
  },
  {
    node: '2',
    color: '#FF0000',
  },
  {
    node: '3',
    color: '#00FF00',
  },
  {
    node: '4',
    color: '#00FF00',
  },
];

const COLUMNS_CONFIG = [
  {
    wch: 20,
  },
  {
    wch: 20,
  },
  {
    wch: 20,
  },
  {
    wch: 20,
  },
];

const TABLE_ROWS = [
  {
    key: 'from',
    title: 'Откуда',
  },
  {
    key: 'to',
    title: 'Куда',
  },
  {
    key: 'value',
    title: 'Значение',
  },
];

const COLOR_TABLE_ROWS = [
  {
    key: 'node',
    title: 'Узел',
  },
  {
    key: 'color',
    title: 'Цвет',
  },
];

const getCode = (data: TRow[], colors: TColorRow[]): string => `
<script src="https://cdn.amcharts.com/lib/4/core.js"></script>
<script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
<script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>

<div id="chartdiv" style="width: 100%; height: 100%;"></div>

<script>
am4core.useTheme(am4themes_animated);

const chart = am4core.create("chartdiv", am4charts.ChordDiagram);

chart.data = ${JSON.stringify(
  [
    ...colors.map((color) => ({
      from: color.node,
      nodeColor: color.color,
    })),

    ...data,
  ],
  null,
  2,
)};

chart.dataFields.fromName = "from";
chart.dataFields.toName = "to";
chart.dataFields.value = "value";
chart.dataFields.color = "nodeColor";

chart.nodes.template.draggable = false;
chart.nodes.template.clickable = true;
chart.nodes.template.padAngle = 0;

chart.links.template.colorMode = "gradient";
</script>
`;

export const ChordScreenshoter = () => {
  const [currentColorData, setCurrentColorData] = React.useState<TColorRow[]>(
    [],
  );
  const [currentData, setCurrentData] = React.useState<TRow[]>([]);
  const [isFullscreen] = useIsFullscreen();

  const [isContentOpen, setIsContentOpen] = React.useState(false);
  const [isColorContentOpen, setIsColorContentOpen] = React.useState(false);
  const [isCodeOpen, setIsCodeOpen] = React.useState(false);
  const [fileKey, setFileKey] = React.useState(0);
  const [colorFileKey, setColorFileKey] = React.useState(0);

  const code = useMemo(
    () => getCode(currentData, currentColorData),
    [currentData, currentColorData],
  );
  const codeInnerUrl = useMemo(
    () => `data:text/html,${encodeURIComponent(code)}`,
    [code],
  );

  return (
    <div className={styles.container}>
      {!isFullscreen && (
        <div className={styles.left}>
          <Chart testId="chart">
            <div>
              <Button
                testId="download-example"
                onClick={() => {
                  const worksheet = XLSX.utils.json_to_sheet(DATA_EXAMPLE);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  worksheet['!cols'] = COLUMNS_CONFIG;
                  XLSX.writeFile(workbook, 'example.xlsx');
                }}
              >
                Скачать пример
              </Button>
            </div>
            <Gap size={16} />
            <div className={styles.formatTable}>
              <Table
                noMargin
                cols={[
                  {
                    key: 'key',
                    title: 'Поле',
                  },
                  {
                    key: 'type',
                    title: 'Тип',
                  },
                ]}
                rows={[
                  {
                    key: 'from',
                    type: 'Строка',
                  },
                  {
                    key: 'to',
                    type: 'Строка',
                  },
                  {
                    key: 'value',
                    type: 'Число',
                  },
                ]}
              />
            </div>
            <Gap size={16} />
            <Input
              testId="file"
              block
              custom={
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) {
                      return;
                    }
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const data = e.target?.result;
                      if (!data) {
                        return;
                      }
                      const workbook = XLSX.read(data, { type: 'binary' });
                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                      const json = XLSX.utils.sheet_to_json(worksheet);
                      setCurrentData(json as TRow[]);
                      setFileKey(fileKey + 1);
                    };
                    reader.readAsBinaryString(file);
                  }}
                />
              }
              label="Файл с данными"
            />

            <Gap size={32} />

            <div>
              <Button
                testId="download-color-example"
                onClick={() => {
                  const worksheet =
                    XLSX.utils.json_to_sheet(COLOR_DATA_EXAMPLE);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  worksheet['!cols'] = COLUMNS_CONFIG;
                  XLSX.writeFile(workbook, 'color-example.xlsx');
                }}
              >
                Скачать пример цветов
              </Button>
            </div>
            <Gap size={16} />
            <div className={styles.formatTable}>
              <Table
                noMargin
                cols={[
                  {
                    key: 'key',
                    title: 'Поле',
                  },
                  {
                    key: 'type',
                    title: 'Тип',
                  },
                ]}
                rows={[
                  {
                    key: 'node',
                    type: 'Строка',
                  },
                  {
                    key: 'color',
                    type: 'Строка, hex',
                  },
                ]}
              />
            </div>
            <Gap size={16} />
            <Input
              testId="color-file"
              block
              custom={
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) {
                      return;
                    }
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const data = e.target?.result;
                      if (!data) {
                        return;
                      }
                      const workbook = XLSX.read(data, { type: 'binary' });
                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                      const json = XLSX.utils.sheet_to_json(worksheet);
                      setCurrentColorData(json as TColorRow[]);
                      setColorFileKey(colorFileKey + 1);
                    };
                    reader.readAsBinaryString(file);
                  }}
                />
              }
              label="Файл цветов"
            />

            <Gap size={32} />

            <div>
              <Button
                testId="open-code"
                color="darkGrey"
                onClick={() => {
                  setIsCodeOpen(!isCodeOpen);
                }}
              >
                Код
              </Button>
            </div>
            <Gap size={16} />

            <div>
              <Button
                testId="open-data"
                color="darkGrey"
                onClick={() => {
                  setIsContentOpen(!isContentOpen);
                }}
              >
                Данные
              </Button>
            </div>
            <Gap size={16} />

            <div>
              <Button
                testId="open-data"
                color="darkGrey"
                onClick={() => {
                  setIsColorContentOpen(!isColorContentOpen);
                }}
              >
                Цвета
              </Button>
            </div>

            <Modal
              testId="data"
              isOpened={isContentOpen}
              onClose={() => setIsContentOpen(false)}
              className={styles.modal}
            >
              <Table
                noMargin
                rows={currentData}
                // @ts-ignore
                cols={TABLE_ROWS}
              />
            </Modal>

            <Modal
              testId="colors"
              isOpened={isColorContentOpen}
              onClose={() => setIsColorContentOpen(false)}
              className={styles.modal}
            >
              <Table
                noMargin
                rows={currentColorData}
                // @ts-ignore
                cols={COLOR_TABLE_ROWS}
              />
            </Modal>

            <Modal
              testId="code"
              isOpened={isCodeOpen}
              onClose={() => setIsCodeOpen(false)}
              className={styles.modal}
            >
              <Modal.Body>
                <pre style={{ maxHeight: 300 }}>{code}</pre>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  testId="copy-code"
                  onClick={() => {
                    copy(code);
                    toast.success('Скопировано');
                    setIsCodeOpen(false);
                  }}
                >
                  Скопировать
                </Button>
              </Modal.Footer>
            </Modal>
          </Chart>
        </div>
      )}
      <div className={styles.right}>
        {currentData.length > 0 && (
          <PageContent>
            <Chart fullHeight testId="chart">
              <iframe
                title="Chord"
                key={fileKey}
                src={codeInnerUrl}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
              />
            </Chart>
          </PageContent>
        )}
      </div>
    </div>
  );
};
