import React, { useEffect, useState } from 'react';
import { useFilename } from '../../../../../shared/hooks/useFilename';
import { makeScreenshot } from '../../../../../shared/utils/makeScreenshot';
import { saveAsFile } from '../../../../../shared/utils/saveAsFile';
import { Tooltip } from '../../../Tooltip/Tooltip';

import UseAnimations from 'react-useanimations';
import download from 'react-useanimations/lib/download';

import styles from './DownloadChart.module.scss';
import { wait } from '../../../../../shared/utils/wait';
import { useBreakpoint } from '../../../../../uikit/hooks/useBreakpoint';
import { Button } from '../../../../../uikit/Button/Button';

export const SCREENSHOT_CLASSNAME = 'js-screenshot';

type TDownloadProps = {
  filename: string;
  forceEchartIntance?: any;
  forceScreenshot?: boolean;
};

export const EChartsInstance = {
  current: null,
};

export const DownloadChart: React.FC<TDownloadProps> = ({
  filename,
  forceScreenshot,
  forceEchartIntance,
}) => {
  const fileNameWithDate = useFilename(filename, 'png');
  const instance = forceEchartIntance || EChartsInstance.current;

  const [forceAnimation, setForceAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceAnimation(false);
    }, 4500);
    return () => {
      clearTimeout(timeout);
    };
  }, [forceAnimation]);

  const handleClick = async () => {
    setForceAnimation(true);
    await wait(1000);

    if (instance && !forceScreenshot) {
      // @ts-ignore
      const dataUrl = instance.getDataURL({
        pixelRatio: 3,
        backgroundColor: '#fff',
      });
      saveAsFile(dataUrl, fileNameWithDate);
    } else {
      makeScreenshot(`.${SCREENSHOT_CLASSNAME}`, fileNameWithDate);
    }
  };

  const breakpoints = useBreakpoint();

  return (
    <Tooltip
      testId={'download-chart'}
      text="Сохранить видимую область графика в файл"
    >
      <Button
        className={styles.button}
        onClick={handleClick}
        color="white"
        reportPanel
        testId="chart-download-button"
      >
        {!breakpoints.isSmall && <span>Скачать</span>}
        <UseAnimations
          key={`${forceAnimation}`}
          autoplay={forceAnimation}
          animation={download}
          size={20}
          wrapperStyle={{
            marginTop: -3,
          }}
        />
      </Button>
    </Tooltip>
  );
};
