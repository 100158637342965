import { DS1R8Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { getGroupByFeature } from '../AllPurposeReport/dataFeatures/groupBy';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'GTIN в опте';

export const ds1o8Config: TReportConfigType<DS1R8Response> = {
  title: NAME,
  backendKey: 'ds1r8',

  dataFeatures: [
    getGroupByFeature<DS1R8Response>({
      options: [
        {
          groupBy: 'buyerInn',
          label: 'ИНН',
          additionalColumns: [
            {
              key: 'buyerInn',
              title: 'ИНН покупателя',
              sort: sorter('buyerInn'),
            },
            {
              key: 'buyerName',
              title: 'Название покупателя',
              sort: sorter('buyerName'),
            },
          ],
        },
        {
          groupBy: 'buyerNetName',
          label: 'Сети',
          additionalColumns: [
            {
              key: 'buyerNetName',
              title: 'Название сети',
              sort: sorter('buyerNetName'),
            },
          ],
        },
      ],
      summarizeFields: ['buyingsCnt', 'sharePercent'],
    }),
  ],

  viewFeatures: [
    getTableFeature<DS1R8Response>({
      defaultSort: 'buyingsCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'buyingsCnt',
          title: 'Объем покупки, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('buyingsCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.buyingsCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.buyingsCnt);
          },
        },
      ],
    }),
    getBarChartFeature<DS1R8Response>({
      nameKey: 'buyerName',
      valueKey: 'buyingsCnt',
      filename: NAME,
      innKey: 'buyerInn',
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
    getBarChartFeature<DS1R8Response>({
      nameKey: 'buyerName',
      valueKey: 'buyingsCnt',
      withPercents: true,
      accumulateBy: 'sharePercent',
      innKey: 'buyerInn',
      icon: 'barChartWithLine',
      filename: NAME,
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
    getTreeMapFeature<DS1R8Response>({
      topName: NAME,
      parentNameKey: 'buyerInn',
      childrenNameKey: 'buyerName',
      innKey: 'buyerInn',
      valueKey: 'buyingsCnt',
      filename: NAME,
      groupKeyToNameMap: {
        buyerInn: 'buyerName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товар',
      icon: 'package',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['gtins'],

  isRequiredFieldsFilled: () => true,
};
