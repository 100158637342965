import { useQuery } from '@tanstack/react-query';
import { $api } from '../../api/utils';
import { useCallback, useEffect, useState } from 'react';
import { queryClient } from '../../store/query';
import isEqual from 'lodash/isEqual';

type Settings = Partial<{
  favoriteReports: Record<string, true>;
  useUsageCount: Record<string, number>;
  lastTimeNotifications: string;
}>;

const getUserSettings = async (): Promise<Settings> => {
  const { data } = await $api.get('/user/settings');
  return data;
};

const setUserSettings = async (settings: Settings): Promise<void> => {
  await $api.post('/user/settings', settings);
};

declare global {
  interface Window {
    resetUserSettings: () => Promise<void>;
  }
}

window.resetUserSettings = async () => {
  await $api.post('/user/settings', {});
  queryClient.invalidateQueries(['userSettings']);
};

const QUERY_CONFIG = {
  cacheTime: 1000 * 60 * 60 * 24,
  staleTime: 1000 * 60 * 2,
  refetchOnWindowFocus: true,
};

const useUserSettings = (): [Settings, (settings: Settings) => void] => {
  const { data: userSettings, isLoading } = useQuery(
    ['userSettings'],
    getUserSettings,
    QUERY_CONFIG,
  );

  const [settingsState, setSettingsState] = useState<Settings>(
    userSettings || {},
  );

  useEffect(() => {
    setSettingsState((settingsState) => {
      const currentSettings = userSettings || {};
      if (!isEqual(settingsState, currentSettings)) {
        return currentSettings;
      }
      return settingsState;
    });
  }, [userSettings]);

  const updateSettings = useCallback(
    (settings: Settings) => {
      if (isLoading) {
        return;
      }
      setSettingsState(settings);
      setUserSettings(settings).then(() => {
        queryClient.invalidateQueries(['userSettings']);
      });
    },
    [isLoading],
  );

  return [settingsState, updateSettings];
};

export const useUserSetting = <TKey extends keyof Settings>(
  key: TKey,
  defaultState: Required<Settings>[TKey],
): [Required<Settings>[TKey], (value: Required<Settings>[TKey]) => void] => {
  const [settings, updateSettings] = useUserSettings();

  const updateSetting = useCallback(
    (value: any) => {
      updateSettings({
        ...settings,
        [key]: value,
      });
    },
    [key, settings, updateSettings],
  );

  return [settings[key] || defaultState, updateSetting];
};
