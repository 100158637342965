import React, { useEffect, useMemo, useState } from 'react';
import styles from './Notifications.module.scss';
import Lottie from 'lottie-react';
import notificationAnimation from './notificationAnimation.json';
import {
  EventsWidget,
  useEvents,
} from '../../../pages/Dashboard/components/Widget/widgets/Events/Events';
import { useOutsideClickRef } from 'rooks';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useUserSetting } from '../../../../shared/hooks/useUserSettings';

export const Notifications: React.FC = () => {
  const { data } = useEvents();
  const events = useMemo(() => data || [], [data]);
  const lastTime = useMemo(() => events[0]?.publishDttm, [data]);
  const [lastSavedTime, setLastSavedTime] = useUserSetting(
    'lastTimeNotifications',
    lastTime,
  );

  const [hasUnread, setHasUnread] = useState(false);

  useEffect(() => {
    const dayJsLastTime = lastTime ? dayjs(lastTime) : null;
    const dayJsLastSavedTime = lastSavedTime ? dayjs(lastSavedTime) : null;

    if (!dayJsLastTime || !dayJsLastSavedTime) {
      return;
    }

    if (dayJsLastTime.isAfter(dayJsLastSavedTime)) {
      setHasUnread(true);
    }
  }, [lastTime, lastSavedTime]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [notificationsRef] = useOutsideClickRef(() => {
    setIsExpanded(false);
  });

  const [isLoopTime, setIsLoopTime] = useState(false);

  useEffect(() => {
    if (hasUnread) {
      setIsLoopTime(true);
      const timeout = setTimeout(() => {
        setIsLoopTime(false);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [hasUnread]);

  return (
    <div
      className={styles.button}
      onClick={() => {
        setIsExpanded(!isExpanded);
        setHasUnread(false);
        setLastSavedTime(lastTime);
      }}
    >
      <Lottie
        className={styles.icon}
        animationData={notificationAnimation}
        autoplay={hasUnread}
        loop={isLoopTime}
        key={`${hasUnread}`}
        width={27}
        height={27}
      />
      <div
        className={classNames(styles.unread, {
          [styles.visible]: hasUnread,
        })}
      />
      {isExpanded && (
        <div className={styles.notifications} ref={notificationsRef}>
          <EventsWidget wrapperClassName={styles.eventsWrapper} />
        </div>
      )}
    </div>
  );
};
