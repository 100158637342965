import { MONTHS } from '../../../../../../shared/months';
import { getDeclarator } from '../../../../../../shared/utils/declOfNum';
import { getSelection } from '../../../../../common/PageWithFilter/utils/getSelection';
import { TFilterCreator, TFilterPreparer } from '../reportConfigType';

const getPlural = (key: string, single: boolean) => {
  if (single) return key;
  return `${key}s`;
};

const prepareIntervalFilterValue =
  (single: boolean, initialDatasetKey?: string): TFilterPreparer =>
  (filterState) => {
    const periodType =
      filterState.interval.datasetKey || initialDatasetKey || 'w';

    const key = getPlural('period', single);
    const values = getSelection(filterState.interval).map((item) => +item);

    return {
      [key]: single ? values[0] : values,
      intervalType: periodType,
    };
  };

const intervalsDeclarator = getDeclarator(
  ['интервал', 'интервала', 'интервалов'],
  true,
  true,
);

export const getIntervalFilter = <TDataItem>({
  single,
  initialDatasetKey,
  datasets,
}: {
  single: boolean;
  initialDatasetKey?: string;
  datasets?: { label: string; value: string }[];
}): TFilterCreator<TDataItem> => {
  return (props) => {
    const reportApi = props.reportApi;

    return {
      filter: {
        label: 'Отчетный интервал',
        icon: 'calendar',
        key: 'interval',
        initialValue: {
          datasetKey: initialDatasetKey ?? 'w',
        },
        isRequired: true,
        initiallyOpen: false,
        getTags: (value): string[] => {
          const values = getSelection(value);
          if (values.length) {
            return [`${values.length}`];
          }
          return [];
        },
        filter: {
          type: 'select',
          uniqueKey: `${props.reportKey}-interval`,
          datasets: datasets ?? [
            {
              label: 'Неделя',
              value: 'w',
            },
            {
              label: 'Месяц',
              value: 'm',
            },
          ],
          initiallySelectFirstOption: true,
          getOptions: async (datasetValue) => {
            const data = await reportApi.fetchAvailablePeriods();
            if (datasetValue === 'w') {
              return Object.values(data.weeks)
                .reverse()
                .map((week: any) => ({
                  label: `${week.numberWeekInTheYear} неделя ${week.year}г. (${week.firstDay} - ${week.lastDay})`,
                  value: week.commonNumberOfWeek,
                }));
            } else {
              return Object.values(data.months)
                .reverse()
                .map((month: any) => ({
                  label: `${MONTHS[month.numberMonthInTheYear - 1]} ${
                    month.year
                  }г.`,
                  value: month.commonNumberOfMonth,
                }));
            }
          },
          withSearch: false,
          canSelectAll: false,
          canSelectNothing: false,
          canSelectMany: !single,
          canBeInverted: false,
        },

        getQueryKeyPart: (filterState) => [filterState.interval],
      },

      preparer: prepareIntervalFilterValue(single, initialDatasetKey),

      descriptionPreparer: (filterState) => {
        const count = getSelection(filterState.interval).length;
        if (!count) {
          return [];
        }
        return [intervalsDeclarator(count)];
      },
    };
  };
};
