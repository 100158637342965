import React from 'react';

import { ReportsTypes, reportsList } from '../../constants';
import { DEFAULT_SELECT_VALUE } from '../../../../../shared/constants';
import { isEmptyObject } from '../../../../../shared/utils/isEmptyObject';
import { Modal } from '../../../../../uikit/Modal/Modal';
import { Button } from '../../../../../uikit/Button/Button';
import { Gap } from '../../../../../uikit/Gap/Gap';
import { Input } from '../../../../../uikit/Input/Input';
import { Spacer } from '../../../../common/Spacer/Spacer';

import styles from './UploadModal.module.scss';
import { getDeclarator } from '../../../../../shared/utils/declOfNum';
import { useEffect, useState } from 'react';
import {
  PeriodType,
  useReportsConfig,
} from '../../../../../api/uploads/uploads';
import { useFormik } from 'formik';
import { AddReportRequest } from '../../../../../api/backend/Api';
import { useInns } from '../../../../common/Header/ProfileModal/hooks';
import { ReportModal } from '../ReportModal/ReportModal';
import { PeriodsModal } from '../PeriodsModal/PeriodsModal';
import { Loader } from '../../../../common/Loader/Loader';

const reportList = [{ type: DEFAULT_SELECT_VALUE, name: 'Не выбрано' }].concat(
  reportsList,
);

const isReportSelected = (report: string): report is ReportsTypes => {
  return !!report && report !== DEFAULT_SELECT_VALUE;
};

const periodsDeclarator = getDeclarator(['период', 'периода', 'периодов']);
const fieldsDeclarator = getDeclarator(['поле', 'поля', 'полей']);
const selectedDeclarator = getDeclarator(
  ['Выбран', 'Выбрано', 'Выбрано'],
  false,
);

export const UploadModal: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (values: AddReportRequest) => void;
}> = ({ show, onClose, onSubmit }) => {
  const { data: config, isLoading: isConfigLoading } = useReportsConfig();
  const { data: inns, isLoading: isInnsLoading } = useInns(true);

  const [showPeriodModal, setShowPeriodModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const [possiblePeriods, setPossiblePeriods] = useState<PeriodType[]>([]);
  const [fieldsToPeriod, setFieldsToPeriod] = useState<Record<string, string>>(
    {},
  );

  const formik = useFormik<AddReportRequest>({
    initialValues: {
      inn: DEFAULT_SELECT_VALUE,
      report: DEFAULT_SELECT_VALUE,
      periods: [],
      fields: {},
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (!config || !inns) {
      return;
    }
    if (!formik.values.inn || formik.values.inn === DEFAULT_SELECT_VALUE) {
      const firstActiveInn = inns.find((item) => item.active);
      if (firstActiveInn?.inn) {
        formik.setFieldValue('inn', firstActiveInn.inn);
      }
    }
  }, [formik.values.inn, formik.setFieldValue, formik, inns, config]);

  useEffect(() => {
    const currentReport = formik.values.report;
    const currentInn = formik.values.inn;
    if (
      isReportSelected(currentReport) &&
      config &&
      currentInn &&
      currentInn !== DEFAULT_SELECT_VALUE
    ) {
      formik.setFieldValue('periods', []);
      formik.setFieldValue('fields', {});

      setPossiblePeriods(config.periods[currentReport]?.[currentInn] || []);
      setFieldsToPeriod(config.defaultFields[currentReport]);
    }
  }, [config, formik.values.report, formik.values.inn, formik.setFieldValue]);

  const disableButton =
    formik.values.inn === DEFAULT_SELECT_VALUE ||
    formik.values.report === DEFAULT_SELECT_VALUE;

  const modalInfo = {
    innInfo: {
      name: inns?.find((item) => item.inn === formik.values.inn)?.name || '',
      inn: formik.values.inn,
    },
    reportInfo: {
      name:
        reportList.find((item) => item.type === formik.values.report)?.name ||
        '',
    },
  };

  if (isConfigLoading || isInnsLoading) {
    return (
      <Modal isOpened onClose={onClose} testId="upload-request-loading">
        <Modal.Title gap>Добавление задания на выгрузку</Modal.Title>
        <Modal.Body>
          <Loader />
        </Modal.Body>
        <Modal.Footer>
          <Button testId="close-button" onClick={onClose} color="grey">
            Закрыть
          </Button>
          <Spacer />
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      {showPeriodModal && (
        <PeriodsModal
          possiblePeriods={possiblePeriods}
          show={showPeriodModal}
          onClose={() => setShowPeriodModal(false)}
          modalInfo={modalInfo}
          initialValue={formik.values.periods}
          onSubmit={(periods: number[]) => {
            if (periods.length === possiblePeriods.length) {
              formik.setFieldValue('periods', []);
            } else {
              formik.setFieldValue('periods', periods);
            }
          }}
        />
      )}
      {showReportModal && (
        <ReportModal
          show={showReportModal}
          onClose={() => setShowReportModal(false)}
          modalInfo={modalInfo}
          possibleValues={fieldsToPeriod}
          initialValue={formik.values.fields}
          onSubmit={(fields: Record<string, string>) => {
            if (isEmptyObject(fields)) {
              formik.setFieldValue('fields', {});
            } else {
              formik.setFieldValue('fields', fields);
            }
          }}
        />
      )}

      <Modal
        isOpened={show}
        onClose={() => {
          if (!showReportModal && !showPeriodModal) {
            onClose();
          }
        }}
        testId={'upload-request'}
      >
        <Modal.Title>Добавление задания на выгрузку</Modal.Title>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Gap size={16} />
            <Input
              label="ИНН"
              name="inn"
              value={formik.values.inn}
              onChange={(e) => {
                formik.setFieldValue('inn', e.target.value);
              }}
              type="select"
              block
              testId={'inn-select'}
            >
              {inns?.map((item) => (
                <option key={item.inn} value={item.inn} disabled={!item.active}>
                  {item.name}
                </option>
              ))}
            </Input>
            <Input
              label="Отчет"
              name="report"
              value={formik.values.report}
              onChange={(e) => {
                formik.setFieldValue('report', e.target.value);
              }}
              type="select"
              block
              testId={'report-select'}
            >
              {reportList.map((item) => (
                <option key={item.type} value={item.type}>
                  {item.name}
                </option>
              ))}
            </Input>
            <Input
              label="Период"
              type="select"
              block
              custom={
                <div className={styles.subModalValue}>
                  <span>
                    {!formik.values.periods.length
                      ? `Выбраны все периоды`
                      : `${selectedDeclarator(
                          formik.values.periods.length,
                        )} ${periodsDeclarator(formik.values.periods.length)}`}
                  </span>
                  <Button
                    disabled={disableButton}
                    onClick={() => setShowPeriodModal(true)}
                    small
                    color="black"
                    testId="period-select-button"
                  >
                    Изменить
                  </Button>
                </div>
              }
              testId={'period-select'}
            />
            <Input
              label="Поля отчета"
              type="select"
              block
              custom={
                <div className={styles.subModalValue}>
                  <span>
                    {isEmptyObject(formik.values.fields)
                      ? `Выбраны все поля`
                      : `Выбрано ${fieldsDeclarator(
                          Object.keys(formik.values.fields).length,
                        )}`}
                  </span>
                  <Button
                    disabled={disableButton}
                    onClick={() => setShowReportModal(true)}
                    small
                    color="black"
                    testId={'fields-select-button'}
                  >
                    Изменить
                  </Button>
                </div>
              }
              testId={'fields-select'}
            />
          </form>
          <Gap size={16} />
        </Modal.Body>
        <Modal.Footer>
          <Spacer />
          <Button
            type="submit"
            disabled={disableButton}
            onClick={() => {
              formik.handleSubmit();
            }}
            testId={'submit-button'}
          >
            Отправить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
