const EMPTY_VALUES = [null, undefined, ''];

export const sorter = (key: string, isNumeric = false) => {
  if (isNumeric) {
    return (a: any, b: any) => {
      if (EMPTY_VALUES.includes(a[key]) || isNaN(a[key])) {
        return -1;
      }
      if (EMPTY_VALUES.includes(b[key]) || isNaN(b[key])) {
        return 1;
      }
      return a[key] - b[key];
    };
  } else {
    return (a: any, b: any) => {
      if (EMPTY_VALUES.includes(a[key])) {
        return -1;
      }
      if (EMPTY_VALUES.includes(b[key])) {
        return 1;
      }
      const aValue = `${a[key]}`.toLowerCase();
      const bValue = `${b[key]}`.toLowerCase();
      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    };
  }
};
