import React from 'react';
import classNames from 'classnames';

import styles from './Legend.module.scss';

type TLegendProps = React.PropsWithChildren<{
  title?: string;
}>;

type TLegend = React.FC<TLegendProps> & {
  Item: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>>;
};

export const Legend: TLegend = ({ children, title }) => {
  return (
    <div className={styles.legend} data-test-id="chart-legend">
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
};

Legend.Item = ({ children, className, ...props }) => (
  <div className={classNames(styles.item, className)} {...props}>
    {children}
  </div>
);
