import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  MENU_ITEMS_FOR_REDIRECT,
  isItemAvailable,
} from './common/Header/Header';
import { Accesses } from './pages/Accesses';
import { Uploads } from './pages/Uploads/AppUploads';

import styles from './main.module.scss';
import { Reports } from './pages/Reports/Reports';
import { AppWiKi } from './pages/Wiki';
import { hasRole } from '../shared/utils/hasRole';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isDev } from '../shared/utils/isDev';
import { AdHocScreenshoter } from './pages/AdHocScreenshoter/AdHocScreenshoter';
import { Dictionaries } from './pages/Dictionaries/Dictionaries';
import { Dictionary } from './pages/Dictionary/Dictionary';
import { Ds1O1 } from './pages/Reports/reports/Ds1O1';
import { Ds1O2 } from './pages/Reports/reports/Ds1O2';
import { Ds1O3 } from './pages/Reports/reports/Ds1O3';
import { Ds1O4 } from './pages/Reports/reports/Ds1O4';
import { Ds1O5 } from './pages/Reports/reports/Ds1O5';
import { Ds1O6 } from './pages/Reports/reports/Ds1O6';
import { Ds1O7 } from './pages/Reports/reports/Ds1O7';
import { Ds1O8 } from './pages/Reports/reports/Ds1O8';
import { Ds2O6 } from './pages/Reports/reports/Ds2O6';
import { Ds2O10 } from './pages/Reports/reports/Ds2O10';
import { Ds3O7 } from './pages/Reports/reports/Ds3O7';
import { Ds1O9 } from './pages/Reports/reports/Ds1O9';
import { Page404 } from '../pages/404';
import { Ds3O2 } from './pages/Reports/reports/Ds3O2';
import { Ds3O8 } from './pages/Reports/reports/Ds3O8';
import { Ds1O12 } from './pages/Reports/reports/Ds1O12';
import { useModuleAOnly } from '../shared/hooks/useModuleAOnly';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { EventsAdmin } from './pages/EventsAdmin/EventsAdmin';
import { Ds2O9 } from './pages/Reports/reports/Ds2O9';
import { LinkAddress } from './pages/LinkAddress/LinkAddress';
import { useAuthorizedUser } from '../shared/hooks/useUser';
import { Files } from './pages/Files/Files';
import { LinkGtin } from './pages/LinkGtin/LinkGtin';
import { SupportAdminList } from './SupportAdmin/SupportAdminList';
import { SupportAdminEditor } from './SupportAdmin/SupportAdminEditor';
import { Loader } from './common/Loader/Loader';
import { Ds1O11 } from './pages/Reports/reports/Ds1O11';
import { Ds2O8 } from './pages/Reports/reports/Ds2O8';
import { Ds2O1 } from './pages/Reports/reports/Ds2O1';
import { Ds2O7 } from './pages/Reports/reports/Ds2O7';

export const AppRoutes: React.FC = () => {
  const userData = useAuthorizedUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/' && hasRole('ADMIN', userData)) {
      navigate('/accesses', {
        replace: true,
      });
    }

    if (location.pathname === '/' && !hasRole('ADMIN', userData)) {
      const possibleMenuItems = MENU_ITEMS_FOR_REDIRECT.filter((item) =>
        isItemAvailable(item, userData),
      );

      if (possibleMenuItems.length > 0) {
        if (possibleMenuItems[0].path !== '/') {
          navigate(possibleMenuItems[0].path, {
            replace: true,
          });
        }
      }
    }
  }, [location, navigate, userData]);

  const isModuleAOnly = useModuleAOnly();

  return (
    <>
      <div className={styles.layout}>
        <Header />
        <Routes>
          <Route element={<AppWiKi />} path="/support" />
          <Route element={<AppWiKi />} path="/support/:guid" />
          <Route element={<Dashboard />} path="/" />
          <Route element={<Reports />} path="/reports" />
          <Route element={<Ds1O1 />} path="/reports/report-ds1-r1" />
          <Route element={<Ds2O1 />} path="/reports/report-ds2-r1" />
          <Route element={<Ds1O2 />} path="/reports/report-ds1-r2" />
          <Route element={<Ds1O3 />} path="/reports/report-ds1-r3" />
          <Route element={<Ds1O4 />} path="/reports/report-ds1-r4" />
          <Route element={<Ds1O5 />} path="/reports/report-ds1-r5" />
          <Route element={<Ds1O6 />} path="/reports/report-ds1-r6" />
          <Route element={<Ds1O7 />} path="/reports/report-ds1-r7" />
          <Route element={<Ds1O8 />} path="/reports/report-ds1-r8" />
          <Route element={<Ds1O9 />} path="/reports/report-ds1-r9" />
          <Route element={<Ds1O11 />} path="/reports/report-ds1-r11" />
          <Route element={<Ds2O8 />} path="/reports/report-ds2-r8" />
          <Route element={<Ds1O12 />} path="/reports/report-ds1-r12" />
          <Route element={<Ds2O9 />} path="/reports/report-ds2-r9" />
          <Route element={<Ds2O6 />} path="/reports/report-ds2-r6" />
          <Route element={<Ds2O7 />} path="/reports/report-ds2-r7" />
          <Route element={<Ds2O10 />} path="/reports/report-ds2-r10" />
          <Route element={<Ds3O2 />} path="/reports/report-ds3-r2" />
          <Route element={<Ds3O7 />} path="/reports/report-ds3-r7" />
          <Route element={<Ds3O8 />} path="/reports/report-ds3-r8" />
          <Route element={<Uploads />} path="/uploads" />
          <Route element={<Accesses />} path="/accesses" />
          <Route element={<EventsAdmin />} path="/events-admin" />
          <Route element={<LinkAddress />} path="/link-address" />
          <Route element={<LinkGtin />} path="/link-gtin" />
          <Route element={<Files />} path="/files" />
          <Route element={<SupportAdminList />} path="/support-admin" />
          <Route element={<SupportAdminEditor />} path="/support-admin/:guid" />
          {!isModuleAOnly && (
            <Route element={<Dictionaries />} path="/dictionaries" />
          )}
          {!isModuleAOnly && (
            <Route
              element={<Dictionary />}
              path="/dictionaries/:dictType/:guid"
            />
          )}
          {isDev() && (
            <Route
              element={<AdHocScreenshoter />}
              path="/adhoc-screenshoter/*"
            />
          )}
          <Route element={<Loader />} path="/loader" />
          <Route element={<Page404 />} path="*" />
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
};
