import React, { forwardRef, HTMLInputTypeAttribute } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';
import { useFocus } from '../../shared/hooks/useFocus';
import { Icon } from '../../newComponents/common/Icon/Icon';

type TInputProps = {
  label?: string;
  error?: string | null;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  block?: boolean;
  noGap?: boolean;
  type?: HTMLInputTypeAttribute | 'select';
  children?: React.ReactNode[];
  custom?: React.ReactNode;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  onRightIconClick?: () => void;
  withClear?: boolean;
  testId: string;
  width?: number;
  small?: boolean;
  search?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      label,
      error,
      disabled,
      block,
      noGap,
      className,
      type,
      children,
      custom,
      rightIcon,
      leftIcon,
      onRightIconClick,
      withClear,
      testId,
      width,
      small,
      search,
      ...props
    },
    ref,
  ) => {
    const { focus, onBlur, onFocus } = useFocus();
    return (
      <label
        className={classNames(
          styles.label,
          {
            [styles.block]: block,
            [styles.noGap]: noGap || search,
          },
          className,
        )}
        style={
          width
            ? {
                width,
              }
            : undefined
        }
      >
        {label && (
          <span className={styles.label} data-test-id={`input_${testId}_label`}>
            {label}
          </span>
        )}
        {!custom && (
          <div
            className={classNames(styles.input, {
              [styles.active]: focus,
              [styles.error]: error,
              [styles.disabled]: disabled,
              [styles.small]: small,
              [styles.search]: search,
            })}
          >
            {leftIcon && (
              <div className={classNames(styles.leftIcon)}>{leftIcon}</div>
            )}
            {React.createElement(type === 'select' ? 'select' : 'input', {
              ref,
              ...props,
              type,
              disabled,
              onBlur: (e: FocusEvent) => {
                onBlur();
                // @ts-ignore
                props.onBlur?.(e);
              },
              onFocus: (e: FocusEvent) => {
                onFocus();
                // @ts-ignore
                props.onFocus?.(e);
              },
              className: styles.inputField,
              children,
              'data-test-id': `input_${testId}_input`,
              'data-test-error': error,
              'data-test-disabled': disabled,
            })}
            {rightIcon && (
              <div
                className={classNames(styles.rightIcon, {
                  [styles.clickable]: onRightIconClick,
                })}
                onClick={onRightIconClick}
              >
                {rightIcon}
              </div>
            )}
            {withClear && (
              <div
                className={classNames(styles.rightIcon, styles.clickable)}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange({
                      target: {
                        value: '',
                      },
                    } as any);
                  }
                }}
              >
                <Icon name="close" />
              </div>
            )}
          </div>
        )}
        {custom}
        <span
          className={classNames(styles.errorText, {
            [styles.visible]: error,
          })}
        >
          {error}
        </span>
      </label>
    );
  },
);
