import React, { useCallback, useMemo, useState } from 'react';
import { hasSubstring, normalizeString } from '../utils/hasSubstring';

export const useSearch = <T extends Record<string, unknown>>(
  data: T[] | undefined,
  expandMatch?: (item: T, search: string) => boolean,
): [
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  filteredData: T[],
] => {
  const [searchValue, setSearchValue] = useState<string>('');
  const searchValueLowerCase = normalizeString(searchValue);

  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setSearchValue(event.target.value);
    },
    [],
  );

  const filteredData = useMemo(() => {
    return (
      data?.filter((item) => {
        const regularMatch = Object.values(item).some((value) => {
          if (typeof value === 'string' || typeof value === 'number') {
            const valueAsString = value.toString();
            return hasSubstring(searchValueLowerCase, valueAsString, true);
          }
          return false;
        });
        const expandedMatch = expandMatch
          ? expandMatch(item, searchValueLowerCase)
          : false;
        return regularMatch || expandedMatch;
      }) || []
    );
  }, [data, expandMatch, searchValueLowerCase]);

  return [searchValue, onChange, filteredData];
};
