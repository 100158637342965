import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { ClientsFilesList } from './ClientFilesList';
import { Button } from '../../../uikit/Button/Button';
import { Spacer } from '../../common/Spacer/Spacer';
import { $api } from '../../../api/utils';
import { toast } from 'react-toastify';
import { queryClient } from '../../../store/query';
import { getDeclarator } from '../../../shared/utils/declOfNum';
import { useAuthorizedUser } from '../../../shared/hooks/useUser';
import { getRole } from '../../../shared/utils/getRole';
import { ROLES } from '../../../shared/constants';
import { useQuery } from '@tanstack/react-query';
import { PageContent } from '../../common/PageContent/PageContent';
import { useLocation } from 'react-router-dom';
import { Page404 } from '../../../pages/404';
import { TurboSelect } from 'react-turbo-select';

const clientUpload = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  await $api.post('/link-gtin/client/upload', formData);
};

const filesCountDeclarator = getDeclarator(['файл', 'файла', 'файлов']);

type TClient = {
  name: string;
  login: string;
  guid: string;
};

const getClients = async (): Promise<TClient[]> => {
  const { data } = await $api.get('/clients');
  return data;
};

const useClients = (enabled: boolean) => {
  return useQuery(['clients'], getClients, { enabled });
};

export const LinkGtin: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const userInfo = useAuthorizedUser();
  const userRoles = userInfo.roles;
  const isAdmin = getRole(userRoles, ROLES.admin);
  const [userGuid, setUserGuid] = useState<string>('');

  const clientsQueryData = useClients(isAdmin);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const clientGuid = query.get('clientGuid');
  useEffect(() => {
    if (isAdmin && clientGuid) {
      setUserGuid(clientGuid);
    }
  }, [isAdmin, clientGuid]);

  useEffect(() => {
    if (!isAdmin) {
      setUserGuid(userInfo.guid);
    }
  }, [isAdmin, userInfo]);

  if (!userInfo.linkGtin && !isAdmin) {
    return <Page404 />;
  }

  let adminSelect = null;

  if (isAdmin) {
    const selectOptions = clientsQueryData.data?.map((client) => ({
      label: client.name,
      value: client.guid,
    }));

    const currentOption = selectOptions?.find(
      (option) => option.value === userGuid,
    );

    adminSelect = (
      <div>
        <TurboSelect
          key={clientsQueryData.data?.length}
          isSearchable
          options={selectOptions}
          onChange={(value) => {
            setUserGuid(value?.value || '');
          }}
          placeholder="Выберите клиента"
          defaultValue={currentOption}
        />
      </div>
    );
  }

  if (isAdmin && !userGuid) {
    return (
      <>
        <PageHeader title="Линкование GTIN">{adminSelect}</PageHeader>
        <PageContent
          queryData={clientsQueryData}
          notFound={clientsQueryData.data?.length === 0 || !userGuid}
          notFoundCustomText={
            clientsQueryData.data?.length === 0 ? (
              <div>Нет клиентов</div>
            ) : (
              <div>Выберите клиента</div>
            )
          }
        ></PageContent>
      </>
    );
  }

  let userName = '';
  if (isAdmin) {
    const client = clientsQueryData.data?.find(
      (client) => client.guid === userGuid,
    );
    userName = client?.name || '';
  }

  const uploadButton = (
    <Button
      testId="send-file"
      onClick={() => {
        fileInputRef.current?.click();
      }}
      disabled={isLoading}
      reportPanel
    >
      {isLoading ? 'Загрузка...' : 'Загрузить файл'}
    </Button>
  );

  return (
    <>
      <PageHeader
        supportKey="l-g"
        title={isAdmin ? `Линкование GTIN ${userName}` : 'Линкование GTIN'}
        withGap
        rightTitleContent={
          <>
            <input
              type="file"
              style={{ display: 'none' }}
              id="file"
              name="file"
              ref={fileInputRef}
              multiple
              onChange={async (e) => {
                const files = e.target.files;
                if (files) {
                  setIsLoading(true);
                  let errorsCount = 0;
                  let successCount = 0;
                  for (let i = 0; i < files.length; i++) {
                    try {
                      await clientUpload(files[i]);
                      successCount++;
                      queryClient.invalidateQueries(['linkGtin', 'filesList']);
                    } catch (e) {
                      errorsCount++;
                      toast.error(`Ошибка загрузки файла ${files[i].name}`);
                    }
                  }
                  if (errorsCount) {
                    toast.error(
                      `Не удалось загрузить ${filesCountDeclarator(
                        errorsCount,
                      )}`,
                    );
                  }
                  if (successCount) {
                    toast.success(
                      `Загрузили ${filesCountDeclarator(successCount)}`,
                    );
                  }
                  e.target.value = '';
                  setIsLoading(false);
                }
              }}
            />
            {!isAdmin && uploadButton}
            <Spacer />
            {isAdmin && adminSelect}
          </>
        }
      ></PageHeader>
      <ClientsFilesList
        notFoundCustomText={
          <>
            На данный момент вы не загрузили ни одного файла.
            <br />
            Нажмите на кнопку, чтобы загрузить ваш первый файл:
            <br />
            {uploadButton}
          </>
        }
        clientGuid={userGuid}
      />
    </>
  );
};
