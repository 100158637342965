/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { $api, resetUserEditableCache } from '../../../api/utils';
import { DictionariesType } from '../../../shared/constants';
import {
  TDictionariesData,
  useDictionariesData,
} from '../../../shared/hooks/useDictionariesData';
import { getDeclarator } from '../../../shared/utils/declOfNum';
import { withErrorHandling } from '../../../shared/utils/withErrorHandling';
import { queryClient } from '../../../store/query';
import { Button } from '../../../uikit/Button/Button';
import { Input } from '../../../uikit/Input/Input';
import { Modal } from '../../../uikit/Modal/Modal';
import { withLoader } from '../../common/FullscreenLoader/FullscreenLoader';
import { PageContent } from '../../common/PageContent/PageContent';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { Spacer } from '../../common/Spacer/Spacer';
import { Pagination } from '../../common/Table/components/Pagination/Pagination';
import { sorter } from '../../common/Table/sorter';
import { Table, TTableColumn } from '../../common/Table/Table';
import { Tooltip } from '../../common/Tooltip/Tooltip';
import XLSX from 'xlsx';
import { Gap } from '../../../uikit/Gap/Gap';
import { useSearch } from '../../../shared/hooks/useSearch';
import { Checkbox } from '../../../uikit/Checkbox/Checkbox';

import styles from './Dictionary.module.scss';
import { saveAsFile } from '../../../shared/utils/saveAsFile';
import { useAllDictionariesNames } from '../../../shared/hooks/useAllDictionariesNames';
import { Icon } from '../../common/Icon/Icon';

import { get, set, del, createStore } from 'idb-keyval';
import { DownloadReport } from '../../common/Table/components/DownloadReport/DownloadReport';

const draftsStore = createStore('drafts-store', 'drafts-store');

const EMPTY_FIELD_VALUE = 'Не определено';

const DICT_TYPE_NAME: Record<DictionariesType, string> = {
  [DictionariesType.gtin]: 'GTIN',
  [DictionariesType.inn]: 'ИНН',
  [DictionariesType.address]: 'ID адреса',
};

const DEFAULT_DICTIONARIES_DATA: TDictionariesData = {
  dictionaries: {},
  values: {},
};

const downloadDictionaryFile = async (
  type: DictionariesType,
  guid: string,
  name: string,
) => {
  const { data } = await $api.get(
    `/dictionaries/${type}/csv?dictGuid=${guid}`,
    {
      responseType: 'blob',
    },
  );
  const href = URL.createObjectURL(new Blob([data]));
  saveAsFile(href, `Резервная-копия_${type}_${name?.replace(/\s/g, '_')}.csv`);
};

const renameDictionary = async (guid: string, name: string) => {
  await $api.put(`/dictionaries`, { guid, name });
};

const saveDictionary = async (
  type: DictionariesType,
  guid: string,
  changedValues: Record<string, string>,
) => {
  const { data } = await $api.put(
    `/dictionaries/${type}/${guid}`,
    changedValues,
  );
  await resetUserEditableCache();
  return data;
};

const uploadDictionaryFile = async (
  type: DictionariesType,
  guid: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await $api.post(
    `/dictionaries/${type}/${guid}/csv`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  await resetUserEditableCache();
  return data;
};

const deleteDictionary = async (guid: string) => {
  const { data } = await $api.delete(`/dictionaries/${guid}`);
  await resetUserEditableCache();
  return data;
};

const VALUES_KEY = `dictionaryValues`;

const fieldsCount = getDeclarator(
  ['измененное поле', 'измененных поля', 'измененных полей'],
  true,
  true,
);

export const Dictionary = () => {
  const navigate = useNavigate();
  const { guid, dictType } = useParams<{
    guid: string;
    dictType: DictionariesType;
  }>();

  const useDictionariesDataResult = useDictionariesData(
    dictType as DictionariesType,
  );

  const { dictionaries, values } =
    useDictionariesDataResult.data || DEFAULT_DICTIONARIES_DATA;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [renameModalIsVisible, setRenameModalIsVisible] = useState(false);

  const rawFileRef = useRef<File | null>(null);
  const [fileNameType, setFileNameType] = useState('');
  const [fileNameName, setFileNameName] = useState('');
  const [fileNameFull, setFileNameFull] = useState('');
  const [fileIsCSV, setFileIsCSV] = useState(false);
  const [sureReplaceModalIsVisible, setSureReplaceModalIsVisible] =
    useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [extraInDictionaryDelete, setExtraInDictionaryDelete] = useState(false);
  const [fileData, setFileData] = useState<Record<string, string> | null>(null);
  const fileMeta = useMemo(() => {
    if (!fileData) {
      return {
        count: 0,
        extraInDictionary: 0,
        extraInFile: 0,
        changedCount: 0,
      };
    }
    const keys = Object.keys(fileData);
    let extraInDictionaryCount = Object.keys(values).length;
    let extraInFileCount = Object.keys(fileData).length;

    const extraInDictionaryKeys: string[] = [];
    const extraInFileKeys: string[] = [];

    Object.keys(values).forEach((key) => {
      if (fileData[key] !== undefined) {
        extraInDictionaryCount -= 1;
      } else {
        extraInDictionaryKeys.push(key);
      }
    });
    Object.keys(fileData).forEach((key) => {
      if (values[key] !== undefined) {
        extraInFileCount -= 1;
      } else {
        extraInFileKeys.push(key);
      }
    });

    const meta = {
      count: keys.length,
      extraInDictionary: extraInDictionaryCount,
      extraInFile: extraInFileCount,
    };

    return meta;
  }, [fileData, values]);

  useEffect(() => {
    if (!showUploadModal) {
      setFileData(null);
      setFileNameName('');
      setFileNameType('');
      setFileNameFull('');
      setFileIsCSV(false);
      setSureReplaceModalIsVisible(false);
    }
  }, [showUploadModal]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hasStoredValues, setHasStoredValues] = useState<false | Dayjs>(false);

  const isStoredTimeChecked = useRef(false);
  useEffect(() => {
    const getStoredTime = async () => {
      const storedTime = await get<number>(
        `${VALUES_KEY}_${guid}_time`,
        draftsStore,
      );
      if (!storedTime) {
        isStoredTimeChecked.current = true;
        return;
      }

      const timeDiff = new Date().getTime() - Number(storedTime);
      if (timeDiff < 1000 * 60 * 60 * 24) {
        setHasStoredValues(dayjs(storedTime));
      }
      isStoredTimeChecked.current = true;
    };
    getStoredTime();
  }, []);

  const [currentValues, setCurrentValues] = useState<Record<string, string>>(
    {},
  );
  const changedKeys = useMemo<Record<string, true>>(() => {
    if (!guid) {
      return {};
    }
    const changedKeys: Record<string, true> = {};
    Object.keys(values).forEach((key) => {
      const oldValue =
        values[key][guid] === EMPTY_FIELD_VALUE ? '' : values[key][guid] || '';
      const currentValue = currentValues[key] || '';
      if (oldValue !== currentValue) {
        changedKeys[key] = true;
      }
    });
    return changedKeys;
  }, [currentValues, guid, values]);

  useEffect(() => {
    if (!guid) {
      return;
    }
    setCurrentValues(
      Object.keys(values).reduce<Record<string, string>>((acc, key) => {
        acc[key] =
          values[key][guid] === EMPTY_FIELD_VALUE ? '' : values[key][guid];
        return acc;
      }, {}),
    );
  }, [values, guid]);

  useEffect(() => {
    if (!guid || !!hasStoredValues || !isStoredTimeChecked.current) {
      return;
    }
    if (Object.keys(changedKeys).length === 0) {
      del(`${VALUES_KEY}_${guid}`, draftsStore);
      del(`${VALUES_KEY}_${guid}_time`, draftsStore);
      return;
    }
    set(`${VALUES_KEY}_${guid}`, currentValues, draftsStore);
    const time = new Date().getTime();
    set(`${VALUES_KEY}_${guid}_time`, time, draftsStore);
  }, [currentValues, guid, hasStoredValues, changedKeys]);

  const uniqueValues = useMemo<string[]>(() => {
    const optionsRecord = Object.values(currentValues).reduce<
      Record<string, true>
    >((acc, value) => {
      acc[value] = true;
      return acc;
    }, {});
    return Object.keys(optionsRecord).filter(Boolean);
  }, [currentValues]);

  const currentDictionaryName = guid && dictionaries?.[guid];
  const [newDictionaryName, setNewDictionaryName] = useState(
    currentDictionaryName || '',
  );
  useEffect(() => {
    if (!renameModalIsVisible) {
      setNewDictionaryName(currentDictionaryName || '');
    }
  }, [currentDictionaryName, renameModalIsVisible]);

  useEffect(() => {
    if (
      guid &&
      !useDictionariesDataResult.isFetching &&
      !dictionaries?.[guid]
    ) {
      navigate(`/dictionaries?dictType="${dictType}"`);
    }
  }, [
    dictionaries,
    guid,
    navigate,
    dictType,
    useDictionariesDataResult.isFetching,
  ]);

  const names = useAllDictionariesNames();

  const newDictIsNotUnique =
    newDictionaryName &&
    newDictionaryName !== currentDictionaryName &&
    names?.includes(newDictionaryName.toLowerCase());

  const tableColumns = useMemo<
    TTableColumn<TDictionariesData['values'][string]>[]
  >(() => {
    if (!guid || !dictType) {
      return [];
    }
    const baseColumns: TTableColumn<TDictionariesData['values'][string]>[] = [
      {
        title: DICT_TYPE_NAME[dictType],
        key: 'id',
        sort: sorter('id'),
        size: '180px',
        render: (row) => (
          <span
            style={{
              fontWeight: changedKeys[row.id] ? 'bold' : undefined,
            }}
          >
            {row.id}
          </span>
        ),
      },
      ...(dictType === DictionariesType.address
        ? [
            {
              title: 'ИНН',
              key: 'inn',
              sort: sorter('inn'),
              size: '180px',
            },
            {
              title: 'Название точки',
              key: 'name',
              sort: sorter('name'),
              size: '180px',
            },
          ]
        : []),
      {
        title: dictType === DictionariesType.address ? 'Адрес' : 'Название',
        key: dictType === DictionariesType.address ? 'address' : 'name',
        sort: sorter(
          dictType === DictionariesType.address ? 'address' : 'name',
        ),
        size: '500px',
        render: (row) => (
          <span
            style={{
              fontWeight: changedKeys[row.id] ? 'bold' : undefined,
            }}
          >
            {dictType === DictionariesType.address ? row.address : row.name}
          </span>
        ),
      },
    ];

    const otherDictKeys = Object.keys(dictionaries).filter(
      (key) => key !== guid,
    );
    const allDictKeys = [guid, ...otherDictKeys];

    const dictionariesColumns = allDictKeys.map<
      TTableColumn<TDictionariesData['values'][string]>
    >((dictGuid) => ({
      title: dictGuid !== guid ? dictionaries[dictGuid] : 'Для заполнения',
      key: dictGuid,
      sort: sorter(dictGuid),
      size:
        dictGuid === guid && otherDictKeys.length !== 0 ? '300px' : undefined,
      grow: true,
      hideForExport: dictGuid !== guid,
      renderForExport: (row: TDictionariesData['values'][string]) =>
        row[dictGuid],
      render:
        dictGuid === guid
          ? (row) => (
              <Input
                list="uniqueValues"
                block
                noGap
                testId={`row-${row.id}`}
                placeholder="Введите данные..."
                value={currentValues[row.id] || ''}
                onChange={(e) => {
                  setCurrentValues({
                    ...currentValues,
                    [row.id]: e.target.value,
                  });
                }}
              />
            )
          : undefined,
    }));

    return [...baseColumns, ...dictionariesColumns];
  }, [guid, dictType, dictionaries, currentValues, changedKeys]);

  const preparedData = useMemo(() => {
    return Object.keys(values).map((key) => ({
      ...values[key],
      id: key,
    }));
  }, [values]);

  const [searchValue, onChange, filteredData] = useSearch(preparedData);

  if (!guid || !dictType) {
    return null;
  }

  const invalidate = () => {
    queryClient.invalidateQueries(['dictionaries']);
    queryClient.invalidateQueries(['dictionariesData', dictType]);
    queryClient.invalidateQueries(['filterDataset']);
    queryClient.invalidateQueries(['allDictionariesNames']);
  };

  const deleteButton = (
    <Button
      onClick={() => {
        setShowDeleteModal(true);
      }}
      testId="delete"
      flex
      color="grey"
      reportPanel
      iconOnly
    >
      <Icon name="trash" clickable size={18} />
    </Button>
  );

  return (
    <>
      <datalist id="uniqueValues">
        {uniqueValues.map((value) => (
          <option key={value}>{value}</option>
        ))}
      </datalist>
      <PageHeader
        supportKey="dict"
        backButton="Справочники"
        onBackClick={() => {
          navigate(`/dictionaries?dictType="${dictType}"`);
        }}
        withGap
        title={currentDictionaryName || `Справочник ${guid}`}
        rightTitleContent={
          <div className={styles.row}>
            <Tooltip testId="rename-dictionary" text="Переименовать справочник">
              <Button
                testId="edit"
                flex
                color="grey"
                reportPanel
                iconOnly
                onClick={() => {
                  setRenameModalIsVisible(true);
                }}
              >
                <Icon name="edit" clickable />
              </Button>
            </Tooltip>
            <Tooltip testId="delete-dictionary" text="Удалить справочник">
              {deleteButton}
            </Tooltip>
            <Tooltip
              testId={'save-dictionary'}
              text={
                Object.keys(changedKeys).length === 0
                  ? 'Сохранить справочник'
                  : `Сохранить ${fieldsCount(
                      Object.keys(changedKeys).length,
                    )} справочника`
              }
            >
              <Button
                reportPanel
                onClick={() => {
                  withLoader(() =>
                    withErrorHandling(async () => {
                      await saveDictionary(
                        dictType,
                        guid,
                        Object.keys(changedKeys).reduce<Record<string, string>>(
                          (acc, key) => {
                            acc[key] = currentValues[key] || '';
                            return acc;
                          },
                          {},
                        ),
                      );
                      del(`${VALUES_KEY}_${guid}`, draftsStore);
                      del(`${VALUES_KEY}_${guid}_time`, draftsStore);
                      invalidate();
                      toast.success(
                        <span>
                          Справочник успешно обновлен. Скачать{' '}
                          <a
                            href="#"
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              downloadDictionaryFile(
                                dictType,
                                guid,
                                currentDictionaryName || guid || '',
                              );
                            }}
                          >
                            резервную копию
                          </a>
                          ?
                        </span>,
                        {
                          autoClose: 10000,
                        },
                      );
                    }),
                  );
                }}
                testId="save"
                disabled={Object.keys(changedKeys).length === 0}
                iconOnly
              >
                <Icon name="save" clickable size={18} />
              </Button>
            </Tooltip>
          </div>
        }
      >
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
          hidden
          ref={fileInputRef}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (!file) {
              return;
            }
            rawFileRef.current = file;
            const reader = new FileReader();
            const filename = file.name;
            const extension = filename.split('.').pop();
            const isCSV = extension === 'csv';
            const [prefix, dictType, name] = filename.split('_');
            if (prefix === 'Справочник' && dictType && name) {
              setFileNameType(dictType);
              setFileNameName(name.toLowerCase());
            }
            setFileNameFull(filename);
            setFileIsCSV(isCSV);

            // get data from xlsx file
            reader.onload = (event) => {
              const data = new Uint8Array(event.target?.result as ArrayBuffer);
              const workbook = XLSX.read(data, {
                type: 'array',
              });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const json = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                raw: false,
              }) as string[][];
              const valueIndex = json[0].findIndex((header) => {
                return header === 'Для заполнения';
              });
              if (valueIndex === -1) {
                toast.error('Неверный формат файла');
                return;
              }
              setFileData(
                json.reduce<Record<string, string>>((acc, line, index) => {
                  const key = line[0];
                  const value = line[valueIndex];
                  const isAcceptableKey = index !== 0 || !isNaN(+key);
                  if (key && isAcceptableKey) {
                    acc[key] = value || '';
                  }
                  return acc;
                }, {}),
              );
              e.target.value = '';
            };
            reader.readAsArrayBuffer(file);
          }}
        />
        <Button
          testId="upload-file"
          color="grey"
          onClick={() => {
            setShowUploadModal(true);
            fileInputRef.current?.click();
          }}
          reportPanel
        >
          Заполнить через файл
        </Button>
        <DownloadReport
          white={false}
          filename={`Справочник_${dictType}_${currentDictionaryName?.replace(
            /\s/g,
            '',
          )}`}
        />
        <Spacer />
        <Pagination />
        <Input
          testId="search"
          noGap
          width={300}
          search
          placeholder="Поиск"
          value={searchValue}
          onChange={onChange}
          withClear
          leftIcon={<Icon size={18} name="search" />}
        />
      </PageHeader>
      <PageContent
        queryData={useDictionariesDataResult}
        notFound={!currentDictionaryName}
      >
        <Table
          getRowKey={(row) => row.id}
          cols={tableColumns}
          rows={filteredData}
          withPagination
        />
      </PageContent>
      <Modal
        testId="upload"
        isOpened={showUploadModal && !!fileData}
        onClose={() => {
          setShowUploadModal(false);
        }}
        className={styles.uploadModal}
      >
        <Modal.Title left>
          <div>Заполнение справочника</div>
        </Modal.Title>
        <Modal.Title left gap sub>
          <div className={styles.fileName}>{fileNameFull}</div>
        </Modal.Title>
        {!fileData && <Modal.Body></Modal.Body>}
        {fileData && (
          <Modal.Body>
            {fileIsCSV && (
              <div className={styles.attention}>
                Вы загружаете файл в формате CSV.
                <br />
                Для этого формата предпросмотр недоступен, данные справочника
                будут заменены. Перед продолжением рекомендуем скачать{' '}
                <a
                  href="#"
                  onClick={() => {
                    downloadDictionaryFile(
                      dictType,
                      guid,
                      currentDictionaryName || guid || '',
                    );
                  }}
                >
                  резервную копию
                </a>{' '}
                справочника.
              </div>
            )}
            {fileNameType && fileNameType !== dictType && (
              <div className={styles.attention}>
                Похоже, вы загружаете файл для справочника по {fileNameType} в
                справочник по {dictType}.<br />
                Это может привести к ошибкам.
              </div>
            )}
            {fileNameName &&
              fileNameName?.toLowerCase?.() !==
                currentDictionaryName?.replace(/\s/g, '')?.toLowerCase?.() && (
                <div className={styles.attention}>
                  Похоже, вы загружаете файл для справочника «{fileNameName}» в
                  справочник «{currentDictionaryName}».
                  <br />
                  Это может привести к ошибкам.
                </div>
              )}
            <Gap size={8} />
            <ul className={styles.fileValidationList}>
              <li>Загружено {fieldsCount(fileMeta.count)} из файла</li>
              <li>
                {fileMeta.extraInFile
                  ? `${fieldsCount(
                      fileMeta.extraInFile,
                    )} из файла отсутствует в справочнике. Они будут проигнорированы`
                  : 'В файле нет лишних записей'}
              </li>
              <li
                className={classNames({
                  [styles.error]: fileMeta.extraInDictionary,
                })}
              >
                {fileMeta.extraInDictionary
                  ? `${fieldsCount(
                      fileMeta.extraInDictionary,
                    )} из справочника отсутствует в файле${
                      fileIsCSV
                        ? ', их предыдущие значения в справочнике будут очищены'
                        : ''
                    }`
                  : 'В файле есть все записи справочника'}
              </li>
            </ul>
            {!fileIsCSV && fileMeta.extraInDictionary > 0 && (
              <>
                <div className={styles.extraFileGroup}>
                  <div>
                    <Checkbox
                      radio
                      checked={!extraInDictionaryDelete}
                      alignCenter
                      onChange={() => {
                        setExtraInDictionaryDelete(false);
                      }}
                      label="Оставить их предыдущие значения в справочнике"
                    />
                  </div>
                  <div>
                    <Checkbox
                      radio
                      checked={extraInDictionaryDelete}
                      alignCenter
                      onChange={() => {
                        setExtraInDictionaryDelete(true);
                      }}
                      label="Очистить их предыдущие значения в справочнике"
                    />
                  </div>
                  <Gap size={8} />
                </div>
              </>
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            testId="close"
            onClick={() => {
              setShowUploadModal(false);
            }}
            color="grey"
            fullWidth
          >
            Отменить
          </Button>
          <Gap horizontal size={16} />
          <Button
            testId="upload"
            onClick={() => {
              if (fileIsCSV) {
                setSureReplaceModalIsVisible(true);
                return;
              }
              const newValues: Record<string, string> = extraInDictionaryDelete
                ? {}
                : Object.keys(values).reduce<Record<string, string>>(
                    (acc, key) => {
                      acc[key] =
                        values[key][guid] === EMPTY_FIELD_VALUE
                          ? ''
                          : values[key][guid];
                      return acc;
                    },
                    {},
                  );
              const changedKeys: Record<string, true> = extraInDictionaryDelete
                ? Object.keys(values).reduce<Record<string, true>>(
                    (acc, key) => {
                      acc[key] = true;
                      return acc;
                    },
                    {},
                  )
                : {};
              if (fileData) {
                Object.keys(fileData).forEach((key) => {
                  if (values[key] === undefined) {
                    return;
                  }
                  if (newValues[key] !== fileData[key]) {
                    changedKeys[key] = true;
                  } else {
                    delete changedKeys[key];
                  }
                  newValues[key] =
                    fileData[key] === EMPTY_FIELD_VALUE ? '' : fileData[key];
                });
              }
              setCurrentValues(newValues);
              setShowUploadModal(false);
              toast.success('Данные заполнены');
            }}
            disabled={!fileData}
            fullWidth
          >
            {fileIsCSV ? 'Загрузить' : 'Заполнить'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        testId="sure-replace-csv"
        isOpened={sureReplaceModalIsVisible}
        onClose={() => {
          setSureReplaceModalIsVisible(false);
        }}
      >
        <Modal.Title gap>
          <div>Вы уверены, что хотите заменить</div>
          <div>все данные в справочнике?</div>
        </Modal.Title>
        <Modal.Footer>
          <Button
            onClick={() => {
              setSureReplaceModalIsVisible(false);
            }}
            color="grey"
            fullWidth
            testId="cancel"
          >
            Отменить
          </Button>
          <Gap horizontal size={16} />
          <Button
            onClick={() => {
              const file = rawFileRef.current;
              if (!file) {
                return;
              }
              withLoader(() =>
                withErrorHandling(async () => {
                  await uploadDictionaryFile(dictType, guid, file);

                  del(`${VALUES_KEY}_${guid}`, draftsStore);
                  del(`${VALUES_KEY}_${guid}_time`, draftsStore);
                  invalidate();
                  queryClient.removeQueries(['dictionariesData', dictType]);
                  toast.success('Справочник успешно загружен');

                  setSureReplaceModalIsVisible(false);
                  setShowUploadModal(false);
                }),
              );
            }}
            fullWidth
            testId="replace"
          >
            Заменить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        testId="delete-dict"
        isOpened={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <Modal.Title gap>
          Вы уверены, что хотите удалить справочник?
        </Modal.Title>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowDeleteModal(false);
            }}
            color="grey"
            fullWidth
            testId="cancel"
          >
            Отменить
          </Button>
          <Gap horizontal size={16} />
          <Button
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  await deleteDictionary(guid);
                  invalidate();
                  toast.success('Справочник успешно удален');
                  navigate('/dictionaries');
                }),
              );
            }}
            fullWidth
            testId="delete"
          >
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        testId="stored-data-restore"
        isOpened={!!hasStoredValues}
        onClose={() => {
          setHasStoredValues(false);
        }}
      >
        <Modal.Title left>Восстановить несохраненную версию?</Modal.Title>
        {hasStoredValues && (
          <Modal.Body>
            <div>В прошлый раз вы не сохранили свои изменения.</div>
            <div>
              Вы можете восстановить их или загрузить сохраненный справочник.
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            color="grey"
            testId="do-not-restore"
            onClick={() => {
              del(`${VALUES_KEY}_${guid}`, draftsStore);
              del(`${VALUES_KEY}_${guid}_time`, draftsStore);
              setHasStoredValues(false);
            }}
            fullWidth
          >
            Отменить
          </Button>
          <Gap horizontal size={16} />
          <Button
            testId="restore"
            onClick={async () => {
              setHasStoredValues(false);
              const storedValues = await get(
                `${VALUES_KEY}_${guid}`,
                draftsStore,
              );
              if (storedValues) {
                setCurrentValues(storedValues);
              }
            }}
            fullWidth
          >
            Восстановить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        testId="rename"
        isOpened={renameModalIsVisible}
        onClose={() => {
          setRenameModalIsVisible(false);
        }}
      >
        <Modal.Title left gap>
          Переименовать справочник
        </Modal.Title>
        <Modal.Body>
          <Input
            testId="new-name"
            value={newDictionaryName}
            onChange={(e) => {
              setNewDictionaryName(e.target.value);
            }}
            placeholder="Введите новое название"
            block
            error={
              newDictIsNotUnique
                ? 'Справочник с таким названием уже существует'
                : undefined
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="grey"
            testId="cancel"
            onClick={() => {
              setRenameModalIsVisible(false);
            }}
            fullWidth
          >
            Отменить
          </Button>
          <Gap horizontal size={16} />
          <Button
            testId="rename"
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  await renameDictionary(guid, newDictionaryName);
                  await resetUserEditableCache();
                  invalidate();
                  toast.success('Справочник успешно переименован');
                  setRenameModalIsVisible(false);
                }),
              );
            }}
            fullWidth
            disabled={!!newDictIsNotUnique || !newDictionaryName}
          >
            Переименовать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
