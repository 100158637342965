import * as Sentry from '@sentry/browser';

const LAST_USE_KEY = 'lastUse';
const MAX_STORE_TIME = 1000 * 60 * 60 * 24 * 5;

const fallbackStorage: Record<string, string> = {};

type TStorage = {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
};
const getKeyLastUse = (key: string): number => {
  try {
    const lastUse = localStorage.getItem(LAST_USE_KEY);
    if (lastUse) {
      const lastUseObj = JSON.parse(lastUse);
      return lastUseObj[key] || 0;
    }
  } catch (e) {
    console.error(e);
  }
  return 0;
};

const saveKeyLastUse = (key: string): void => {
  try {
    const lastUse = localStorage.getItem(LAST_USE_KEY);
    const lastUseObj = lastUse ? JSON.parse(lastUse) : {};
    lastUseObj[key] = Date.now();
    localStorage.setItem(LAST_USE_KEY, JSON.stringify(lastUseObj));
  } catch (e) {
    console.error(e);
  }
};

const deleteKeyLastUse = (key: string): void => {
  try {
    const lastUse = localStorage.getItem(LAST_USE_KEY);
    if (lastUse) {
      const lastUseObj = JSON.parse(lastUse);
      delete lastUseObj[key];
      localStorage.setItem(LAST_USE_KEY, JSON.stringify(lastUseObj));
    }
  } catch (e) {
    console.error(e);
  }
};

export const storage: TStorage = {
  getItem: (key) => {
    saveKeyLastUse(key);
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return fallbackStorage[key] || null;
    }
  },

  setItem: (key, value) => {
    saveKeyLastUse(key);
    fallbackStorage[key] = value;
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      if ((e as Error).name === 'QuotaExceededError') {
        clearOldKeys();
      }
    }
  },

  removeItem: (key) => {
    deleteKeyLastUse(key);
    delete fallbackStorage[key];
    try {
      localStorage.removeItem(key);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  },
};

const clearOldKeys = (): void => {
  const allKeys = Object.keys(fallbackStorage);
  allKeys.forEach((key) => {
    const lastUse = getKeyLastUse(key);
    if (lastUse) {
      const timePassed = Date.now() - lastUse;
      if (timePassed > MAX_STORE_TIME) {
        storage.removeItem(key);
      }
    }
  });
};

setTimeout(clearOldKeys, 1000 * 60 * 2);
setInterval(clearOldKeys, 1000 * 60 * 60 * 24);
