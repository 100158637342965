import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { $api } from '../../api/utils';
import { Loader } from '../../newComponents/common/Loader/Loader';
import { getDefaultTestWeek } from '../utils/isDev';
import { parseReportKey } from '../utils/parseReportKey';

export type TAvailablePeriodProps = {
  from: Date;
  to: Date;
  availablePeriod: {
    from: Date;
    to: Date;
  };
};

const getPeriod = async (datasetId: number) => {
  const { data } = await $api.get(`/data/ds${datasetId}/interval`);
  return data;
};

const QUERY_CONFIG = {
  retry: false,
  refetchOnWindowFocus: false,
};

const useAvailablePeriod = (reportName: string) => {
  const { datasetId } = parseReportKey(reportName);
  return useQuery(
    ['availablePeriod', datasetId],
    () => getPeriod(datasetId),
    QUERY_CONFIG,
  );
};

export const withAvailablePeriod = (
  reportName: string,
  Component: React.FC<TAvailablePeriodProps>,
): React.FC => {
  const AvailablePeriodWrapper: React.FC = () => {
    const { data, isLoading } = useAvailablePeriod(reportName);
    const [from, to, availablePeriod] = useMemo(() => {
      const testModeWeek = getDefaultTestWeek();
      if (testModeWeek) {
        const from = testModeWeek[0];
        const to = testModeWeek[1];
        return [from, to, { from, to }];
      }
      if (!data) {
        return [
          new Date(),
          new Date(),
          {
            from: new Date(),
            to: new Date(),
          },
        ];
      }
      const fromPeriod = dayjs(data.min, 'YYYY-MM-DD');
      const toPeriod = dayjs(data.max, 'YYYY-MM-DD');
      const availablePeriod = {
        from: fromPeriod.toDate(),
        to: toPeriod.toDate(),
      };
      const from = dayjs(toPeriod).subtract(6, 'days');
      return [from.toDate(), toPeriod.toDate(), availablePeriod];
    }, [data]);
    if (isLoading) {
      return <Loader fullScreen />;
    }
    return <Component from={from} to={to} availablePeriod={availablePeriod} />;
  };

  return AvailablePeriodWrapper;
};
