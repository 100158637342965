import React from 'react';
import { Button } from '../../../../uikit/Button/Button';
import XLSX from 'xlsx';
import { Table } from '../../../common/Table/Table';
import { Chart } from '../../../common/Chart/Chart';
import {
  COLOR_SCHEMES,
  RegionsMap,
} from '../../../common/RegionsMap/RegionsMap';
import {
  AS_IS_FORMATTER,
  EXACT_NUMERIC_FORMATTER,
  DEFAULT_NUMERIC_FORMATTER,
  DEFAULT_PERCENT_FORMATTER,
} from '../../../../shared/utils/formatters';
import { PageContent } from '../../../common/PageContent/PageContent';
import { useIsFullscreen } from '../../../common/PageContent/useIsFullscreen';
import { Input } from '../../../../uikit/Input/Input';

import styles from './map.module.scss';
import { Gap } from '../../../../uikit/Gap/Gap';
import { Modal } from '../../../../uikit/Modal/Modal';
import { LineSelect } from '../../../common/LineSelect/LineSelect';
import { useSearchQueryState } from '../../../../shared/hooks/useSearchQueryState';

type TRow = {
  regionCode: string;
  value?: number;
  subValue?: number;
};

const DATA_EXAMPLE: TRow[] = [
  {
    regionCode: '78',
    value: 100000,
    subValue: 0.1,
  },
  {
    regionCode: '53',
    value: 200000,
    subValue: 0.2,
  },
];

const COLUMNS_CONFIG = [
  {
    wch: 20,
  },
  {
    wch: 20,
  },
  {
    wch: 20,
  },
  {
    wch: 20,
  },
];

const TABLE_ROWS = [
  {
    key: 'regionCode',
    title: 'Код региона',
  },
  {
    key: 'value',
    title: 'Основное значение',
  },
  {
    key: 'subValue',
    title: 'Дополнительное значение',
  },
];

const FORMATTERS: Record<
  string,
  {
    formatter: (value: number) => string;
    label: string;
  }
> = {
  asIs: {
    formatter: AS_IS_FORMATTER,
    label: AS_IS_FORMATTER(10000),
  },
  exactNumeric: {
    formatter: EXACT_NUMERIC_FORMATTER,
    label: EXACT_NUMERIC_FORMATTER(20000),
  },
  defaultNumeric: {
    formatter: DEFAULT_NUMERIC_FORMATTER,
    label: DEFAULT_NUMERIC_FORMATTER(300000),
  },
  defaultPercent: {
    formatter: DEFAULT_PERCENT_FORMATTER,
    label: DEFAULT_PERCENT_FORMATTER(0.7),
  },
};

export const MapScreenshoter = () => {
  const [currentData, setCurrentData] = React.useState<TRow[]>([]);
  const [isFullscreen] = useIsFullscreen();

  const [valueLabel, setValueLabel] = useSearchQueryState<string>(
    'valueLabel',
    'Основное значение',
  );
  const [subValueLabel, setSubValueLabel] = useSearchQueryState<string>(
    'subValueLabel',
    'Дополнительное значение',
  );
  const [colorScheme, setColorScheme] = useSearchQueryState<
    keyof typeof COLOR_SCHEMES
  >('colorScheme', Object.keys(COLOR_SCHEMES)[0] as keyof typeof COLOR_SCHEMES);

  const [isContentOpen, setIsContentOpen] = React.useState(false);

  const [valueFormatter, setValueFormatter] = useSearchQueryState<
    keyof typeof FORMATTERS
  >('valueFormatter', 'asIs');
  const [subValueFormatter, setSubValueFormatter] = useSearchQueryState<
    keyof typeof FORMATTERS
  >('subValueFormatter', 'asIs');

  const [fileKey, setFileKey] = React.useState(0);

  return (
    <div className={styles.container}>
      {!isFullscreen && (
        <div className={styles.left}>
          <Chart testId="chart">
            <div>
              <Button
                testId="download-example"
                onClick={() => {
                  const worksheet = XLSX.utils.json_to_sheet(DATA_EXAMPLE);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  worksheet['!cols'] = COLUMNS_CONFIG;
                  XLSX.writeFile(workbook, 'example.xlsx');
                }}
              >
                Скачать пример
              </Button>
            </div>
            <Gap size={16} />
            <div className={styles.formatTable}>
              <Table
                noMargin
                cols={[
                  {
                    key: 'key',
                    title: 'Поле',
                  },
                  {
                    key: 'type',
                    title: 'Тип',
                  },
                ]}
                rows={[
                  {
                    key: 'regionCode',
                    type: 'Строка',
                  },
                  {
                    key: 'value',
                    type: 'Число',
                  },
                  {
                    key: 'subValue',
                    type: 'Число',
                  },
                ]}
              />
            </div>
            <Gap size={16} />
            <Input
              testId="file"
              block
              custom={
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) {
                      return;
                    }
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const data = e.target?.result;
                      if (!data) {
                        return;
                      }
                      const workbook = XLSX.read(data, { type: 'binary' });
                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                      const json = XLSX.utils.sheet_to_json(worksheet);
                      setCurrentData(json as TRow[]);
                      setFileKey(fileKey + 1);
                    };
                    reader.readAsBinaryString(file);
                  }}
                />
              }
              label="Файл"
            />
            <Input
              testId="valueLabel"
              block
              value={valueLabel}
              label="valueLabel"
              onChange={(e) => setValueLabel(e.target.value)}
            />
            <Input
              testId="subValueLabel"
              block
              value={subValueLabel}
              label="subValueLabel"
              onChange={(e) => setSubValueLabel(e.target.value)}
            />
            <Input
              testId="colorScheme"
              block
              label="Цвет"
              custom={
                <LineSelect
                  testId="colorScheme"
                  value={colorScheme}
                  onChange={setColorScheme}
                  // @ts-ignore
                  options={Object.keys(COLOR_SCHEMES).map((key) => ({
                    value: key,
                    label: key,
                  }))}
                />
              }
            />

            <Input
              testId="valueFormatter"
              block
              label="Форматирование значения"
              custom={
                <LineSelect
                  testId="valueFormatter"
                  value={valueFormatter}
                  // @ts-ignore
                  onChange={setValueFormatter}
                  options={Object.keys(FORMATTERS).map((key) => ({
                    value: key,
                    // @ts-ignore
                    label: FORMATTERS[key].label,
                  }))}
                />
              }
            />

            <Input
              testId="subValueFormatter"
              block
              label="Форматирование подзначения"
              custom={
                <LineSelect
                  testId="subValueFormatter"
                  value={subValueFormatter}
                  // @ts-ignore
                  onChange={setSubValueFormatter}
                  options={Object.keys(FORMATTERS).map((key) => ({
                    value: key,
                    // @ts-ignore
                    label: FORMATTERS[key].label,
                  }))}
                />
              }
            />
            <div>
              <Button
                testId="open-data"
                color="darkGrey"
                onClick={() => {
                  setIsContentOpen(!isContentOpen);
                }}
              >
                Данные
              </Button>
            </div>

            <Modal
              testId="data"
              isOpened={isContentOpen}
              onClose={() => setIsContentOpen(false)}
              className={styles.modal}
            >
              <Table
                noMargin
                rows={currentData}
                // @ts-ignore
                cols={TABLE_ROWS}
              />
            </Modal>
          </Chart>
        </div>
      )}
      <div className={styles.right}>
        {currentData.length > 0 && (
          <PageContent canMakeScreenshot>
            <Chart fullHeight testId="chart">
              <RegionsMap
                key={fileKey}
                // @ts-ignore
                data={currentData}
                subValueFormatter={FORMATTERS[subValueFormatter].formatter}
                valueFormatter={FORMATTERS[valueFormatter].formatter}
                valueLabel={valueLabel}
                subValueLabel={subValueLabel}
                colorScheme={colorScheme}
              />
            </Chart>
          </PageContent>
        )}
      </div>
    </div>
  );
};
