import React, { useState } from 'react';
import { withRestriction } from '../../../shared/hocs/withRestriction';
import { PageHeader } from '../../common/PageHeader/PageHeader';
import { $api } from '../../../api/utils';
import { useQuery } from '@tanstack/react-query';
import { PageContent } from '../../common/PageContent/PageContent';

import styles from './EventsAdmin.module.scss';
import { Button } from '../../../uikit/Button/Button';
import { Event } from '../../common/Event/Event';
import dayjs from 'dayjs';
import { Input } from '../../../uikit/Input/Input';
import { withLoader } from '../../common/FullscreenLoader/FullscreenLoader';
import { queryClient } from '../../../store/query';
import { withErrorHandling } from '../../../shared/utils/withErrorHandling';
import { Gap } from '../../../uikit/Gap/Gap';
import { MENU_ITEMS } from '../../common/Header/Header';
import { REPORTS } from '../Reports/constants';

type TEvent = {
  createDttm: string;
  guid: string;
  link: string;
  publishDttm: string;
  text: string;
  title: string;
  type: 'ERROR' | 'INFO' | 'OTHER';
};

const getEvents = async () => {
  const { data } = await $api.get<TEvent[]>('/events?limit=1000');
  return data.map((item) => ({
    ...item,
    createDttm: dayjs(item.createDttm)
      .utc(true)
      .local()
      .format('YYYY-MM-DDTHH:mm'),
    publishDttm: dayjs(item.publishDttm)
      .utc(true)
      .local()
      .format('YYYY-MM-DDTHH:mm'),
  }));
};

const useEvents = () => {
  return useQuery(['events'], getEvents);
};

const QUICK_BUTTONS_LIST: Array<{
  title: string;
  link: string;
}> = [
  ...MENU_ITEMS.filter((item) => item.isVisibleInEventsForm).map((item) => ({
    title: item.title,
    link: item.path,
  })),
  ...REPORTS.map((item) => ({
    title: `ДС${item.sectionKey}-Р${item.reportKey}`,
    link: `/reports/report-ds${item.sectionKey}-r${item.reportKey}`,
  })),
];

const EventForm: React.FC<{
  event?: TEvent;
}> = ({ event }) => {
  const isCreate = !event;
  const [isExpanded, setIsExpanded] = useState(false);

  const [previewEvent, setPreviewEvent] = useState<TEvent>(() => {
    if (event) {
      return event;
    }
    return {
      createDttm: new Date().toISOString(),
      guid: '',
      link: '',
      publishDttm: new Date().toISOString(),
      text: '',
      title: '',
      type: 'OTHER',
    };
  });

  if (!isExpanded && isCreate) {
    return (
      <div className={styles.event}>
        <Button
          testId={`create-${previewEvent.guid}`}
          small
          onClick={() => setIsExpanded(true)}
        >
          Создать
        </Button>
      </div>
    );
  }

  if (!isExpanded && !isCreate) {
    return (
      <div className={styles.event}>
        <div className={styles.preview}>
          <Event event={previewEvent} />
        </div>
        <br />
        <div>
          Ссылка: <strong>{previewEvent.link}</strong>
        </div>
        <div>
          Дата создания:{' '}
          <strong>
            {dayjs(previewEvent.createDttm).format('DD.MM.YYYY HH:mm')} (
            {dayjs(previewEvent.createDttm).fromNow()})
          </strong>
        </div>
        <div>
          Дата публикации:{' '}
          <strong>
            {dayjs(previewEvent.publishDttm).format('DD.MM.YYYY HH:mm')} (
            {dayjs(previewEvent.publishDttm).fromNow()})
          </strong>
        </div>
        <br />
        <Button
          testId={`edit-${previewEvent.guid}`}
          small
          onClick={() => setIsExpanded(true)}
        >
          Редактировать
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.event}>
      <div className={styles.preview}>
        <Event event={previewEvent} />
      </div>
      <br />
      <div>
        <Input
          width={400}
          testId={`title-${previewEvent.guid}`}
          label="Заголовок"
          value={previewEvent.title}
          onChange={(e) =>
            setPreviewEvent({
              ...previewEvent,
              title: e.target.value,
            })
          }
        />
      </div>
      <div>
        <Input
          width={400}
          testId={`text-${previewEvent.guid}`}
          label="Текст"
          value={previewEvent.text}
          onChange={(e) =>
            setPreviewEvent({
              ...previewEvent,
              text: e.target.value,
            })
          }
        />
      </div>
      <div>
        <Input
          width={400}
          testId={`link-${previewEvent.guid}`}
          label="Ссылка"
          value={previewEvent.link}
          onChange={(e) =>
            setPreviewEvent({
              ...previewEvent,
              link: e.target.value,
            })
          }
          noGap
        />
        <div className={styles.quickButtonsList}>
          <span>Быстрые ссылки:</span>
          {QUICK_BUTTONS_LIST.map((item) => (
            <Button
              color="black"
              small
              testId="quick-link"
              onClick={() => {
                setPreviewEvent({
                  ...previewEvent,
                  link: item.link,
                });
              }}
            >
              {item.title}
            </Button>
          ))}
        </div>
        {previewEvent.link.startsWith('http://') ||
        previewEvent.link.startsWith('https://') ? (
          <div>
            Ссылка ведет на внешний ресурс{' '}
            <a href={previewEvent.link} target={'_blank'} rel="noreferrer">
              {previewEvent.link.replace('http://', '').replace('https://', '')}
            </a>
            .
          </div>
        ) : (
          <div>
            Ссылка ведет{' '}
            <a href={previewEvent.link} target={'_blank'} rel="noreferrer">
              {window.location.host}/
              {previewEvent.link.startsWith('/')
                ? previewEvent.link.slice(1)
                : previewEvent.link}
            </a>
            .
          </div>
        )}
        <br />
      </div>
      <div>
        <Input
          width={400}
          testId={`publishDttm-${previewEvent.guid}`}
          label="Дата публикации"
          value={dayjs(previewEvent.publishDttm).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) =>
            setPreviewEvent({
              ...previewEvent,
              publishDttm: dayjs(e.target.value).format('YYYY-MM-DDTHH:mm'),
            })
          }
          type="datetime-local"
        />
        {dayjs(previewEvent.publishDttm).isAfter(dayjs()) && (
          <div>
            Время публикации еще не наступило. Событие будет опубликовано{' '}
            {dayjs(previewEvent.publishDttm).fromNow()}
          </div>
        )}
      </div>
      <div>
        {['ERROR', 'INFO', 'OTHER'].map((type) => (
          <div key={type}>
            <label>
              <input
                type="radio"
                name={`type-${previewEvent.guid}`}
                checked={previewEvent.type === type}
                onChange={() =>
                  setPreviewEvent({
                    ...previewEvent,
                    type: type as 'ERROR' | 'INFO' | 'OTHER',
                  })
                }
              />
              {type}
            </label>
          </div>
        ))}
      </div>
      <br />
      <div>
        <Button
          testId={`save-${previewEvent.guid}`}
          small
          onClick={() => {
            withLoader(() =>
              withErrorHandling(async () => {
                if (isCreate) {
                  await $api.post('/events', {
                    ...previewEvent,
                    createDttm: dayjs(previewEvent.createDttm).utc().format(),
                    publishDttm: dayjs(previewEvent.publishDttm).utc().format(),
                  });
                  setIsExpanded(false);
                  setPreviewEvent({
                    createDttm: new Date().toISOString(),
                    guid: '',
                    link: '',
                    publishDttm: new Date().toISOString(),
                    text: '',
                    title: '',
                    type: 'OTHER',
                  });
                  queryClient.invalidateQueries(['events']);
                  return;
                }
                await $api.put(`/events/${previewEvent.guid}`, {
                  ...previewEvent,
                  createDttm: dayjs(previewEvent.createDttm).utc().format(),
                  publishDttm: dayjs(previewEvent.publishDttm).utc().format(),
                });
                setIsExpanded(false);
                queryClient.invalidateQueries(['events']);
              }),
            );
          }}
        >
          Сохранить
        </Button>
        <Gap horizontal size={10} />
        {!isCreate && (
          <Button
            testId={`save-${previewEvent.guid}`}
            small
            onClick={() => {
              withLoader(() =>
                withErrorHandling(async () => {
                  await $api.delete(`/events/${previewEvent.guid}`);
                  setIsExpanded(false);
                  queryClient.invalidateQueries(['events']);
                }),
              );
            }}
          >
            Удалить
          </Button>
        )}
      </div>
    </div>
  );
};

export const EventsAdmin: React.FC = withRestriction(['ADMIN'], () => {
  const queryData = useEvents();
  return (
    <>
      <PageHeader title="Уведомления" />
      <PageContent queryData={queryData}>
        <div className={styles.eventsList}>
          <EventForm />
          {queryData.data?.map?.((event) => (
            <EventForm event={event} key={event.guid} />
          ))}
        </div>
      </PageContent>
    </>
  );
});
