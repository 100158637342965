import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import styles from './ProfileModal.module.scss';
import { ROLE_NAMES } from '../../../../shared/constants';
import { getRole } from '../../../../shared/utils/getRole';
import { TInn, useInns } from './hooks';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

import { queryClient } from '../../../../store/query';
import { Button } from '../../../../uikit/Button/Button';
import { Gap } from '../../../../uikit/Gap/Gap';
import { useAuthorizedUser } from '../../../../shared/hooks/useUser';
import { fetchRevokeInn } from '../../../../api/delegation/delegation';
import { withLoader } from '../../FullscreenLoader/FullscreenLoader';
import { RevokeModal } from '../../../pages/Delegation/components/RevokeModal/RevokeModal';
import { CreatingCertificateSignatureModal } from '../../../pages/Delegation/components/CreatingCertificateSignatureModal/CreatingCertificateSignatureModal';

type TProfileModalProps = {
  visible: boolean;
  onClose: () => void;
};

const Delegation: React.FC<{
  isActive: boolean;
}> = ({ isActive }) => {
  const { isFetching, data: inns } = useInns(isActive);

  const innsList = inns || ([] as TInn[]);

  const [revokeInn, setRevokeInn] = React.useState<TInn | null>(null);
  const [activateInn, setActivateInn] = React.useState<TInn | null | true>(
    null,
  );

  return (
    <>
      {revokeInn && (
        <RevokeModal
          item={revokeInn}
          show
          onClose={(e?: MouseEvent) => {
            if (e) {
              e.stopPropagation();
            }
            setRevokeInn(null);
          }}
          onRevokeInn={async () => {
            await withLoader(async () => {
              await fetchRevokeInn(revokeInn.inn);
              queryClient.invalidateQueries(['inns']);
            });
            setRevokeInn(null);
          }}
        />
      )}

      {activateInn && (
        <CreatingCertificateSignatureModal
          type={activateInn === true ? 'add' : 'activate'}
          selectedItem={activateInn}
          show
          onClose={(e: MouseEvent) => {
            if (e) {
              e.stopPropagation();
            }
            setActivateInn(null);
            queryClient.invalidateQueries(['inns']);
          }}
        />
      )}

      <div className={styles.subTitle}>
        Делегирование{' '}
        {isFetching && (
          <UseAnimations
            animation={loading}
            size={16}
            loop
            autoplay
            wrapperStyle={{
              marginTop: -17,
              overflow: 'visible',
            }}
          />
        )}
      </div>
      {innsList.map((inn, index) => (
        <div className={styles.inn} key={index}>
          <div className={styles.label}>
            {inn.name}
            <span className={styles.spacer} />
            {inn.active && (
              <span
                className={styles.toggleStatus}
                onClick={() => setRevokeInn(inn)}
              >
                Отозвать
              </span>
            )}
            {!inn.active && (
              <span
                className={styles.toggleStatus}
                onClick={() => setActivateInn(inn)}
              >
                Активировать
              </span>
            )}
          </div>
          <span
            className={classNames(styles.status, {
              [styles.active]: inn.active,
            })}
          >
            {inn.active ? 'Активен' : 'Отозван'}
          </span>
          <div className={styles.value}>{inn.inn}</div>
        </div>
      ))}
      <Gap />
      <Button
        testId="add-inn"
        color="black"
        onClick={() => setActivateInn(true)}
        fullWidth
      >
        Добавить ИНН
      </Button>
    </>
  );
};

export const ProfileModal: React.FC<TProfileModalProps> = ({
  visible,
  onClose,
}) => {
  const userInfo = useAuthorizedUser();

  const isClient = getRole(userInfo?.roles, 'CLIENT');
  const isClientAdmin = getRole(userInfo?.roles, 'CLIENT_ADMIN');

  const canDelegate = isClient || isClientAdmin;

  return createPortal(
    <div
      className={classNames(styles.wrapper, {
        [styles.visible]: visible,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div
        className={classNames(styles.modal, {
          [styles.visible]: visible,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.close} onClick={onClose} />
        <div className={styles.title}>Мои данные</div>
        <div className={styles.profile}>
          <div className={styles.name}>{userInfo?.name}</div>
          <div className={styles.meta}>
            {
              // @ts-ignore
              ROLE_NAMES[userInfo?.roles?.[0]] || ''
            }
          </div>
        </div>
        <div className={styles.email}>
          <div className={styles.label}>E-mail</div>
          <div className={styles.value}>{userInfo?.login}</div>
        </div>
        {canDelegate && <Delegation isActive={visible} />}
      </div>
    </div>,
    document.body,
  );
};
