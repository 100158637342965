import { $api } from '../utils';
import { formatError } from '../../shared/utils/withErrorHandling';
import {
  AddressResponse,
  GtinResponse,
  RFCodeResponse,
  InnResponse,
} from './Api';
import { useQuery } from '@tanstack/react-query';
import {
  contextLengthApi,
  useLoadedPercent,
} from '../../shared/contexts/contentLength';

async function getData<TResponseType>(
  url: string,
  queryKey?: unknown[],
): Promise<TResponseType> {
  const queryKeyString = queryKey ? JSON.stringify(queryKey) : '';
  try {
    const r = await $api.get(url, {
      onDownloadProgress: (event) => {
        if (queryKeyString && event.total && event.loaded) {
          contextLengthApi.current.setContentLengthsPercent(
            queryKeyString,
            Math.round((100 * event.loaded) / event.total),
          );
        }
      },
    });

    return r.data;
  } catch (error) {
    throw new Error(formatError(error));
  } finally {
    if (queryKeyString) {
      contextLengthApi.current.deleteContentLengthsPercents(queryKeyString);
    }
  }
}

const getAddresses = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<AddressResponse[]>('/data/addresses', queryKey);
const getBuyerNetworks = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<string[]>('/data/buyer-networks/bulk', queryKey);
const getBuyers = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<InnResponse[]>('/data/buyers/bulk', queryKey);
const getGtins = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<GtinResponse[]>('/data/gtins', queryKey);
const getRfCodes = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<RFCodeResponse[]>('/data/rf-codes', queryKey);
const getRetailSellerNetworks = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<string[]>('/data/seller-networks/retail', queryKey);
const getRetailSellers = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<InnResponse[]>('/data/sellers/retail', queryKey);
const getDs1r6Participants = ({ queryKey }: { queryKey: unknown[] }) =>
  getData<InnResponse[]>('/data/participants', queryKey);

const QUERY_KEYS = {
  addresses: ['commonData', 'addresses'],
  buyerNetworks: ['commonData', 'buyer-networks'],
  buyers: ['commonData', 'buyers'],
  gtins: ['commonData', 'gtins'],
  rfCodes: ['commonData', 'rf-codes'],
  retailSellerNetworks: ['commonData', 'seller-networks', 'retail'],
  retailSellers: ['commonData', 'sellers', 'retail'],
  participants: ['commonData', 'sellers', 'participants'],
};

const QUERY_KEYS_STRINGS = {
  addresses: JSON.stringify(QUERY_KEYS.addresses),
  buyerNetworks: JSON.stringify(QUERY_KEYS.buyerNetworks),
  buyers: JSON.stringify(QUERY_KEYS.buyers),
  gtins: JSON.stringify(QUERY_KEYS.gtins),
  rfCodes: JSON.stringify(QUERY_KEYS.rfCodes),
  retailSellerNetworks: JSON.stringify(QUERY_KEYS.retailSellerNetworks),
  retailSellers: JSON.stringify(QUERY_KEYS.retailSellers),
  participants: JSON.stringify(QUERY_KEYS.participants),
};

const QUERY_OPTIONS = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
};

const useAddresses = () =>
  useQuery(QUERY_KEYS.addresses, getAddresses, QUERY_OPTIONS);
const useBuyerNetworks = () =>
  useQuery(QUERY_KEYS.buyerNetworks, getBuyerNetworks, QUERY_OPTIONS);
const useBuyers = () => useQuery(QUERY_KEYS.buyers, getBuyers, QUERY_OPTIONS);
const useGtins = () => useQuery(QUERY_KEYS.gtins, getGtins, QUERY_OPTIONS);
const useRfCodes = () =>
  useQuery(QUERY_KEYS.rfCodes, getRfCodes, QUERY_OPTIONS);
const useRetailSellerNetworks = () =>
  useQuery(
    QUERY_KEYS.retailSellerNetworks,
    getRetailSellerNetworks,
    QUERY_OPTIONS,
  );
const useRetailSellers = () =>
  useQuery(QUERY_KEYS.retailSellers, getRetailSellers, QUERY_OPTIONS);
const useDs1r6Participants = () =>
  useQuery(QUERY_KEYS.participants, getDs1r6Participants, QUERY_OPTIONS);

export type TCommonData = {
  addresses: AddressResponse[];
  buyerNetworks: string[];
  buyers: InnResponse[];
  gtins: GtinResponse[];
  rfCodes: RFCodeResponse[];
  retailSellerNetworks: string[];
  retailSellers: InnResponse[];
  participants: InnResponse[];
  isSomeLoading: boolean;
  queriesStatuses: Record<string, boolean>;
};

const EMPTY: any[] = [];

export const useCommonData = (): TCommonData => {
  const addresses = useAddresses();
  const buyerNetworks = useBuyerNetworks();
  const buyers = useBuyers();
  const gtins = useGtins();
  const rfCodes = useRfCodes();
  const retailSellerNetworks = useRetailSellerNetworks();
  const retailSellers = useRetailSellers();
  const participants = useDs1r6Participants();

  const queriesList = {
    addresses,
    buyerNetworks,
    buyers,
    gtins,
    rfCodes,
    retailSellerNetworks,
    retailSellers,
    participants,
  };

  return {
    addresses: addresses.data || EMPTY,
    buyerNetworks: buyerNetworks.data || EMPTY,
    buyers: buyers.data || EMPTY,
    gtins: gtins.data || EMPTY,
    rfCodes: rfCodes.data || EMPTY,
    retailSellerNetworks: retailSellerNetworks.data || EMPTY,
    retailSellers: retailSellers.data || EMPTY,
    participants: participants.data || EMPTY,

    isSomeLoading: Object.values(queriesList).some(
      ({ isLoading }) => isLoading,
    ),
    queriesStatuses: Object.entries(queriesList).reduce(
      (acc, [key, { isLoading }]) => ({ ...acc, [key]: isLoading }),
      {},
    ),
  };
};

export const useCommonDataPercents = (): Record<string, number> => {
  const addressesPercent = useLoadedPercent(QUERY_KEYS_STRINGS.addresses);
  const buyerNetworksPercent = useLoadedPercent(
    QUERY_KEYS_STRINGS.buyerNetworks,
  );
  const buyersPercent = useLoadedPercent(QUERY_KEYS_STRINGS.buyers);
  const gtinsPercent = useLoadedPercent(QUERY_KEYS_STRINGS.gtins);
  const rfCodesPercent = useLoadedPercent(QUERY_KEYS_STRINGS.rfCodes);
  const retailSellerNetworksPercent = useLoadedPercent(
    QUERY_KEYS_STRINGS.retailSellerNetworks,
  );
  const retailSellersPercent = useLoadedPercent(
    QUERY_KEYS_STRINGS.retailSellers,
  );
  const participantsPercent = useLoadedPercent(QUERY_KEYS_STRINGS.participants);

  return {
    addresses: addressesPercent,
    buyerNetworks: buyerNetworksPercent,
    buyers: buyersPercent,
    gtins: gtinsPercent,
    rfCodes: rfCodesPercent,
    retailSellerNetworks: retailSellerNetworksPercent,
    retailSellers: retailSellersPercent,
    participants: participantsPercent,
  };
};
