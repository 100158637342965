import { DS3R2Response } from '../../../../../api/reports/Api';
import { sorter } from '../../../../common/Table/sorter';
import {
  getGroupByFeature,
  PRESET_SHOULD_HIDE_CHECKERS,
} from '../AllPurposeReport/dataFeatures/groupBy';
import {
  getTransposeFeature,
  TWithTransposedFields,
} from '../AllPurposeReport/dataFeatures/transpose';
import { getIntervalFilter } from '../AllPurposeReport/filters/interval';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { getIntervalTransposeConfig } from '../AllPurposeReport/presets/transposeIntervals';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getLinesChartFeature } from '../AllPurposeReport/viewFeatures/linesChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';

const NAME = 'Динамика цен на GTIN по России и регионам';

export const ds3o2Config: TReportConfigType<DS3R2Response> = {
  title: NAME,
  backendKey: 'ds3r2',

  dataFeatures: [
    getTransposeFeature<DS3R2Response>(
      getIntervalTransposeConfig('YYYY-MM-DD', 'gtin', [
        {
          label: 'Средневзвешенная цена за шт/кг, руб.',
          valueKey: 'avgPrice',
        },
      ]),
    ),
    getGroupByFeature<DS3R2Response>({
      options: [
        {
          groupBy: 'gtin',
          label: 'GTIN',
          additionalColumns: [
            {
              key: 'gtin',
              title: 'GTIN',
              sort: sorter('gtin'),
            },
            {
              key: 'gtinName',
              title: 'Наименование товара',
              sort: sorter('gtinName'),
            },
          ],
        },
        {
          groupBy: 'dictName',
          shouldHide: PRESET_SHOULD_HIDE_CHECKERS.notDataset('gtins', ['gtin']),
          label: 'Справочник',
          additionalColumns: [
            {
              key: 'dictName',
              title: 'Название в справочнике',
              sort: sorter('dictName'),
            },
          ],
        },
      ],
      summarizeFields: ['salesCnt', /^transposed_.*/],
      customGetter: ({ item, key, groupBy, rawData }) => {
        if (groupBy === 'dictName' && key.startsWith('transposed_')) {
          const reportInterval = key.substring('transposed_'.length);
          const dictName = item.dictName;
          const allItems = rawData.filter(
            (i) =>
              i.dictName === dictName && i.reportInterval === reportInterval,
          );
          let totalMoney = 0;
          let totalCount = 0;
          for (const i of allItems) {
            totalMoney += i.salesCnt * i.avgPrice;
            totalCount += i.salesCnt;
          }
          return totalCount ? totalMoney / totalCount : 0;
        }
        return undefined;
      },
    }),
  ],

  viewFeatures: [
    getTableFeature<TWithTransposedFields<DS3R2Response>>({
      defaultSort: 'LAST',
      filename: NAME,
      baseColumns: [],
    }),
    getLinesChartFeature<TWithTransposedFields<DS3R2Response>>({
      filename: NAME,
      nameKey: 'gtinName',
      innKey: 'gtin',
      valuesPrefix: 'transposed_',
      icon: 'barChartWithLine',
      groupKeyToNameMap: {
        gtin: 'gtinName',
      },
    }),
  ],

  filters: [
    getIntervalFilter({
      single: false,
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      commonDataKey: 'gtins',
      mode: 'gtins',
      label: 'Товар',
      icon: 'package',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: false,
      commonDataKey: 'rfCodes',
      mode: 'codes',
      label: 'Субъект Федерации',
      icon: 'location',
      single: true,
      singleSelectAllLabel: 'Тотал Россия',
    }),
  ],
  requiredData: {
    gtinDictionaries: true,
  },
  requiredCommonData: ['gtins', 'rfCodes'],

  autoApplyOnFilterReady: true,

  isRequiredFieldsFilled: (filter) => Boolean(filter.periods?.length),
};
