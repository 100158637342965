const devMode = process.env.NODE_ENV === 'development';

export const apiPath = devMode
  ? 'https://dev.retaildata.center/api/v1'
  : `${window.location.origin}/api/v1`;

export const apiPathV2 = devMode
  ? 'https://dev.retaildata.center/api/v2'
  : `${window.location.origin}/api/v2`;

export const apiPathBase = devMode
  ? 'https://dev.retaildata.center'
  : `${window.location.origin}`;
