import React from 'react';
import classNames from 'classnames';

import { useIsFullscreen } from '../../useIsFullscreen';

import styles from './FullscreenButton.module.scss';
import { Tooltip } from '../../../Tooltip/Tooltip';

import UseAnimations from 'react-useanimations';
import maximizeMinimize2 from 'react-useanimations/lib/maximizeMinimize2';
import { useBreakpoint } from '../../../../../uikit/hooks/useBreakpoint';

export const FullscreenButton = () => {
  const [isFullscreen, setIsFullscreen] = useIsFullscreen();

  const breakpoints = useBreakpoint();

  if (breakpoints.isSmall) {
    return null;
  }

  return (
    <Tooltip
      testId={'fullscreen-button'}
      text={!isFullscreen ? 'Развернуть на весь экран' : 'Свернуть'}
    >
      <div
        className={classNames(styles.button, {
          [styles.isFullscreen]: isFullscreen,
        })}
        onClick={() => {
          setIsFullscreen(!isFullscreen);
        }}
        data-is-fullscreen={isFullscreen}
        data-test-id="fullscreen-button"
      >
        <UseAnimations
          animation={maximizeMinimize2}
          size={24}
          key={`${isFullscreen}`}
          reverse={isFullscreen}
          autoplay={isFullscreen}
          wrapperStyle={{
            marginTop: -3,
          }}
        />
      </div>
    </Tooltip>
  );
};
