import { isSmall } from '../../uikit/hooks/useBreakpoint';
import {
  DEFAULT_CATEGORY_AXIS,
  DEFAULT_CONFIG,
  DEFAULT_GRID,
  DEFAULT_ZOOM,
  MOBILE_GRID,
} from '../charts';
import { CHART_COLORS, OPERATION_TYPES_COLORS } from '../constants';
import { formatNumericNames } from '../utils/formatNumericNames';

type TItem = {
  name: string;
  value: number;
};

export const getBarConfig = (
  data: TItem[],
  subValuesKeys?: string[],
  tooltipFormatter?: (item: any) => string,
) => {
  const small = isSmall();
  const series = subValuesKeys?.length
    ? subValuesKeys.map((key, index) => ({
        id: `salesCnt_${key}`,
        data: data.map((item: any) => item.subValues[index]),
        type: 'bar',
        stack: 'salesCnt',
        name: key,
        yAxisIndex: 0,
        itemStyle: {
          color: OPERATION_TYPES_COLORS[index],
        },
        tooltip: {
          trigger: 'item',
          formatter: tooltipFormatter,
          valueFormatter: function (value: number) {
            return formatNumericNames(value);
          },
        },
      }))
    : [
        {
          id: 'salesCnt',
          data,
          type: 'bar',
          name: 'Кол-во продаж',
          yAxisIndex: 0,
          itemStyle: {
            color: CHART_COLORS[0],
          },
          tooltip: {
            trigger: 'item',
            formatter: tooltipFormatter,
            valueFormatter: function (value: number) {
              return formatNumericNames(value);
            },
          },
        },
      ];
  return {
    ...DEFAULT_CONFIG,
    grid: small
      ? MOBILE_GRID
      : {
          ...DEFAULT_GRID,
          top: 90,
        },
    xAxis: {
      ...DEFAULT_CATEGORY_AXIS,
      data: data.map((item) => item.name),
    },
    yAxis: [
      {
        id: 'salesCnt',
        name: 'Кол-во продаж',
        type: 'value',
        axisLabel: {
          formatter: function (value: number) {
            return formatNumericNames(value);
          },
        },
      },
    ],
    dataZoom: [
      {
        ...DEFAULT_ZOOM,
        end: 3000 / data.length,
      },
      {
        ...DEFAULT_ZOOM,
        type: 'slider',
        end: 3000 / data.length,
      },
    ],
    series,
  };
};
