import { DS1R5Response } from '../../../../../api/reports/Api';
import { EXACT_NUMERIC_FORMATTER } from '../../../../../shared/utils/formatters';
import { sorter } from '../../../../common/Table/sorter';
import { periodFilter } from '../AllPurposeReport/filters/period';
import { getSelectFilter } from '../AllPurposeReport/filters/select';
import { TReportConfigType } from '../AllPurposeReport/reportConfigType';
import { getBarChartFeature } from '../AllPurposeReport/viewFeatures/barChart';
import { getTableFeature } from '../AllPurposeReport/viewFeatures/table';
import { getTreeMapFeature } from '../AllPurposeReport/viewFeatures/treeMap';

const NAME = 'Продавцы для Покупателя';

export const ds1o5Config: TReportConfigType<DS1R5Response> = {
  title: NAME,
  backendKey: 'ds1r5',

  dataFeatures: [],

  viewFeatures: [
    getTableFeature<DS1R5Response>({
      defaultSort: 'salesCnt',
      filename: NAME,
      baseColumns: [
        {
          key: 'sellerInn',
          title: 'ИНН продавца',
          sort: sorter('sellerInn'),
        },
        {
          key: 'sellerName',
          title: 'Наименование продавца',
          sort: sorter('sellerName'),
        },
        {
          key: 'salesCnt',
          title: 'Объем продаж, шт.',
          isNumeric: true,
          withBar: true,
          sort: sorter('salesCnt', true),
          render: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
          renderForExport: (row) => {
            return EXACT_NUMERIC_FORMATTER(row.salesCnt);
          },
        },
      ],
    }),
    getBarChartFeature<DS1R5Response>({
      nameKey: 'sellerName',
      valueKey: 'salesCnt',
      innKey: 'sellerInn',
      filename: NAME,
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
      },
    }),
    getBarChartFeature<DS1R5Response>({
      nameKey: 'sellerName',
      valueKey: 'salesCnt',
      innKey: 'sellerInn',
      withPercents: true,
      accumulateBy: 'sharePercent',
      icon: 'barChartWithLine',
      filename: NAME,
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
      },
    }),
    getTreeMapFeature<DS1R5Response>({
      topName: NAME,
      parentNameKey: 'sellerInn',
      childrenNameKey: 'sellerName',
      innKey: 'sellerInn',
      valueKey: 'salesCnt',
      filename: NAME,
      groupKeyToNameMap: {
        sellerInn: 'sellerName',
      },
    }),
  ],

  filters: [
    periodFilter,
    getSelectFilter({
      shouldUseNetwork: true,
      shouldUseDictionary: false,
      required: true,
      single: true,
      commonDataKey: 'buyers',
      label: 'Покупатели',
    }),
    getSelectFilter({
      shouldUseNetwork: false,
      shouldUseDictionary: true,
      label: 'GTIN',
      icon: 'package',
      commonDataKey: 'gtins',
      mode: 'gtins',
    }),
  ],
  requiredData: {
    availablePeriod: true,
    gtinDictionaries: true,
  },
  requiredCommonData: ['buyers', 'buyerNetworks'],

  isRequiredFieldsFilled: (config) =>
    Boolean(config.inn) || Boolean(config.netName),
};
