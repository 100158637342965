import dayjs from 'dayjs';
import { MONTHS_SHORT_GENITIVE } from '../months';

const currentYear = dayjs().year();

export const getDateRangeTags = (value: any) => {
  if (!Array.isArray(value)) {
    return [];
  }
  const [from, to] = value;
  const dayJsFrom = dayjs(from);
  const dayJsTo = dayjs(to);
  const year = dayJsFrom.year();
  if (dayJsFrom.isSame(dayJsTo, 'day')) {
    const monthName = MONTHS_SHORT_GENITIVE[dayJsFrom.month()].toLowerCase();
    return [
      `${dayJsFrom.date()} ${monthName}${
        year !== currentYear ? ` ${year}` : ''
      }`,
    ];
  }
  if (dayJsFrom.isSame(dayJsTo, 'month')) {
    const monthName = MONTHS_SHORT_GENITIVE[dayJsFrom.month()].toLowerCase();
    return [
      `${dayjs(from).format('D')}-${dayjs(to).format('D')} ${monthName}${
        year !== currentYear ? ` ${year}` : ''
      }`,
    ];
  }
  const fromYear = dayJsFrom.year();
  const toYear = dayJsTo.year();
  const isDifferentYear = fromYear !== toYear;
  const format = isDifferentYear || year !== currentYear ? 'DD.MM.YY' : 'DD.MM';
  return [`${dayJsFrom.format(format)} - ${dayJsTo.format(format)}`];
};
