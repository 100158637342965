const regex = RegExp(/ds(\d+)r(\d+)/);

export const parseReportKey = (
  reportKey: string,
): { datasetId: number; reportId: number } => {
  const match = regex.exec(reportKey);
  const datasetId = match ? match[1] : null;
  const reportId = match ? match[2] : null;

  if (!datasetId || !reportId) {
    throw new Error(`Invalid report key: ${reportKey}`);
  }

  const numberDatasetId = Number(datasetId);
  const numberReportId = Number(reportId);

  if (Number.isNaN(numberDatasetId) || Number.isNaN(numberReportId)) {
    throw new Error(`Invalid report key: ${reportKey}`);
  }

  return {
    datasetId: numberDatasetId,
    reportId: numberReportId,
  };
};
