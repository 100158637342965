import { useState } from 'react';

export const useNow = (): number => {
  const [now, setNow] = useState(Date.now());

  setTimeout(() => {
    setNow(Date.now());
  }, 1000);

  return now;
};
