import { useQuery } from '@tanstack/react-query';
import { getDictionaries } from '../utils/getDictionaries';

export const useAllDictionariesNames = () => {
  const queryData = useQuery(
    ['allDictionariesNames'],
    async () => {
      const dicts = await getDictionaries();
      return dicts.map((dict) => dict.name.toLowerCase());
    },
    {
      placeholderData: [],
    },
  );
  return queryData.data;
};
